export default (client, defaultParams) => ({
  getUsersByStatus: (status, country) => (
    client(`/admin/grin4u/users/find?country=${country}&status=${status}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  findUsers: (q, country) => (
    client(`/admin/grin4u/users/find?country=${country}&q=${encodeURIComponent(q)}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  createSubscription: (payload) => (
    client('/admin/subscription', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  getUserSubscriptions: (id) => (
    client(`/admin/subscriptions/user/${id}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  getSubscription: (id) => (
    client(`/admin/subscription/${id}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  setSubscriptionStatus: (id, status) => (
    client(`/admin/subscription/${id}/status/${status}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  update: (id, payload) => (
    client(`/admin/subscription/${id}/update`, {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
});
