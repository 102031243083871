import apiClient from '../../api';

export const login = async (payload) => {
  try {
    const { data } = await apiClient.users.login(payload);
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.users.getMessage(e.response.data.error),
        };
      }
    }
    if (e.response && e.response.status === 401) {
      return {
        status: false,
        msg: apiClient.users.getMessage('no_login'),
      };
    }
  }
  return null;
};

export const forgetPassword = async (payload) => {
  try {
    const { data } = await apiClient.users.forgetPassword(payload);
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.users.getMessage(e.response.data.error),
        };
      }
    }
  }
  return null;
};

export default {
  login,
  forgetPassword,
};
