import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

const DeltaPercentageBadge = ({
  n,
  format,
}) => (
  <div style={{ display: 'block', color: n >= 0 ? '#090' : '#c30' }}>
    <FontAwesomeIcon icon={n >= 0 ? faArrowUp : faArrowDown} color={n >= 0 ? '#090' : '#c30'} />
    {numeral(Math.abs(n)).format(format)}
    %
  </div>
);

DeltaPercentageBadge.defaultProps = {
  format: '0,000',
};

DeltaPercentageBadge.propTypes = {
  n: PropTypes.number.isRequired,
  format: PropTypes.string,
};

export default DeltaPercentageBadge;
