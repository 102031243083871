/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isMobile from 'is-mobile';

import {
  Tabs,
  Button,
  Select,
} from 'antd';
import { getScooterMarker } from '../../components/MapComponent';
import Loading from '../../components/Loading';
import AlertsTable from '../../components/AlertsTable';

import {
  getAlerts,
  setAlertStatus,
} from '../../services/incidents';

import {
  sendDeviceCommand,
  getCommandForAction,
} from '../../services/devices';
import useQuery from '../../hooks/useQuery';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';
import AlertItem from '../../components/AlertItem';

const { Option } = Select;

const { TabPane } = Tabs;

const IncidentsModule = ({
  tenant,
  user,
  // eslint-disable-next-line no-unused-vars
  fleets,
  fleetId,
}) => {
  const qParams = useQuery();
  const viewIncidentId = qParams.get('view') || null;
  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState({});
  const [deviceStatusAfterSolve, setDeviceStatusAfterSolve] = useState('live');
  const [newAlertsByHour, setNewAlertsByHour] = useState({});

  const setAlertsInStatus = (status, newAlerts) => {
    setAlerts((s) => ({
      ...s,
      [status]: newAlerts,
    }));
  };
  const loadAlerts = async (status) => {
    const rsp = await getAlerts({
      status: status.toLowerCase(),
      fleetId,
    });
    if (rsp && rsp.status) {
      setAlertsInStatus(status.toLowerCase(), rsp.data);
      if (status.toUpperCase() === 'NEW') {
        const g = isMobile() ? 2 : 1;
        const byHours = rsp.data.reduce((a, x) => {
          let hours = moment.utc().diff(moment.utc(x.created_at), 'hours');
          hours = Math.floor(hours / g) * g;
          if (hours >= 24) {
            hours = 24;
          }
          return {
            ...a,
            [hours]: (a[hours] || 0) + 1,
          };
        }, {});
        setNewAlertsByHour(byHours);
      }
    }
    setLoading(false);
  };

  const loadAllAlerts = () => {
    loadAlerts('NEW');
    loadAlerts('SOLVED');
  };

  const onDeviceAction = async (action, type, ref, params = null) => {
    const command = getCommandForAction(action, params);
    if (!command) {
      return null;
    }
    const rsp = await sendDeviceCommand({
      type,
      ref,
      command,
      sync: true,
    });
    if (rsp && rsp.status) {
      loadAllAlerts();
    }
    return null;
  };
  const onChangeAlertStatus = async (id, newStatus, deviceStatus = null) => {
    const rsp = await setAlertStatus({
      id,
      deviceStatusAfterSolve: deviceStatus,
      status: newStatus,
    });
    if (rsp && rsp.status) {
      loadAllAlerts();
    }
    return null;
  };
  const solveAllNew = async () => {
    alerts?.new?.map((x) => onChangeAlertStatus(x.id, 'SOLVED', deviceStatusAfterSolve));
  };

  useEffect(() => {
    setLoading(true);
    loadAllAlerts();
    const pidSync = window.setInterval(() => {
      loadAllAlerts();
    }, 5000);

    return () => {
      if (pidSync) {
        window.clearInterval(pidSync);
      }
    };
  }, [fleetId]);

  if (loading || !tenant) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }
  const g = isMobile() ? 2 : 1;
  const colsByHour = 24 / g + 2;
  // const stepsStore = getStepsStore(store);
  // const pendingStepsStore = (stepsStore.filter((x) => !x.completed));
  let viewingIncident = null;
  if (viewIncidentId) {
    viewingIncident = alerts?.new?.find(
      (x) => x.id === parseInt(viewIncidentId, 10),
    ) || alerts?.solved?.find((x) => x.id === viewIncidentId);
  }
  console.log('viewingIncident', viewingIncident);
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Incidentes
      </div>
      <div className={stylesLayout.content}>
        <div>
          <div className={styles.hoursTimeline}>
            <div className={styles.line} style={{ gridTemplateColumns: `repeat(${colsByHour}, 1fr)` }}>
              <div className={[styles.titleLine].join(' ')}>Horas</div>
              {[...Array(colsByHour - 1).keys()].map((h) => (
                <div className={[styles.hour, newAlertsByHour[h] ? styles.hasValue : ''].join(' ')} key={h}>
                  {h * g >= 24 ? `>${h * g}` : h * g}
                </div>
              ))}
            </div>
            <div className={styles.line} style={{ gridTemplateColumns: `repeat(${colsByHour}, 1fr)` }}>
              <div className={[styles.titleLine].join(' ')}>Incidentes</div>
              {[...Array(colsByHour - 1).keys()].map((h) => (
                <div className={[styles.item, newAlertsByHour[h * g] ? styles.hasValue : ''].join(' ')} key={h}>
                  {newAlertsByHour[h * g] || 0}
                </div>
              ))}
            </div>
          </div>
        </div>
        {viewingIncident && (
          <div style={{
            background: '#faf4e8',
            border: '1px solid #edd',
            borderRadius: 5,
            padding: '0 var(--spacing_half)',
            marginBottom: 'var(--spacing)',
          }}
          >
            <AlertItem
              alert={viewingIncident}
              user={user}
              onAction={onDeviceAction}
              onChangeAlertStatus={
                (id, status) => onChangeAlertStatus(id, status, deviceStatusAfterSolve)
              }
            />
          </div>
        )}
        <Tabs defaultActiveKey="new">
          <TabPane
            tab={(
              <>
                {alerts?.new?.length || 0}
                {' '}
                sin asignar
              </>
            )}
            key="new"
          >
            <>
              <div className={styles.tools} style={{ textAlign: 'right' }}>
                {user.can.admin_incidents
                  && (
                    <>
                      <span style={{ marginRight: 10 }}>Dejar los patines en status:</span>
                      <Select
                        allowClear
                        showSearch={false}
                        style={{ marginRight: 10, width: 200, textAlign: 'left' }}
                        placeholder="Seleccionar..."
                        value={deviceStatusAfterSolve}
                        onChange={(v) => setDeviceStatusAfterSolve(v)}
                      >
                        <Option key="live">
                          <img src={getScooterMarker('live')} alt="" style={{ width: 25, marginRight: 11 }} />
                          Desplegado
                        </Option>
                        <Option key="disabled">
                          <img src={getScooterMarker('disabled')} alt="" style={{ width: 25, marginRight: 11 }} />
                          Deshabilitado
                        </Option>
                        <Option key="in_ride">
                          <img src={getScooterMarker('in_ride')} alt="" style={{ width: 25, marginRight: 11 }} />
                          En viaje
                        </Option>

                      </Select>
                      <Button type="secondary" size="medium" onClick={() => solveAllNew()}>
                        Resolver todos
                      </Button>
                    </>
                  )}
              </div>

              {alerts?.new?.length > 0
                ? (
                  <AlertsTable
                    user={user}
                    alerts={alerts && alerts?.new}
                    onAction={onDeviceAction}
                    onChangeAlertStatus={
                      (id, status) => onChangeAlertStatus(id, status, deviceStatusAfterSolve)
                    }
                  />
                )
                : <div className={stylesLayout.message}> No hay incidentes</div>}
            </>

          </TabPane>
          <TabPane tab={`${alerts?.solved?.length || 0} solucionado${alerts?.solved?.length > 1 ? 's' : ''} recientemente`} key="solved">
            <>
              {alerts?.solved?.length > 0
                ? (
                  <AlertsTable
                    user={user}
                    alerts={alerts && alerts?.solved}
                    onAction={onDeviceAction}
                    onChangeAlertStatus={
                      (id, status) => onChangeAlertStatus(id, status, deviceStatusAfterSolve)
                    }
                  />
                )
                : <div className={stylesLayout.message}>No hay incidentes</div>}
            </>

          </TabPane>
        </Tabs>

      </div>
    </div>
  );
};

IncidentsModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};

export default IncidentsModule;
