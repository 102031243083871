import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';

import authReducer from './modules/Auth/reducer';
import defaultLayoutReducer from './layouts/DefaultLayout/reducer';

const rootReducer = combineReducers({
  routing,
  layout: defaultLayoutReducer,
  auth: authReducer,
});

export default rootReducer;
