/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import '../../helpers/map';
import React, {
  // eslint-disable-next-line no-unused-vars
  useEffect, useState, useRef, useMemo,
} from 'react';
import isMobile from 'is-mobile';
import PropTypes from 'prop-types';
import {
  Select, Input, Button, notification, Slider, Dropdown,
  Menu, Checkbox,
} from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faTimes,
  faCheck,
  faSyncAlt,
  faShuffle,
  faLocationArrow,
} from '@fortawesome/free-solid-svg-icons';
import { GOOGLE_MAPS_API_KEY } from '../../environment';
import MobileScooterInfo from '../../components/Devices/MobileScooterInfo';
import Loading from '../../components/Loading';
import DeviceList from '../../components/DeviceList';

import useQuery from '../../hooks/useQuery';

import RowByStatus from '../../components/RowByStatus';
import { MapComponent, getScooterMarker } from '../../components/MapComponent2';

import {
  findDevices,
  getDeviceByTypeQROrRef,
  sendDeviceCommand,
  getCommandForAction,
}
  from '../../services/devices';

import {
  getUsersOfTenantWithRoles,
} from '../../services/tenants';

import {
  getGeofencesAndPointsForFleet,
} from '../../services/geofences';

import {
  colorZone,
} from '../../helpers/params';

import {
  distance,
} from '../../helpers/utils';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';
import TaskHandleModule from '../TaskHandleModule';

const { Option } = Select;

const NewMonitorModule = ({
  // eslint-disable-next-line no-unused-vars
  position,
  fleets,
  user,
  fleetId,
}) => {
  const queryParams = useQuery();
  const queryParamQ = queryParams.get('q');
  const defaultFilterStatus = ['stolen_suspect', 'in_ride', 'missing_connection', 'out_of_area', 'disabled', 'stolen'];
  const defaultFilterStatusLive = [...defaultFilterStatus, 'live'];
  let lastUsedStatusFilters = null;
  try {
    lastUsedStatusFilters = localStorage.getItem('__monitor__last_status_filters') ? JSON.parse(localStorage.getItem('__monitor__last_status_filters')) : defaultFilterStatus;
  } catch (e) {
    lastUsedStatusFilters = defaultFilterStatus;
  }

  const [showTraffic, setShowTraffic] = useState(localStorage.getItem('__monitor__showTraffic') ? !!+localStorage.getItem('__monitor__showTraffic') : false);
  const [showScooterInfo, setShowScooterInfo] = useState(localStorage.getItem('__monitor__showScooterInfo') ? !!+localStorage.getItem('__monitor__showScooterInfo') : true);
  const [showDeployPoints, setShowDeployPoints] = useState(localStorage.getItem('__monitor__showDeployPoints') ? !!+localStorage.getItem('__monitor__showDeployPoints') : true);
  const [nLoad, setNLoad] = useState(0);
  const [periodUpdate, setPeriodUpdate] = useState(60000);
  const [deviceUpdating, setDeviceUpdating] = useState(null);
  const [mapPosition, setMapPosition] = useState(null);
  const [mapMoving, setMapMoving] = useState(false);
  const [clusterGridSize, setClusterGridSize] = useState(40);
  const [devices, setDevices] = useState([]);
  const [tenantUsers, setTenantUsers] = useState([]);
  const [loadDevicePid, setLoadDevicePid] = useState(null);
  const loadDevicePidRef = useRef(loadDevicePid);
  loadDevicePidRef.current = loadDevicePid;

  const [geofences, setGeofences] = useState(null);
  const [points, setPoints] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [filterRecentLocation, setFilterRecentLocation] = useState('all');
  const [filterStatus, setFilterStatus] = useState(lastUsedStatusFilters);
  const filterStatusRef = useRef(filterStatus);
  filterStatusRef.current = filterStatus;
  const [filterBattery, setFilterBattery] = useState([0, 100]);
  const [filterLastReport, setFilterLastReport] = useState([0, 120]);
  const [filterQ, setFilterQ] = useState(queryParamQ || '');
  const [loading, setLoading] = useState(false);
  const [selectedMode, setSelectedMode] = useState('single');
  const [multipleSelectedDevices, setMultipleSelectedDevices] = useState([]);
  const [showFilters, setShowFilters] = useState(filterQ !== '');
  const [showList, setShowList] = useState(false);

  const selectedFleet = fleets.find((x) => x.id === fleetId);

  const loadGeofences = async () => {
    const rsp = await getGeofencesAndPointsForFleet([fleetId], ['ACTIVE']);
    if (rsp && rsp.status) {
      setGeofences(rsp.data.geofences.filter((x) => !['INACTIVE', 'TRIP_MICROZONE'].includes(x.type)));
      setPoints(rsp.data.points);
    } else {
      setGeofences([]);
    }
  };

  const getTenantUsers = async () => {
    if (isMobile() && !user.can?.admin_incidents) { return; }
    const rsp = await getUsersOfTenantWithRoles(['transportist', 'motorist', 'warehouse_operator']);
    if (rsp?.status) {
      const mapUsers = rsp.data.filter((x) => x.data?.position?.lat
        && x.data?.position?.created_at
        && moment.utc(x.data?.position?.created_at).format('YYYY-MM-DDTHH:mm:ss') > moment.utc().subtract(1, 'hour').format('YYYY-MM-DDTHH:mm:ss'));
      setTenantUsers(mapUsers);
    }
  };
  const loadDevices = async (pos = null) => {
    getTenantUsers();
    if (loadDevicePidRef?.current) {
      window.clearTimeout(loadDevicePidRef.current);
    }
    setLoading(true);
    // loadInternalTransport(pos);
    let getStatus = filterStatusRef?.current.length > 0 ? filterStatusRef?.current : null;
    if (getStatus && (getStatus.includes('pickup_street_day_25') || getStatus.includes('pickup_street_night_40') || getStatus.includes('pickup_street_night_60'))) {
      getStatus = ['live', 'disabled', 'missing_connection'];
    }
    const rsp = await findDevices({
      q: null,
      fleetId,
      status: getStatus,
      position: pos,
      output: 'map',
    });
    let period = 60000;
    if (rsp && rsp.status) {
      const d = rsp.data.map((x) => ({
        ...x,
        lat: x.lat + (0.0000 * (Math.random() - 0.5)),
        lng: x.lng + (0.0000 * (Math.random() - 0.5)),
      }));
      setDevices(d);
      if (d?.filter((x) => x.status === 'stolen_suspect').length > 0) {
        period = 20000;
      }
    } else {
      setDevices([]);
    }
    setLoading(false);
    setNLoad((s) => s + 1);

    setPeriodUpdate(period);
    const pid = window.setTimeout(() => loadDevices(pos), period);
    setLoadDevicePid(pid);
  };

  const onCloseResume = () => {
    setSelectedDevice(null);
  };

  const removeDeviceFromMultiple = (id) => {
    setMultipleSelectedDevices(multipleSelectedDevices.filter((x) => x.id !== id));
  };

  const onSelectMultipleDevice = (marker) => {
    if (
      (multipleSelectedDevices.map((x) => x.id)).includes(marker.id)
    ) {
      return removeDeviceFromMultiple(marker.id);
    }
    setMultipleSelectedDevices([...multipleSelectedDevices, marker]);
    return null;
  };

  const onSelectSingleDevice = async (marker) => {
    setSelectedDevice(null);
    setDeviceUpdating(true);
    const rsp = await getDeviceByTypeQROrRef({
      type: marker.type,
      code: marker.ref,
    });
    if (rsp && rsp.status) {
      setSelectedDevice(rsp.data);
    }
    setDeviceUpdating(false);
  };

  const onSelectDevice = async (marker) => {
    if (selectedMode === 'single') {
      await onSelectSingleDevice(marker);
    }
    if (selectedMode === 'multiple') {
      await onSelectMultipleDevice(marker);
    }
  };

  const onDeviceAction = async (action, type, ref, params = null) => {
    setDeviceUpdating(true);
    const command = getCommandForAction(action, params);
    if (!command) {
      return null;
    }
    const rsp = await sendDeviceCommand({
      type,
      ref,
      command,
      sync: true,
    });
    setDeviceUpdating(false);
    if ((rsp && rsp.status)) {
      notification.success({
        message: 'Comando enviado con éxito.',
        placement: 'bottom',
      });
    } else {
      notification.error({
        message: 'Ocurrió un error al enviar el comando. Intenta nuevamente.',
        placement: 'bottom',
      });
    }
    return null;
  };

  const onStatusFilterChange = (value) => {
    if (value.includes('pickup_street_day_25')) {
      if (value.includes('stolen_suspect')) {
        setFilterStatus(['pickup_street_day_25', 'stolen_suspect']);
      } else {
        setFilterStatus(['pickup_street_day_25']);
      }
      setFilterBattery([0, 25]);
    } else if (value.includes('pickup_street_night_40')) {
      if (value.includes('stolen_suspect')) {
        setFilterStatus(['pickup_street_night_40', 'stolen_suspect']);
      } else {
        setFilterStatus(['pickup_street_night_40']);
      }
      setFilterBattery([0, 40]);
    } else if (value.includes('pickup_street_night_60')) {
      if (value.includes('stolen_suspect')) {
        setFilterStatus(['pickup_street_night_60', 'stolen_suspect']);
      } else {
        setFilterStatus(['pickup_street_night_60']);
      }
      setFilterBattery([0, 60]);
    } else {
      setFilterStatus(value);
    }
  };
  const onBatteryFilterChange = (value) => {
    setFilterBattery(value);
  };
  const onLastReportFilterChange = (value) => {
    setFilterLastReport(value);
  };

  const filterDevicesByUpdated = (devs) => devs.filter((d) => {
    if (d.status === 'stolen') {
      return moment(d.geo_updated_at) > moment().subtract(3, 'hour');
    }
    return moment(d.geo_updated_at) > moment().subtract(20, 'day');
  });
  const filterDevices = (devs) => {
    let filteredDevices = devs.map((d) => ({
      ...d, id: d.id, latitude: d.lat, longitude: d.lng,
    }));
    filteredDevices = filterDevicesByUpdated(filteredDevices);
    if (filterQ) {
      const filterQArray = filterQ.split(/,| /).filter((x) => !!x).map((x) => x.toUpperCase());
      filteredDevices = filteredDevices.filter(
        (d) => (filterQArray.includes(d.qr) || filterQArray.includes(d.ref)),
      );
    }
    if (filterLastReport) {
      filteredDevices = filteredDevices.filter(
        (d) => d.data.mins_since_last_connection > (filterLastReport[0] * 60)
          && d.data.mins_since_last_connection < (filterLastReport[1] * 60),
      );
    }
    if (filterBattery) {
      filteredDevices = filteredDevices.filter(
        (d) => ['stolen_suspect', 'missing_connection', 'disabled'].includes(d.status) || (d.data.battery >= filterBattery[0] && d.data.battery <= filterBattery[1]),
      );
    }
    const splitRecentLocation = moment.utc().subtract(20, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
    switch (filterRecentLocation) {
      case 'old':
        filteredDevices = filteredDevices.filter(
          (d) => d.geo_updated_at < splitRecentLocation,
        );
        break;
      case 'recent':
        filteredDevices = filteredDevices.filter(
          (d) => d.geo_updated_at >= splitRecentLocation,
        );
        break;
      default:
        break;
    }

    if (filterStatus && filterStatus.length > 0) {
      filteredDevices = filteredDevices.filter(
        (d) => {
          if (d.type === 'internal_transport') {
            return true;
          }
          const bat = parseInt(d.data?.battery, 10);

          if (filterStatus === '') {
            return d.status === '';
          }
          if (filterStatus.includes('with_error')) {
            if (d.data.coderr > 0) {
              return true;
            }
          }
          if (filterStatus.includes('pickup_street_day_25')) {
            if (['stolen_suspect', 'missing_connection', 'disabled'].includes(d.status)) {
              return true;
            }
            if (['live'].includes(d.status) && bat < filterBattery[1]) {
              return true;
            }
            return false;
          }
          if (filterStatus.includes('pickup_street_night_40')) {
            if (['stolen_suspect', 'missing_connection', 'disabled'].includes(d.status)) {
              return true;
            }
            if (['live'].includes(d.status) && bat < filterBattery[1]) {
              return true;
            }
            return false;
          }
          if (filterStatus.includes('pickup_street_night_60')) {
            if (['stolen_suspect', 'missing_connection', 'disabled'].includes(d.status)) {
              return true;
            }
            if (['live'].includes(d.status) && bat < filterBattery[1]) {
              return true;
            }
            return false;
          }

          if (filterStatus.includes('out_of_area')) {
            if (d.out_of_area) {
              if (d.out_of_area) {
                return true;
              }
            }
          }

          return d.status !== '' && filterStatus.includes(d.status);
        },
      );
    }
    return filteredDevices;
  };
  const filterInGeofence = (ds, geofence) => {
    const polygonGeofence = new window.google.maps.Polygon({
      paths: geofence.points.map((x) => ({ lat: x.latitude, lng: x.longitude })),
    });
    const rsp = ds.filter((d) => (
      polygonGeofence.containsLatLng(d.lat, d.lng)
    ));
    return rsp;
  };
  const filterInOverlay = (ds, overlay) => {
    const rsp = ds.filter((d) => (
      overlay.getBounds().contains({ lat: d.lat, lng: d.lng })
    ));
    return rsp;
  };
  const onRightClickGeofence = (geofence) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`¿Seleccionar todos los dispositivos visibles en esta zona (${geofence.name})?`)) {
      const filteredDevices = filterInGeofence(filterDevices(devices), geofence);
      setSelectedMode('multiple');
      setMultipleSelectedDevices(filteredDevices);
    }
  };
  const onSelectDevicesInOverlay = (type, overlay) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('¿Seleccionar todos los dispositivos visibles en este sector?')) {
      const filteredDevices = filterInOverlay(filterDevices(devices), overlay);
      setSelectedMode('multiple');
      setMultipleSelectedDevices(filteredDevices);
    }
  };

  const sparseDevices = () => {
    const newDevices = devices?.map((x) => ({
      ...x,
      lat: x.lat + ((Math.random() - 0.5) * 0.0007),
      lng: x.lng + ((Math.random() - 0.5) * 0.0007),
    }
    ));
    setDevices(newDevices);
  };

  useEffect(async () => {
    localStorage.setItem('__monitor__showTraffic', showTraffic ? 1 : 0);
  }, [showTraffic]);

  useEffect(async () => {
    localStorage.setItem('__monitor__showScooterInfo', showScooterInfo ? 1 : 0);
  }, [showScooterInfo]);
  useEffect(async () => {
    localStorage.setItem('__monitor__showDeployPoints', showDeployPoints ? 1 : 0);
  }, [showDeployPoints]);

  useEffect(async () => {
    localStorage.setItem('__monitor__last_status_filters', JSON.stringify(filterStatus));
  }, [filterStatus]);

  useEffect(() => (() => {
    document.title = `Paperboy${process.env.NODE_ENV !== 'production' ? ' - LOCAL' : ''}`;
    if (loadDevicePidRef?.current) {
      window.clearTimeout(loadDevicePidRef.current);
    }
  }), []);
  useEffect(() => {
    if (!queryParamQ) {
      return;
    }
    setFilterQ(queryParamQ);
    setShowFilters(true);
  }, [queryParamQ]);

  useEffect(() => {
    if (isMobile()) { return; }
    const centerLocation = {
      latitude: selectedFleet.cost.headquarter.lat,
      longitude: selectedFleet.cost.headquarter.lng,
      lat: selectedFleet.cost.headquarter.lat,
      lng: selectedFleet.cost.headquarter.lng,
    };

    setMapPosition(centerLocation);
    loadDevices(centerLocation);
    loadGeofences();
  }, [fleetId, filterStatus]);

  useEffect(() => {
    if (mapMoving) {
      return;
    }
    if (mapPosition) { return; }
    if (!isMobile()) { return; }
    if (!position.latitude) { return; }
    const dist = mapPosition?.latitude
      ? (distance(position.latitude, position.longitude,
        mapPosition.latitude, mapPosition.longitude) * 1000)
      : 100000;

    /*
    if (false && user.id === 1) {
      notification.success({
        message:
        `Posicion:${position.latitude}, ${position.longitude} (prev: ${mapPosition?.latitude},
          ${mapPosition?.longitude}), dist: ${dist}`,
        placement: 'bottom',
        duration: 1,
      });
    }
    */
    if (dist < 50) {
      return;
    }
    const centerLocation = isMobile()
      ? {
        latitude: position.latitude,
        longitude: position.longitude,
        lat: position.latitude,
        lng: position.longitude,
      }
      : {
        latitude: selectedFleet.cost.headquarter.lat,
        longitude: selectedFleet.cost.headquarter.lng,
        lat: selectedFleet.cost.headquarter.lat,
        lng: selectedFleet.cost.headquarter.lng,
      };
    if (!mapPosition) {
      setMapPosition(centerLocation);
      loadDevices(centerLocation);
      loadGeofences();
    }
  }, [fleetId, filterStatus, position?.latitude, position?.longitude]);

  const visibleDevices = filterDevices(devices);
  // const onlyOneDevice = visibleDevices && visibleDevices.length === 1;

  const menuBarHeight = document.getElementById('menubar') && document.getElementById('menubar').clientHeight;
  const monitorResultsHeight = document.getElementById('monitorResults') && document.getElementById('monitorResults').clientHeight;

  const getDeviceComponent = (d) => {
    switch (d.type) {
      case 'scooter':
      default:
        return (
          <MobileScooterInfo
            fleets={fleets}
            buttonCols={2}
            user={user}
            position={position}
            onClose={onCloseResume}
            defaultDevice={selectedDevice}
            openActions
            onAction={onDeviceAction}
            updating={deviceUpdating}
          />
        );
    }
  };

  const showSidebar = (selectedMode === 'multiple' && multipleSelectedDevices?.length > 0) || (selectedDevice || deviceUpdating) || showList;
  /* // eslint-disable-next-line no-unused-vars
  const renderedMapComponent = useMemo(() => {
    return (
      <MapComponent
        key={fleetId}
        apiKey={GOOGLE_MAPS_API_KEY}
        onMapMoving={(s) => { setMapMoving(s); }}
        currentLocation={position}
        defaultZoom={isMobile() ? 16 : 15}
        defaultCenter={{ lat: mapPosition?.lat, lng: mapPosition?.lng }}
        clusterGridSize={clusterGridSize}
        onCenterChange={(center) => {
          setMapPosition(center); }}
        minZoom={isMobile() ? 16 : 13}
        maxZoom={18}
        containerStyle={{
          height: '100%',
        }}
        markers={showDevices && [...visibleDevices, ...internalTransports]}
        usersMarkers={!isMobile() && tenantUsers.map((x) => ({
          lat: x.data?.position?.lat,
          lng: x.data?.position?.lng,
          id: x.email,
          label: (
            <div style={{ textAlign: 'center' }}>
              <strong>
                {x.name}
              </strong>
              <br />
              Act:
              {' '}
              {moment.utc(x.data?.position?.created_at).fromNow()}
              <br />
              {moment(x.data?.position?.created_at).format('YYYY-MM-DDTHH:mm:ss')}
            </div>),
        })).filter((x) => x.lat && x.lng)}
        selectedMarkers={selectedMode === 'multiple' ?
        multipleSelectedDevices.map((x) => x.id) : []}
        onSelectMarker={onSelectDevice}
        onPolygonRightClick={(polygon) => onRightClickGeofence(polygon)}
        onOverlayComplete={(type, overlay) => onSelectDevicesInOverlay(type, overlay)}
        drawingControlEnabled={!isMobile()}
        polygons={geofences
          && geofences.map(
            (x) => ({ ...x, color: x.status === 'INACTIVE' ? '#999999' : (colorZone[x.type]) }),
          )}
      />
    );
  }, [visibleDevices, fleetId,
    internalTransports, geofences, mapPosition?.lng, mapPosition?.lat, selectedDevice,
    deviceUpdating, multipleSelectedDevices, window.innerHeight, monitorResultsHeight]); */

  if (!mapPosition) {
    return null;
  }
  const stolenSuspectDevices = visibleDevices ? visibleDevices.filter((x) => x.status === 'stolen_suspect').length : 0;
  if (stolenSuspectDevices > 0) {
    document.title = `${stolenSuspectDevices} sospecha de robo - Paperboy ${process.env.NODE_ENV !== 'production' ? ' - LOCAL' : ''}`;
  } else {
    document.title = `Paperboy${process.env.NODE_ENV !== 'production' ? ' - LOCAL' : ''}`;
  }

  return (
    <>
      <div style={{
        display: 'none',
        position: 'absolute',
        fontSize: 14,
        zIndex: 1000,
        top: 0,
        right: 0,
        padding: 5,
        background: '#ff0',
      }}
      >
        {position && `${position.latitude}, ${position.longitude}`}
        <br />
        {mapPosition && `${mapPosition.lat}, ${mapPosition.lng}`}
      </div>
      <div className={[styles.main].join(' ')}>
        <div className={[styles.tools, styles.filters, showFilters ? styles.visible : ''].join(' ')}>
          <div className={styles.title}>
            <div className={styles.closeBtn} onClick={() => setShowFilters(false)} role="button">
              <FontAwesomeIcon icon={faTimes} style={{ fontSize: 18 }} />
            </div>
            <span>
              Filtrar
            </span>
          </div>

          <div className={styles.statusQr}>
            <div className={styles.statusFilter}>
              <Select
                mode="multiple"
                allowClear
                showSearch={false}
                style={{ width: '100%' }}
                placeholder="Selecciona status..."
                value={filterStatus}
                onChange={onStatusFilterChange}
              >
                <Option key="pickup_street_day_25">
                  <img src={getScooterMarker('pickup_street_day_25')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Recoger de calle Día (
                  {'<25%'}
                  )
                </Option>
                <Option key="pickup_street_night_40">
                  <img src={getScooterMarker('pickup_street_night_40')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Recoger de calle Noche (
                  {'<40%'}
                  )
                </Option>
                <Option key="pickup_street_night_60">
                  <img src={getScooterMarker('pickup_street_night_60')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Recoger de calle Noche (
                  {'<60%'}
                  )
                </Option>
                <Option key="live">
                  <img src={getScooterMarker('live')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Desplegado
                </Option>
                <Option key="in_ride">
                  <div style={{
                    display: 'inline-block', width: 25, height: 25, marginRight: 11, position: 'relative',
                  }}
                  >
                    <img src={getScooterMarker('in_ride')} alt="" style={{ width: 20 }} />
                    <img
                      src={getScooterMarker('in_ride', true)}
                      alt=""
                      style={{
                        width: 15, top: 10, left: 10, position: 'absolute',
                      }}
                    />
                  </div>

                  En viaje
                </Option>
                <Option key="reserved">
                  <img src={getScooterMarker('reserved')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Reservado
                </Option>
                <Option key="stolen_suspect">
                  <img src={getScooterMarker('stolen_suspect')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Sospecha de robo
                </Option>
                <Option key="stolen">
                  <img src={getScooterMarker('stolen')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Robado

                </Option>
                <Option key="in_warehouse">
                  <img src={getScooterMarker('in_warehouse')} alt="" style={{ width: 25, marginRight: 11 }} />
                  En bodega
                </Option>
                <Option key="in_transport_to_deploy">
                  <img src={getScooterMarker('in_transport_to_deploy')} alt="" style={{ width: 25, marginRight: 11 }} />
                  En transporte a ser desplegado
                </Option>
                <Option key="in_transport_to_warehouse">
                  <img src={getScooterMarker('in_transport_to_warehouse')} alt="" style={{ width: 25, marginRight: 11 }} />
                  En transporte a bodega
                </Option>
                <Option key="in_transport_to_test">
                  <img src={getScooterMarker('in_transport_to_test')} alt="" style={{ width: 25, marginRight: 11 }} />
                  En transporte a pruebas
                </Option>
                <Option key="motorist">
                  <img src={getScooterMarker('motorist')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Motorizado
                </Option>
                <Option key="disabled">
                  <img src={getScooterMarker('disabled')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Deshabilitado
                </Option>
                <Option key="missing_connection">
                  <img src={getScooterMarker('missing_connection')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Sin conexión
                </Option>
                <Option key="damaged">
                  <img src={getScooterMarker('in_transport_to_warehouse')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Dañado
                </Option>
                <Option key="maintenance">
                  <img src={getScooterMarker('maintenance')} alt="" style={{ width: 25, marginRight: 11 }} />
                  En mantenimiento
                </Option>
                <Option key="in_transport_to_grin4u_user">
                  <img src={getScooterMarker('in_transport_to_grin4u_user')} alt="" style={{ width: 25, marginRight: 11 }} />
                  En transporte a usuario Grin 4U
                </Option>
                <Option key="no_status">
                  <img src={getScooterMarker('no_status')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Sin Estado
                </Option>
                <Option key="with_error">
                  <img src={getScooterMarker('with_error')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Con error
                </Option>
                <Option key="out_of_area">
                  <img src={getScooterMarker('in_ride', true)} alt="" style={{ width: 25, marginRight: 11 }} />
                  Fuera de zona
                </Option>

              </Select>
              <div>
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => {
                    onStatusFilterChange(['pickup_street_day_25']);
                  }}
                >
                  <small>Recoger de calle Día (&lt;25%)</small>
                </div>
                {' '}
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => {
                    onStatusFilterChange(['pickup_street_night_40']);
                  }}
                >
                  <small>Recoger de calle Noche (&lt;40%)</small>
                </div>
                {' '}
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => {
                    onStatusFilterChange(['pickup_street_night_60']);
                  }}
                >
                  <small>Recoger de calle Noche (&lt;60%)</small>
                </div>
                {' '}
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => setFilterStatus(defaultFilterStatus)}
                >
                  <small>Filtro por defecto</small>
                </div>
                {' '}
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => setFilterStatus(defaultFilterStatusLive)}
                >
                  <small>Filtro por defecto + desplegados</small>
                </div>
                {' '}
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => setFilterStatus(['live'])}
                >
                  <small>Solo desplegados</small>
                </div>
                {' '}
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => { setFilterStatus(['missing_connection']); setFilterBattery([0, 100]); setFilterLastReport([0, 480]); }}
                >
                  <small>Solo Sin conexión</small>
                </div>
                {' '}
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => { setFilterStatus(['stolen_suspect']); setFilterBattery([0, 100]); setFilterLastReport([0, 480]); }}
                >
                  <small>Solo Sospecha de robo</small>
                </div>
                {' '}
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => setFilterStatus(['in_transport_to_deploy', 'in_transport_to_warehouse', 'in_transport_to_test'])}
                >
                  <small>En transporte</small>
                </div>
              </div>
            </div>
            <div className={styles.searchqr}>
              <Input placeholder="QR o MAC..." key={filterQ} defaultValue={filterQ} onKeyDown={(e) => (e.key === 'Enter' ? setFilterQ(e.target.value) : null)} />
            </div>
          </div>
          <div className={styles.extraFilters1}>
            <div className={styles.recentLocation}>
              <Select
                style={{ width: '100%' }}
                options={[
                  { label: 'Todos los patines', value: 'all' },
                  { label: 'Con ubicación reciente (<20m)', value: 'recent' },
                  { label: 'Sin ubicación reciente (>20m)', value: 'old' },
                ]}
                value={filterRecentLocation}
                onChange={(v) => setFilterRecentLocation(v)}
              />
            </div>
            <div className={styles.batteryFilter}>
              <div>
                Batería:
              </div>
              <Slider
                onChange={onBatteryFilterChange}
                range
                value={filterBattery}
              />
              <div>
                {filterBattery[0]}
                {' '}
                a
                {' '}
                {filterBattery[1]}
              </div>
            </div>
            {true
              && (
                <div className={styles.lastReportFilter}>
                  <div>
                    Ultimo reporte (horas):
                  </div>
                  <Slider
                    onChange={onLastReportFilterChange}
                    range
                    defaultValue={filterLastReport}
                    min={0}
                    max={720}
                  />
                  <div>
                    {filterLastReport[0]}
                    {' '}
                    a
                    {' '}
                    {filterLastReport[1]}
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className={[styles.inner, showSidebar ? styles.withSecondaryBar : null].join(' ')}>
          <div className={styles.map}>
            <div className={styles.results} id="monitorResults">
              <div className={styles.line}>
                <div style={{ display: 'inline-block' }}>
                  {visibleDevices.filter((x) => x.type !== 'internal_transport').length}
                  {' '}
                  {visibleDevices.filter((x) => x.type !== 'internal_transport').length === 1 ? 'dispositivo' : 'dispositivos'}
                  {loading && <span> ...</span>}
                </div>
                <div>
                  {(nLoad && periodUpdate) ? (
                    <span style={{
                      display: 'inline-block', marginRight: 5, fontSize: 12, verticalAlign: 'middle', marginTop: -5,
                    }}
                    >
                      Cargas:
                      {' '}
                      {nLoad}
                      {` (p:${periodUpdate / 1000}s) `}
                    </span>
                  ) : null}
                  <Button onClick={() => loadDevices(mapPosition)}>
                    <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
                  </Button>
                </div>
              </div>
              <div className={styles.byStatus}>
                <RowByStatus
                  devices={visibleDevices}
                  fleetId={fleetId}
                  computeMetricStyle={(status, n) => (
                    ['stolen', 'stolen_suspect'].includes(status) && n > 0
                      ? {
                        color: '#fff',
                        background: '#f30',
                      }
                      : {
                        color: '#444',
                        background: '#fff',
                      }
                  )}
                  showStatus={filterStatus.filter((x) => !['pickup_street_day_25', 'pickup_street_night_60', 'pickup_street_night_40', 'out_of_area', 'with_error'].includes(x))}
                  size="small"
                  cols={isMobile() ? 4 : 8}
                  onClick={(k) => { onStatusFilterChange([k]); }}
                />
              </div>
              <div className={styles.selectorClusterGridSize}>
                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  <small>Nivel de agrupación de patines en mapa:</small>
                  {' '}
                </div>
                <div style={{
                  display: 'inline-block', width: 200, marginLeft: 10, verticalAlign: 'middle',
                }}
                >
                  <Slider
                    onChange={(v) => setClusterGridSize(v)}
                    value={clusterGridSize}
                    min={1}
                    max={50}
                  />
                </div>
              </div>

            </div>
            <div
              style={{
                height: window.innerHeight
                  - (isMobile() ? (menuBarHeight + 52) : (monitorResultsHeight + 40))
                  - (isMobile() && (selectedDevice || deviceUpdating) ? 300 : 0),
              }}
            >
              <MapComponent
                key={fleetId}
                fleetId={fleetId}
                traffic={showTraffic}
                apiKey={GOOGLE_MAPS_API_KEY}
                onMapMoving={(s) => { setMapMoving(s); }}
                currentLocation={position}
                showLabel={showScooterInfo}
                defaultZoom={isMobile() ? 15 : selectedFleet?.cost?.headquarter?.zoom || 15}
                defaultCenter={{ lat: mapPosition?.lat, lng: mapPosition?.lng }}
                clusterGridSize={clusterGridSize}
                onCenterChange={(center) => { setMapPosition(center); }}
                minZoom={isMobile() ? 13 : 13}
                maxZoom={20}
                containerStyle={{
                  height: '100%',
                }}
                deployPoints={showDeployPoints ? [...points] : []}
                markers={[...visibleDevices]}
                usersMarkers={tenantUsers?.map((x) => ({
                  lat: x.data?.position?.lat,
                  lng: x.data?.position?.lng,
                  id: x.email,
                  label: (
                    <div style={{ textAlign: 'center' }}>
                      <strong>
                        {x.name}
                      </strong>
                      <br />
                      Act:
                      {' '}
                      {moment.utc(x.data?.position?.created_at).fromNow()}
                      <br />
                      {moment(x.data?.position?.created_at).format('YYYY-MM-DDTHH:mm:ss')}
                    </div>),
                })).filter((x) => x.lat && x.lng)}
                selectedMarkers={selectedMode === 'multiple' ? multipleSelectedDevices.map((x) => x.id) : []}
                onSelectMarker={onSelectDevice}
                onPolygonRightClick={(polygon) => onRightClickGeofence(polygon)}
                onOverlayComplete={(type, overlay) => onSelectDevicesInOverlay(type, overlay)}
                drawingControlEnabled={!isMobile()}
                polygons={geofences
                  && geofences.map(
                    (x) => ({ ...x, color: x.status === 'INACTIVE' ? '#999999' : (colorZone[x.type]) }),
                  )}
              />
            </div>

            <div className={styles.bottomTools}>
              <Button
                className={stylesLayout.roundedButton}
                type="primary"
                onClick={() => setShowFilters(true)}
              >
                <FontAwesomeIcon icon={faFilter} style={{ marginRight: 'var(--spacing_quarter)' }} />
              </Button>

              <Button
                className={stylesLayout.roundedButton}
                type="primary"
                onClick={() => sparseDevices()}
              >
                <FontAwesomeIcon icon={faShuffle} style={{ marginRight: 'var(--spacing_quarter)' }} />
              </Button>

              {!isMobile() && (
                <Dropdown
                  trigger={['click']}
                  overlay={(
                    <Menu>
                      <Menu.Item onClick={() => setSelectedMode('single')}>
                        {selectedMode === 'single' && <FontAwesomeIcon icon={faCheck} style={{ marginRight: 'var(--spacing_quarter)' }} />}
                        Único
                      </Menu.Item>
                      <Menu.Item onClick={() => setSelectedMode('multiple')}>
                        {selectedMode === 'multiple' && <FontAwesomeIcon icon={faCheck} style={{ marginRight: 'var(--spacing_quarter)' }} />}
                        Múltiple
                      </Menu.Item>
                    </Menu>
                  )}
                  placement="top"
                >
                  <Button
                    className={stylesLayout.roundedButton}
                    type="primary"
                  >
                    Seleccionar...
                  </Button>
                </Dropdown>
              )}
              {!isMobile()
                && (
                  <Button
                    className={stylesLayout.roundedButton}
                    type="primary"
                    onClick={() => {
                      setShowList((s) => !s);
                    }}
                  >
                    {showList ? 'Ocultar' : 'Mostrar'}
                    {' '}
                    lista de patines
                  </Button>
                )}
              <Button
                className={stylesLayout.roundedButton}
                type="primary"
                onClick={() => {
                  setMapPosition(
                    { lat: position?.latitude, lng: position?.longitude },
                  );
                  loadDevices();
                }}
              >
                <FontAwesomeIcon icon={faLocationArrow} />
              </Button>
              <Button
                className={stylesLayout.roundedButton}
                type="primary"
                onClick={() => {
                  setMapPosition(
                    {
                      lat: selectedFleet?.cost?.headquarter?.lat,
                      lng: selectedFleet?.cost?.headquarter?.lng,
                    },
                  );
                  loadDevices();
                }}
              >
                Ver zona
              </Button>

              <div className={styles.layers}>
                <Checkbox
                  defaultChecked={!!showTraffic}
                  onChange={(e) => { setShowTraffic(e.target.checked); }}
                >
                  Tráfico
                </Checkbox>
                <Checkbox
                  defaultChecked={!!showScooterInfo}
                  onChange={(e) => { setShowScooterInfo(e.target.checked); }}
                >
                  Info de patines
                </Checkbox>
                <Checkbox
                  defaultChecked={!!showDeployPoints}
                  onChange={(e) => { setShowDeployPoints(e.target.checked); }}
                >
                  Puntos de deploy
                </Checkbox>
              </div>

            </div>

          </div>

          {showList && (
            <div className={styles.sidebar} style={{ height: 'calc(100% - 0px)', overflow: 'auto' }}>
              <DeviceList devices={visibleDevices} />
            </div>
          )}

          {selectedMode === 'multiple'
            && multipleSelectedDevices && multipleSelectedDevices.length > 0
            && (
              <div className={[styles.sidebar, styles.taskArea].join(' ')}>
                <TaskHandleModule
                  userPosition={position}
                  user={user}
                  initialDevices={multipleSelectedDevices}
                  onChangeDevices={(ds) => { setMultipleSelectedDevices(ds); }}
                  fleetId={fleetId}
                  permissions={{
                    reassign: user?.can?.manage_tasks,
                    modifyDevices: user?.can?.manage_tasks,
                    modifyTask: user?.can?.manage_tasks,
                    viewTask: user?.can?.handle_tasks,
                  }}
                />
              </div>
            )}
          {selectedMode === 'single' && (selectedDevice || deviceUpdating)
            && (
              <div className={[styles.sidebar, styles.selectedDevice].join(' ')}>
                {(deviceUpdating && !selectedDevice)
                  ? <div style={{ padding: '130px 10px', textAlign: 'center' }}><Loading /></div>
                  : (
                    selectedDevice
                    && (
                      <>
                        <div className={styles.closebar}>
                          <div className={styles.close} onClick={onCloseResume} role="button">
                            <FontAwesomeIcon icon={faTimes} style={{ fontSize: 18 }} />
                          </div>
                        </div>
                        <div className={styles.content}>
                          {getDeviceComponent(selectedDevice)}
                        </div>

                      </>
                    )
                  )}
              </div>
            )}
        </div>
      </div>
    </>
  );
};
NewMonitorModule.propTypes = {
  user: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};
export default NewMonitorModule;
