/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  Form, Input, Button, notification, Select,
} from 'antd';

import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import stylesForm from '../../common/form.module.scss';
import stylesLayout from '../../common/layout.module.scss';

import { register } from '../../services/users';

const RegisterModule = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const doRegister = async (values) => {
    setLoading(true);
    const rsp = await register({
      email: values.email,
      name: values.name,
      password: '',
      data: { country: values.country },
      registration_complete: 1,
    });
    if (rsp?.status) {
      notification.success({
        message: `Usuario ${values.email} registrado correctamente`,
        placement: 'bottom',
        key: 'result',
      });
      history.push(`/customer/${rsp.data.id}`);
    } else {
      notification.error({
        message: 'Error al crear el usuario.',
        placement: 'bottom',
        key: 'result',
      });
    }
    setLoading(false);
  };

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>Crear nuevo usuario</div>
      <div className={styles.cnt}>
        <div className={styles.login}>
          <div className={`${styles.form} ${stylesForm.form}`}>
            <div className={stylesForm.fields}>
              <Form
                name="basic"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 22 }}
                autoComplete="off"
                form={form}
                initialValues={{ email: '', name: '' }}
                onFinish={doRegister}
                size="large"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'El email es requerido' }]}
                >
                  <Input placeholder="Email..." type="email" />
                </Form.Item>
                <Form.Item
                  label="Nombre"
                  name="name"
                  rules={[{ required: true, message: 'El nombre es requerido' }]}
                >
                  <Input placeholder="Nombre..." type="text" />
                </Form.Item>
                <Form.Item
                  label="País"
                  name="country"
                  rules={[{ required: true, message: 'El país es requerido' }]}
                >
                  <Select
                    style={{ width: 300 }}
                    options={['CL', 'PE'].map((x) => ({ label: x, value: x }))}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={loading}>
                    Registrar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RegisterModule.defaultProps = {
};

RegisterModule.propTypes = {
};

export default RegisterModule;
