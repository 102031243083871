/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap, useJsApiLoader, MarkerF,
  MarkerClusterer, Polygon, Polyline,
  DrawingManager,
  LoadScript,
  HeatmapLayer,
  OverlayView,
  TrafficLayer,
  InfoBox,
} from '@react-google-maps/api';
import moment from 'moment';
import { createEvent } from '../../services/events';

import userMarker from '../../assets/user.svg';
import blackPoint from '../../assets/points/black.svg';

import ScooterMarker from '../../assets/scootersMap';
import InternalTransportMarker from '../../assets/internalTransport';

import {
  aproxMinsToHoursDays, distance,
} from '../../helpers/utils';

import {
  getSimProviderByIccid,
} from '../../helpers/params';

const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => ({
  x: offsetWidth + labelAnchor.x,
  y: offsetHeight + labelAnchor.y,
});

const CustomMarker = (props) => {
  const {
    icon,
  } = props;
  const { label: labelX, ...markerProps } = props;
  const [marker, setMarker] = useState(null);

  return (
    <>
      <MarkerF
        {...markerProps}
        icon={icon}
        onLoad={(m) => setMarker(m)}
        onClick={() => { props.onSelectMarker(); }}
      >
        {props.label && marker && marker.anchorPoint && (
          <OverlayView
            key={`${props.key}-ov`}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(x, y) => getPixelPositionOffset(x, y, props.labelAnchor)}
            clusterer={props.clusterer}
            position={props.position}
            onClick={() => { props.onSelectMarker(); }}
            style={{ border: '1px solid red' }}
          >
            <div
              style={props.labelStyle}
              onClick={() => { props.onSelectMarker(); }}
            >
              {props.label}
            </div>
          </OverlayView>
        )}
      </MarkerF>
    </>
  );
};
CustomMarker.defaultProps = {
  label: <></>,
  infoWindow: null,
  onSelectMarker: () => { },
};
CustomMarker.propTypes = {
  marker: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
  infoWindow: PropTypes.object,
  label: PropTypes.object,
  onSelectMarker: PropTypes.func,
};

const EditablePolygon = (props) => {
  const [polygon, setPolygon] = useState(null);
  return (
    <Polygon
      onLoad={(p) => setPolygon(p)}
      {...props}
    />
  );
};

EditablePolygon.defaultProps = {
  onPolygonPathChange: () => { },
};
EditablePolygon.propTypes = {
  onPolygonPathChange: PropTypes.func,
};

export const getScooterMarker = (status, type = 'scooter', alert = false, marker = {}) => {
  if (type === 'internal_transport') {
    if (marker.qr?.startsWith('MOT') || marker.qr?.startsWith('CLMOT') || marker.qr?.startsWith('PEMOT')) {
      return InternalTransportMarker.moto;
    }
    if (marker.qr?.startsWith('VAN') || marker.qr?.startsWith('CLVAN') || marker.qr?.startsWith('PEVAN')) {
      return InternalTransportMarker.van;
    }
    return InternalTransportMarker.moto;
  }
  switch (status) {
    case 'live':
      return ScooterMarker.greenlight;
    case 'reserved':
      return ScooterMarker.yellow;
    case 'in_ride':
      if (alert) {
        return ScooterMarker.rideRed;
      }
      return ScooterMarker.rideGreen;

    case 'in_transport_to_deploy':
    case 'in_transport_to_grin4u_user':
      return ScooterMarker.blue;
    case 'in_transport_to_warehouse':
      return ScooterMarker.purple;
    case 'motorist':
      return ScooterMarker.pink;
    case 'in_warehouse':
      return ScooterMarker.orange;
    case 'stolen_suspect':
      return ScooterMarker.stolenPurple;
    case 'damaged':
      return ScooterMarker.red;
    case 'maintenance':
      return ScooterMarker.coffee;
    case 'stolen':
      return ScooterMarker.stolenBlack;
    default:
      return ScooterMarker.gray;
  }
};
let pidUpdateCenter;
export const MapComponent = ((props) => {
  const [map, setMap] = React.useState(null);
  const [mapHover, setMapHover] = useState(null);

  /* useEffect(() => {
    if (!map) {
      return;
    }
    map.panTo(props.defaultCenter);
  }, [props.defaultCenter, map]); */
  const onLoad = React.useCallback((map) => {
    setMap(map);
    (async () => {
      const url2 = `${document.location.pathname}${document.location.search}${document.location.hash}`;
      const payload = {
        event: 'paperboy:create_map',
        item_type: 'system',
        user_id: 0,
        item_id: 0,
        lat: props.defaultCenter?.lat,
        lng: props.defaultCenter?.lng,
        data: {
          fleet_id: props.fleetId || 0,
          url: url2,
        },
      };
      await createEvent(payload);
    })();
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);
  const markerSize = (map?.getZoom() || props.zoom) < 18 ? 33 : 55;
  return (
    <GoogleMap
      mapContainerStyle={props.containerStyle}
      center={props.defaultCenter}
      zoom={props.defaultZoom}
      options={{
        minZoom: props.minZoom,
        maxZoom: props.maxZoom,
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onDragEnd={() => {
        if (props.onCenterChange && map?.getBounds()) {
          if (
            map.getBounds().getCenter().lat() !== props.defaultCenter.lat
            && map.getBounds().getCenter().lng() !== props.defaultCenter.lng
          ) {
            console.log('update, location changed', map.getBounds().getCenter().lat(), props.defaultCenter.lat);
            props.onCenterChange(
              {
                lat: map.getBounds().getCenter().lat(),
                lng: map.getBounds().getCenter().lng(),
              },
            );
          } else {
            console.log('no update, location did not changed');
          }
        }
      }}
    >
      {props.drawingControlEnabled && (
        <DrawingManager
          defaultDrawingMode={null}
          onOverlayComplete={
            (o) => {
              if (props.onOverlayComplete) { props.onOverlayComplete(o.type, o.overlay); }
              o.overlay.setMap(null);
            }
          }
          options={{
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [
                window.google.maps.drawing.OverlayType.RECTANGLE,
              ],
            },
            rectangleOptions: {
              fillColor: '#999999',
              fillOpacity: 0.5,
              strokeWeight: 1,
              clickable: false,
              editable: false,
              zIndex: 5,
            },
          }}
        />
      )}
      {props.currentLocation && props.currentLocation.latitude && props.currentLocation.longitude
        && (
          <CustomMarker
            key={`current-location-${map?.getZoom()}`}
            position={{
              lat: (props.currentLocation.latitude),
              lng: (props.currentLocation.longitude),
            }}
            icon={props.currentLocation?.icon ? {
              scaledSize: new window.google.maps.Size(markerSize, markerSize),
              url: props.currentLocation.icon,
              anchor: new window.google.maps.Point(markerSize / 2, markerSize),
            } : null}
            color="#444"
            marker={null}
            label={<>Tu ubicación</>}
            labelAnchor={{ x: -120, y: -15 }}
            labelStyle={{
              backgroundColor: '#fff',
              textAlign: 'center',
              color: '#444',
              padding: '3px',
              borderRadius: '3px',
              border: '1px solid #eee',
              width: 80,
            }}
            infoWindow={<></>}
          />
        )}

      {props.deployPoints?.map((x) => (
        <CustomMarker
          // eslint-disable-next-line prefer-template
          key={'deploy-point-' + x.id + '-' + map?.getZoom()}
          icon={{
            scaledSize: new window.google.maps.Size(8, 8),
            url: blackPoint,
            anchor: new window.google.maps.Point(4, 4),

          }}
          position={{
            lat: (x.lat),
            lng: (x.lng),
          }}
          label={null}
          labelAnchor={{ x: -120, y: -15 }}
          labelStyle={{
            backgroundColor: '#fff',
            textAlign: 'center',
            color: '#444',
            padding: '3px',
            borderRadius: '3px',
            border: '1px solid #eee',
            width: 80,
          }}
        />
      ))}

      {props.lines && props.lines.map((line) => (
        <Polyline path={line.path} />
      ))}
      {props.polygons && props.polygons.map((polygon) => (
        <EditablePolygon
          key={polygon.id}
          editable={polygon.editable}
          draggable={polygon.editable}
          paths={polygon.points.map((p) => ({ lat: p.latitude, lng: p.longitude }))}
          onPolygonPathChange={
            (path) => props.onPolygonPathChange && props.onPolygonPathChange(polygon.id, path)
          }
          onClick={() => (props.onClickPolygon && props.onClickPolygon(polygon.id))}
          onRightClick={() => props.onPolygonRightClick && props.onPolygonRightClick(polygon)}
          options={{
            fillColor: polygon.color || '#ffcc00',
            strokeColor: polygon.color || '#ffcc00',
            strokeWeight: 2,
            zIndex: (polygon.type === 'MICROZONE' || polygon.type === 'MICROZONE_WAREHOUSE') ? 3 : 1,

          }}
        />
      ))}
      {props.blocks && props.blocks.map((block, index) => (
        <Polygon
          key={block.id || index}
          onClick={() => { if (block.label) { setMapHover(block.label); } }}
          paths={[
            { lat: block.lat0, lng: block.lng0 },
            { lat: block.lat0, lng: block.lng1 },
            { lat: block.lat1, lng: block.lng1 },
            { lat: block.lat1, lng: block.lng0 },
          ]}
          options={{
            fillColor: block.color || '#ffcc00',
            strokeColor: block.color || '#ffcc00',
            fillOpacity: block.opacity || 1,
            strokeOpacity: 0.25,
            strokeWeight: block.strokeWeight || 1,
            zIndex: 5,
          }}
        />
      ))}
      <MarkerClusterer
        onClick={(cluster) => {
          if (props.onCenterChange && cluster?.center) {
            if (
              cluster?.center.lat() !== props.defaultCenter.lat
              && cluster?.center.lng() !== props.defaultCenter.lng
            ) {
              console.log('update, location changed by opening cluster', cluster?.center.lat(), cluster?.center.lng());
              props.onCenterChange(
                {
                  lat: cluster?.center.lat(),
                  lng: cluster?.center.lng(),
                },
              );
            } else {
              console.log('no update, location did not changed by opening cluster');
            }
          }
        }}
        maximumClusterSize={props.maximumClusterSize || 5}
        minimumClusterSize={props.minimumClusterSize || 2}
        maxZoom={18}
        gridSize={props.clusterGridSize || 40}
      >
        {(clusterer) => (
          <>
            {props.markers && props.markers.filter((x) => {
              if (!map || !map.getBounds()) {
                return false;
              }
              const e1 = {
                lat: map.getBounds().getSouthWest().lat(),
                lng: map.getBounds().getSouthWest().lng(),
              };
              const e2 = {
                lat: map.getBounds().getNorthEast().lat(),
                lng: map.getBounds().getNorthEast().lng(),
              };
              const inside = (
                x.lat > e1.lat
                && x.lat < e2.lat
                && x.lng > e1.lng
                && x.lng < e2.lng
              );
              return inside;
            }).map((marker) => (
              <CustomMarker
                clusterer={clusterer}
                key={`d-${marker.id}-${map.getZoom()}`}
                position={{
                  lat: (marker.latitude || marker.lat),
                  lng: (marker.longitude || marker.lng),
                }}
                onSelectMarker={() => {
                  if (props.onSelectMarker) { props.onSelectMarker(marker); }
                }}
                icon={marker.icon !== 'none' ? {
                  scaledSize: marker.iconScale
                    || new window.google.maps.Size(markerSize, markerSize),
                  url: marker.icon || getScooterMarker(
                    marker.status, marker.type, marker.data?.out_of_area,
                    marker,
                  ),
                  anchor: new window.google.maps.Point(markerSize / 2, markerSize),
                } : null}
                color="#ffcc00"
                marker={marker}
                label={(
                  props.showLabel
                    ? marker.label
                    || (
                      <div style={{ textAlign: 'center' }}>
                        {marker.qr}
                        {marker.type !== 'internal_transport' && (
                          <>
                            {' - '}
                            {marker.status_tr}
                          </>
                        )}
                        {marker.out_of_area && ' - Fuera de zona'}
                        <br />
                        {marker.type !== 'internal_transport' && marker.data.batsco ? (
                          <>
                            Bat:
                            {' '}
                            {marker.data.batsco}
                            %
                          </>
                        ) : 'Sin batería'}
                        {marker.data.batiot && (
                          <>
                            {' '}
                            (IOT:
                            {' '}
                            {marker.data.batiot}
                            %)
                          </>
                        )}
                        {marker.data.sim_provider && (
                          <>
                            <br />
                            Sim:
                            {' '}
                            {marker.data.sim_provider}
                            {' '}
                            (
                            RSSI:
                            {' '}
                            {marker.data.rssi}
                            )
                          </>
                        )}
                        {marker.data.mins_since_last_connection && (
                          <>
                            <br />
                            Últ. con.:
                            {' '}
                            {aproxMinsToHoursDays(marker.data.mins_since_last_connection)}

                          </>
                        )}
                        {marker.geo_updated_at && (
                          <>
                            <br />
                            Últ. pos.:
                            {' '}
                            {moment.utc(marker.geo_updated_at).fromNow(true)}

                          </>
                        )}
                        {marker.status === 'missing_connection'
                          && (
                            <>
                              <br />
                              Ult no encontrado:
                              {' '}
                              {marker.data?.last_not_found && marker.data?.last_not_found.at > marker.data.last_status_change_at ? moment.utc(marker.data?.last_not_found?.at).local().format('DD/MM HH:mm') : 'Sin info'}
                            </>
                          )}
                        {marker.data?.instant_velocity_km_h !== -1
                          && (
                            <>
                              <br />
                              Vel instantanea:
                              {' '}
                              {marker.data.instant_velocity_km_h}
                              {' '}
                              km/h
                            </>
                          )}
                        {marker.data?.coderr > 0 && (
                          <span>
                            <br />
                            Err:
                            {' '}
                            {marker.data?.coderr}
                          </span>
                        )}
                      </div>
                    )
                    : null)}
                labelAnchor={{ x: -180, y: -37 }}
                labelStyle={{
                  backgroundColor: props.selectedMarkers?.includes(marker.id) ? '#06c' : 'white',
                  color: props.selectedMarkers?.includes(marker.id) ? '#fff' : '444',
                  padding: '3px',
                  fontSize: 10,
                  borderRadius: '3px',
                  border: '1px solid #eee',
                  width: 120,
                }}
              />

            ))}
          </>
        )}
      </MarkerClusterer>
      <MarkerClusterer
        onClick={() => { }}
        minimumClusterSize={10000}
        maxZoom={19}
        gridSize={props.clusterGridSize || 40}
      >
        {(clusterer) => (
          props.usersMarkers && props.usersMarkers.map((marker) => (
            <CustomMarker
              key={`u-${marker.id}`}
              clusterer={clusterer}
              position={{
                lat: (marker.lat),
                lng: (marker.lng),
              }}
              color="#ff0099"
              icon={{
                scaledSize: new window.google.maps.Size(markerSize, markerSize),
                url: userMarker,
                anchor: new window.google.maps.Point(markerSize / 2, markerSize),

              }}
              marker={marker}
              label={marker.label}
              labelAnchor={{ x: -180, y: -37 }}
              labelStyle={{
                backgroundColor: '#ffeecc',
                color: '#444',
                padding: '3px',
                fontSize: 10,
                borderRadius: '3px',
                border: '1px solid #eee',
                width: 120,
              }}
            />
          )))}
      </MarkerClusterer>
      {
        props.traffic
        && <TrafficLayer />
      }
      {
        props.heatmap
        && (
          <HeatmapLayer
            data={props.heatmap.data}
            options={{ radius: 20, ...props.heatmap.options }}
          />
        )
      }
    </GoogleMap>
  );
});

export default { MapComponent, getScooterMarker };
