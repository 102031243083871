/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import Board from '@asseinfo/react-kanban';
import '@asseinfo/react-kanban/dist/styles.css';
import DeviceCard from './DeviceCard';

const defaultBoard = {
  columns: [
    {
      id: 1,
      title: 'Próximas misiones',
      cards: [
        {
          id: 1,
          device: {
            qr: 'AAAA',
          },
        },
      ],
    },
    {
      id: 2,
      title: 'Misiones enviadas',
      cards: [
        {
          id: 2,
          device: {
            qr: 'BBBB',
          },
        },
      ],
    },
    {
      id: 2,
      title: 'Completadas',
      cards: [
        {
          id: 2,
          device: {
            qr: 'BBBB',
          },
        },
      ],
    },
  ],
};

const KanbanModule = () => {
  const [board] = useState(defaultBoard);

  return (

    <div style={{ padding: 20, display: 'grid', gap: 20 }}>
      <Board
        renderCard={({ device }, { removeCard, dragging }) => (
          <DeviceCard dragging={dragging} device={device}>
            <button type="button" onClick={removeCard}>Remove Card</button>
          </DeviceCard>
        )}
      >
        {board}

      </Board>
    </div>
  );
};
export default KanbanModule;
