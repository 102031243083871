/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Metric from '../Metric';

import {
  statusTranslations,
} from '../../helpers/params';

import styles from './index.module.scss';

const RowByStatus = ({
  devices,
  cols,
  size,
  layout,
  computeMetricStyle,
  fleetId,
  onClick,
  showStatus,

}) => {
  const qtyPerStatus = useMemo(() => {
    const r = Object.fromEntries(showStatus.map((k) => [k, 0]));
    if (!devices || devices.length === 0) {
      return null;
    }
    devices.map((x) => {
      if (!r[x.status]) {
        r[x.status] = { total: 0, zones: {} };
      }

      r[x.status].total += 1;
      (x.data?.in_geozones || [])
        .map((y) => {
          r[x.status].zones[y] = (r[x.status].zones[y] || 0) + 1;
          return null;
        });
      return null;
    });
    return r;
  }, [devices, showStatus]);

  return (
    <div
      className={styles.byStatus}
      style={{
        display: layout === 'grid' ? 'grid' : 'block',
        gridTemplateColumns: layout === 'grid' ? `repeat(${cols}, 1fr)` : 'auto',
      }}
    >
      {qtyPerStatus && Object.keys(qtyPerStatus).map((k) => (
        <div
          style={{
            display: layout === 'grid' ? 'block' : 'inline-block',
            marginRight: layout === 'grid' ? 0 : 'var(--spacing_half)',
          }}
          key={k}
        >
          <Metric
            onClick={() => onClick(k)}
            layout={layout}
            style={computeMetricStyle(k, qtyPerStatus[k].total)}
            key={k}
            title={k ? statusTranslations[k] || k : 'Sin estado'}
            n={qtyPerStatus[k].total}
            size={size}
            subtitle={fleetId === 1 && (
              <>
                <div>
                  Las Condes:
                  {' '}
                  {(qtyPerStatus[k].zones && qtyPerStatus[k].zones[1]) || '-'}
                </div>
                <div>
                  Vitacura:
                  {' '}
                  {(qtyPerStatus[k].zones && qtyPerStatus[k].zones[40]) || '-'}
                </div>
              </>
            )}
          />

        </div>
      ))}
    </div>
  );
};
RowByStatus.defaultProps = {
  devices: [],
  cols: 6,
  computeMetricStyle: () => { },
  fleetId: 0,
  size: 'normal',
  layout: 'inline',
  onClick: () => { },
  showStatus: [],
};

RowByStatus.propTypes = {
  devices: PropTypes.array,
  fleetId: PropTypes.number,
  cols: PropTypes.number,
  computeMetricStyle: PropTypes.any,
  size: PropTypes.string,
  layout: PropTypes.string,
  onClick: PropTypes.func,
  showStatus: PropTypes.array,
};

export default RowByStatus;
