import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import {
  Button,
  Input,
  notification,
  Space,
  Tabs,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import moment from 'moment';
import CustomerGrin4UInfo from '../CustomerGrin4UInfo';
import Grin4USubscriptions from '../Grin4USubscriptions';
import Loading from '../../components/Loading';

import stylesLayout from '../../common/layout.module.scss';

import {
  getById,
  updatePhone,
} from '../../services/users';

const { TabPane } = Tabs;

const Grin4UCustomerModule = ({
  user,
}) => {
  const { id: idParam } = useParams();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [newPhone, setNewPhone] = useState('');

  const loadCustomer = async (id, showNotification) => {
    if (showNotification) {
      notification.info({
        message: 'Actualizando cliente...',
        placement: 'top',
        key: 'result',
        duration: 0,
      });
    }
    const rsp = await getById(id);
    if (rsp && rsp.status) {
      const newCustomer = rsp.data;
      if (customer?.events) {
        newCustomer.events = customer.events;
      }
      if (customer?.customer_events) {
        newCustomer.customer_events = customer.customer_events;
      }

      setCustomer(newCustomer);
      setNewPhone(newCustomer.phone || ' ');
      if (showNotification) {
        notification.success({
          message: 'Cliente actualizado.',
          placement: 'top',
          key: 'result',
        });
      }
    } else if (showNotification) {
      notification.error({
        message: 'Error al actualizar cliente.',
        placement: 'top',
        key: 'result',
      });
    }
    setLoading(false);
  };

  const changePhone = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando teléfono...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await updatePhone(customer.id, newPhone);
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Teléfono cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar teléfono.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  useEffect(() => {
    loadCustomer(idParam);
  }, [idParam]);

  if (loading) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading />
        </div>
      </div>
    );
  }
  if (!customer) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          Cliente no encontrado
        </div>
      </div>
    );
  }

  const onUpdate = () => loadCustomer(customer.id, true);

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>Grin 4U - Cliente</div>
      <div className={stylesLayout.content}>
        <table className={stylesLayout.infotable}>
          <tr>
            <td width="25%">Usuario</td>
            <td>
              <Link to={`/customer/${customer.id}`}>{customer.email}</Link>
              <br />
              {customer.name}
            </td>
          </tr>
          <tr>
            <td width="25%">Creado como cliente Grin 4U</td>
            <td>
              {customer.data.grin4uinfo?.created_at ? moment.utc(customer.data.grin4uinfo?.created_at).local().format('YYYY-MM-DD HH:mm:ss') : ''}
            </td>
          </tr>
          <tr>
            <td width="25%">Status Grin 4U</td>
            <td>
              {customer.grin4u_status_tr}
            </td>
          </tr>
          <tr>
            <td width="25%">Teléfono</td>
            <td>
              <Space direction="horizontal" align="center">
                {user.can.manage_users
                  ? (
                    <div>
                      <Input
                        value={newPhone}
                        autoComplete="off"
                        id="customer-tel"
                        onChange={(e) => setNewPhone(e.target.value)}
                        style={{ width: 300 }}
                      />
                    </div>
                  )
                  : newPhone}
                {user.can.manage_users
                  && <Button onClick={changePhone}>Cambiar Teléfono</Button>}
                {' '}
                {customer?.phone && (
                  <>
                    <a
                      href={`http://wa.me/${customer.phone.trim().replaceAll('+', '')}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#25D366', fontSize: 24, marginRight: 5 }} />
                    </a>
                  </>
                )}
              </Space>
            </td>
          </tr>
        </table>
        <Tabs style={{ marginTop: 'var(--spacing4x)' }}>
          <TabPane
            tab={(
              <>
                Información del cliente
              </>
            )}
            key="trips"
          >
            <CustomerGrin4UInfo customer={customer} user={user} onUpdate={onUpdate} />
          </TabPane>
          <TabPane
            tab={(
              <>
                Suscripciones
              </>
            )}
            key="subscriptions"
          >
            <Grin4USubscriptions
              customer={customer}
              country={customer.data?.country}
              user={user}
              onUpdate={onUpdate}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

Grin4UCustomerModule.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Grin4UCustomerModule;
