/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import isMobile from 'is-mobile';
import PropTypes from 'prop-types';

import {
  Button,
} from 'antd';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapComponent } from '../../components/MapComponent';
import GeofenceItem from '../../components/GeofenceItem';

import {
  getGeofencesForFleet,
  setGeofencesPoints,
  createGeofence,
  updateGeofence,
} from '../../services/geofences';

import {
  calculateCentroid,
} from '../../helpers/utils';

import {
  colorZone,
} from '../../helpers/params';

import styles from './index.module.scss';

const GeoModule = ({ position, fleets, fleetId }) => {
  const [mapCenter, setMapCenter] = useState(null);
  const [mapPosition, setMapPosition] = useState(null);
  const [mapPanTo, setMapPanTo] = useState(null);
  const [geofences, setGeofences] = useState(null);
  const [snackMessage, setSnackMessage] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const setTemporaryMessage = ({ message, type, time = 2000 }) => {
    setSnackMessage({
      message,
      type,
    });
    window.setTimeout(() => {
      setSnackMessage(null);
    }, time);
  };

  const loadGeofences = async () => {
    const rsp = await getGeofencesForFleet([fleetId], ['all']);
    if (rsp && rsp.status) {
      setGeofences(rsp.data);
    } else {
      setGeofences([]);
    }
  };

  const onCreateGeofence = async () => {
    const delta = 0.01;
    const rsp = await createGeofence({
      name: 'Nueva zona',
      status: 'INACTIVE',
      type: 'ALLOW',
      fleet_id: fleetId,
      points: [
        { latitude: mapCenter.lat - delta, longitude: mapCenter.lng - delta },
        { latitude: mapCenter.lat - delta, longitude: mapCenter.lng + delta },
        { latitude: mapCenter.lat + delta, longitude: mapCenter.lng + delta },
        { latitude: mapCenter.lat + delta, longitude: mapCenter.lng - delta },
      ],
    });
    if (rsp && rsp.status) {
      setGeofences((s) => [...s, { ...rsp.data, editable: true }]);
    }
  };
  const getGeofenceById = (id) => geofences.find((x) => x.id === id);

  const onGeofencePathChanged = async (id, points) => {
    console.log('CHANGE GEOFENCE', id, ' POINTS: ', points);
    await setGeofencesPoints(id, points);
    setGeofences((s) => s.map((x) => (x.id === id ? { ...x, points } : x)));
  };
  const onStartEditingGeofence = (id) => {
    setGeofences((s) => s.map((x) => ({ ...x, editable: x.id === id })));
    const currentGeofence = getGeofenceById(id);
    if (currentGeofence) {
      const center = calculateCentroid(currentGeofence.points);
      setMapPanTo(center);
      window.setTimeout(() => setMapPanTo(null), 1000);
    }
  };

  const onSaveEditingGeofence = async (id, fields) => {
    const newFields = { ...fields };
    delete newFields.points;
    await updateGeofence(id, newFields);
    setGeofences((s) => s.map(
      (x) => (x.id === id ? { ...x, ...newFields, editable: false } : { ...x, editable: false }),
    ));
    loadGeofences();
  };
  const onDeleteGeofence = async (id) => {
    await updateGeofence(id, { status: 'DELETED' });
    setGeofences((s) => s.map(
      (x) => (x.id === id ? null : { ...x, editable: false }),
    ).filter((x) => !!x));
  };

  const onEndEditingGeofence = () => {
    setGeofences((s) => s.map((x) => ({ ...x, editable: false })));
  };

  useEffect(() => {
    if (position && position.latitude && !mapPosition) {
      setMapPosition(position);
      setMapCenter({ lat: position.latitude, lng: position.longitude });
    }
  }, [position]);

  useEffect(() => {
    loadGeofences();
    const selectedFleet = fleets.find((x) => x.id === fleetId);
    setMapPosition({
      latitude: selectedFleet.cost.headquarter.lat,
      longitude: selectedFleet.cost.headquarter.lng,
    });
    setMapCenter({
      lat: selectedFleet.cost.headquarter.lat,
      lng: selectedFleet.cost.headquarter.lng,
    });
  }, [fleetId]);

  if (!mapPosition) {
    return null;
  }

  return (
    <>
      <div className={[styles.main].join(' ')}>
        <div className={styles.list}>
          <div className={styles.actions}>
            <div>
              <Button
                type="primary"
                block
                onClick={onCreateGeofence}
              >
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
                Agregar zona
              </Button>
            </div>
          </div>
          <div className={styles.content}>
            {geofences && fleetId
              && geofences.filter(
                (x) => x.fleet_id === fleetId,
              ).map((g) => (
                <GeofenceItem
                  key={g.id}
                  geofence={g}
                  fleets={fleets}
                  onStartEditing={(id) => onStartEditingGeofence(id)}
                  onSaveEditing={onSaveEditingGeofence}
                  onEndEditing={onEndEditingGeofence}
                  onDelete={onDeleteGeofence}
                  open={g.editable}
                />
              ))}

          </div>
        </div>

        <div className={styles.map}>
          <MapComponent
            fleetId={fleetId}
            zoom={13}
            key={fleetId}
            defaultCenter={{ lat: mapPosition.latitude, lng: mapPosition.longitude }}
            panTo={mapPanTo && { lat: mapPanTo.latitude, lng: mapPanTo.longitude }}
            minZoom={1}
            onBoundsChanged={(p1, p2, center) => { setMapCenter(center); }}
            maxZoom={20}
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: isMobile() ? 300 : 'calc(100% - 40px)' }} />}
            containerElement={(
              <div
                style={{
                  height: isMobile() ? 400 : window.innerHeight - 40,
                }}
              />
            )}
            onClickPolygon={(polygon) => onStartEditingGeofence(polygon.id)}
            mapElement={<div style={{ borderRadius: 5, height: isMobile() ? 400 : '100%' }} />}
            markers={[]}
            onPolygonPathChange={onGeofencePathChanged}
            polygons={geofences && fleetId
              && geofences.filter(
                (x) => x.fleet_id === fleetId,
              ).map((x) => ({ ...x, color: x.status === 'INACTIVE' ? '#999999' : (colorZone[x.type]) }))}
          />
        </div>
        {snackMessage
          && (
            <div className={[styles.message, styles[snackMessage.type]].join(' ')}>
              {snackMessage.message}
            </div>
          )}
      </div>
    </>
  );
};
GeoModule.propTypes = {
  position: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};
export default GeoModule;
