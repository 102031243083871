/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './index.module.scss';

const MqttLogList = ({
  events,
  showMore,
}) => (

  <div className={styles.events}>
    {(!events || events.length === 0)
      ? <div>No hay eventos</div>
      : (
        <div className={styles.list}>
          {
            events.map((e) => (
              <div className={styles.item}>
                <div className={styles.date}>
                  {moment.utc(e.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
                  <br />
                  {e.message_type}
                </div>
                <div className={styles.data}>
                  <div style={{ fontFamily: 'monospace', wordBreak: 'break-all' }}>
                    <small>
                      {JSON.stringify(e.data)}
                    </small>

                  </div>
                </div>
              </div>
            ))
          }
          {showMore}
        </div>
      )}
  </div>
);
MqttLogList.defaultProps = {
  showMore: null,
};

MqttLogList.propTypes = {
  events: PropTypes.array.isRequired,
  showMore: PropTypes.object,
};

export default MqttLogList;
