export default (client, defaultParams) => ({
  all: () => (
    client('/fleets', {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  setDevicesStatus: (payload) => (
    client('/fleet/set_devices_status', {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  getMessage: (k) => {
    const messages = {
      '': '',
    };
    return (messages[k] || k);
  },
});
