/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isMobile from 'is-mobile';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLock,
  faUnlock,
  faPlug,
  faCog,
  faPowerOff,
  faExclamationCircle,
  faLocationArrow,
  faVolumeMute,
  faVolumeUp,
} from '@fortawesome/free-solid-svg-icons';

import { Button, notification } from 'antd';
import wifi0 from '../../../assets/signal/wifi-0.svg';
import wifi1 from '../../../assets/signal/wifi-1.svg';
import wifi2 from '../../../assets/signal/wifi-2.svg';
import wifi3 from '../../../assets/signal/wifi-3.svg';
import wifi4 from '../../../assets/signal/wifi-4.svg';

import styles from './index.module.scss';

import {
  getBatteryIcon,
} from '../../../helpers/params';
import { getCommandForAction, getDeviceByTypeQROrRef, sendDeviceCommand } from '../../../services/devices';

const ScooterBlock = ({
  // eslint-disable-next-line no-unused-vars
  user,
  defaultDevice,
}) => {
  const [device, setDevice] = useState(defaultDevice);

  useEffect(() => { setDevice(defaultDevice); }, [defaultDevice]);

  const updateDevice = async () => {
    if (!device) {
      return;
    }
    const rsp = await getDeviceByTypeQROrRef({
      type: device.type,
      code: device.ref,
    });
    if (rsp && rsp.status) {
      setDevice(rsp.data);
    }
  };

  const onAction = async (action, params = null, showNotification = true) => {
    const command = getCommandForAction(action, params);
    if (!command) {
      return null;
    }
    if (showNotification) {
      notification.destroy('in_progress');
      notification.destroy('result');
      notification.info({
        message: 'Enviando comando...',
        placement: 'bottom',
        duration: 0,
        key: 'in_progress',
      });
    }
    const rsp = await sendDeviceCommand({
      type: device.type,
      ref: device.ref,
      command,
      sync: true,
    });
    if (showNotification) {
      notification.destroy('in_progress');
    }
    if ((rsp?.status)) {
      if (showNotification) {
        notification.success({
          message: 'Enviado con éxito.',
          placement: 'bottom',
          key: 'result',
        });
      }
    } else if (showNotification) {
      notification.error({
        message: 'Ocurrió un error al enviar el comando. Intenta nuevamente.',
        placement: 'bottom',
        key: 'result',
      });
    }
    updateDevice();
    return rsp?.status;
  };

  if (!device) {
    return null;
  }
  let connectionColor = null;
  let signalLevel = null;
  let signalIcon = wifi0;
  if (device.data?.rssi) {
    if (device.data?.rssi < 10) {
      connectionColor = '#f60';
      signalLevel = 'Muy baja señal';
      signalIcon = wifi1;
    } else if (device.data?.rssi < 20) {
      connectionColor = '#fc0';
      signalLevel = 'Baja señal';
      signalIcon = wifi2;
    } else if (device.data?.rssi < 30) {
      connectionColor = '#06c';
      signalLevel = 'Señal buena';
      signalIcon = wifi3;
    } else {
      signalLevel = 'Excelente señal';
      connectionColor = '#093';
      signalIcon = wifi4;
    }
  }
  if (!device.is_online_last_30s) {
    signalIcon = wifi0;
    connectionColor = '#c30';
  }

  return (
    <>
      <div className={styles.device}>
        <div className={styles.icons}>
          <div className={styles.connectionStatus}>
            <img
              alt=""
              title={`${device.is_online_last_30s ? 'Online (últ 30s)' : 'Offline (últ 30s)'} ${signalLevel && ` - ${signalLevel}`}`}
              src={signalIcon}
              style={{
                width: 25, marginTop: -2, marginLeft: 12, color: connectionColor,
              }}
            />
            <div className={styles.legend}>
              {device.is_online_last_30s ? 'Online (últ 30s)' : 'Offline (últ 30s)'}
              {signalLevel && ` - ${signalLevel}`}
            </div>
          </div>
          <div className={styles.lockStatus}>
            <FontAwesomeIcon
              icon={
                device.data.locked === 1 ? faLock : (device.data.locked === 0 ? faUnlock : null)
              }
              className={device.data.locked ? styles.locked : styles.unlocked}
            />
            <div className={styles.legend}>
              {device.data.locked ? 'Bloqueado' : 'Desbloqueado'}
            </div>
          </div>
          <div className={styles.engineStatus}>
            <FontAwesomeIcon
              icon={faCog}
              className={device.data.engine_on === '1' ? styles.engineOn : styles.engineOff}
            />
            <br />
            <div className={styles.legend}>
              {device.data.engine_on === '1' ? 'Motor encendido' : 'Motor apagado'}
            </div>
          </div>
          <div className={styles.battery}>
            <div className={styles.info}>
              <FontAwesomeIcon icon={getBatteryIcon(device.data.batsco)} style={{ fontSize: 18 }} />
              {' '}
              {device.data.charging === '1' ? <FontAwesomeIcon icon={faPlug} style={{ fontSize: 12 }} /> : null}
              {' '}
              {device.data.batsco}
            </div>
            <div className={styles.legend}>Batería</div>
          </div>
        </div>
        <div className={styles.info}>
          {device.data?.coderr > 0 && (
            <div style={{
              display: 'inline-block',
              background: '#f33',
              color: '#fff',
              fontWeight: 'bold',
              padding: '5px 15px',
              borderRadius: 20,
              fontSize: 14,
              marginBottom: 40,
            }}
            >
              Error
              {' '}
              {device.data?.coderr}
            </div>
          )}
          <table className={styles.table}>

            <tr>
              <td width="25%">Status</td>
              <td>
                {device.status_tr}
                {device.data?.out_of_area ? ' - FUERA DE AREA' : ''}
              </td>
            </tr>
            <tr>
              <td width="25%">Últ Mensaje:</td>
              <td>
                {device.data.last_message_from_device_at ? (
                  <>
                    <abbr title={moment.utc(device.data.last_message_from_device_at).local().format('YYYY-MM-DD HH:mm:ss')}>
                      {moment.utc(device.data.last_message_from_device_at).fromNow()}

                    </abbr>
                    {' '}
                    -
                    {' '}
                    {moment.utc(device.data.last_message_from_device_at).local().format('YYYY-MM-DD HH:mm:ss')}
                    <br />
                    <div style={{ fontFamily: 'monospace', wordBreak: 'break-all' }}><small>{JSON.stringify(device.data?.last_message_from_device)}</small></div>
                  </>
                )
                  : 'Sin info'}

              </td>
            </tr>
            <tr>
              <td>Heartbeat</td>
              <td>
                {device.data.last_message_from_device_at ? (
                  <>
                    <abbr title={moment.utc(device.data.last_heartbeat_from_device_at).local().format('YYYY-MM-DD HH:mm:ss')}>
                      {moment.utc(device.data.last_heartbeat_from_device_at).fromNow()}

                    </abbr>
                    {' '}
                    -
                    {' '}
                    {moment.utc(device.data.last_heartbeat_from_device_at).local().format('YYYY-MM-DD HH:mm:ss')}
                    , (p:
                    {' '}
                    {device.data.heartbeat_period}
                    s)
                  </>
                )
                  : 'Sin info'}
                {' '}
                <Button
                  size="small"
                  onClick={() => onAction('set_heartbeat', { period: 20 })}
                  type="secondary"
                >

                  Set 20s
                </Button>
                {' '}
                <Button
                  size="small"
                  onClick={() => onAction('set_heartbeat', { period: 600 })}
                  type="secondary"
                >

                  Set 10m
                </Button>
              </td>
            </tr>
          </table>
          <div className={styles.actions}>
            <>
              <div className={styles.commands} style={{ gridTemplateColumns: `repeat(${isMobile() ? 2 : 4}, 1fr)` }}>
                <Button
                  onClick={() => onAction('unlock')}
                  size="small"
                  type="secondary"
                >
                  <FontAwesomeIcon icon={faUnlock} />
                  Desbloquear
                </Button>
                <Button
                  onClick={() => onAction('lock')}
                  size="small"
                  type="secondary"
                >
                  <FontAwesomeIcon icon={faLock} />
                  Bloquear
                </Button>
                <Button
                  onClick={() => onAction('buzz_on')}
                  size="small"
                  type="secondary"
                >
                  <FontAwesomeIcon icon={faVolumeUp} />
                  Buzz On
                </Button>
                <Button
                  onClick={() => onAction('buzz_off')}
                  size="small"
                  type="secondary"
                >
                  <FontAwesomeIcon icon={faVolumeMute} />
                  Buzz Off
                </Button>
                <Button
                  onClick={() => onAction('request_geolocation')}
                  size="small"
                  type="secondary"
                >
                  <FontAwesomeIcon icon={faLocationArrow} />
                  Act geolocalización
                </Button>
                {user?.can.devices_advanced_ops && (
                  <>
                    <Button
                      size="small"
                      onClick={() => onAction('alerts_on')}
                      type="secondary"
                    >
                      <FontAwesomeIcon icon={faExclamationCircle} />
                      Hab. Alertas
                    </Button>
                    <Button
                      onClick={() => onAction('alerts_off')}
                      type="secondary"
                      size="small"
                    >
                      Deshab. Alertas
                    </Button>

                    <Button
                      onClick={() => onAction('power_off')}
                      size="small"
                      danger
                    >
                      <FontAwesomeIcon icon={faPowerOff} />
                      Apagar
                    </Button>
                  </>
                )}
              </div>

            </>
          </div>
        </div>
      </div>

    </>
  );
};
ScooterBlock.defaultProps = {
};
ScooterBlock.propTypes = {
  user: PropTypes.object.isRequired,
  defaultDevice: PropTypes.object.isRequired,
};
export default ScooterBlock;
