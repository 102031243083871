import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Table } from 'antd';

import numeral from 'numeral';
import moment from 'moment';
import isMobile from 'is-mobile';
import useBiTable from '../../../hooks/useBiTable';
import useHorusQuery from '../../../hooks/useHorusQuery';

import Loading from '../../../components/Loading';

import stylesLayout from '../../../common/layout.module.scss';
import {
  RenderLineChart, RenderBarChart, avgLast, accumulatedArray,
} from '../../../components/Charts';
import Metric from '../../../components/Metric';
import DeltaPercentageBadge from '../../../components/DeltaPercentBadge';

const SalesModule = ({
  selectedFleet,
}) => {
  const {
    data,
    status,
    load,
  } = useBiTable();
  const {
    data: hourlyData,
    load: loadHourly,
  } = useHorusQuery();

  const loadAll = () => {
    load(`${selectedFleet?.id}_daily_resume`);

    const hourlyQuery = `
  select * from (         
    select
    'today' type,
    weekday_hour,
    round(avg(count_non_paid),2) count_non_paid,
    round(avg(amount_non_paid),2) amount_non_paid,
    round(avg(count_paid),2) count_paid,
    round(avg(amount_paid),2) amount_paid,
    round(avg(count_paid_new_users),2) count_paid_new_users,
    round(avg(amount_paid_new_users),2) amount_paid_new_users,
    round(avg(count),2) count,
    round(avg(amount_total),2) amount_total,
    round(avg(devices_used),2) devices_used,
    round(avg(minutes),2) minutes
    
    from (         
             
             select
       concat(WEEKDAY(t.trip_start_at + interval ${selectedFleet?.cost?.tz} hour),'-',date_format(t.trip_start_at + interval ${selectedFleet?.cost?.tz} hour, '%H')) weekday_hour,
       date_format(t.trip_start_at + interval ${selectedFleet?.cost?.tz} hour, '%m-%d') day,
       sum(if(t.status = 'FINISHED', 1, 0)) count_non_paid,
       round(sum(if(t.status = 'FINISHED', t.total / json_extract(cop.data, '$.fx')*json_extract(cot.data, '$.fx'), 0))) amount_non_paid,
       sum(if(t.status = 'PAID', 1, 0)) count_paid,
       round(sum(if(t.status = 'PAID', t.total / json_extract(cop.data, '$.fx')*json_extract(cot.data, '$.fx'), 0))) amount_paid,
       sum(if(t.status = 'PAID' and u.created_at>t.trip_start_at-interval 24 hour, 1, 0)) count_paid_new_users,
       round(sum(if(t.status = 'PAID' and u.created_at>t.trip_start_at-interval 24 hour, t.total / json_extract(cop.data, '$.fx')*json_extract(cot.data, '$.fx'), 0))) amount_paid_new_users,
       count(*) count,
       round(sum(t.total / json_extract(cop.data, '$.fx')*json_extract(cot.data, '$.fx'))) amount_total,
       count(distinct t.device_id) as devices_used,
       avg(TIMESTAMPDIFF(SECOND, t.trip_start_at, t.end_at)/60) minutes
    from
       trips t use index (ix_trips_trip_start_at),
       users u,
       countries cop,
       countries cot
    where
       cop.code = json_unquote(json_extract(t.data, '$.country'))
       and cot.code = json_unquote(json_extract(t.data, '$.cost_scheme.country'))
       and t.user_id = u.id
       and (t.total > 0)
       and t.total / json_extract(cop.data, '$.fx') < 30
       and t.fleet_id = ${selectedFleet?.id}
       and t.status in
       (
          'FINISHED',
          'PAID'
       )
       and (
        (
         t.trip_start_at between date(now()+interval ${selectedFleet?.cost?.tz} hour)-interval ${selectedFleet?.cost?.tz} hour-interval 0 day
            and date(now()+interval ${selectedFleet?.cost?.tz} hour)-interval ${selectedFleet?.cost?.tz} hour-interval -1 day
        )
       )
           
       and u.email not like '%@grow.mobi'
       and u.email<>'pviojo@gmail.com'
    group by
       1,2
    order by
       1
    ) tmp group by 1,2
    union all
    select 
    'prev',
    k, 
    round(json_unquote(json_extract(data, '$.count_non_paid')),2),
    round(json_unquote(json_extract(data, '$.amount_non_paid')),2),
    round(json_unquote(json_extract(data, '$.count_paid')),2),
    round(json_unquote(json_extract(data, '$.amount_paid')),2),
    round(json_unquote(json_extract(data, '$.count_paid_new_users')),2),
    round(json_unquote(json_extract(data, '$.amount_paid_new_users')),2),
    round(json_unquote(json_extract(data, '$.count')),2),
    round(json_unquote(json_extract(data, '$.amount_total')),2),
    round(json_unquote(json_extract(data, '$.devices_used')),2),
    round(json_unquote(json_extract(data, '$.minutes')),2)
    
    
    from paperboy_bi.store where tbl='${selectedFleet?.id}_hourly_weekday_resume' and k like '${(moment().isoWeekday() - 1)}_%'
    ) tmp order by 1,2;
  
  `;

    loadHourly(hourlyQuery);
  };

  useEffect(() => {
    loadAll();
  }, []);
  useEffect(() => {
    loadAll();
  }, [selectedFleet?.id]);

  if (status === 'loading') {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading />
        </div>
      </div>
    );
  }

  const tableColumns = [
    {
      title: 'Día',
      dataIndex: 'day',
      key: 'day',
      width: 100,
      defaultSortOrder: 'descend',
      render: (v, r) => (
        <>
          {v}
          <br />
          <small>{r.weekday}</small>
        </>
      ),
      sorter: (a, b) => (a.day < b.day ? -1 : 1),
    },

    {
      title: 'Pagados',
      dataIndex: 'count_paid',
      key: 'count_paid',
      align: 'right',
      render: (v, r) => (
        <>
          {numeral(v).format('0,000')}
          <br />
          <small>
            <DeltaPercentageBadge n={r.delta_count_paid_7d * 100} />
          </small>
        </>
      ),
    },
    {
      title: 'Monto pagado',
      dataIndex: 'amount_paid',
      key: 'amount_paid',
      align: 'right',
      render: (v, r) => (
        <>
          {numeral(v).format('0,000')}
          <br />
          <small>
            <DeltaPercentageBadge n={r.delta_amount_paid_7d * 100} />
          </small>
        </>
      ),
    },
    {
      title: 'Pagados por nuevos usuarios',
      dataIndex: 'count_paid_new_users',
      key: 'count_paid_new_users',
      align: 'right',
      render: (v) => numeral(v).format('0,000'),
    },
    {
      title: 'Monto pagado por nuevos usuarios',
      dataIndex: 'amount_paid_new_users',
      key: 'amount_paid_new_users',
      align: 'right',
      render: (v) => numeral(v).format('0,000'),
    },
    {
      title: '% viajes pagados nuevos usuarios',
      dataIndex: 'x',
      key: 'x',
      align: 'right',
      render: (v, r) => numeral((r.count_paid_new_users / r.count_paid) * 100).format('0,000.0'),
    },
    {
      title: '% monto pagados nuevos usuarios',
      dataIndex: 'x',
      key: 'x',
      align: 'right',
      render: (v, r) => numeral((r.amount_paid_new_users / r.amount_paid) * 100).format('0,000.0'),
    },
    {
      title: 'Total',
      dataIndex: 'count',
      key: 'count',
      align: 'right',
      render: (v) => numeral(v).format('0,000'),
    },
    {
      title: 'Monto total',
      dataIndex: 'amount_total',
      key: 'amount_total',
      align: 'right',
      render: (v) => numeral(v).format('0,000'),
    },
    {
      title: 'Patines usados',
      dataIndex: 'devices_used',
      key: 'devices_used',
      align: 'right',
      render: (v) => numeral(v).format('0,000'),
    },
    {
      title: 'Mins promedio',
      dataIndex: 'minutes',
      key: 'minutes',
      align: 'right',
      render: (v) => numeral(v).format('0,000.00'),
    },
    {
      title: 'No pagados',
      dataIndex: 'count_non_paid',
      key: 'count_non_paid',
      align: 'right',
      render: (v) => numeral(v).format('0,000'),
    },
    {
      title: 'Monto no pagado',
      dataIndex: 'amount_non_paid',
      key: 'amount_non_paid',
      align: 'right',
      render: (v) => numeral(v).format('0,000'),
    },
    {
      title: '% No pagado',
      dataIndex: 'x',
      key: 'x',
      align: 'right',
      render: (v, r) => numeral((r.count_non_paid / r.count) * 100).format('0,000.0'),
    },
    {
      title: '% Monto No pagado',
      dataIndex: 'x',
      key: 'x',
      align: 'right',
      render: (v, r) => numeral((r.amount_non_paid / r.amount_total) * 100).format('0,000.0'),
    },
    {
      title: 'Monto por viaje',
      dataIndex: 'x',
      key: 'x',
      align: 'right',
      render: (v, r) => numeral(r.amount_paid / r.count_paid).format('0,000.00'),
    },
    {
      title: 'Monto por patín usado',
      dataIndex: 'x',
      key: 'x',
      align: 'right',
      render: (v, r) => numeral(r.amount_paid / r.devices_used).format('0,000.00'),
    },
    {
      title: 'VOS',
      dataIndex: 'max_vos',
      key: 'max_vos',
      align: 'right',
      render: (v, r) => (
        <>
          {numeral(v).format('0,000')}
          <br />
          <small>
            <DeltaPercentageBadge n={r.delta_max_vos_7d * 100} />
          </small>
        </>
      ),
    },
    {
      title: 'VOS Promedio',
      dataIndex: 'avg_vos',
      key: 'avg_vos',
      align: 'right',
      render: (v, r) => (
        <>
          {numeral(v).format('0,000')}
          {' '}
          <small>
            (
            {numeral((v / r.max_vos) * 100).format('0,000')}
            %)
          </small>
          <br />
          <small>
            <DeltaPercentageBadge n={r.delta_avg_vos_7d * 100} />
          </small>

        </>
      ),
    },

    {
      title: 'UTR Viajes/VOS',
      dataIndex: 'max_vos',
      key: 'max_vos',
      align: 'right',
      render: (v, r) => numeral(r.count_paid / r.max_vos).format('0,000.00'),
    },
    {
      title: 'UTR Viajes/Patín usado',
      dataIndex: 'max_vos',
      key: 'max_vos',
      align: 'right',
      render: (v, r) => numeral(r.count_paid / r.devices_used).format('0,000.00'),
    },
    {
      title: 'AR %',
      dataIndex: 'ar',
      key: 'ar',
      align: 'right',
      render: (v, r) => numeral(Math.min(r.devices_used / r.max_vos, 1) * 100).format('0,000'),
    },
    {
      title: 'Monto pagado por VOS',
      dataIndex: 'ar',
      key: 'ar',
      align: 'right',
      render: (v, r) => numeral(r.amount_paid / r.max_vos).format('0,000.00'),
    },
  ];

  const hourlyTableColumns = [
    {
      title: 'Hora',
      dataIndex: 'weekday_hour',
      key: 'weekday_hour',
      width: 100,
      defaultSortOrder: 'ascend',
      render: (v) => (
        <>
          {parseInt(v.split('-').slice(-1), 10)}
        </>
      ),
      sorter: (a, b) => (a.weekday_hour < b.weekday_hour ? -1 : 1),
    },

    {
      title: 'Pagados hoy vs/prev',
      dataIndex: 'count_paid',
      key: 'count_paid',
      align: 'right',
      render: (v, r) => (
        <>
          {numeral(v).format('0,000')}
          {' '}
          /
          {' '}
          {numeral(r.prev.count_paid || 0).format('0,000')}
          {' '}
          <span className={r.diff_pct_count_paid > 0 ? stylesLayout.badgePositive : stylesLayout.badgeNegative}>{r.prev.count_paid ? r.diff_pct_count_paid_str : ''}</span>
        </>
      ),
    },
    {
      title: 'Monto pagado hoy vs/prev',
      dataIndex: 'amount_paid',
      key: 'amount_paid',
      align: 'right',
      render: (v, r) => (
        <>
          {numeral(v).format('0,000')}
          {' '}
          /
          {' '}
          {numeral(r.prev.amount_paid || 0).format('0,000')}
          {' '}
          <span
            className={r.diff_pct_amount_paid > 0
              ? stylesLayout.badgePositive : stylesLayout.badgeNegative}
          >
            {r.prev.count_paid ? r.diff_pct_amount_paid_str : ''}
          </span>
        </>
      ),
    },
  ];

  const hourlyResume = hourlyData.filter((x) => x.type === 'today')
    .map((x) => ({
      ...x,
      prev: hourlyData.find((x2) => x2.type === 'prev' && x2.weekday_hour === x.weekday_hour) || {},
    })).map((x) => ({
      ...x,
      diff_pct_count_paid: parseInt(((x.count_paid / x.prev.count_paid) - 1) * 100, 10),
      diff_pct_count_paid_str: `${x.count_paid / x.prev.count_paid >= 1 ? '+' : ''}${parseInt(((x.count_paid / x.prev.count_paid) - 1) * 100, 10)}%`,
      diff_pct_amount_paid: parseInt(((x.amount_paid / x.prev.amount_paid) - 1) * 100, 10),
      diff_pct_amount_paid_str: `${x.amount_paid / x.prev.amount_paid >= 1 ? '+' : ''}${parseInt(((x.amount_paid / x.prev.amount_paid) - 1) * 100, 10)}%`,
    }));

  console.log('hourlyData', hourlyResume);
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        <div style={{ float: 'right' }}>
          <Button onClick={loadAll} size="large">
            <FontAwesomeIcon icon={faSyncAlt} spin={status === 'loading'} />
          </Button>
        </div>
        Reporte - Ventas
        <br />
        <small>{selectedFleet.name}</small>
        <div className={stylesLayout.subtitle}>
          * Moneda y hora local (Chile considera GMT-3)
        </div>
      </div>
      <div className={stylesLayout.content}>
        <div className={stylesLayout.sectionHeader}>Hoy</div>
        <div style={{
          marginBottom: 'var(--spacing)', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 'var(--spacing)',
        }}
        >
          <Metric
            title="Pagados"
            format="0,000"
            n={
              hourlyResume.reduce((a, x) => a + x.count_paid, 0)
            }
            isDeltaPositive={(delta) => delta >= 0}
            delta={
              (hourlyResume.reduce((a, x) => a + x.count_paid, 0)
                / hourlyResume.reduce((a, x) => a + (x.prev.count_paid || 0), 0)) - 1
            }
          />
          <Metric
            title="Monto pagado"
            format="$0,000"
            n={
              hourlyResume.reduce((a, x) => a + x.amount_paid, 0)
            }
            isDeltaPositive={(delta) => delta >= 0}
            delta={
              (hourlyResume.reduce((a, x) => a + x.amount_paid, 0)
                / hourlyResume.reduce((a, x) => a + (x.prev.amount_paid || 0), 0)) - 1
            }
          />
        </div>

        <Table
          size="small"
          pagination={{ hideOnSinglePage: true, defaultPageSize: 30 }}
          locale={{ emptyText: 'Sin datos' }}
          columns={hourlyTableColumns}
          rowClassName={(r) => (
            (r.diff_pct_amount_paid > 0) ? stylesLayout.rowPositive : stylesLayout.rowNegative
          )}
          dataSource={hourlyResume}
        />

        <div className={stylesLayout['2cols']}>
          <div>
            <RenderBarChart
              data={
                hourlyResume.map((x) => ({
                  h: parseInt(x.weekday_hour.split('-').slice(-1), 10),
                  amount_paid: x.amount_paid,
                  prev_amount_paid: x.prev.amount_paid,
                }))
              }
              yAxisScale="linear"
              xAxisType="linear"
              yAxisMin={0}
              title={(
                <>
                  Monto pagado por hora
                </>
              )}
              width={100}
              height={isMobile() ? 60 : 40}
              xAxisStepSize={1}
              xLabelsField="h"
              yDatasets={{
                'Pagado hoy': 'amount_paid',
                Previo: 'prev_amount_paid',
              }}
            />
          </div>
          <div>
            <RenderLineChart
              data={
                accumulatedArray(
                  accumulatedArray(
                    hourlyResume.map((x) => ({
                      h: parseInt(x.weekday_hour.split('-').slice(-1), 10),
                      amount_paid: x.amount_paid,
                      prev_amount_paid: x.prev.amount_paid,
                    })), 'amount_paid', 'acc_amount_paid',
                  ),
                  'prev_amount_paid', 'acc_prev_amount_paid',
                )
              }
              yAxisScale="linear"
              xAxisType="linear"
              yAxisMin={0}
              title={(
                <>
                  Monto pagado por hora acumulado
                </>
              )}
              width={100}
              height={isMobile() ? 60 : 40}
              xAxisStepSize={1}
              xLabelsField="h"
              yDatasets={{
                'Pagado hoy': 'acc_amount_paid',
                Previo: 'acc_prev_amount_paid',
              }}
            />
          </div>
        </div>
        <div className={stylesLayout.sectionHeader}>Histórico</div>
        <div style={{ fontSize: 'var(--small)', marginBottom: 10 }}>
          Actualizado:
          {' '}
          {moment.utc(data[data.length - 1]?.updated_at).fromNow()}
        </div>

        <Table
          size="small"
          pagination={{ hideOnSinglePage: true, defaultPageSize: 14 }}
          locale={{ emptyText: 'Sin datos' }}
          rowClassName={(r) => (
            moment(r.day).weekday() >= 5 ? stylesLayout.rowWeekend : stylesLayout.rowWeekday
          )}
          columns={tableColumns}
          dataSource={data.map((x, i) => ({
            ...x.data,
            delta_count_paid_7d: data[i - 7]?.data.count_paid
              ? (x.data.count_paid / data[i - 7]?.data.count_paid) - 1 : null,
            delta_amount_paid_7d: data[i - 7]?.data.amount_paid
              ? (x.data.amount_paid / data[i - 7]?.data.amount_paid) - 1 : null,
            delta_max_vos_7d: data[i - 7]?.data.max_vos
              ? (x.data.max_vos / data[i - 7]?.data.max_vos) - 1 : null,
            delta_avg_vos_7d: data[i - 7]?.data.avg_vos
              ? (x.data.avg_vos / data[i - 7]?.data.avg_vos) - 1 : null,

            updated_at: x.updated_at,
          }))}
        />

        <RenderLineChart
          data={avgLast(
            data.map((x) => ({
              day: x.data.day,
              count_paid: x.data.count_paid,
            })),
            7, 'count_paid', 'count_paid_avg7',
          )}
          yAxisScale="linear"
          xAxisType="linear"
          yAxisMin={0}
          title={(
            <>
              Viajes pagados
            </>
          )}
          width={100}
          height={isMobile() ? 60 : 28}
          xAxisStepSize={1}
          xLabelsField="day"
          yDatasets={{
            'Viajes Pagados': 'count_paid',
            'Media Móvil 7': 'count_paid_avg7',
          }}
        />
        <RenderLineChart
          data={avgLast(
            data.map((x) => ({
              day: x.data.day,
              amount_paid: x.data.amount_paid,
            })),
            7, 'amount_paid', 'amount_paid_avg7',
          )}
          yAxisScale="linear"
          xAxisType="linear"
          yAxisMin={0}
          title={(
            <>
              Monto pagado
            </>
          )}
          width={100}
          height={isMobile() ? 60 : 28}
          xAxisStepSize={1}
          xLabelsField="day"
          yDatasets={{
            'Monto Pagado': 'amount_paid',
            'Media Móvil 7': 'amount_paid_avg7',
          }}
        />

        <RenderLineChart
          data={avgLast(
            avgLast(
              data.map((x) => ({
                day: x.data.day,
                vos: x.data.max_vos,
                avg_vos: x.data.avg_vos,
              })),
              7, 'vos', 'vos_avg7',
            ), 7, 'avg_vos', 'avg_vos_avg7',
          )}
          yAxisScale="linear"
          xAxisType="linear"
          yAxisMin={0}
          title={(
            <>
              VOS
            </>
          )}
          width={100}
          height={isMobile() ? 60 : 28}
          xAxisStepSize={1}
          xLabelsField="day"
          yDatasets={{
            VOS: 'vos',
            'Media Móvil 7 VOS': 'vos_avg7',
            'Avg VOS': 'avg_vos',
            'Media Móvil 7 Avg VOS': 'avg_vos_avg7',
          }}
        />
        <RenderLineChart
          data={
            avgLast(
              data.map((x) => ({
                day: x.data.day,
                paid_per_vos: x.data.amount_paid / x.data.max_vos,
              })),
              7, 'paid_per_vos', 'paid_per_vos_avg7',
            )
          }
          yAxisScale="linear"
          xAxisType="linear"
          yAxisMin={0}
          title={(
            <>
              Monto pagado por VOS
            </>
          )}
          width={100}
          height={isMobile() ? 60 : 28}
          xAxisStepSize={1}
          xLabelsField="day"
          yDatasets={{
            'Pagado por VOS': 'paid_per_vos',
            'Media Móvil 7': 'paid_per_vos_avg7',
          }}
        />
        <RenderLineChart
          data={
            avgLast(
              data.map((x) => ({
                day: x.data.day,
                paid_per_trip: x.data.amount_paid / x.data.count_paid,
              })),
              7, 'paid_per_trip', 'paid_per_trip_avg7',
            )
          }
          yAxisScale="linear"
          xAxisType="linear"
          yAxisMin={0}
          title={(
            <>
              Monto pagado por viaje
            </>
          )}
          width={100}
          height={isMobile() ? 60 : 28}
          xAxisStepSize={1}
          xLabelsField="day"
          yDatasets={{
            'Monto pagado por viaje': 'paid_per_trip',
            'Media Móvil 7 Monto pagado por viaje': 'paid_per_trip_avg7',
          }}
        />

        <RenderLineChart
          data={
            avgLast(
              avgLast(
                data.map((x) => ({
                  day: x.data.day,
                  pct_count_paid_new_users: (
                    x.data.count_paid_new_users / x.data.count_paid) * 100,
                  pct_amount_paid_new_users: (
                    x.data.amount_paid_new_users / x.data.amount_paid) * 100,
                })),
                7, 'pct_count_paid_new_users', 'pct_count_paid_new_users_avg7',
              ), 7, 'pct_amount_paid_new_users', 'pct_amount_paid_new_users_avg7',
            )
          }
          yAxisScale="linear"
          xAxisType="linear"
          yAxisMin={0}
          title={(
            <>
              % Viajes pagados por nuevos usuarios
            </>
          )}
          width={100}
          height={isMobile() ? 60 : 28}
          xAxisStepSize={1}
          xLabelsField="day"
          yDatasets={{
            'Media Móvil 7 % Viajes pagado por nuevos usuarios': 'pct_count_paid_new_users_avg7',
            'Media Móvil 7 % Monto pagado por nuevos usuarios': 'pct_amount_paid_new_users_avg7',
          }}
        />
        <RenderLineChart
          data={
            avgLast(
              data.map((x) => ({
                day: x.data.day,
                minutes: x.data.minutes,
              })),
              7, 'minutes', 'minutes_avg7',
            )
          }
          yAxisScale="linear"
          xAxisType="linear"
          yAxisMin={0}
          title={(
            <>
              Minutos promedio por viaje
            </>
          )}
          width={100}
          height={isMobile() ? 60 : 28}
          xAxisStepSize={1}
          xLabelsField="day"
          yDatasets={{
            'Minutos promedio por viaje': 'minutes',
            'Media Móvil 7 minutos promedio por viajee': 'minutes_avg7',
          }}
        />
        <RenderLineChart
          data={
            avgLast(
              data.map((x) => ({
                day: x.data.day,
                ar: Math.min(x.data.devices_used / x.data.max_vos, 1) * 100,
              })),
              7, 'ar', 'ar_avg7',
            )
          }
          yAxisScale="linear"
          xAxisType="linear"
          yAxisMin={0}
          title={(
            <>
              Activation Rate %
            </>
          )}
          width={100}
          height={isMobile() ? 60 : 28}
          xAxisStepSize={1}
          xLabelsField="day"
          yDatasets={{
            AR: 'ar',
            'Media Móvil 7': 'ar_avg7',
          }}
        />
        <RenderLineChart
          data={
            avgLast(
              avgLast(
                data.map((x) => ({
                  day: x.data.day,
                  utr_vos: x.data.count_paid / x.data.max_vos,
                  utr_devices: x.data.count_paid / x.data.devices_used,
                })),
                7, 'utr_vos', 'utr_vos_avg7',
              ), 7, 'utr_devices', 'utr_devices_avg7',
            )
          }
          yAxisScale="linear"
          xAxisType="linear"
          yAxisMin={0}
          title={(
            <>
              UTR
            </>
          )}
          width={100}
          height={isMobile() ? 60 : 28}
          xAxisStepSize={1}
          xLabelsField="day"
          yDatasets={{
            'UTR VOS': 'utr_vos',
            'Media Móvil 7 UTR VOS': 'utr_vos_avg7',
            'UTR Patines usados': 'utr_devices',
            'Media Móvil 7 UTR Patines usados': 'utr_devices_avg7',
          }}
        />
        <RenderLineChart
          data={
            avgLast(
              avgLast(
                data.map((x) => ({
                  day: x.data.day,
                  utr_vos: x.data.count_paid / x.data.max_vos,
                  utr_devices: x.data.count_paid / x.data.devices_used,
                })),
                7, 'utr_vos', 'utr_vos_avg7',
              ), 7, 'utr_devices', 'utr_devices_avg7',
            ).map((x) => ({
              day: x.day,
              ratio_utr: x.utr_vos / x.utr_devices,
              ratio_utr_avg7: x.utr_vos_avg7 / x.utr_devices_avg7,
            }))
          }
          yAxisScale="linear"
          xAxisType="linear"
          yAxisMin={0}
          title={(
            <>
              UTR Ratio (VOS/Used)
            </>
          )}
          width={100}
          height={isMobile() ? 60 : 28}
          xAxisStepSize={1}
          xLabelsField="day"
          yDatasets={{
            'Ratio UTR': 'ratio_utr',
            'Ratio Media Móvil 7 UTR': 'ratio_utr_avg7',
          }}
        />
      </div>
    </div>
  );
};
SalesModule.propTypes = {
  selectedFleet: PropTypes.object.isRequired,
};
export default SalesModule;
