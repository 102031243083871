export default (client, defaultParams) => ({
  find: (payload) => (
    client(`/trips/find?q=${payload.q}`, {
      method: 'GET',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  findRecentForDevice: (deviceId, days) => (
    client(`/trips/recent_for_device/${deviceId}?days=${days}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  setGrinQualification: (id, q) => (
    client(`/admin/trip/${id}/grin_qualification/${q}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  start: (id) => (
    client(`/admin/trip/${id}/start`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  setPaid: (id) => (
    client(`/admin/trip/${id}/set_paid`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  changeTotal: (id, amount) => (
    client(`/admin/trip/${id}/change_total/${amount}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  doRefund: (id, amount) => (
    client(`/admin/trip/${id}/refund/${amount}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  end: (id) => (
    client(`/admin/trip/${id}/end`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  endNoPay: (id) => (
    client(`/admin/trip/${id}/end/no_pay`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  pay: (id) => (
    client(`/trip/${id}/pay`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  recent: (hours = 3) => (
    client(`/admin/trips/recent?hours=${hours}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  get: (id) => (
    client(`/admin/trip/${id}?include_card=1&include_map=1&include_payments=1`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  getWithLogs: (id) => (
    client(`/admin/trip/${id}?include_device_events=1&include_trip_events=1&include_card=1&include_map=1&include_payments=1`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  getMessage: (k) => {
    const messages = {
    };
    return (messages[k] || k);
  },
});
