/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button, Select, DatePicker, Slider,
} from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import { CompactPicker } from 'react-color';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import { SimpleMapComponent } from '../../components/SimpleMapComponent';
import { weekdaysName } from '../../helpers/utils';
import useLocalStorage from '../../hooks/useLocalStorage';

import styles from './index.module.scss';

import * as tothService from '../../services/toth';

const { RangePicker } = DatePicker;

dayjs.extend(utc);

// const MAX = 1000;

const UsageModule = ({
  fleets,
  fleetId,
}) => {
  const weekdaysFilter = [{
    label: 'Días de semana',
    value: '1,2,3,4,5',
  }, {
    label: 'Fines de semana',
    value: '0,6',
  }];

  const tz = dayjs().utcOffset() / 60;

  const hoursFilter = [{
    label: 'Mañanas (0-11)',
    value: [...Array(12).keys()].map((i) => (i - tz) % 24).join(','),
  }, {
    label: 'Tardes (12-17)',
    value: [...Array(6).keys()].map((i) => (i + 12 - tz) % 24).join(','),
  }, {
    label: 'Tardes (18-23)',
    value: [...Array(6).keys()].map((i) => (i + 18 - tz) % 24).join(','),
  }];

  const [nMap, setNMap] = useState(0);
  const [mapPosition, setMapPosition] = useState(null);
  const [weekdays, setWeekdays] = useState(weekdaysFilter.map((x) => x.value));
  const [hours, setHours] = useState(hoursFilter.map((x) => x.value));
  const [show, setShow] = useState('expectedTrips');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [updatedAt, setUpdatedAt] = useState({});
  const [comparationFilter, setComparationFilter] = useState(null);
  const [deployLevelFilter, setDeployLevelFilter] = useState([0, 501]);

  const defaultBlockColors = {
    ok: { label: 'OK', color: '#009900' },
    overdeployed: { label: 'Sobredeployado con viajes', color: '#ffcc00' },
    overdeployed_no_trips: { label: 'Sobredeployado sin viajes', color: '#ff6600' },
    subdeployed: { label: 'Subdeployado', color: '#8d1f8d' },
    explore1: { label: 'Explorar (prioridad 1)', color: '#000000' },
    explore2: { label: 'Explorar (prioridad 2)', color: '#666666' },
    explore3: { label: 'Explorar (prioridad 3)', color: '#aaaaaa' },
  };

  const [blockColors, setBlockColors] = useLocalStorage('__use_block_colors', defaultBlockColors);

  const [range, setRange] = useState([dayjs().startOf('week').subtract(7, 'days'), dayjs().startOf('week')]);

  const onChangeRange = (dates) => {
    const newDates = [...dates];
    // newDates[0] = moment.max(dates[0], dayjs().startOf('week').subtract(14, 'days'));
    setRange(newDates);
  };

  const updateData = (key, value) => {
    setData((s) => ({
      ...s,
      [key]: value,
    }));
  };

  const loadData = async () => {
    setLoading(true);
    const momentStart = range[0].utc().format('YYYY-MM-DD HH:mm:ss');
    const momentEnd = range[1].utc().format('YYYY-MM-DD HH:mm:ss');
    const [
      rspTripsStarted,
      rspDevicesDeployed,
    ] = await Promise.all([
      tothService.compute({
        metric: 'geo.trips.GetStarted',
        configurations: {
          paperboy: 'paperboy',
        },
        parameters: {
          fleets: String(fleetId),
          start: momentStart,
          hours: hours.reduce((a, x) => a.concat(x.split(',')), []).filter((x) => !!x).join(','),
          weekdays: weekdays.reduce((a, x) => a.concat(x.split(',')), []).filter((x) => !!x).join(','),
          end: momentEnd,
        },
      }),
      tothService.compute({
        metric: 'geo.devices.RecentlyDeployed',
        configurations: {
          paperboy: 'paperboy',
        },
        parameters: {
          fleets: String(fleetId),
        },
      }),
    ]);
    let comp = {};
    if (rspTripsStarted?.status) {
      const max = Math.max(...rspTripsStarted.data.map((x) => parseFloat(x.trips_per_day)));
      rspTripsStarted.data.map((x) => {
        if (!comp[`${x.lat}:${x.lng}`]) {
          comp[`${x.lat}:${x.lng}`] = {};
        }
        comp[`${x.lat}:${x.lng}`] = { ...comp[`${x.lat}:${x.lng}`], ...x };
        return null;
      });
      const blocks = rspTripsStarted.data.map(
        (x, index) => ({
          id: `${Math.random()}-${index}`,
          lat0: parseFloat(x.lat),
          lat1: parseFloat(x.lat) + parseFloat(x.delta),
          lng0: parseFloat(x.lng),
          lng1: parseFloat(x.lng) + parseFloat(x.delta),
          label: `Viajes diarios (sem anterior): ${parseFloat((x.trips_per_day).toFixed(2))}`,
          value: parseFloat(x.trips_per_day),
          color: '#ff6600',
          strokeWeight: 0,
          opacity: Math.min(0.2 + ((0.8 * parseFloat(x.trips_per_day)) / max), 1),
        }),
      );
      updateData('tripsStarted', blocks);
    } else {
      updateData('tripsStarted', []);
    }
    if (rspDevicesDeployed?.status) {
      const max = Math.max(...rspDevicesDeployed.data.map((x) => parseFloat(x.devices_deployed)));
      rspDevicesDeployed.data.map((x) => {
        if (!comp[`${x.lat}:${x.lng}`]) {
          comp[`${x.lat}:${x.lng}`] = {};
        }
        comp[`${x.lat}:${x.lng}`] = { ...comp[`${x.lat}:${x.lng}`], ...x };
        return null;
      });
      const blocks = rspDevicesDeployed.data.map(
        (x, index) => ({
          id: `${Math.random()}-${index}`,
          lat0: parseFloat(x.lat),
          lat1: parseFloat(x.lat) + parseFloat(x.delta),
          lng0: parseFloat(x.lng),
          lng1: parseFloat(x.lng) + parseFloat(x.delta),
          label: `Patines desplegados (últ 24h): ${parseFloat(x.devices_deployed)}`,
          value: parseFloat(x.devices_deployed),
          color: '#009900',
          strokeWeight: 0,
          opacity: Math.min(0.2 + ((0.8 * parseFloat(x.devices_deployed)) / max), 1),
        }),
      );
      updateData('devicesDeployed', blocks);
    } else {
      updateData('devicesDeployed', []);
    }
    setLoading(false);
    comp = Object.values(comp);
    comp = comp.map((x) => ({
      trips_per_day: 0,
      devices_deployed: 0,
      ...x,
    }));
    comp = comp.map((x, index) => {
      let type = 'ok';
      let blockStatus = '';
      let labelStatus = 'OK';
      let opacity = 0.5;
      const deployLevel = x.trips_per_day > 0
        ? Math.min(Math.round((x.devices_deployed / x.trips_per_day) * 100), 500)
        : 501;
      if (x.devices_deployed > 0 && x.trips_per_day > 0) {
        blockStatus = 'areas_deployed_with_trips';
      } else if (x.devices_deployed > 0 && x.trips_per_day <= 0) {
        blockStatus = 'areas_deployed_without_trips';
      } else if (x.devices_deployed <= 0 && x.trips_per_day > 0) {
        blockStatus = 'areas_with_trips_no_deployed';
      }

      if (x.devices_deployed > x.trips_per_day * 1.25) {
        opacity = Math.min((0.1 * (x.devices_deployed / x.trips_per_day)), 1);
        if (x.trips_per_day === 0) {
          type = 'overdeployed_no_trips';
          labelStatus = 'Sobredeployado sin viajes';
        } else {
          type = 'overdeployed';
          labelStatus = 'Sobredeployado con viajes';
        }
      } else if (x.trips_per_day >= 4 / 7 && x.devices_deployed === 0) {
        type = 'explore1';
        labelStatus = 'Explorar (prioridad 1)';
      } else if (x.trips_per_day >= 2 / 7 && x.devices_deployed === 0) {
        type = 'explore2';
        labelStatus = 'Explorar (prioridad 2)';
      } else if (x.trips_per_day > 1 && x.devices_deployed < x.trips_per_day) {
        type = 'subdeployed';
        labelStatus = 'Subdeployado';
      } else if (x.trips_per_day < 2 / 7) {
        type = 'explore3';
        labelStatus = 'Explorar  (prioridad 3)';
      }
      return {
        id: `${Math.random()}-${index}`,
        lat0: parseFloat(x.lat),
        lat1: parseFloat(x.lat) + parseFloat(x.delta),
        latC: parseFloat(x.lat) + parseFloat(x.delta / 2),
        lng0: parseFloat(x.lng),
        lng1: parseFloat(x.lng) + parseFloat(x.delta),
        lngC: parseFloat(x.lng) + parseFloat(x.delta / 2),
        status: labelStatus,
        deployLevel,
        blockStatus,
        trips_per_day: x.trips_per_day,
        devices_deployed: x.devices_deployed,
        type,
        label: (
          <div>
            <strong>{labelStatus}</strong>
            <div>
              {`Viajes: diarios=${parseFloat(x.trips_per_day).toFixed(2)}, semana=${parseFloat(x.trips_per_day * 7).toFixed(0)}`}
            </div>
            <div>
              {`Patines desplegados (hoy AM): ${parseFloat(x.devices_deployed)}`}
            </div>
            <div>
              {`% de deploy sobre viajes: ${deployLevel <= 1000 ? `${deployLevel}%` : 'Infinito'}`}
            </div>
          </div>),
        value: parseFloat(x.devices_deployed),
        strokeWeight: 0,
        opacity,
      };
    });
    updateData('comparation', comp);
  };

  const onDeployLevelFilter = (value) => {
    setDeployLevelFilter(value);
  };

  useEffect(() => {
    const selectedFleet = fleets.find((x) => x.id === fleetId);
    setMapPosition({
      latitude: selectedFleet.cost.headquarter.lat,
      longitude: selectedFleet.cost.headquarter.lng,
    });
    loadData();
  }, [fleetId]);

  const filteredComparation = data.comparation?.filter((x) => {
    if (comparationFilter && x.blockStatus !== comparationFilter) { return null; }
    if (x.deployLevel < deployLevelFilter[0] || x.deployLevel > deployLevelFilter[1]) {
      return null;
    }
    return x;
  })
    .filter((x) => !!x);

  return (
    <>
      <div className={[styles.main].join(' ')}>
        <div className={styles.tools}>
          <div className={styles.filters}>
            <div className={[styles.item, styles.range].join(' ')}>
              <RangePicker
                ranges={{
                  Hoy: [dayjs().startOf('day'), dayjs().endOf('day')],
                  Ayer: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')],
                  Anteayer: [dayjs().subtract(2, 'day').startOf('day'), dayjs().subtract(2, 'day').endOf('day')],
                  '3 días antes': [dayjs().subtract(3, 'day').startOf('day'), dayjs().subtract(3, 'day').endOf('day')],
                  '4 días antes': [dayjs().subtract(4, 'day').startOf('day'), dayjs().subtract(4, 'day').endOf('day')],
                  '5 días antes': [dayjs().subtract(5, 'day').startOf('day'), dayjs().subtract(5, 'day').endOf('day')],
                  '6 días antes': [dayjs().subtract(6, 'day').startOf('day'), dayjs().subtract(6, 'day').endOf('day')],
                  'Hace 7 días (mismo horario)': [dayjs().subtract(7, 'day').startOf('day'), dayjs().subtract(7, 'day')],
                  'Semana actual': [dayjs().startOf('week'), dayjs().endOf('week')],
                  'Mes actual': [dayjs().startOf('month'), dayjs().endOf('month')],
                  'Semana anterior': [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
                  'Mes anterior': [dayjs().subtract(1, 'months').startOf('month'), dayjs().subtract(1, 'months').endOf('month')],
                }}
                size="large"
                defaultValue={range}
                showTime
                format="YYYY/MM/DD HH:mm:ss"
                onChange={onChangeRange}
              />
              <div style={{ fontSize: 12 }}>
                Hora local:
                {' '}
                {dayjs().local().format('YYYY-MM-DD HH:mm')}
                , Zona horaria:
                {' '}
                {dayjs().utcOffset() / 60}
                , Hora UTC:
                {' '}
                {dayjs().utc().format('YYYY-MM-DD HH:mm')}
              </div>
            </div>

            <div className={[styles.item, styles.weekdaysFilter].join(' ')}>
              <Select
                mode="multiple"
                allowClear
                showSearch={false}
                style={{ width: '100%' }}
                placeholder="Selecciona días..."
                value={weekdays}
                onChange={(v) => setWeekdays(v)}
                options={weekdaysFilter}
              />
            </div>
            <div className={[styles.item, styles.hoursFilter].join(' ')}>
              <Select
                mode="multiple"
                allowClear
                showSearch={false}
                style={{ width: '100%' }}
                placeholder="Selecciona horas..."
                value={hours}
                onChange={(v) => setHours(v)}
                options={hoursFilter}
              />
            </div>
          </div>
          <div>
            <Button onClick={loadData} size="large">
              <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
            </Button>
          </div>
        </div>
        <div className={styles.maps}>
          <div className={[styles.map, styles.mapTrips].join(' ')}>
            <div className={styles.mapTitle}>
              Viajes iniciados en rango de fechas y condiciones (
              {data.tripsStarted?.length}
              {' '}
              áreas)
            </div>
            <div className={styles.mapContent}>
              {mapPosition
                && (
                  <SimpleMapComponent
                    key={`${nMap} - ${fleetId}`}
                    minimumClusterSize={1000}
                    zoom={mapPosition.zoom || 13}
                    onChangeBoundsZoom={(a, b, c, z) => {
                      setMapPosition({ latitude: c.lat, longitude: c.lng, zoom: z });
                    }}
                    defaultCenter={{ lat: mapPosition.latitude, lng: mapPosition.longitude }}
                    panTo={{ lat: mapPosition.latitude, lng: mapPosition.longitude }}
                    minZoom={1}
                    maxZoom={20}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places,visualization"
                    loadingElement={<div style={{ height: '100%' }} />}
                    containerElement={(
                      <div
                        style={{
                          height: '100%',
                        }}
                      />
                    )}
                    mapElement={<div style={{ borderRadius: 5, height: 'calc(100%)' }} />}
                    onSelectMarker={() => { }}
                    blocks={data.tripsStarted}
                  />
                )}
            </div>
          </div>
          <div className={[styles.map, styles.mapDevices].join(' ')}>
            <div className={styles.mapTitle}>
              Patines deployados hoy AM  (
              {data.devicesDeployed?.length}
              {' '}
              áreas)
              {' '}

            </div>
            <div className={styles.mapContent}>
              {mapPosition
                && (
                  <SimpleMapComponent
                    key={`${nMap} - ${fleetId}`}
                    minimumClusterSize={1000}
                    zoom={mapPosition.zoom || 13}
                    onChangeBoundsZoom={(a, b, c, z) => {
                      setMapPosition({ latitude: c.lat, longitude: c.lng, zoom: z });
                    }}
                    defaultCenter={{ lat: mapPosition.latitude, lng: mapPosition.longitude }}
                    panTo={{ lat: mapPosition.latitude, lng: mapPosition.longitude }}
                    minZoom={1}
                    maxZoom={20}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places,visualization"
                    loadingElement={<div style={{ height: '100%' }} />}
                    containerElement={(
                      <div
                        style={{
                          height: '100%',
                        }}
                      />
                    )}
                    mapElement={<div style={{ borderRadius: 5, height: 'calc(100%)' }} />}
                    onSelectMarker={() => { }}
                    blocks={data.devicesDeployed}
                  />
                )}
            </div>
          </div>
          <div className={[styles.map, styles.mapComparation].join(' ')}>
            <div className={styles.mapTitle}>
              Comparación
              <div className={styles.toolsSec}>
                <CSVLink
                  filename={`comparacion-patines-consumo-${moment.utc().local().format('YYYY-MM-DD')}.csv`}
                  data={(filteredComparation || []).map((x) => ({
                    lat0: parseFloat(x.lat0).toFixed(6),
                    lng0: parseFloat(x.lng0).toFixed(6),
                    latC: parseFloat(x.latC).toFixed(6),
                    lngC: parseFloat(x.lngC).toFixed(6),
                    lat1: parseFloat(x.lat1).toFixed(6),
                    lng1: parseFloat(x.lng1).toFixed(6),
                    devices_deployed: parseInt(x.devices_deployed, 10),
                    trips_per_day: parseFloat(x.trips_per_day).toFixed(2),
                    deploy_level: x.deployLevel,
                    status: x.status,
                    block_status: x.blockStatus,
                  }))}
                >
                  <Button type="secondary">
                    <FontAwesomeIcon icon={faDownload} style={{ marginRight: 5 }} />
                    Exportar mapa como CSV
                  </Button>
                </CSVLink>
              </div>
            </div>
            <div className={styles.mapContent}>
              {mapPosition
                && (
                  <SimpleMapComponent
                    key={`${nMap} - ${fleetId}`}
                    minimumClusterSize={1000}
                    zoom={mapPosition.zoom || 13}
                    onChangeBoundsZoom={(a, b, c, z) => {
                      setMapPosition({ latitude: c.lat, longitude: c.lng, zoom: z });
                    }}
                    defaultCenter={{ lat: mapPosition.latitude, lng: mapPosition.longitude }}
                    panTo={{ lat: mapPosition.latitude, lng: mapPosition.longitude }}
                    minZoom={1}
                    maxZoom={20}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places,visualization"
                    loadingElement={<div style={{ height: '100%' }} />}
                    containerElement={(
                      <div
                        style={{
                          height: '100%',
                        }}
                      />
                    )}
                    mapElement={<div style={{ borderRadius: 5, height: 'calc(100%)' }} />}
                    onSelectMarker={() => { }}
                    blocks={filteredComparation?.map((x) => (
                      { ...x, color: blockColors[x.type].color }
                    ))}
                  />
                )}
            </div>

          </div>
          <div className={[styles.legend].join(' ')}>
            <div style={{ marginBottom: 'var(--spacing)' }}><strong>Comparación viajes en rango de fecha y condiciones vs deploy de hoy</strong></div>
            <div className={[styles.byUsage].join(' ')}>
              <div
                className={[styles.item, comparationFilter === 'areas_deployed_with_trips' ? styles.selected : ''].join(' ')}
                onClick={() => setComparationFilter(comparationFilter === 'areas_deployed_with_trips' ? null : 'areas_deployed_with_trips')}
              >
                <div className={styles.label}>
                  Areas deployadas con viaje
                </div>
                <div className={styles.qty}>
                  {data.comparation?.filter(
                    (x) => x.blockStatus === 'areas_deployed_with_trips',
                  ).length}
                  {' '}
                  {data.devicesDeployed?.length > 0 && (
                    <>
                      (
                      {((data.comparation?.filter(
                        (x) => x.blockStatus === 'areas_deployed_with_trips',
                      ).length / (data.devicesDeployed?.length)) * 100).toFixed(2)}
                      %)
                    </>
                  )}
                </div>
                <div
                  className={[styles.filter, comparationFilter === 'areas_deployed_with_trips' ? styles.selected : ''].join(' ')}
                >
                  {comparationFilter === 'areas_deployed_with_trips' ? 'Quitar filtro' : 'Filtrar'}
                </div>
              </div>
              <div
                className={[styles.item, comparationFilter === 'areas_deployed_without_trips' ? styles.selected : ''].join(' ')}
                onClick={() => setComparationFilter(comparationFilter === 'areas_deployed_without_trips' ? null : 'areas_deployed_without_trips')}
              >
                <div className={styles.label}>
                  Areas deployadas sin viaje
                </div>
                <div className={styles.qty}>
                  {data.comparation?.filter(
                    (x) => x.blockStatus === 'areas_deployed_without_trips',
                  ).length}
                </div>

                <div
                  className={[styles.filter, comparationFilter === 'areas_deployed_without_trips' ? styles.selected : ''].join(' ')}
                >
                  {comparationFilter === 'areas_deployed_without_trips' ? 'Quitar filtro' : 'Filtrar'}
                </div>
              </div>
              <div
                className={[styles.item, comparationFilter === 'areas_with_trips_no_deployed' ? styles.selected : ''].join(' ')}
                onClick={() => setComparationFilter(comparationFilter === 'areas_with_trips_no_deployed' ? null : 'areas_with_trips_no_deployed')}
              >
                <div className={styles.label}>
                  Areas con viaje sin deploy
                </div>
                <div className={styles.qty}>
                  {data.comparation?.filter(
                    (x) => x.blockStatus === 'areas_with_trips_no_deployed',
                  ).length}
                  {' '}
                </div>

                <div
                  className={[styles.filter, comparationFilter === 'areas_with_trips_no_deployed' ? styles.selected : ''].join(' ')}
                >
                  {comparationFilter === 'areas_with_trips_no_deployed' ? 'Quitar filtro' : 'Filtrar'}
                </div>
              </div>
            </div>
            <div className={styles.deployLevelFilter}>
              <div>
                Filtrar por % de deploy sobre viajes:
              </div>
              <Slider
                onChange={onDeployLevelFilter}
                range
                defaultValue={deployLevelFilter}
                min={0}
                max={501}
              />
            </div>
            <div className={styles.colors}>
              {Object.keys(blockColors).map((k) => (
                <div className={styles.color} key={k}>
                  <div
                    className={styles.color}
                    onxClick={() => {
                      setBlockColors((s) => ({ ...s, [k]: { ...s[k], open: !s[k].open } }));
                    }}
                    style={{
                      width: 18,
                      height: 18,
                      margin: 5,
                      backgroundColor: blockColors[k].color,
                      opacity: 0.75,
                    }}
                  />
                  {blockColors[k].open
                    && (
                      <div
                        className={styles.colorSelect}
                      >
                        <CompactPicker
                          color={blockColors[k].color.replace('#', '')}
                          onChangeComplete={(c) => {
                            setBlockColors((s) => (
                              {
                                ...s,
                                [k]: { ...s[k], color: c.hex, open: false },
                              }));
                          }}
                        />
                      </div>
                    )}
                  <div className={styles.name}>
                    {blockColors[k].label}
                    <div className={styles.qty}>

                      {data.comparation?.filter(
                        (x) => x.type === k,
                      ).length}

                    </div>
                  </div>

                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
UsageModule.propTypes = {
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};
export default UsageModule;
