/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Col, Row, Button, Select, Table, Badge,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import isMobile from 'is-mobile';
import {
  statusTranslations,
} from '../../helpers/params';

import Loading from '../../components/Loading';

import * as horusService from '../../services/horus';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';
import Metric from '../../components/Metric';
import { RenderLineChart } from '../../components/Charts';
import { parseJsonData } from '../../helpers/utils';

const activityTr = {
  'Reparation created': 'Reparación hecha',
  'Damage created': 'Daño registrado',
  'Damage fixed': 'Daño reparado',
};
const WarehouseDashboardModule = ({
  tenant,
  fleetId,
}) => {
  const [loading, setLoading] = useState(true);
  const [chartDays, setChartDays] = useState(3);
  const [data, setData] = useState({});
  const [updatedAt, setUpdatedAt] = useState({});

  const updateData = (key, value) => {
    setData((s) => ({
      ...s,
      [key]: value,
    }));
  };

  const loadChartStats = async () => {
    setLoading(true);
    const queryScooterStats = ` select
    floor(unix_timestamp(stored_at)/600)*600*1000 as ts,
max(if(k.key='devices_fleet_status:${fleetId},damaged', value, 0)) damaged,
max(if(k.key='devices_fleet_status:${fleetId},maintenance', value, 0)) maintenance,
max(if(k.key='devices_fleet_status:${fleetId},in_warehouse', value, 0)) in_warehouse,
max(if(k.key='devices_fleet_status:${fleetId},maintenance_waiting_spare', value, 0)) maintenance_waiting_spare 
     from kvs k where k.key like 'devices_fleet_status:${fleetId},%' and k.stored_at between now()-interval ${chartDays} day and now()
     group by 1;
`;
    console.log('queryScooterStats', queryScooterStats);
    const rspScooterStats = await horusService.executeQuery(queryScooterStats);
    if (rspScooterStats?.status) {
      updateData('scooterStats', rspScooterStats.data.map((x) => ({
        ...x,
        in_street: parseInt(x.live, 10) + parseInt(x.disabled, 10)
          + parseInt(x.in_ride, 10) + parseInt(x.missing_connection, 10),
      })));
    } else {
      updateData('scooterStats', []);
    }
    setLoading(false);
  };
  const loadData = async () => {
    setLoading(true);

    const queryStatusNow = ` select
    fleet_id, fleet_name,
    sum(if(status='maintenance_waiting_spare', devices, 0)) maintenance_waiting_spare,
    sum(if(status='maintenance', devices, 0)) maintenance,
    sum(if(status='damaged', devices, 0)) damaged
    from (
        select f.id fleet_id, f.name fleet_name, d.status, count(distinct d.id) devices

        from devices d, fleets f where
            f.id=d.fleet_id
            and f.id=${fleetId}
        group by 1,3
    ) tmp group by 1;`;

    const queryReparations = `select 
    date(n.created_at-interval 4 hour) day, u.email email, count(distinct d.qr) n_devices, group_concat(distinct d.qr) qr_devices
    from notes n, users u, devices d where u.id=n.user_id and  d.fleet_id=${fleetId} and d.id=n.item_id and n.item_type='DEVICE' and note in ('Damage Fixed', 'Reparation created') 
    and n.created_at>date(now()-interval 4 hour)+interval 4 hour - interval 28 day and u.id<>1 group by 1, 2 order by 1 desc, 2 asc;
    `;
    const queryDamages = `select date(n.created_at-interval 4 hour) day, count(distinct if(note='Damage created', d.qr, null)) n_devices_damaged,
    count(distinct if(note='Damage fixed', d.qr, null)) n_devices_fixed,
    group_concat(distinct if(note='Damage created', d.qr, null)) qr_devices_damaged,
    group_concat(distinct if(note='Damage fixed', d.qr, null)) qr_devices_fixed
    from notes n, users u, devices d where u.id=n.user_id and  d.fleet_id=${fleetId} 
    and d.id=n.item_id and n.item_type='DEVICE' and note in ('Damage created', 'Damage fixed') and n.created_at>date(now()-interval 4 hour)+interval 4 hour - interval 28 day group by 1 order by 1 desc;
    ;
    `;

    const queryDevicesInWarehouseWithDamages = `select d.qr, d.ref, d.status from devices d where d.status in ('in_warehouse') and json_extract(d.data, '$.has_damage')=true and d.fleet_id=${fleetId};`;
    const queryDevicesWithDamagesByType = `select if(damage.type='Otro (detallar)', concat('Otro - ', damage.note), damage.type) type, count(distinct d.qr) n, group_concat(distinct d.qr) qr_devices from devices d, JSON_TABLE(json_extract(d.data, '$.damages'), '$[*]' COLUMNS(
      type VARCHAR(100) PATH "$.type",
      note VARCHAR(100) PATH "$.note",
      status VARCHAR(100) PATH "$.status"
    )) damage where  damage.status='NEW' and d.fleet_id=${fleetId}
    group by 1 order by 1;`;

    const queryDevicesCreatedByDay = `select date(d.created_at +  INTERVAL Json_extract(f.cost, '$.tz') hour) day, count(*) n from devices d, fleets f where f.id=d.fleet_id and d.fleet_id=${fleetId}  and date(d.created_at +  INTERVAL Json_extract(f.cost, '$.tz') hour)>date(now()+  INTERVAL Json_extract(f.cost, '$.tz') hour)-interval 14 day group by 1 order by 1 desc;`;
    const queryInMaintenanceOrDamagedList = `select qr, status, json_unquote(json_extract(d.data, '$.last_status_change_at'))+interval -4 hour last_status_change, timestampdiff(day, json_unquote(json_extract(d.data, '$.last_status_change_at'))+interval -4 hour, now()+interval -4 hour) days_since_last_status_change, max(n.created_at) last_damage_created_at from devices d left join notes n on n.item_type='device' and n.item_id=d.id and n.note='Damage created' where fleet_id=${fleetId} and status in ('damaged','maintenance') group by 1 order by days_since_last_status_change asc;
    `;
    const queryRecentActivity = `SELECT u.email,
    n.note,
    n.data,
    d.qr,
    n.created_at + INTERVAL Json_extract(f.cost, '$.tz') hour created_at
FROM   notes n,
    users u,
    devices d,
    fleets f
WHERE  n.user_id = u.id
    AND n.item_type = 'device'
    AND n.item_id = d.id
    AND d.fleet_id = ${fleetId}
    AND d.fleet_id = f.id
    AND ( n.note LIKE 'Damage %'
           OR n.note LIKE 'Reparation %' )
    AND n.created_at>now()-interval 7 day          
ORDER  BY n.created_at DESC
    `;

    const [
      rspDevicesByStatusNow,
      rspReparations,
      rspDamages,
      rspInMaintenanceOrDamagedList,
      rspRecentActivity,
      rspDevicesCreatedByDay,
      rspDevicesInWarehouseWithDamages,
      rspDevicesWithDamagesByType,
    ] = await Promise.all([
      horusService.executeQuery(queryStatusNow),
      horusService.executeQuery(queryReparations),
      horusService.executeQuery(queryDamages),
      horusService.executeQuery(queryInMaintenanceOrDamagedList),
      horusService.executeQuery(queryRecentActivity),
      horusService.executeQuery(queryDevicesCreatedByDay),
      horusService.executeQuery(queryDevicesInWarehouseWithDamages),
      horusService.executeQuery(queryDevicesWithDamagesByType),
    ]);
    if (rspDevicesByStatusNow?.status) {
      const devicesByStatusNow = rspDevicesByStatusNow.data[0];
      updateData('devicesByStatusNow', devicesByStatusNow);
    } else {
      updateData('devicesByStatusNow', null);
    }
    if (rspReparations?.status) {
      updateData('reparations', rspReparations.data);
    } else {
      updateData('reparations', null);
    }
    if (rspDamages?.status) {
      updateData('damages', rspDamages.data);
    } else {
      updateData('damages', null);
    }
    if (rspInMaintenanceOrDamagedList?.status) {
      updateData('inMaintenanceOrDamagedList', rspInMaintenanceOrDamagedList.data);
    } else {
      updateData('inMaintenanceOrDamagedList', null);
    }
    if (rspDevicesCreatedByDay?.status) {
      updateData('devicesCreatedByDay', rspDevicesCreatedByDay.data);
    } else {
      updateData('devicesCreatedByDay', null);
    }
    if (rspDevicesInWarehouseWithDamages?.status) {
      updateData('devicesInWarehouseWithDamages', rspDevicesInWarehouseWithDamages.data);
    } else {
      updateData('devicesInWarehouseWithDamages', null);
    }
    if (rspDevicesWithDamagesByType?.status) {
      updateData('devicesWithDamagesByType', rspDevicesWithDamagesByType.data);
    } else {
      updateData('devicesWithDamagesByType', null);
    }
    if (rspRecentActivity?.status) {
      updateData('recentActivity', parseJsonData(rspRecentActivity.data, 'data'));
    } else {
      updateData('recentActivity', null);
    }
    setUpdatedAt(moment());
    setLoading(false);
  };

  useEffect(() => {
    setData({});
    loadData();
    const pidSync = window.setInterval(() => {
      loadData();
    }, 60000);

    return () => {
      if (pidSync) {
        window.clearInterval(pidSync);
      }
    };
  }, [fleetId]);

  useEffect(() => {
    loadChartStats();
    const pidSync = window.setInterval(() => {
      loadChartStats();
    }, 60000);

    return () => {
      if (pidSync) {
        window.clearInterval(pidSync);
      }
    };
  }, [fleetId, chartDays]);

  if (!tenant) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Dashboard De Taller
        <div className={stylesLayout.secAction} style={{ marginTop: -5 }}>
          <Button onClick={() => { loadData(); loadChartStats(); }} size="medium">
            <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
          </Button>
        </div>
        <div className={stylesLayout.small}>
          Actualizado:
          {' '}
          {moment(updatedAt).fromNow()}
        </div>
      </div>
      <div className={stylesLayout.content} id="content">
        <Row gutter={24}>
          <Col sm={24} xs={24}>
            {data.devicesByStatusNow
              && (
                <div className={styles.resume} style={{ marginBottom: 20 }}>
                  <div className={styles.byStatus} style={{ gridTemplateColumns: 'repeat(3, 1fr)' }}>
                    {['maintenance', 'maintenance_waiting_spare', 'damaged'].map((k) => (
                      <Metric
                        key={k}
                        title={k ? statusTranslations[k] || k : 'Sin estado'}
                        n={data.devicesByStatusNow[k]}
                      />
                    ))}
                  </div>
                </div>
              )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={24} xs={24}>
            <div className={styles.block}>
              <div className={styles.title}>
                Scooters por estado
              </div>
              <div className={styles.tools}>
                <Select
                  onChange={(v) => { setChartDays(v); }}
                  value={chartDays}
                  options={[2, 3, 5, 7, 14].map((x) => ({ label: `${x} días`, value: x }))}
                />
              </div>
              <RenderLineChart
                data={data?.scooterStats || []}
                yAxisScale="linear"
                xAxisType="time"
                yAxisMin={0}
                width={100}
                height={isMobile() ? 60 : 28}
                xAxisStepSize={1}
                xLabelsField="ts"
                yDatasets={{
                  Dañados: 'damaged',
                  'En mantenimiento': 'maintenance',
                  'En mantenimiento, En espera de repuestos': 'maintenance_waiting_spare',
                  'En bodega': 'in_warehouse',
                }}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={24} xs={24}>
            <div className={styles.block}>
              <div className={styles.title}>
                Patines en estado En bodega y con daños registrados en el sistema
              </div>
              <Table
                size="small"
                pagination={{ hideOnSinglePage: true, defaultPageSize: 500 }}
                locale={{ emptyText: 'Sin datos' }}
                columns={[
                  {
                    title: 'QR',
                    dataIndex: 'qr',
                    key: 'qr',
                    render: (v) => (v ? <Link to={`/device/${v}`}>{v}</Link> : ''),
                  },
                  {
                    title: 'MAC',
                    dataIndex: 'ref',
                    key: 're',
                    render: (v) => <Link to={`/device/${v}`}>{v}</Link>,
                  },
                ]}
                dataSource={data.devicesInWarehouseWithDamages?.map((d, i) => ({
                  key: i,
                  ...d,
                }))}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={24} xs={24}>
            <div className={styles.block}>
              <div className={styles.title}>
                Cantidad de patines por tipo de daño
                {data.devicesWithDamagesByType
                  && <Badge overflowCount={10000} count={data.devicesWithDamagesByType?.reduce((a, x) => a + x.n, 0)} style={{ backgroundColor: 'var(--mainColor)' }} />}
              </div>
              <Table
                size="small"
                pagination={{ hideOnSinglePage: true, defaultPageSize: 500 }}
                locale={{ emptyText: 'Sin datos' }}
                columns={[
                  {
                    title: 'Tipo de daño',
                    dataIndex: 'type',
                    key: 'type',
                    render: (v) => v,
                  },
                  {
                    title: 'Cantidad',
                    dataIndex: 'n',
                    key: 'n',
                    width: 50,
                    render: (v) => v,
                  },
                  {
                    title: 'Patines',
                    dataIndex: 'qr_devices',
                    align: 'center',

                    key: 'qr_devices',
                    render: (v) => (
                      <div style={{
                        width: '100%',
                      }}
                      >
                        {(v ? v.split(',').map((x) => <Link style={{ marginRight: 5 }} to={`/device/${x.toUpperCase()}`}>{x.toUpperCase()}</Link>) : '')}
                      </div>
                    ),
                  },
                ]}
                dataSource={data.devicesWithDamagesByType?.map((d, i) => ({
                  key: i,
                  ...d,
                }))}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={24} xs={24}>
            <div className={styles.block}>
              <div className={styles.title}>
                Patines reparados por día y usuario
                <br />
                <small>
                  * considera reparación registradas en el sistema y daños solucionados
                </small>
              </div>
              <Table
                size="small"
                pagination={{ hideOnSinglePage: true, defaultPageSize: 500 }}
                locale={{ emptyText: 'Sin datos' }}
                columns={[
                  {
                    title: 'Día',
                    dataIndex: 'day',
                    key: 'day',
                    render: (v) => moment.utc(v).format('DD/MM'),
                  },
                  {
                    title: 'Usuario',
                    dataIndex: 'email',
                    key: 'email',
                    render: (v) => v,
                    sorter: (a, b) => (a.email < b.email ? -1 : 1),
                  },

                  {
                    title: 'Cantidad de patines reparados',
                    dataIndex: 'n_devices',
                    align: 'center',
                    key: 'n_devices',
                    render: (v) => v,
                    sorter: (a, b) => (a.n_devices < b.n_devices ? -1 : 1),
                  },
                  {
                    title: 'Patines',
                    dataIndex: 'qr_devices',
                    align: 'center',
                    width: 200,
                    key: 'qr_devices',
                    render: (v) => (
                      <small>
                        {(v ? v.split(',').map((x) => <Link style={{ marginRight: 5 }} to={`/device/${x.toUpperCase()}`}>{x.toUpperCase()}</Link>) : '')}
                      </small>
                    ),
                  },
                ]}
                dataSource={data.reparations?.map((d, i) => ({
                  key: i,
                  ...d,
                }))}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col sm={24} xs={24}>
            <div className={styles.block}>
              <div className={styles.title}>
                Daños creados por día
              </div>
              <Table
                size="small"
                pagination={{ hideOnSinglePage: true, defaultPageSize: 500 }}
                locale={{ emptyText: 'Sin datos' }}
                columns={[
                  {
                    title: 'Día',
                    dataIndex: 'day',
                    key: 'day',
                    defaultSortOrder: 'ascend',
                    render: (v) => moment.utc(v).format('DD/MM'),
                  },
                  {
                    title: 'Cantidad de patines con daños registrados',
                    dataIndex: 'n_devices_damaged',
                    align: 'center',
                    key: 'n_devices',
                    render: (v) => v,
                    sorter: (a, b) => (a.n_devices < b.n_devices ? -1 : 1),
                  },
                  {
                    title: 'Patines con daños registrados',
                    dataIndex: 'qr_devices_damaged',
                    align: 'center',
                    key: 'qr_devices_damaged',
                    width: 200,
                    render: (v) => (
                      <small>
                        {(v ? v.split(',').map((x) => <Link style={{ marginRight: 5 }} to={`/device/${x.toUpperCase()}`}>{x.toUpperCase()}</Link>) : '')}
                      </small>
                    ),
                  },
                  {
                    title: 'Cantidad de patines con daños solucionados',
                    dataIndex: 'n_devices_fixed',
                    align: 'center',
                    key: 'n_devices',
                    render: (v) => v,
                    sorter: (a, b) => (a.n_devices < b.n_devices ? -1 : 1),
                  },
                  {
                    title: 'Patines con daños solucionados',
                    dataIndex: 'qr_devices_fixed',
                    align: 'center',
                    width: 200,
                    key: 'qr_devices_fixed',
                    render: (v) => (
                      <small>
                        {(v ? v.split(',').map((x) => <Link style={{ marginRight: 5 }} to={`/device/${x.toUpperCase()}`}>{x.toUpperCase()}</Link>) : '')}
                      </small>
                    ),
                  },
                ]}
                dataSource={data.damages?.map((d, i) => ({
                  key: i,
                  ...d,
                }))}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={24} xs={24}>
            <div className={styles.block}>
              <div className={styles.title}>
                Actividad reciente
              </div>
              <Table
                size="small"
                pagination={{ hideOnSinglePage: true, defaultPageSize: 500 }}
                locale={{ emptyText: 'Sin datos' }}
                columns={[
                  {
                    title: 'Usuario',
                    dataIndex: 'email',
                    key: 'email',
                    render: (v) => v,
                  },
                  {
                    title: 'QR',
                    dataIndex: 'qr',
                    key: 'qr',
                    render: (v) => <Link to={`/device/${v}`}>{v}</Link>,
                  },
                  {
                    title: 'Actividad',
                    dataIndex: 'note',
                    align: 'center',
                    key: 'note',
                    render: (v) => activityTr[v] ?? v,
                  },
                  {
                    title: 'Detalle',
                    dataIndex: 'data',
                    align: 'center',
                    key: 'data',
                    render: (v) => (
                      <>
                        {v.damage?.type ? (
                          <>
                            Daño:
                            {' '}
                            {v.damage?.type}
                            <br />
                            <small>{v.damage?.note}</small>
                          </>
                        )
                          : (
                            <>
                              Reparación:
                              {' '}
                              {v.reparation?.type}
                              <br />
                              <small>{v.reparation?.note}</small>

                            </>
                          )}

                      </>
                    ),
                  },
                  {
                    title: 'Fecha creación',
                    dataIndex: 'created_at',
                    key: 'created_at',
                    render: (v) => moment.utc(v).local().format('YYYY-MM-DD HH:mm'),
                    align: 'center',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => moment(a.created_at).format('X') - moment(b.created_at).format('X'),
                  },
                ]}
                dataSource={data.recentActivity?.map((d, i) => ({
                  key: i,
                  ...d,
                }))}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={24} xs={24}>
            <div className={styles.block}>
              <div className={styles.title}>
                Patines Dañados y en Mantenimiento
              </div>
              <Table
                size="small"
                pagination={{ hideOnSinglePage: true, defaultPageSize: 500 }}
                locale={{ emptyText: 'Sin datos' }}
                columns={[
                  {
                    title: 'QR',
                    dataIndex: 'qr',
                    key: 'qr',
                    render: (v) => <Link to={`/device/${v}`}>{v}</Link>,
                  },
                  {
                    title: 'Status',
                    dataIndex: 'status',
                    align: 'center',
                    key: 'status',
                    render: (v) => v,
                  },
                  {
                    title: 'Ult cambio de estado',
                    dataIndex: 'last_status_change',
                    key: 'last_status_change',
                    render: (v) => moment.utc(v).format('YYYY-MM-DD'),
                    align: 'center',
                    defaultSortOrder: 'ascend',
                    sorter: (a, b) => moment(a.last_status_change).format('X') - moment(b.last_status_change).format('X'),
                  },
                  {
                    title: 'Ult daño creado',
                    dataIndex: 'last_damage_created_at',
                    key: 'last_damage_created_at',
                    align: 'center',
                    render: (v) => (v ? moment.utc(v).format('YYYY-MM-DD') : '-'),
                  },
                ]}
                dataSource={data.inMaintenanceOrDamagedList?.map((d, i) => ({
                  key: i,
                  ...d,
                }))}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col sm={24} xs={24}>
            <div className={styles.block}>
              <div className={styles.title}>
                Patines creados en el sistema por día
              </div>
              <Table
                size="small"
                pagination={{ hideOnSinglePage: true, defaultPageSize: 500 }}
                locale={{ emptyText: 'Sin datos' }}
                columns={[
                  {
                    title: 'Día',
                    dataIndex: 'day',
                    key: 'day',
                    render: (v) => moment.utc(v).format('DD/MM'),
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => (a.day < b.day ? -1 : 1),
                  },

                  {
                    title: 'Cantidad de patines creados',
                    dataIndex: 'n',
                    align: 'center',
                    key: 'n',
                    render: (v) => v,
                  },
                ]}
                dataSource={data.devicesCreatedByDay?.map((d, i) => ({
                  key: i,
                  ...d,
                }))}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

WarehouseDashboardModule.propTypes = {
  tenant: PropTypes.object.isRequired,
  fleetId: PropTypes.number.isRequired,
};

export default WarehouseDashboardModule;
