/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useSubscription, useMqttState } from 'mqtt-react-hooks';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faShare, faCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Input,
  Button,
} from 'antd';
import styles from './index.module.scss';

const tryTranslate = (message) => {
  let rsp = null;
  try {
    const m = JSON.parse(message);
    switch (m.command) {
      case 1: {
        const action = (m.value === '0') ? 'desbloqueo' : 'bloqueo';
        if (m.response === 0) {
          rsp = `Comando de ${action} enviado satisfactoriamente`;
        } else {
          rsp = `Comando de ${action} no procesado por el patín`;
        }
        break;
      }
      case 39: {
        if (m.response === 0) {
          rsp = `Heartbeat seteado en ${m.value} segundos`;
        } else {
          rsp = 'Error al setear el Heartbeat del patín';
        }
        break;
      }
      case 50: {
        if (m.value === '0') {
          rsp = 'Buzz apagado';
        } else if (m.value === '1') {
          rsp = 'Buzz encendido';
        }
        break;
      }
      case 6: {
        if (m.value === '0') {
          rsp = 'Modo de conducción normal';
        } else if (m.value === '1') {
          rsp = 'Modo de conducción eco';
        } else if (m.value === '2') {
          rsp = 'Modo de conducción sport';
        }
        break;
      }
      case 57: {
        if (m.response === 0) {
          if (m.value === '1') {
            rsp = 'Alertas activadas';
          } else if (m.value === '1') {
            rsp = 'Alertas DESACTIVADAS';
          }
        } else {
          rsp = 'Error el setear alertas';
        }
        break;
      }
      case 58: {
        if (m.response === 0) {
          if (m.value === '1') {
            rsp = 'Solicitud de envío de Heartbeat';
          }
        } else {
          rsp = 'Error en solicitud de envío de Heartbeat';
        }
        break;
      }
      case 8: {
        if (m.value === '0') {
          rsp = 'Modo de frenado debil';
        } else if (m.value === '1') {
          rsp = 'Modo de frenado medio';
        } else if (m.value === '2') {
          rsp = 'Modo de frenado fuerte';
        }
        break;
      }
      case 27: {
        const vel = parseInt(m.value, 10) / 10;
        if (m.response === 0) {
          rsp = `Velocidad del patín seteada a ${vel} km/h`;
        } else if (m.value === '1') {
          rsp = 'Error el setear la velocidad del patín';
        }
        break;
      }
      case 222: {
        rsp = 'IOT reiniciado';
        break;
      }
      case 17: {
        if (m.value === '1') {
          rsp = 'Patín APAGADO Y DESBLOQUEADO';
        }
        break;
      }
      case 101: {
        const action = (m.response === 0) ? 'desbloqueado' : 'bloqueado';
        rsp = `Estado de bloqueo del patin: ${action}`;
        break;
      }
      case 201:
        rsp = 'Respuesta de actualizar geolocalización:  ';
        if (m.response['position.latitude'] === 0 && m.response['position.longitude'] === 0) {
          rsp += ' SIN POSICIÓN';
        } else {
          rsp += `${m.response['position.latitude']}, ${m.response['position.longitude']}`;
        }
        break;
      default:
    }
    return rsp;
  } catch (e) {
    console.log(e);
  }
  return rsp;
};

const MqttSubscriptionList = ({
  title, topic, height, showResend, showTranslations, canSend,
}) => {
  const { message, connectionStatus } = useSubscription(topic);
  const { client } = useMqttState();
  const [messages, setMessages] = useState([]);
  const [command, setCommand] = useState('');
  useEffect(() => {
    if (message) setMessages((msgs) => [{ time: (new Date()).getTime(), message }, ...msgs]);
  }, [message]);

  const onSubmitCommand = (c) => {
    client.publish(topic, c);
  };

  return (
    <div className={styles.cnt}>
      <div className={styles.meta}>
        <FontAwesomeIcon icon={faCircle} style={{ color: connectionStatus === 'Connected' ? '#0c0' : '#c30' }} />
        {' '}
        {title ? `${title} - ` : ''}
        {topic}
        <FontAwesomeIcon
          style={{
            float: 'right', background: '#eee', padding: 5, width: 25, height: 25,
          }}
          icon={faTrashAlt}
          onClick={() => { setMessages([]); }}
        />
      </div>
      <div className={styles.command}>
        {canSend && (
          <>
            <Input
              placeholder="Command..."
              type="text"
              value={command}
              onChange={(e) => setCommand(e.target.value)}
              onKeyDown={(e) => (e.key === 'Enter' ? onSubmitCommand(command) : null)}
            />
            <Button onClick={() => onSubmitCommand(command)}>Send</Button>
          </>
        )}
      </div>
      <div className={styles.list} style={{ height }}>
        {
          messages.map((m, i) => {
            const msgTranslation = showTranslations && tryTranslate(m.message.message);
            return (
              <div className={styles.item} key={i}>
                <div>
                  <div className={styles.time}>{moment(m.time).format('H:mm:ss')}</div>
                  {msgTranslation && <div className={styles.translation}>{msgTranslation}</div>}
                  <div className={styles.message}>{m.message.message}</div>
                </div>
                {showResend && (
                  <div
                    onClick={() => {
                      setCommand(m.message.message);
                      onSubmitCommand(m.message.message);
                    }}
                    style={{ color: 'var(--mainColor)', cursor: 'pointer' }}
                  >
                    <small><FontAwesomeIcon icon={faShare} style={{ color: 'var(--mainColor)' }} /></small>
                  </div>
                )}
              </div>
            );
          })
        }
      </div>
    </div>
  );
};
MqttSubscriptionList.defaultProps = {
  height: 300,
  title: null,
  showResend: false,
  showTranslations: false,
  canSend: false,
};
MqttSubscriptionList.propTypes = {
  height: PropTypes.number,
  topic: PropTypes.string.isRequired,
  title: PropTypes.string,
  showResend: PropTypes.bool,
  showTranslations: PropTypes.bool,
  canSend: PropTypes.bool,
};

export default MqttSubscriptionList;
