import React, { useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import {
  Table, Input, Button, Row, Col,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';

const PromocodesTable = ({
  promocodes,
  onCreatePromocode,
  show = ['table'],
}) => {
  const [showForm, setShowForm] = useState(false);
  const [uses, setUses] = useState(1);
  const [durationDays, setDurationDays] = useState(30);
  const [discount, setDiscount] = useState(0);
  const columns = [
    {
      title: 'Código',
      dataIndex: ['promocode', 'code'],
      key: 'code',
    },
    {
      title: 'Asignado',
      dataIndex: 'assigned_at',
      key: 'assigned_at',
      render: (x) => (
        moment.utc(x).local().format('YYYY-MM-DD HH:mm:ss')
      ),
    },
    {
      title: 'Usos actuales',
      dataIndex: 'uses',
      key: 'uses',
    },
    {
      title: 'Usos disponibles',
      dataIndex: 'available_uses',
      key: 'available_uses',
    },
    {
      title: 'Status',
      dataIndex: ['promocode', 'status_tr'],
      key: 'status',
    },
    {
      title: 'Configuración',
      dataIndex: ['promocode', 'data'],
      key: 'data',
      render: (data) => <>{JSON.stringify(data)}</>,
    },
    {
      title: 'Creada',
      dataIndex: 'promocode.created_at',
      key: 'created_at',
      render: (x) => (
        moment.utc(x).local().format('YYYY-MM-DD HH:mm:ss')
      ),
    },
    {
      title: 'Inicio de vigencia',
      dataIndex: ['promocode', 'start_at'],
      key: 'start_at',
      render: (x) => (
        moment.utc(x).local().format('YYYY-MM-DD HH:mm:ss')
      ),
    },
    {
      title: 'Fin de vigencia',
      dataIndex: ['promocode', 'use_end_at'],
      key: 'use_end_at',
      render: (x) => (
        moment.utc(x).local().format('YYYY-MM-DD HH:mm:ss')
      ),
    },
  ].filter((x) => !!x);
  return (
    <>
      {show.includes('form')
        && (
          <div className={styles.form}>
            {!showForm
              ? (
                <Button onClick={() => setShowForm(true)} type="secondary">
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
                  {' '}
                  Agregar código promocional
                </Button>
              )
              : (
                <>
                  <Row gutter={24}>
                    <Col sm={8} xs={24}>
                      Cantidad de usos:
                      <Input value={uses} onChange={(e) => setUses(e.target.value)} placeholder="Usos..." />
                    </Col>
                    <Col sm={8} xs={24}>
                      Duración (días):
                      <Input value={durationDays} onChange={(e) => setDurationDays(e.target.value)} placeholder="Duración (dias)..." />
                    </Col>
                    <Col sm={8} xs={24}>
                      Descuento (moneda local):
                      <Input value={discount} onChange={(e) => setDiscount(e.target.value)} placeholder="Descuento..." />
                      <small>* Usar punto como separador decimal, ej 1.60</small>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col sm={24} xs={24}>
                      <Button
                        disabled={!discount || !durationDays || !uses}
                        onClick={() => {
                          onCreatePromocode({
                            duration_days: parseInt(durationDays, 10),
                            data:
                            {
                              max_uses_per_user: parseInt(uses, 10),
                              discount: {
                                type: 'amount',
                                discounted_amount: parseFloat(discount),
                              },
                            },
                          });
                        }}
                        type="secondary"
                      >
                        Crear código promocional
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
          </div>
        )}

      <div className={styles.table}>
        <Table
          size="small"
          pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
          locale={{ emptyText: 'Sin datos' }}
          columns={columns}
          dataSource={promocodes.map((d) => ({
            key: d.id,
            ...d,
          }))}
        />
      </div>
    </>
  );
};

PromocodesTable.defaultProps = {
  promocodes: [],
  onCreatePromocode: () => { },
  show: ['table'],
};
PromocodesTable.propTypes = {
  promocodes: PropTypes.array,
  onCreatePromocode: PropTypes.func,
  show: PropTypes.array,
};

export default PromocodesTable;
