/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import { Connector } from 'mqtt-react-hooks';

import isMobile from 'is-mobile';
import BlankLayout from './layouts/BlankLayout';
import AdminLayout from './layouts/AdminLayout';

import BoardModule from './modules/BoardModule';
import DashboardModule from './modules/DashboardModule';
import ReportSalesModule from './modules/Reports/SalesModule';
import ReportWeeklyModule from './modules/Reports/WeeklyModule';
import ReportMonthlyModule from './modules/Reports/MonthlyModule';
import KanbanModule from './modules/KanbanModule';
import Grin4UCustomerModule from './modules/Grin4UCustomerModule';
import Grin4UHomeModule from './modules/Grin4UHomeModule';
import UserModule from './modules/UserModule';
import ActivityModule from './modules/ActivityModule';
import UserInfoModule from './modules/UserInfoModule';
import SystemModule from './modules/SystemModule';
import OperationalDashboardModule from './modules/OperationalDashboardModule';
import WarehouseDashboardModule from './modules/WarehouseDashboardModule';
import CustomersDashboardModule from './modules/CustomersDashboardModule';
import WorkersDashboardModule from './modules/WorkersDashboardModule';
import FleetManagementModule from './modules/FleetManagementModule';
import IncidentsModule from './modules/IncidentsModule';
import TasksModule from './modules/TasksModule';
import GeoModule from './modules/GeoModule';
import PreregisterModule from './modules/PreregisterModule';
import RegisterModule from './modules/RegisterModule';
import MultiOpsModule from './modules/MultiOpsModule';
import LoginModule from './modules/LoginModule';
import LogoutModule from './modules/LogoutModule';
import DeviceModule from './modules/DeviceModule';
import DevicesModule from './modules/DevicesModule';
import DevicesNonLocatableModule from './modules/DevicesNonLocatableModule';
import MonitorModule from './modules/MonitorModule';
import NewMonitorModule from './modules/NewMonitorModule';
import ViewerModule from './modules/ViewerModule';
import TripsModule from './modules/TripsModule';
import TripModule from './modules/TripModule';
import CustomerModule from './modules/CustomerModule';
import DevicesUpdateFirmwareModule from './modules/DevicesUpdateFirmwareModule';
import UsageModule from './modules/UsageModule';
import Usage2Module from './modules/Usage2Module';
import DeployModule from './modules/DeployModule';
import MobileDeviceModule from './modules/MobileDeviceModule';
import BalanceModule from './modules/BalanceModule';
import DeployPlanModule from './modules/DeployPlanModule';
import NewDeviceModule from './modules/NewDeviceModule';
import DeviceIncidentAnalysis from './modules/DeviceIncidentAnalysisModule';
import QueryModule from './modules/QueryModule';

const Routes = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search.slice(1)); // id=123
    let currentParams = {};
    try {
      currentParams = JSON.parse(localStorage.getItem('__parameters'));
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
    const parameters = {
      ...currentParams,
    };
    // eslint-disable-next-line guard-for-in
    for (const [k, v] of params) {
      parameters[k] = v;
    }
    localStorage.setItem('__parameters', JSON.stringify(parameters));
  }, []);
  return (
    <Connector
      brokerUrl="mqtt://broker.grow.mobi:9001"
      options={{ protocol: 'wss', username: 'growdevice', password: '4F7C84871LQD' }}
    >
      <BrowserRouter>
        <Switch>
          <BlankLayout exact path="/login" component={LoginModule} />
          <BlankLayout exact path="/logout" component={LogoutModule} />
          <AdminLayout
            exact
            path="/"
            redirectTo={(user) => {
              if (user?.can?.view_authority) {
                return '/ver';
              }
              if (user?.can?.view_operational_dashboard || user?.can?.monitor) {
                return '/monitor';
              }
              if (user?.can?.manage_incidents) {
                return '/incidents';
              }
              if (user?.can?.view_dashboard) {
                return '/dashboard';
              }
              return '/ver';
            }}
          />
          <AdminLayout exact path="/operational_dashboard" component={() => OperationalDashboardModule} authorization={(user) => user?.can?.view_operational_dashboard} />
          <AdminLayout exact path="/dashboard/operational" component={() => OperationalDashboardModule} authorization={(user) => user?.can?.view_operational_dashboard} />
          <AdminLayout exact path="/dashboard/warehouse" component={() => WarehouseDashboardModule} authorization={(user) => user?.can?.view_operational_dashboard} />
          <AdminLayout exact path="/dashboard/customers" component={() => CustomersDashboardModule} authorization={(user) => user?.can?.view_operational_dashboard} />
          <AdminLayout exact path="/dashboard/workers" component={() => WorkersDashboardModule} authorization={(user) => user?.can?.manage_employees} />
          <AdminLayout exact path="/usuario" component={() => UserModule} />
          <AdminLayout exact path="/pre" component={() => PreregisterModule} authorization={(user) => user?.can?.activate} />
          <AdminLayout exact path="/register" component={() => RegisterModule} authorization={(user) => user?.can?.manage_users || user?.can?.manage_employees} />
          <AdminLayout exact path="/dashboard" component={() => DashboardModule} authorization={(user) => user?.can?.view_dashboard} />
          <AdminLayout exact path="/fleet" component={() => FleetManagementModule} authorization={(user) => user?.can?.manage_fleet} />
          <AdminLayout exact path="/multi" component={() => MultiOpsModule} authorization={(user) => user?.can?.manage_fleet} />
          <AdminLayout exact path="/misiones" component={() => TasksModule} authorization={(user) => user?.can?.handle_tasks} />
          <AdminLayout exact path="/incidents" component={() => IncidentsModule} authorization={(user) => user?.can?.manage_incidents} />
          <AdminLayout exact path="/create_device" component={() => NewDeviceModule} authorization={(user) => user?.can?.manage_devices} />
          <AdminLayout exact path="/devices" component={() => DevicesModule} authorization={(user) => user?.can?.manage_devices} />
          <AdminLayout exact path="/devices_non_locatable" component={() => DevicesNonLocatableModule} authorization={(user) => user?.can?.manage_devices} />
          <AdminLayout exact path="/devices/:refs" component={() => DevicesModule} authorization={(user) => user?.can?.manage_devices} />
          <AdminLayout exact path="/monitor" component={() => NewMonitorModule} authorization={(user) => user?.can?.monitor} />
          <AdminLayout exact path="/monitor2" component={() => MonitorModule} authorization={(user) => user?.can?.monitor} />
          <AdminLayout exact path="/ver" component={() => ViewerModule} authorization={(user) => user?.can?.monitor || user?.can?.view_authority} />
          <AdminLayout exact path="/map" component={() => UsageModule} authorization={(user) => user?.can?.view_dashboard} />
          <AdminLayout exact path="/query" fixedInner component={() => QueryModule} authorization={(user) => user?.can?.view_dashboard} />
          <AdminLayout exact path="/usage" component={() => UsageModule} authorization={(user) => user?.can?.view_dashboard} />
          <AdminLayout exact path="/u2" component={() => Usage2Module} authorization={(user) => user?.can?.view_dashboard} />
          <AdminLayout exact path="/deploy" component={() => DeployModule} authorization={(user) => user?.can?.view_dashboard} />
          <AdminLayout exact path="/balance" component={() => BalanceModule} authorization={(user) => (user?.can?.view_dashboard || user?.can?.monitor)} />
          <AdminLayout exact path="/geo" component={() => GeoModule} authorization={(user) => user?.can?.manage_geozones} />
          <AdminLayout exact path="/grin4u" component={() => Grin4UHomeModule} authorization={(user) => user?.can?.executive_grin4u} />
          <AdminLayout exact path="/system" component={() => SystemModule} authorization={(user) => user?.can?.view_dashboard} />
          <AdminLayout exact path="/device_incident_analysis" component={() => DeviceIncidentAnalysis} authorization={(user) => user?.can?.view_dashboard} />
          <AdminLayout exact path="/devices_update_firmware" component={() => DevicesUpdateFirmwareModule} />
          <AdminLayout exact path="/kanban" component={() => KanbanModule} />
          <AdminLayout exact path="/reports/sales" component={() => ReportSalesModule} authorization={(user) => user?.can?.view_dashboard} />
          <AdminLayout exact path="/reports/weekly" component={() => ReportWeeklyModule} authorization={(user) => user?.can?.view_dashboard} />
          <AdminLayout exact path="/reports/monthly" component={() => ReportMonthlyModule} authorization={(user) => user?.can?.view_dashboard} />
          <AdminLayout
            exact
            path="/device"
            component={() => ((isMobile())
              ? MobileDeviceModule : DeviceModule)}
            fixedInner={isMobile()}
            authorization={(user) => user?.can?.manage_devices}
          />
          <AdminLayout
            exact
            path="/device/:q"
            component={() => ((isMobile())
              ? MobileDeviceModule : DeviceModule)}
            fixedInner={isMobile()}
            authorization={(user) => user?.can?.manage_devices}
          />
          <AdminLayout exact path="/trips" component={() => TripsModule} authorization={(user) => user?.can?.manage_trips} />
          <AdminLayout exact path="/board" component={() => BoardModule} authorization={(user) => user?.can?.view_dashboard} />
          <AdminLayout exact path="/actividad" component={() => ActivityModule} />
          <AdminLayout exact path="/trip/:id" component={() => TripModule} authorization={(user) => user?.can?.manage_trips} />
          <AdminLayout exact path="/user_info/:hash" component={() => UserInfoModule} />
          <AdminLayout exact path="/customer/:id" component={() => CustomerModule} authorization={(user) => (user?.can?.manage_trips || user?.can?.manage_users || user?.can?.manage_employees)} />
          <AdminLayout exact path="/grin4u/customer/:id" component={() => Grin4UCustomerModule} authorization={(user) => (user?.can?.executive_grin4u)} />
          <AdminLayout exact path="/deploy_plans" component={() => DeployPlanModule} authorization={(user) => user?.can?.manage_geozones} />
        </Switch>
      </BrowserRouter>
    </Connector>
  );
};

Routes.defaultProps = {
};

Routes.propTypes = {
};

export default Routes;
