import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button, Form, Input, Modal, Select,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import { useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { createDevice, getDeviceByTypeQRInFleet } from '../../services/devices';

import stylesLayout from '../../common/layout.module.scss';
import styles from './index.module.scss';
import Loading from '../../components/Loading';

const NewDeviceModule = ({
  // eslint-disable-next-line no-unused-vars
  user,
  // eslint-disable-next-line no-unused-vars
  position,
  fleets,
  fleetId,
}) => {
  const [fields, setFields] = useState({});
  const history = useHistory();
  const [saving, setSaving] = useState(false);

  const saveEditing = async () => {
    const ref = fields.has_iot === 'SI' ? fields.ref : fields.serial_number_scooter;
    const { qr } = fields;
    if (!qr || !ref) {
      return;
    }
    if (qr.indexOf(' ') >= 0) {
      Modal.error({
        width: 800,
        title: 'Error',
        content: 'El QR no puede tener espacios',
        icon: <ExclamationCircleOutlined />,
      });
      return;
    }
    if (ref.indexOf(' ') >= 0) {
      Modal.error({
        width: 800,
        title: 'Error',
        content: 'La MAC o el Número de serie no puede tener espacios',
        icon: <ExclamationCircleOutlined />,
      });
      return;
    }
    setSaving(true);

    const rsp2 = await getDeviceByTypeQRInFleet(qr, 'scooter');
    if (rsp2 && rsp2.status) {
      setSaving(false);
      Modal.error({
        width: 800,
        title: 'Error',
        content: 'Ya existe un patín con este QR asignado a una flota',
        icon: <ExclamationCircleOutlined />,
      });
    }

    const payload = {
      qr,
      ref,
      fleet_id: fleetId,
      data: {
        qr: fields.qr,
        iot_type: fields.has_iot,
        scooter_model: fields.model,
        serial_number_scooter: fields.serial_number_scooter,
        serial_number_battery: fields.serial_number_battery,
        serial_number_scooter_board: fields.serial_number_scooter_board,
        created_by: user.id,
        created_from: 'manually',
      },
      lat: position.latitude,
      lng: position.longitude,
    };
    const rsp = await createDevice(payload);
    if (rsp?.status) {
      const d = rsp.data;
      history.push(`/device/${d.qr}`);
    }
    setSaving(false);
  };

  const setField = (field, value) => {
    setFields((s) => ({ ...s, [field]: value }));
  };

  const selectedFleet = fleets.find((x) => x.id === fleetId);

  if (!selectedFleet.cost.allow_device_manual_creation) {
    return <>No es posible crear patines en esta flota</>;
  }
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Patines
      </div>
      <div className={stylesLayout.content}>

        <div className={styles.form}>
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            autoComplete="off"
          >
            <div className={styles.field}>
              <Form.Item
                label="QR"
              >
                <Input type="text" placeholder="QR..." value={fields.qr} onChange={(e) => setField('qr', e.target.value)} />
              </Form.Item>
            </div>

            <div className={styles.field}>
              <Form.Item
                label="Tiene IOT?"
              >
                <Select
                  onChange={(v) => {
                    setField('has_iot', v);
                  }}
                  options={['SI', 'NO'].map((x) => ({ label: x, value: x }))}
                  style={{ width: '100%' }}
                  placeholder="Tiene IOT?..."
                />
              </Form.Item>
            </div>
            {fields.has_iot === 'SI' && (
              <div className={styles.field}>
                <Form.Item
                  label="MAC IOT"
                >
                  <Input type="text" placeholder="MAC IOT..." value={fields.ref} onChange={(e) => setField('ref', e.target.value)} />
                </Form.Item>
              </div>
            )}
            <div className={styles.field}>
              <Form.Item
                label="Número de serie del patín"
              >
                <Input type="text" placeholder="Número de serie del patín..." value={fields.serial_number_scooter} onChange={(e) => setField('serial_number_scooter', e.target.value)} />
              </Form.Item>
            </div>
            <div className={styles.field}>
              <Form.Item
                label="Número de serie de bateria"
              >
                <Input type="text" placeholder="Número de serie de bateria..." value={fields.serial_number_battery} onChange={(e) => setField('serial_number_battery', e.target.value)} />
              </Form.Item>
            </div>
            <div className={styles.field}>
              <Form.Item
                label="Número de serie de tarjeta de patín"
              >
                <Input type="text" placeholder="Número de serie de tarjeta de patín..." value={fields.serial_number_scooter_board} onChange={(e) => setField('serial_number_scooter_board', e.target.value)} />
              </Form.Item>
            </div>
            <div className={styles.field}>
              <Form.Item
                label="Modelo"
              >
                <Select
                  onChange={(v) => {
                    setField('model', v);
                  }}
                  options={['SKICK', 'SMAX'].map((x) => ({ label: x, value: x }))}
                  style={{ width: '100%' }}
                  placeholder="Modelo..."
                />
              </Form.Item>
            </div>
            <div className={styles.buttons}>
              {saving
                ? <div style={{ marginTop: 15 }}><Loading /></div>
                : (
                  <Button onClick={saveEditing} type="secondary" disabled={!(fields.has_iot === 'SI' ? fields.ref : fields.serial_number_scooter) || !fields.qr}>
                    <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                    Guardar
                  </Button>
                )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
NewDeviceModule.propTypes = {
  user: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};

export default NewDeviceModule;
