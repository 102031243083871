import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  logout,
} from '../../helpers/storage';

const LogoutModule = () => {
  const history = useHistory();
  useEffect(() => {
    console.log('logout');
    logout();
    window.setTimeout(() => {
      history.push('/login');
    }, 2000);
  }, []);

  return null;
};

export default LogoutModule;
