/* eslint-disable */
/* eslint-disable no-tabs */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { SimpleMapComponent } from '../../components/SimpleMapComponent';

import styles from './index.module.scss';

import * as tothService from '../../services/toth';


// const MAX = 1000;

const DeployModule = ({
  fleets,
  fleetId,
}) => {
  const [nMap] = useState(0);
  const [mapPosition, setMapPosition] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const updateData = (key, value) => {
    setData((s) => ({
      ...s,
      [key]: value,
    }));
  };

  const loadData = async () => {
    setLoading(true);
    const [
      rspDevicesDeployed,
    ] = await Promise.all([
      tothService.compute({
        metric: 'geo.devices.Last7DDeployed',
        configurations: {
          paperboy: 'paperboy',
        },
        parameters: {
          fleets: String(fleetId),
        },
      }),
    ]);
    if (rspDevicesDeployed?.status) {
      const blocks = rspDevicesDeployed.data.map(
        (x, index) => ({
          ...x,
          id: `${Math.random()}-${index}`,
          lat0: parseFloat(x.lat),
          lat1: parseFloat(x.lat) + parseFloat(x.delta),
          lng0: parseFloat(x.lng),
          lng1: parseFloat(x.lng) + parseFloat(x.delta),
          color: '#096'
        }),
      );
      updateData('devicesDeployed', blocks);
    } else {
      updateData('devicesDeployed', []);
    }
    setLoading(false);
  };
  useEffect(() => {
    const selectedFleet = fleets.find((x) => x.id === fleetId);
    setMapPosition({
      latitude: selectedFleet.cost.headquarter.lat,
      longitude: selectedFleet.cost.headquarter.lng,
    });
    loadData();
  }, [fleetId]);

  const getMap = (n) => {
    const key = `l_${n}`;
    const dd = data.devicesDeployed?.filter((x) => x[key] > 0);
    return (
      <div className={[styles.map].join(' ')}>
        <div className={styles.mapTitle}>
          Áreas deployadas
          {' '}
          {n}
          {' '}
          días atrás {dd && <>(
            {dd?.length}
            )</>}
          {' '}

        </div>
        <div className={styles.mapContent}>
          {mapPosition
            && (
              <SimpleMapComponent
                key={`${nMap} - ${fleetId}`}
                minimumClusterSize={1000}
                zoom={mapPosition.zoom || 13}
                onChangeBoundsZoom={(a, b, c, z) => {
                  setMapPosition({ latitude: c.lat, longitude: c.lng, zoom: z });
                }}
                defaultCenter={{ lat: mapPosition.latitude, lng: mapPosition.longitude }}
                panTo={{ lat: mapPosition.latitude, lng: mapPosition.longitude }}
                minZoom={1}
                maxZoom={20}
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places,visualization"
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={(
                  <div
                    style={{
                      height: '100%',
                    }}
                  />
                )}
                mapElement={<div style={{ borderRadius: 5, height: 'calc(100%)' }} />}
                onSelectMarker={() => { }}
                blocks={dd?.map((x) => ({
                  ...x,
                  label: 'Patines desplegados ' + x[key],
                  value: x[key],
                }))}
              />
            )}
        </div>
      </div>
    );
  };
  const getCompareMap = (n1, n2) => {
    const key1 = `l_${n1}`;
    const key2 = `l_${n2}`;
    const dd = data.devicesDeployed?.filter((x) => x[key1] > 0 || x[key2] > 0);
    return (
      <div className={[styles.map].join(' ')}>
        <div className={styles.mapTitle}>
          Comparación Áreas deployadas
          {' '}
          {n1} y {n2}
          {' '}
          días atrás (
          {dd?.length})
          {' '}

        </div>
        <div className={styles.mapContent}>
          {mapPosition
            && (
              <SimpleMapComponent
                key={`${nMap} - ${fleetId}`}
                minimumClusterSize={1000}
                zoom={mapPosition.zoom || 13}
                onChangeBoundsZoom={(a, b, c, z) => {
                  setMapPosition({ latitude: c.lat, longitude: c.lng, zoom: z });
                }}
                defaultCenter={{ lat: mapPosition.latitude, lng: mapPosition.longitude }}
                panTo={{ lat: mapPosition.latitude, lng: mapPosition.longitude }}
                minZoom={1}
                maxZoom={20}
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places,visualization"
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={(
                  <div
                    style={{
                      height: '100%',
                    }}
                  />
                )}
                mapElement={<div style={{ borderRadius: 5, height: 'calc(100%)' }} />}
                onSelectMarker={() => { }}
                blocks={dd?.map((x) => ({
                  ...x,
                  label: 'Patines desplegados (d-' + n1 + '=' + x[key1] + ', d-' + n2 + '=' + x[key2] + '). ' + (x[key1] === 0 ? 'Quitado' : (x[key1] > 0 && x[key2] > 0 ? 'Se mantiene' : 'Agregado')),
                  value: x[key1] - x[key2],
                  color: x[key1] === 0 ? '#f00' : (x[key1] > 0 && x[key2] > 0 ? '#fc0' : '#096'),
                }))}
              />
            )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={[styles.main].join(' ')}>
        <div className={styles.tools}>
          <div className={styles.filters} />
          <div>
            <Button onClick={loadData} size="large">
              <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
            </Button>
          </div>
        </div>
        <div className={styles.maps}>
          {[...Array(8).keys()].map((x) => getMap(x))}
          {[...Array(4).keys()].map((x) => getCompareMap(0, x + 1))}
        </div>
      </div>
    </>
  );
};
DeployModule.propTypes = {
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};
export default DeployModule;
