/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Input, Select, Radio, Form,
} from 'antd';
import { faTrashAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './index.module.scss';

const { Option } = Select;

const GeofenceItem = ({
  geofence,
  onStartEditing,
  onEndEditing,
  onSaveEditing,
  onDelete,
  fleets,
  open,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [fields, setFields] = useState(geofence);
  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  const startEditing = () => {
    setIsOpen(true);
    onStartEditing(geofence.id);
  };
  const saveEditing = () => {
    setIsOpen(false);
    onSaveEditing(geofence.id, fields);
  };
  const endEditing = () => {
    setIsOpen(false);
    onEndEditing(geofence.id);
  };
  const setField = (field, value) => {
    setFields((s) => ({ ...s, [field]: value }));
  };
  const deleteItem = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('¿Estás seguro de eliminar esta zona?')) {
      onDelete(geofence.id);
    }
  };
  return (
    <div className={styles.cnt}>
      {!isOpen
        && (
          <div className={styles.info} onClick={startEditing}>
            <div className={[styles.title, styles[`status_${geofence.status.toLowerCase()}`]].join(' ')}>
              {geofence.name}
              <br />
              <small>{geofence.type_tr}</small>
              {' '}
              -
              {' '}
              <small>{geofence.status_tr}</small>
            </div>
            <div className={styles.editBtn}>
              <Button onClick={startEditing}>Editar</Button>
            </div>
          </div>
        )}
      {isOpen && (
        <div className={styles.editArea}>
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            autoComplete="off"
          >
            <div className={styles.field}>
              <Form.Item
                label="Nombre"
              >
                <Input type="text" placeholder="Nombre de la zona..." value={fields.name} onChange={(e) => setField('name', e.target.value)} />
              </Form.Item>
            </div>
            <div className={styles.field}>
              <Form.Item
                label="Tipo"
              >
                <Radio.Group onChange={(e) => setField('type', e.target.value)} value={fields.type}>
                  <Radio value="ALLOW">Permitir uso</Radio>
                  <Radio value="DENY">Prohibir uso y cierre de viaje (velocidad a 15km/h)</Radio>
                  <Radio value="MICROZONE">Microzona (de uso interno)</Radio>
                  <Radio value="MICROZONE_WAREHOUSE">Microzona bodega (de uso interno)</Radio>
                  <Radio value="TRIP_MICROZONE">Zona para asignación de viajes (de uso interno)</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className={styles.field}>
              <Form.Item
                label="Status"
              >
                <Radio.Group onChange={(e) => setField('status', e.target.value)} value={fields.status}>
                  <Radio value="ACTIVE">Activa</Radio>
                  <Radio value="INACTIVE">Inactiva</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className={styles.field}>
              <Form.Item
                label="Flota"
              >
                <Select
                  placeholder="Seleccionar flota..."
                  value={fields.fleet_id}
                  onChange={(id) => setField('fleet_id', id)}
                  style={{ width: '100%' }}
                >
                  {fleets && fleets.map((f) => (
                    <Option key={f.id} value={f.id}>{f.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.buttons}>
              <Button onClick={saveEditing} type="secondary">
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                Guardar
              </Button>
              <a style={{ marginLeft: 10 }} onClick={endEditing}>Salir</a>
              <Button danger onClick={deleteItem} style={{ float: 'right' }}>
                <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: 5 }} />
                {' '}
                Eliminar
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

GeofenceItem.defaultProps = {
  onStartEditing: () => { },
  onEndEditing: () => { },
  onSaveEditing: () => { },
  onDelete: () => { },
  open: false,
};
GeofenceItem.propTypes = {
  geofence: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  onStartEditing: PropTypes.func,
  onEndEditing: PropTypes.func,
  onSaveEditing: PropTypes.func,
  onDelete: PropTypes.func,
  open: PropTypes.bool,
};

export default GeofenceItem;
