/* eslint-disable guard-for-in */
import numeral from 'numeral';
import moment from 'moment';

export const parseJsonData = (array, key) => array.map((obj) => ({
  ...obj,
  [key]: JSON.parse(obj[key]) || {},
}));

export const humanFileSize = (bytes, si = false, dp = 1) => {
  let fbytes = bytes;
  const thresh = si ? 1000 : 1024;

  if (Math.abs(fbytes) < thresh) {
    return `${fbytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    fbytes /= thresh;
    u += 1;
  } while (Math.round(Math.abs(fbytes) * r) / r >= thresh && u < units.length - 1);

  return `${fbytes.toFixed(dp)} ${units[u]}`;
};

export const sendWhatsAppMessage = (phone, message) => {
  const text = message.replaceAll('\n', '%0A');
  window.location = `https://wa.me/${phone}?text=${text}`;
};

export const isJSON = (text) => {
  if (typeof text !== 'string') {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

export const aproxMinsToHoursDays = (mins) => {
  if (mins < 60) {
    return `${Math.floor(mins)} mins`;
  }
  if (mins < 2880) {
    return `${Math.floor(mins / 60)} hs`;
  }
  return `${Math.floor(mins / 1440)} dias`;
};
export const calculateArea = (points) => {
  let area = 0;
  let i;
  let j;
  let point1;
  let point2;

  for (i = 0, j = points.length - 1; i < points.length; j = i, i += 1) {
    point1 = points[i];
    point2 = points[j];
    if (point1 && point2 && point1.longitude && point1.latitude
      && point2.longitude && point2.latitude) {
      area += point1.longitude * point2.latitude;
      area -= point1.latitude * point2.longitude;
    }
  }
  area /= 2;

  return area;
};

export const calculateCentroid = (oPoints) => {
  const points = oPoints.filter((x) => !!x).map((x) => ({
    ...x,
    latitude: x.lat || x.latitude,
    longitude: x.lng || x.longitude,
  })).filter((x) => x.latitude && x.longitude);

  if (!points || points.length === 0) {
    return { latitude: 0, longitude: 0 };
  }
  if (points && points.length === 1) {
    return { latitude: points[0].latitude, longitude: points[0].longitude };
  }
  if (points && points.length === 2) {
    return {
      latitude: (points[0].latitude + points[1].latitude) / 2,
      longitude: (points[0].longitude + points[1].longitude) / 2,
    };
  }
  let x = 0;
  let y = 0;
  let i;
  let j;
  let f;
  let point1;
  let point2;

  for (i = 0, j = points.length - 1; i < points.length; j = i, i += 1) {
    point1 = points[i];
    point2 = points[j];
    if (point1 && point2 && point1.longitude && point1.latitude
      && point2.longitude && point2.latitude) {
      f = point1.longitude * point2.latitude - point2.longitude * point1.latitude;
      x += (point1.longitude + point2.longitude) * f;
      y += (point1.latitude + point2.latitude) * f;
    }
  }
  f = calculateArea(points) * 6;
  if (f === 0) {
    return {
      latitude: (points[0].latitude + points[1].latitude) / 2,
      longitude: (points[0].longitude + points[1].longitude) / 2,
    };
  }
  return {
    latitude: y / f, longitude: x / f, lat: y / f, lng: x / f,
  };
};

export const convertPositionListToSegments = (positions, seconds) => {
  let lastTs = null;
  const segments = [];
  let segment = [];
  const sortedPositions = positions.map((x) => ({ ...x, t: parseInt(moment(x.created_at).format('X'), 10) })).sort((a, b) => (a.t > b.t ? 1 : -1));
  // eslint-disable-next-line no-restricted-syntax
  for (const i in sortedPositions) {
    const p = sortedPositions[i];
    if (!lastTs || p.t - lastTs > seconds) {
      if (segment && segment.length > 0) {
        segments.push(segment);
        segment = [];
      }
    }
    segment.push(p);
    lastTs = p.t;
  }
  segments.push(segment);

  return segments;
};

export const formatPriceFromBillingScheme = (scheme, value) => (`${scheme?.currencySymbol || '$'}${numeral(value).format(scheme?.currencyFormat || '0.00')}`);

// eslint-disable-next-line no-nested-ternary
export const getTripQualificationColor = (grinQualification) => (grinQualification === 100
  ? '#090' : (grinQualification === 50 ? '#f60' : '#c30'));

export const interpolateColor = (color1, color2, factor) => {
  const result = color1.slice();
  for (let i = 0; i < 3; i += 1) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
};

export const convertToRGB = (h) => {
  const aRgbHex = h.match(/.{1,2}/g);
  const aRgb = [
    parseInt(aRgbHex[0], 16),
    parseInt(aRgbHex[1], 16),
    parseInt(aRgbHex[2], 16),
  ];
  console.log('aRgb', aRgb);
  return aRgb;
};

export const weekdaysName = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

export const distance = (lat1, lon1, lat2, lon2) => {
  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0;
  }

  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2)
    + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist *= 1.609344;

  return dist;
};

export const deviceMessage = (device) => (`${device.qr} - ${device.fleet?.name || 'Sin Flota'} - ${device.status_tr}

https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}
        
https://paperboy.grow.mobi/device/${device.qr}

${device.data?.locked === 0 ? 'Desbloqueado' : 'Bloqueado'} - ${parseInt(device.data?.engine_on, 10) === 0 ? 'Motor apagado' : 'Motor encendido'} - ${device.data?.batsco}% de batería

${device.data.instant_velocity_km_h > 0 ? `Velocidad instantánea: ${device.data.instant_velocity_km_h} km/h` : ''}
Últ Posición: ${device.geo_updated_at ? `${moment.utc(device.geo_updated_at).fromNow()}` : 'Sin info'}
Últ mensaje: ${device.data.last_message_from_device_at ? `${moment.utc(device.data.last_message_from_device_at).fromNow()}` : 'Sin info'}
Últ HB: ${device.data.last_heartbeat_from_device_at ? `${moment.utc(device.data.last_heartbeat_from_device_at).fromNow()}` : 'Sin info'}`);

export default {
  weekdaysName,
  distance,
  sendWhatsAppMessage,
  deviceMessage,
  convertPositionListToSegments,
  getTripQualificationColor,
  calculateCentroid,
  interpolateColor,
  convertToRGB,
  aproxMinsToHoursDays,
  calculateArea,
  isJSON,
  formatPriceFromBillingScheme,
};
