/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import moment from "moment";

import {Col, Row, Table, Button, Badge, Select} from "antd";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faStar,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import isMobile from "is-mobile";

import numeral from "numeral";
import {SimpleMapComponent} from "../../components/SimpleMapComponent";

import {statusTranslations} from "../../helpers/params";

import Loading from "../../components/Loading";

import * as tothService from "../../services/toth";
import * as devicesService from "../../services/devices";
import * as horusService from "../../services/horus";

import styles from "./index.module.scss";
import stylesLayout from "../../common/layout.module.scss";
import Metric from "../../components/Metric";
import {getTripQualificationColor} from "../../helpers/utils";
import ConnectedWeatherWidget from "../../components/Weather/ConnectedWeatherWidget";
import {RenderLineChart} from "../../components/Charts";

const OperationalDashboardModule = ({tenant, fleets, fleetId}) => {
  const [loading, setLoading] = useState(true);
  const [chartDays, setChartDays] = useState(1);
  const [data, setData] = useState({});
  const [updatedAt, setUpdatedAt] = useState({});

  const updateData = (key, value) => {
    setData((s) => ({
      ...s,
      [key]: value,
    }));
  };

  const loadChartStats = async () => {
    setLoading(true);
    const queryDeployedBattery = `
    select ts, 
    n_0_0,
    n_0_10,
    n_10_20,
    n_20_30,
    n_30_40,
    n_40_50,
    n_50_60,
    n_60_70,
    n_70_80,
    n_80_90,
    n_90_100,
    (n_0_0+n_0_10) 0_10,
    (n_0_0+n_0_10+n_10_20) 0_20,
    (n_20_30+n_30_40+n_40_50) 20_50,
    (n_50_60+n_60_70+n_70_80) 50_80,
    (n_80_90+n_90_100) 80_100,
    (n_50_60+n_60_70+n_70_80+n_80_90+n_90_100) 50_100
    from (
    select
        floor(unix_timestamp(stored_at)/600)*600*1000 as ts,
        max(if(k.key='0_0', k.value, 0)) n_0_0,
        max(if(k.key='0_10', k.value, 0)) n_0_10,
        max(if(k.key='10_20', k.value, 0)) n_10_20,
        max(if(k.key='20_30', k.value, 0)) n_20_30,
        max(if(k.key='30_40', k.value, 0)) n_30_40,
        max(if(k.key='40_50', k.value, 0)) n_40_50,
        max(if(k.key='50_60', k.value, 0)) n_50_60,
        max(if(k.key='60_70', k.value, 0)) n_60_70,
        max(if(k.key='70_80', k.value, 0)) n_70_80,
        max(if(k.key='80_90', k.value, 0)) n_80_90,
        max(if(k.key='90_100', k.value, 0)) n_90_100
    from (
    select stored_at, replace(k.key, 'devices_fleet_status:${fleetId},live:bat_', '') "key", json_extract(value, '$.n') value 
             from kvs k where k.key like 'devices_fleet_status:${fleetId},live:bat_%' and k.stored_at between now()-interval ${chartDays} day and now()
    ) k group by 1
    ) tmp;
    `;

    const queryScooterStats = ` select
    floor(unix_timestamp(stored_at)/600)*600*1000 as ts,
max(if(k.key='devices_fleet_status:${fleetId},live', value, 0)) live,
max(if(k.key='devices_fleet_status:${fleetId},missing_connection', value, 0)) missing_connection,
max(if(k.key='devices_fleet_status:${fleetId},in_transport_to_warehouse', value, 0)) in_transport_to_warehouse,
max(if(k.key='devices_fleet_status:${fleetId},in_transport_to_test', value, 0)) in_transport_to_test,
max(if(k.key='devices_fleet_status:${fleetId},stolen_suspect', value, 0)) stolen_suspect,
max(if(k.key='devices_fleet_status:${fleetId},in_transport_to_deploy', value, 0)) in_transport_to_deploy,
    max(if(k.key='devices_fleet_status:${fleetId},disabled', value, 0)) disabled,
            max(if(k.key='devices_fleet_status:${fleetId},in_ride', value, 0)) in_ride,
            max(if(k.key='devices_fleet_status:${fleetId},in_warehouse', value, 0)) in_warehouse,
    max(if(k.key='devices_fleet_status:${fleetId},available_for_deploy', value, 0)) total_available_for_street
    
     from kvs k where k.key like 'devices_fleet_status:${fleetId},%' and k.stored_at between now()-interval ${chartDays} day and now()
     group by 1;
`;
    const [rspScooterStats, rspDeployedBattery] = await Promise.all([
      horusService.executeQuery(queryScooterStats),
      horusService.executeQuery(queryDeployedBattery),
    ]);

    if (rspScooterStats?.status) {
      updateData(
        "scooterStats",
        rspScooterStats.data.map((x) => ({
          ...x,
          in_street:
            parseInt(x.live, 10) +
            parseInt(x.disabled, 10) +
            parseInt(x.in_ride, 10) +
            parseInt(x.missing_connection, 10) +
            parseInt(x.stolen_suspect, 10),
        }))
      );
    } else {
      updateData("scooterStats", []);
    }

    if (rspDeployedBattery?.status) {
      updateData("deployedBattery", rspDeployedBattery.data);
    } else {
      updateData("deployedBattery", []);
    }

    setLoading(false);
  };
  const loadData = async () => {
    setLoading(true);

    const queryDeployedYesterdayAndToday = `select l.qr, d.status,
    json_unquote(json_extract(d.data, '$.has_damage')) has_damage,
    greatest(json_unquote(json_extract(d.data, '$.last_message_from_device_at')),
    json_unquote(json_extract(d.data, '$.last_heartbeat_from_device_at'))) last_conn,
    json_unquote(json_extract(d.data, '$.last_status_change_at')) last_status_change_at
    from devices d,
        kvs k,
      JSON_TABLE(json_extract(k.value, '$.qrs_arr'), '$[*]' COLUMNS(
        qr VARCHAR(100) PATH "$"         )) l
        where d.qr=l.qr and k.key like 'devices_fleet_status:${fleetId},live:bat%'
        and k.stored_at between date(now()+interval -4 hour)-interval -4 hour+interval -1 day
          and date(now()+interval -4 hour)-interval -4 hour+interval 0 day
        and l.qr not in (
        select l.qr from kvs k,
      JSON_TABLE(json_extract(k.value, '$.qrs_arr'), '$[*]' COLUMNS(
        qr VARCHAR(100) PATH "$"         )) l
        where k.key like 'devices_fleet_status:${fleetId},live:bat%' and
        k.stored_at between date(now()+interval -4 hour)-interval -4 hour+interval 0 day
        and date(now()+interval -4 hour)-interval -4 hour+interval 1 day group by 1
        )
        and d.fleet_id=${fleetId}
    group by 1;
      `;

    const queryStatusByProviderNow = `select status, sum(if(provider='Twilio', n, 0)) n_twilio, sum(if(provider='Otro', n, 0)) n_otro from (
      select status,  provider, count(*) n, group_concat(distinct qr) from (
      select qr, status, 
      if(json_extract(d.data, '$.iccid') like '%8988%', 'Twilio', 'Otro') provider,
      json_extract(d.data, '$.iot_firmware_version') iot_firmware_version, json_extract(d.data, '$.iccid'), greatest(json_unquote(json_extract(d.data, '$.last_message_from_device_at')), json_unquote(json_extract(d.data, '$.last_heartbeat_from_device_at')))-interval 4 hour last_conn from devices d where d.fleet_id=${fleetId}
      and ifnull(json_unquote(json_extract(d.data, '$.iot_type')), 'SI')<>'NO'
       order by last_conn desc
      ) tmp group by 1,2) tmp group by 1;
      `;

    const queryStatusNow = ` select
    fleet_id, fleet_name,
    sum(if(status='in_transport_to_warehouse', devices, 0)) in_transport_to_warehouse,
    sum(if(status='in_transport_to_deploy', devices, 0)) in_transport_to_deploy,
    sum(if(status='in_transport_to_test', devices, 0)) in_transport_to_test,
    sum(if(status='live', devices, 0)) live,
    sum(if(status='live', devices_more_than_30_bat, 0)) live_more_than_30_bat,
    sum(if(status='live', devices_more_than_50_bat, 0)) live_more_than_50_bat,
    sum(if(status='live', devices_more_than_75_bat, 0)) live_more_than_75_bat,
    avg(if(status='live', avg_bat, null)) avg_bat_live,
    sum(if(status='in_warehouse', devices, 0)) in_warehouse,
    sum(if(status='in_warehouse_need_test', devices, 0)) in_warehouse_need_test,
    sum(if(status='motorist', devices, 0)) motorist,
    sum(if(status='reserved', devices, 0)) reserved,
    sum(if(status='disabled', devices, 0)) disabled,
    sum(if(status='maintenance', devices, 0)) maintenance,
    sum(if(status='in_ride', devices, 0)) in_ride,
    sum(if(status='stolen_suspect', devices, 0)) stolen_suspect,
    sum(if(status='damaged', devices, 0)) damaged,
    sum(if(status='stolen', devices, 0)) stolen,
    sum(if(status='recovered', devices, 0)) recovered,
    sum(if(status='missing_connection', devices, 0)) missing_connection
    from (
        select f.id fleet_id, f.name fleet_name, d.status, count(distinct d.id) devices,
        count(distinct if(json_unquote(json_extract(d.data, '$.batsco'))>=30, d.id, null)) devices_more_than_30_bat,
        count(distinct if(json_unquote(json_extract(d.data, '$.batsco'))>=50, d.id, null)) devices_more_than_50_bat,
        count(distinct if(json_unquote(json_extract(d.data, '$.batsco'))>=75, d.id, null)) devices_more_than_75_bat,
        avg(json_unquote(json_extract(d.data, '$.batsco'))) avg_bat
        from devices d, fleets f where
            f.id=d.fleet_id
            and qr is not null and coalesce(json_unquote(json_extract(data, '$.iot_type')), "SI")<>"NO" 
            and f.id=${fleetId}
        group by 1,3
    ) tmp group by 1;`;

    const sc = 125;
    const queryMissingConnectionMap = `select lat, lng, delta, live,total, missing_connection, missing_connection/total pct_missing_connection from (
      select floor(lat*${sc})/${sc} lat, floor(lng*${sc})/${sc} lng, 1/${sc} delta, sum(if(status='live', 1, 0)) live, 
      sum(if(status='missing_connection', 1, 0)) missing_connection, count(*) total from devices d where d.fleet_id=${fleetId} and d.status in ('live', 'missing_connection') group by 1,2
      having total>1
      ) tmp;`;

    const queryStolenTwilio = ` select qr, status, json_unquote(json_extract(d.data, '$.sim_iccid_provider')) provider, json_unquote(json_extract(d.data, '$.iccid')) iccid,
    greatest(json_unquote(json_extract(d.data, '$.last_message_from_device_at')), json_unquote(json_extract(d.data, '$.last_heartbeat_from_device_at')))-interval 4 hour last_conn
     from devices d where d.status='stolen' and d.fleet_id=${fleetId} and json_extract(d.data, '$.sim_iccid_provider')='Twilio'
     order by last_conn asc;`;

    const queryRecentChangedPositionMissingConnections = `select d.id, d.lat, d.lng, d.qr, date_format(json_unquote(json_extract(d.data, '$.last_status_change_at')), '%Y-%m-%d %H:%i:%s') last_status_change, d.geo_updated_at from devices d where d.fleet_id=${fleetId} and d.status='missing_connection' and date_format(json_unquote(json_extract(d.data, '$.last_status_change_at')), '%Y-%m-%d %H:%i:%s')<now()-interval 1 day and d.geo_updated_at>now()-interval 1 day and date_format(json_unquote(json_extract(d.data, '$.last_status_change_at')), '%Y-%m-%d %H:%i:%s')<d.geo_updated_at order by d.geo_updated_at desc`;

    const [
      rspDevicesWithError,
      rspRecentChangedPositionMissingConnections,
      rspMissingConnectionMap,
      rspDevicesByStatusAndProviderNow,
      rspDevicesByStatusNow,
      rspDevicesMissingConnection,
      rspDevicesLiveWithNoAvailableError,
      rspTripsInProcessLong,
      rspStolenTwilio,
    ] = await Promise.all([
      devicesService.findDevices({
        filters: {coderr: {gt: "0"}},
        fleetId,
      }),
      horusService.executeQuery(queryRecentChangedPositionMissingConnections),
      horusService.executeQuery(queryMissingConnectionMap),
      horusService.executeQuery(queryStatusByProviderNow),
      horusService.executeQuery(queryStatusNow),
      devicesService.findDevices({
        fleetId,
        output: "map",
        status: ["missing_connection"],
      }),
      tothService.compute({
        metric: "devices.get.WithNoAvailableError",
        configurations: {
          paperboy: "paperboy",
        },
        parameters: {
          fleets: fleetId,
          hours: 3,
        },
      }),
      tothService.compute({
        metric: "trips.get.InProcess",
        configurations: {
          paperboy: "paperboy",
        },
        parameters: {
          fleets: String(fleetId),
          min_minutes: 60,
        },
      }),
      horusService.executeQuery(queryStolenTwilio),
    ]);
    console.log(
      "rspRecentChangedPositionMissingConnections",
      rspRecentChangedPositionMissingConnections
    );

    if (rspDevicesByStatusAndProviderNow?.status) {
      const r = rspDevicesByStatusAndProviderNow.data.reduce(
        (a, x) => ({...a, [x.status]: x}),
        {}
      );
      r.in_street = {
        status: "in_street",
        n_twilio:
          parseInt(r.live?.n_twilio || 0, 10) +
          parseInt(r.disabled?.n_twilio || 0, 10) +
          parseInt(r.reserved?.n_twilio || 0, 10) +
          parseInt(r.in_ride?.n_twilio || 0, 10) +
          parseInt(r.missing_connection?.n_twilio || 0, 10),
        n_otro:
          parseInt(r.live?.n_otro || 0, 10) +
          parseInt(r.disabled?.n_otro || 0, 10) +
          parseInt(r.reserved?.n_otro || 0, 10) +
          parseInt(r.in_ride?.n_otro || 0, 10) +
          parseInt(r.missing_connection?.n_otro || 0, 10),
      };
      const s = rspDevicesByStatusAndProviderNow.data;
      s.push(r.in_street);
      s.push({
        status: "% sin conexión en calle",
        n_twilio: numeral(
          (r.missing_connection?.n_twilio || 0) / (r.in_street?.n_twilio || 0)
        ).format("0%"),
        n_otro: numeral(
          (r.missing_connection?.n_otro || 0) / (r.in_street?.n_otro || 0)
        ).format("0%"),
      });

      updateData("devicesByStatusAndProviderNow", s);
    } else {
      updateData("devicesByStatusAndProviderNow", null);
    }

    if (rspMissingConnectionMap?.status) {
      const s = rspMissingConnectionMap.data.map((x, index) => {
        let c = "#999999";
        if (x.pct_missing_connection >= 0.15) {
          c = "#cc3300";
        } else if (x.pct_missing_connection >= 0.1) {
          c = "#ffcc00";
        } else {
          c = "#009933";
        }
        return {
          ...x,
          id: `${Math.random()}-${index}`,
          lat0: parseFloat(x.lat),
          lat1: parseFloat(x.lat) + parseFloat(x.delta),
          lng0: parseFloat(x.lng),
          lng1: parseFloat(x.lng) + parseFloat(x.delta),
          value: parseFloat(x.pct_missing_connection),
          color: c,
          opacity: Math.min(0.8 * (x.total / 20), 0.8),
          label: (
            <div>
              <div>Total de patines: {x.total}</div>
              <div>Patines CON conexión: {x.live}</div>
              <div>
                Patines SIN conexión: {x.missing_connection}(
                {numeral(x.pct_missing_connection).format("0%")})
              </div>
            </div>
          ),
        };
      });
      updateData("missingConnectionMap", s);
    } else {
      updateData("missingConnectionMap", []);
    }
    if (rspDevicesWithError?.status) {
      updateData("devicesWithErrors", rspDevicesWithError.data);
    } else {
      updateData("devicesWithErrors", []);
    }

    if (rspStolenTwilio?.status) {
      updateData("stolenTwilio", rspStolenTwilio.data);
    } else {
      updateData("stolenTwilio", []);
    }
    if (rspDevicesByStatusNow?.status) {
      console.log("rspDevicesByStatusNow", rspDevicesByStatusNow);
      const devicesByStatusNow = rspDevicesByStatusNow.data[0];
      if (devicesByStatusNow) {
        devicesByStatusNow.in_street =
          parseInt(devicesByStatusNow.live, 10) +
          parseInt(devicesByStatusNow.disabled, 10) +
          parseInt(devicesByStatusNow.reserved, 10) +
          parseInt(devicesByStatusNow.in_ride, 10) +
          parseInt(devicesByStatusNow.missing_connection, 10);
        updateData("devicesByStatusNow", devicesByStatusNow);
      } else {
        updateData("devicesByStatusNow", null);
      }
    } else {
      updateData("devicesByStatusNow", null);
    }

    if (rspRecentChangedPositionMissingConnections?.status) {
      console.log(
        "rspRecentChangedPositionMissingConnections",
        rspRecentChangedPositionMissingConnections.data
      );
      updateData(
        "recentChangedPositionMissingConnections",
        rspRecentChangedPositionMissingConnections.data
      );
    } else {
      console.log("rspRecentChangedPositionMissingConnections NO");
      updateData("recentChangedPositionMissingConnections", []);
    }
    if (rspDevicesMissingConnection?.status) {
      updateData("devicesMissingConnection", rspDevicesMissingConnection.data);
    } else {
      updateData("devicesMissingConnection", []);
    }
    if (rspDevicesLiveWithNoAvailableError?.status) {
      updateData(
        "devicesLiveWithNoAvailableError",
        rspDevicesLiveWithNoAvailableError.data
      );
    } else {
      updateData("devicesLiveWithNoAvailableError", []);
    }
    if (rspTripsInProcessLong?.status) {
      updateData("tripsInProcessLong", rspTripsInProcessLong.data);
    } else {
      updateData("tripsInProcessLong", []);
    }
    setUpdatedAt(moment());
    setLoading(false);
  };
  useEffect(() => {
    setData({});
    loadData();
    const pidSync = window.setInterval(() => {
      loadData();
    }, 60000);

    return () => {
      if (pidSync) {
        window.clearInterval(pidSync);
      }
    };
  }, [fleetId]);

  useEffect(() => {
    loadChartStats();
    const pidSync = window.setInterval(() => {
      loadChartStats();
    }, 60000);

    return () => {
      if (pidSync) {
        window.clearInterval(pidSync);
      }
    };
  }, [fleetId, chartDays]);

  if (!tenant) {
    return (
      <div className={stylesLayout.page}>
        <Loading />
      </div>
    );
  }

  const selectedFleet = fleets.find((x) => x.id === fleetId);

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Dashboard Operacional
        <div className={stylesLayout.secAction} style={{marginTop: -5}}>
          <Button
            onClick={() => {
              loadData();
              loadChartStats();
            }}
            size='medium'
          >
            <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
          </Button>
        </div>
        <div className={stylesLayout.small}>
          Actualizado: {moment(updatedAt).fromNow()}
        </div>
      </div>
      <div className={stylesLayout.content} id='content'>
        <>
          {false && (
            <Row gutter={24}>
              <Col sm={24} xs={24}>
                <ConnectedWeatherWidget
                  city={selectedFleet.cost.headquarter.city}
                  lat={selectedFleet.cost.headquarter.lat}
                  lng={selectedFleet.cost.headquarter.lng}
                />
              </Col>
            </Row>
          )}
          <Row gutter={24}>
            <Col sm={24} xs={24}>
              {data.devicesByStatusNow && (
                <div className={styles.resume} style={{marginBottom: 20}}>
                  <div
                    className={styles.byStatus}
                    style={{gridTemplateColumns: "repeat(3, 1fr)"}}
                  >
                    {["in_street", "live", "missing_connection"].map((k) => (
                      <Metric
                        key={k}
                        title={k ? statusTranslations[k] || k : "Sin estado"}
                        subtitle={
                          k === "live" ? (
                            <>
                              <br />
                              {
                                data.devicesByStatusNow.live_more_than_30_bat
                              }{" "}
                              patines con batería &gt; 30%
                              <br />
                              {
                                data.devicesByStatusNow.live_more_than_50_bat
                              }{" "}
                              patines con batería &gt; 50%
                              <br />
                              {
                                data.devicesByStatusNow.live_more_than_75_bat
                              }{" "}
                              patines con batería &gt; 75%
                            </>
                          ) : (
                            ""
                          )
                        }
                        n={data.devicesByStatusNow[k]}
                        formatTotalPercent='0%'
                        totalPercent={
                          k !== "in_street"
                            ? data.devicesByStatusNow[k] /
                              (data.devicesByStatusNow.in_street || 1)
                            : null
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
              <div className={styles.block}>
                <div
                  className={styles.byStatus}
                  style={{gridTemplateColumns: "repeat(5, 1fr)"}}
                >
                  {data.devicesByStatusNow &&
                    Object.keys(data.devicesByStatusNow)
                      .filter((x) => x !== "fleet_id" && x !== "fleet_name")
                      .map((k) => (
                        <Metric
                          key={k}
                          title={k ? statusTranslations[k] || k : "Sin estado"}
                          n={data.devicesByStatusNow[k]}
                          size='small'
                        />
                      ))}
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} xs={24}>
              <div className={styles.block}>
                <div className={styles.title}>Scooters por estado</div>
                <div className={styles.tools}>
                  <Select
                    onChange={(v) => {
                      setChartDays(v);
                    }}
                    value={chartDays}
                    options={[1, 2, 3, 5, 7, 14, 28].map((x) => ({
                      label: `${x} días`,
                      value: x,
                    }))}
                  />
                </div>
                <RenderLineChart
                  data={data?.scooterStats || []}
                  yAxisScale='linear'
                  xAxisType='time'
                  yAxisMin={0}
                  width={100}
                  height={isMobile() ? 60 : 28}
                  xAxisStepSize={1}
                  xLabelsField='ts'
                  yDatasets={{
                    Desplegados: "live",
                    "Sin Conexión": "missing_connection",
                    Deshabilitados: "disabled",
                    "En viaje": "in_ride",
                    "Total en calle": "in_street",
                    "En transporte a Deploy": "in_transport_to_deploy",
                    "En transporte a Bodega": "in_transport_to_warehouse",
                    "En transporte a Pruebas": "in_transport_to_test",
                    "En Bodega": "in_warehouse",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} xs={24}>
              <div className={styles.block}>
                <div className={styles.title}>
                  Scooters desplegados por nivel de batería
                </div>
                <div className={styles.tools}>
                  <Select
                    onChange={(v) => {
                      setChartDays(v);
                    }}
                    value={chartDays}
                    options={[1, 2, 3, 5, 7, 14, 28].map((x) => ({
                      label: `${x} días`,
                      value: x,
                    }))}
                  />
                </div>
                <RenderLineChart
                  data={data?.deployedBattery || []}
                  yAxisScale='linear'
                  xAxisType='time'
                  yAxisMin={0}
                  width={100}
                  height={isMobile() ? 60 : 28}
                  xAxisStepSize={1}
                  xLabelsField='ts'
                  yDatasets={{
                    "0%": "n_0_0",
                    "0-10%": "0_10",
                    "0-20%": "0_20",
                    "20-50%": "20_50",
                    "50-100%": "50_100",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} xs={24}>
              <div className={styles.block}>
                <div className={styles.title}>
                  % Scooters sin conexión sobre total en calle
                  <br />
                  <small>
                    * Se muestran solo momentos con al menos 40 patines en calle
                  </small>
                </div>
                <div className={styles.tools}>
                  <Select
                    onChange={(v) => {
                      setChartDays(v);
                    }}
                    value={chartDays}
                    options={[1, 2, 3, 5, 7, 14, 28].map((x) => ({
                      label: `${x} días`,
                      value: x,
                    }))}
                  />
                </div>
                <RenderLineChart
                  data={(data?.scooterStats || []).map((x) => ({
                    ...x,
                    pct_missing_connection:
                      x.in_street >= 40
                        ? parseInt(
                            (x.missing_connection / x.in_street) * 100,
                            10
                          )
                        : null,
                  }))}
                  yAxisScale='linear'
                  xAxisType='time'
                  yAxisMin={0}
                  width={100}
                  height={isMobile() ? 60 : 28}
                  xAxisStepSize={1}
                  xLabelsField='ts'
                  yDatasets={{
                    "% Sin Conexión": "pct_missing_connection",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} xs={24}>
              <div className={styles.block}>
                <div className={styles.title}>
                  Dispositivos por status y proveedor de SIM
                </div>
                <Table
                  size='small'
                  pagination={{hideOnSinglePage: true, defaultPageSize: 500}}
                  locale={{emptyText: "Sin datos"}}
                  columns={[
                    {
                      title: "Status",
                      dataIndex: "status",
                      key: "status",
                      defaultSortOrder: "ascend",
                      render: (v) => statusTranslations[v] || v,
                      sorter: (a, b) =>
                        (statusTranslations[a.status] || a.status) <
                        (statusTranslations[b.status] || b.status)
                          ? -1
                          : 1,
                    },
                    {
                      title: "Twilio",
                      dataIndex: "n_twilio",
                      align: "center",
                      key: "n_twilio",
                    },
                    {
                      title: "Otro",
                      dataIndex: "n_otro",
                      align: "center",
                      key: "n_otro",
                    },
                  ]}
                  dataSource={data.devicesByStatusAndProviderNow?.map((d) => ({
                    key: d.id,
                    ...d,
                  }))}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} xs={24}>
              <div className={styles.block}>
                <div className={styles.title}>
                  Dispositivos con posición reciente y sin conexión
                </div>
                <Table
                  size='small'
                  pagination={{hideOnSinglePage: true, defaultPageSize: 500}}
                  locale={{emptyText: "Sin datos"}}
                  columns={[
                    {
                      title: "QR",
                      dataIndex: "qr",
                      key: "qr",
                      render: (v, r) => <Link to={`/device/${r.qr}`}>{v}</Link>,
                    },
                    {
                      title: "Posición",
                      dataIndex: "lat",
                      align: "center",
                      key: "lat",
                      render: (v, r) => (
                        <>
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${r.lat},${r.lng}`}
                            target='_map'
                          >
                            <span>
                              {r.lat}, {r.lng}
                            </span>
                          </a>{" "}
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${r.lat},${r.lng}`}
                            target='_map'
                          >
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                          </a>
                        </>
                      ),
                    },
                    {
                      title: "Últ cambio de posición",
                      dataIndex: "geo_updated_at",
                      align: "center",
                      defaultSortOrder: "descend",
                      key: "geo_updated_at",
                      sorter: (a, b) =>
                        moment(b.geo_updated_at).format("X") -
                        moment(a.geo_updated_at).format("X"),
                      render: (v) => moment(v).fromNow(),
                    },
                    {
                      title: "Sin conexión desde",
                      dataIndex: "last_status_change",
                      align: "center",
                      key: "last_status_change",
                      sorter: (a, b) =>
                        moment(b.last_status_change).format("X") -
                        moment(a.last_status_change).format("X"),
                      render: (v) =>
                        moment.utc(v).local().format("YYYY-MM-DD HH:mm:ss"),
                    },
                  ]}
                  dataSource={data.recentChangedPositionMissingConnections?.map(
                    (d) => ({
                      key: d.id,
                      ...d,
                    })
                  )}
                />
              </div>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col sm={24} xs={24}>
              <div className={styles.block}>
                <div className={styles.title}>% Sin conexión por sector</div>
                <div style={{height: 500}}>
                  <SimpleMapComponent
                    key={`${fleetId}`}
                    minimumClusterSize={1000}
                    zoom={14}
                    defaultCenter={{
                      lat: selectedFleet.cost.headquarter.lat,
                      lng: selectedFleet.cost.headquarter.lng,
                    }}
                    minZoom={12}
                    maxZoom={20}
                    googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places,visualization'
                    loadingElement={<div style={{height: "100%"}} />}
                    containerElement={
                      <div
                        style={{
                          height: "100%",
                        }}
                      />
                    }
                    mapElement={
                      <div style={{borderRadius: 5, height: "calc(100%)"}} />
                    }
                    onSelectMarker={() => {}}
                    blocks={data?.missingConnectionMap}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col sm={24} xs={24}>
              <div className={styles.block}>
                <div className={styles.title}>
                  Patines robados Twilio{" "}
                  {data.stolenTwilio && (
                    <Badge
                      count={data.stolenTwilio?.length}
                      style={{backgroundColor: "var(--mainColor)"}}
                    />
                  )}
                </div>
                <Table
                  size='small'
                  pagination={{hideOnSinglePage: true, defaultPageSize: 500}}
                  locale={{emptyText: "Sin datos"}}
                  columns={[
                    {
                      title: "Patín",
                      dataIndex: "qr",
                      align: "center",
                      key: "qr",
                      render: (v, r) => <Link to={`/device/${r.qr}`}>{v}</Link>,
                      sorter: (a, b) => (a.qr < b.qr ? -1 : 1),
                    },
                    {
                      title: "ICCID",
                      dataIndex: "iccid",
                      align: "center",
                      key: "iccid",
                      render: (v) => v,
                      sorter: (a, b) => (a.iccid < b.iccid ? -1 : 1),
                    },
                    {
                      title: "Últ conexión",
                      dataIndex: "last_conn",
                      align: "center",
                      key: "last_conn",
                      render: (v) => (v ? moment.utc(v).fromNow() : ""),
                      sorter: (a, b) =>
                        moment(b.last_connection).format("X") -
                        moment(a.last_connection).format("X"),
                    },
                  ]}
                  dataSource={data.stolenTwilio?.map((d) => ({
                    key: d.qr,
                    ...d,
                  }))}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} xs={24}>
              <div className={styles.block}>
                <div className={styles.title}>
                  Viajes en proceso más de 60 minutos{" "}
                  {data.tripsInProcessLong && (
                    <Badge
                      count={data.tripsInProcessLong?.length}
                      style={{backgroundColor: "var(--mainColor)"}}
                    />
                  )}
                </div>
                <Table
                  size='small'
                  pagination={{hideOnSinglePage: true, defaultPageSize: 500}}
                  locale={{emptyText: "Sin datos"}}
                  columns={[
                    {
                      title: "#",
                      key: "index",
                      render: (value, item, index) => 1 + index,
                    },
                    {
                      title: "Patín",
                      dataIndex: "device_qr",
                      align: "center",
                      key: "device_qr",
                      render: (v, r) => (
                        <Link to={`/device/${r.device_qr}`}>{v}</Link>
                      ),
                      sorter: (a, b) => (a.device_qr < b.device_qr ? -1 : 1),
                    },

                    {
                      title: "Trip Id",
                      dataIndex: "trip_id",
                      align: "center",
                      key: "trip_id",
                      render: (v, r) => (
                        <Link to={`/trip/${r.trip_id}`}>{v}</Link>
                      ),
                      sorter: (a, b) => (a.trip_id < b.trip_id ? -1 : 1),
                    },
                    {
                      title: "Email",
                      key: "user_email",
                      align: "center",
                      dataIndex: "user_email",
                      render: (v, r) => (
                        <>
                          <Link to={`/trips/?q=${v}`}>{v}</Link>
                          <FontAwesomeIcon
                            icon={faStar}
                            style={{
                              display: "inline-block",
                              color: getTripQualificationColor(
                                r.user_grin_qualification
                              ),
                            }}
                          />
                        </>
                      ),
                    },
                    {
                      title: "Minutos",
                      key: "minutes",
                      align: "center",
                      dataIndex: "minutes",
                      render: (v) => v,
                      sorter: (a, b) => (a.minutes < b.minutes ? -1 : 1),
                    },
                    {
                      title: "Status",
                      key: "trip_status",
                      align: "center",
                      dataIndex: "trip_status",
                      render: (v) => v,
                    },
                  ]}
                  dataSource={data.tripsInProcessLong?.map((d) => ({
                    key: d.id,
                    ...d,
                  }))}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} xs={24}>
              <div className={styles.block}>
                <div className={styles.title}>
                  Dispositivos sin conexión{" "}
                  {data.devicesMissingConnection && (
                    <Badge
                      count={data.devicesMissingConnection?.length}
                      style={{backgroundColor: "var(--mainColor)"}}
                    />
                  )}
                </div>
                <Table
                  size='small'
                  pagination={{hideOnSinglePage: true, defaultPageSize: 500}}
                  locale={{emptyText: "Sin datos"}}
                  columns={[
                    {
                      title: "#",
                      key: "index",
                      render: (value, item, index) => 1 + index,
                    },
                    {
                      title: "QR",
                      dataIndex: "qr",
                      align: "center",
                      key: "qr",
                      render: (qr, r) => (
                        <Link to={`/device/${r.ref}`}>{qr}</Link>
                      ),
                      sorter: (a, b) => (a.qr < b.qr ? -1 : 1),
                    },
                    {
                      title: "Últ Heartbeat",
                      dataIndex: "data",
                      defaultSortOrder: "ascend",
                      key: "last_hb",
                      align: "center",
                      render: (v) =>
                        v
                          ? moment
                              .utc(v.last_heartbeat_from_device_at)
                              .fromNow()
                          : "",
                      sorter: (a, b) =>
                        moment(b.data.last_heartbeat_from_device_at).format(
                          "X"
                        ) -
                        moment(a.data.last_heartbeat_from_device_at).format(
                          "X"
                        ),
                    },
                    {
                      title: "Últ conexión",
                      dataIndex: "last_connection",
                      key: "last_connection",
                      align: "center",
                      render: (v) => (v ? moment.utc(v).fromNow() : ""),
                      sorter: (a, b) =>
                        moment(b.last_connection).format("X") -
                        moment(a.last_connection).format("X"),
                    },
                    {
                      title: "Bloqueado",
                      dataIndex: "data",
                      key: "locked",
                      align: "center",
                      render: (v) => (v.locked ? "Si" : "No"),
                      sorter: (a, b) => b.data.locked - a.data.locked,
                    },
                  ]}
                  dataSource={data.devicesMissingConnection?.map((d) => ({
                    key: d.id,
                    ...d,
                  }))}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} xs={24}>
              <div className={styles.block}>
                <div className={styles.title}>
                  Dispositivos desplegados con errores al intentar viaje (últ 3
                  horas){" "}
                  {data.devicesLiveWithNoAvailableError && (
                    <Badge
                      count={data.devicesLiveWithNoAvailableError?.length}
                      style={{backgroundColor: "var(--mainColor)"}}
                    />
                  )}
                </div>
                <Table
                  size='small'
                  pagination={{hideOnSinglePage: true, defaultPageSize: 500}}
                  locale={{emptyText: "Sin datos"}}
                  columns={[
                    {
                      title: "#",
                      key: "index",
                      render: (value, item, index) => 1 + index,
                    },
                    {
                      title: "QR",
                      dataIndex: "qr",
                      align: "center",
                      key: "qr",
                      render: (qr, r) => (
                        <Link to={`/device/${r.ref}`}>{qr}</Link>
                      ),
                      sorter: (a, b) => (a.qr < b.qr ? -1 : 1),
                    },
                    {
                      title: "Cantidad de errores",
                      key: "checkAvailableFailureNotAlive",
                      align: "center",
                      dataIndex: "checkAvailableFailureNotAlive",
                      render: (v) => v,
                      sorter: (a, b) =>
                        a.checkAvailableFailureNotAlive <
                        b.checkAvailableFailureNotAlive
                          ? -1
                          : 1,
                    },
                  ]}
                  dataSource={data.devicesLiveWithNoAvailableError?.map(
                    (d) => ({
                      key: d.id,
                      ...d,
                    })
                  )}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} xs={24}>
              <div className={styles.block}>
                <div className={styles.title}>
                  Dispositivos con error{" "}
                  {data.devicesWithErrors && (
                    <Badge
                      count={data.devicesWithErrors?.length}
                      style={{backgroundColor: "var(--mainColor)"}}
                    />
                  )}
                </div>
                <Table
                  size='small'
                  pagination={{hideOnSinglePage: true, defaultPageSize: 500}}
                  locale={{emptyText: "Sin datos"}}
                  columns={[
                    {
                      title: "#",
                      key: "index",
                      render: (value, item, index) => 1 + index,
                    },
                    {
                      title: "QR",
                      dataIndex: "qr",
                      align: "center",
                      key: "qr",
                      render: (qr, r) => (
                        <Link to={`/device/${r.ref}`}>{qr}</Link>
                      ),
                      sorter: (a, b) => (a.qr < b.qr ? -1 : 1),
                    },
                    {
                      title: "Error",
                      key: "coderr",
                      align: "center",
                      dataIndex: ["data", "coderr"],
                      render: (v) => v,
                      sorter: (a, b) =>
                        // eslint-disable-next-line no-nested-ternary
                        parseInt(a.data?.coderr, 10) ===
                        parseInt(b.data?.coderr, 10)
                          ? 0
                          : parseInt(a.data?.coderr, 10) <
                            parseInt(b.data?.coderr, 10)
                          ? -1
                          : 1,
                    },
                    {
                      title: "Status",
                      dataIndex: "status",
                      align: "center",
                      key: "status",
                      render: (v) => statusTranslations[v],
                      sorter: (a, b) =>
                        statusTranslations[a.status] <
                        statusTranslations[b.status]
                          ? -1
                          : 1,
                    },
                    {
                      title: "Últ Heartbeat",
                      dataIndex: "data",
                      key: "hb",
                      align: "center",
                      render: (v) =>
                        v.last_heartbeat_from_device_at
                          ? moment
                              .utc(v.last_heartbeat_from_device_at)
                              .fromNow()
                          : "",
                      sorter: (a, b) =>
                        moment(b.data.last_heartbeat_from_device_at).format(
                          "X"
                        ) -
                        moment(a.data.last_heartbeat_from_device_at).format(
                          "X"
                        ),
                    },
                    {
                      title: "Últ mensaje recibido",
                      dataIndex: "data",
                      key: "lm",
                      defaultSortOrder: "ascend",
                      align: "center",
                      render: (v) =>
                        v.last_message_from_device_at
                          ? moment.utc(v.last_message_from_device_at).fromNow()
                          : "",
                      sorter: (a, b) =>
                        moment(b.data.last_message_from_device_at).format("X") -
                        moment(a.data.last_message_from_device_at).format("X"),
                    },
                  ]}
                  dataSource={data.devicesWithErrors?.map((d) => ({
                    key: d.id,
                    ...d,
                  }))}
                />
              </div>
            </Col>
          </Row>
        </>
      </div>
    </div>
  );
};

OperationalDashboardModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};

export default OperationalDashboardModule;
