/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import '../../helpers/map';
import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import isMobile from 'is-mobile';
import PropTypes from 'prop-types';
import {
  Select, Input, Button, notification, Slider, Dropdown,
  Menu, Checkbox,
} from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faTimes,
  faCheck,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import MobileScooterInfo from '../../components/Devices/MobileScooterInfo';
import Loading from '../../components/Loading';

import useQuery from '../../hooks/useQuery';

import RowByStatus from '../../components/RowByStatus';
import { MapComponent, getScooterMarker } from '../../components/MapComponent';

import {
  findDevices,
  getDeviceByTypeQROrRef,
  sendDeviceCommand,
  getCommandForAction,
}
  from '../../services/devices';

import {
  getGeofencesForFleet,
} from '../../services/geofences';

import {
  colorZone,
} from '../../helpers/params';
import * as tothService from '../../services/toth';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';
import TaskHandleModule from '../TaskHandleModule';
import InternalTransportResume from '../../components/Devices/InternalTransportResume';

const { Option } = Select;

const MonitorModule = ({
  // eslint-disable-next-line no-unused-vars
  position,
  fleets,
  user,
  fleetId,
}) => {
  const queryParams = useQuery();
  const queryParamQ = queryParams.get('q');
  const defaultFilterStatus = ['stolen_suspect', 'in_ride', 'missing_connection', 'out_of_area', 'disabled', 'stolen'];
  const defaultFilterStatusLive = [...defaultFilterStatus, 'live'];
  let lastUsedStatusFilters = null;
  try {
    lastUsedStatusFilters = localStorage.getItem('__monitor__last_status_filters') ? JSON.parse(localStorage.getItem('__monitor__last_status_filters')) : defaultFilterStatus;
  } catch (e) {
    lastUsedStatusFilters = defaultFilterStatus;
  }

  const [showDevices, setShowDevices] = useState(true);
  const [showForecast, setShowForecast] = useState(true);
  const [deviceUpdating, setDeviceUpdating] = useState(null);
  const [mapPosition, setMapPosition] = useState(null);
  const [findLocation, setFindLocation] = useState(null);
  const [clusterGridSize, setClusterGridSize] = useState(40);
  const findLocationRef = useRef(findLocation);
  findLocationRef.current = findLocation;
  const [devices, setDevices] = useState([]);
  const [loadDevicePid, setLoadDevicePid] = useState(null);
  const loadDevicePidRef = useRef(loadDevicePid);
  loadDevicePidRef.current = loadDevicePid;

  const [internalTransports, setInternalTransports] = useState([]);
  const [geofences, setGeofences] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [filterStatus, setFilterStatus] = useState(lastUsedStatusFilters);
  const filterStatusRef = useRef(filterStatus);
  filterStatusRef.current = filterStatus;
  const [filterBattery, setFilterBattery] = useState([isMobile() ? 0 : 20, 100]);
  const [filterLastReport, setFilterLastReport] = useState([0, 48]);
  const [filterQ, setFilterQ] = useState(queryParamQ || '');
  const [loading, setLoading] = useState(false);
  const [selectedMode, setSelectedMode] = useState('single');
  const [multipleSelectedDevices, setMultipleSelectedDevices] = useState([]);
  const [showFilters, setShowFilters] = useState(filterQ !== '');
  const [forecastTrips, setForecastTrips] = useState([]);
  const loadGeofences = async () => {
    const rsp = await getGeofencesForFleet([1, 2, 3, 4, 5, 6], ['all']);
    if (rsp && rsp.status) {
      setGeofences(rsp.data);
    } else {
      setGeofences([]);
    }
  };

  const loadForecast = async () => {
    setLoading(true);
    const momentStart = moment().startOf('week').subtract(30, 'days').utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const momentEnd = moment().startOf('week').utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const [
      rspTripsStarted,
    ] = await Promise.all([
      tothService.compute({
        metric: 'geo.trips.GetStarted',
        configurations: {
          paperboy: 'paperboy',
        },
        parameters: {
          fleets: String(fleetId),
          start: momentStart,
          hours: [(parseInt(moment.utc().format('HH'), 10)) % 24, (parseInt(moment.utc().format('HH'), 10) + 1) % 24].join(','),
          end: momentEnd,
        },
      }),
    ]);
    if (rspTripsStarted?.status) {
      const max = Math.max(...rspTripsStarted.data.map((x) => parseFloat(x.trips_per_day)));
      const blocks = rspTripsStarted.data.map(
        (x, index) => ({
          id: `${Math.random()}-${index}`,
          lat0: parseFloat(x.lat),
          lat1: parseFloat(x.lat) + parseFloat(x.delta),
          lng0: parseFloat(x.lng),
          lng1: parseFloat(x.lng) + parseFloat(x.delta),
          label: `Viajes diarios (sem anterior): ${parseFloat((x.trips_per_day).toFixed(2))}`,
          value: parseFloat(x.trips_per_day),
          color: '#444444',
          strokeWeight: 0,
          opacity: Math.min(0.2 + ((0.8 * parseFloat(x.trips_per_day)) / max), 1),
        }),
      );
      setForecastTrips(blocks);
    } else {
      setForecastTrips([]);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const loadInternalTransport = async (pos = null) => {
    setLoading(true);
    const rsp = await findDevices({
      fleetId,
      type: 'internal_transport',
      position: pos || findLocationRef.current,
      output: 'map',
    });
    if (rsp && rsp.status) {
      setInternalTransports(rsp.data);
    } else {
      setInternalTransports([]);
    }
  };
  const loadDevices = async (pos = null) => {
    if (loadDevicePidRef?.current) {
      console.log('clearing previous timeout', loadDevicePidRef.current);
      window.clearTimeout(loadDevicePidRef.current);
    }
    setLoading(true);
    // loadInternalTransport(pos);
    let getStatus = filterStatusRef?.current.length > 0 ? filterStatusRef?.current : null;
    if (getStatus && (getStatus.includes('pickup_street_day_40') || getStatus.includes('pickup_street_night_60'))) {
      getStatus = ['live', 'disabled', 'missing_connection'];
    }
    console.log('loading devices');
    const rsp = await findDevices({
      q: null,
      fleetId,
      position: pos || findLocationRef.current,
      output: 'map',
    });
    if (rsp && rsp.status) {
      const d = rsp.data.map((x) => ({
        ...x,
        lat: x.lat + (0.0000 * (Math.random() - 0.5)),
        lng: x.lng + (0.0000 * (Math.random() - 0.5)),
      }));
      setDevices(d);
    } else {
      setDevices([]);
    }
    setLoading(false);
    const pid = window.setTimeout(() => loadDevices(), 20000);
    setLoadDevicePid(pid);
  };

  const onCloseResume = () => {
    setSelectedDevice(null);
  };

  const removeDeviceFromMultiple = (id) => {
    setMultipleSelectedDevices(multipleSelectedDevices.filter((x) => x.id !== id));
  };

  const onSelectMultipleDevice = (marker) => {
    if (
      (multipleSelectedDevices.map((x) => x.id)).includes(marker.id)
    ) {
      return removeDeviceFromMultiple(marker.id);
    }
    setMultipleSelectedDevices([...multipleSelectedDevices, marker]);
    return null;
  };

  const onSelectSingleDevice = async (marker) => {
    setSelectedDevice(null);
    setDeviceUpdating(true);
    const rsp = await getDeviceByTypeQROrRef({
      type: marker.type,
      code: marker.ref,
    });
    if (rsp && rsp.status) {
      setSelectedDevice(rsp.data);
    }
    setDeviceUpdating(false);
  };

  const onSelectDevice = async (marker) => {
    if (selectedMode === 'single') {
      await onSelectSingleDevice(marker);
    }
    if (selectedMode === 'multiple') {
      await onSelectMultipleDevice(marker);
    }
  };

  const onDeviceAction = async (action, type, ref, params = null) => {
    setDeviceUpdating(true);
    const command = getCommandForAction(action, params);
    if (!command) {
      return null;
    }
    const rsp = await sendDeviceCommand({
      type,
      ref,
      command,
      sync: true,
    });
    setDeviceUpdating(false);
    if ((rsp && rsp.status)) {
      notification.success({
        message: 'Comando enviado con éxito.',
        placement: 'bottom',
      });
    } else {
      notification.error({
        message: 'Ocurrió un error al enviar el comando. Intenta nuevamente.',
        placement: 'bottom',
      });
    }
    return null;
  };

  const onStatusFilterChange = (value) => {
    if (value.includes('pickup_street_day_40')) {
      setFilterStatus(['pickup_street_day_40']);
      setFilterBattery([0, 40]);
    } else if (value.includes('pickup_street_night_40')) {
      setFilterStatus(['pickup_street_night_40']);
      setFilterBattery([0, 40]);
    } else if (value.includes('pickup_street_night_60')) {
      setFilterStatus(['pickup_street_night_60']);
      setFilterBattery([0, 60]);
    } else {
      setFilterStatus(value);
    }
  };
  const onBatteryFilterChange = (value) => {
    setFilterBattery(value);
  };
  const onLastReportFilterChange = (value) => {
    setFilterLastReport(value);
  };

  const filterDevicesByUpdated = (devs) => devs.filter((d) => {
    if (d.status === 'stolen') {
      return moment(d.geo_updated_at) > moment().subtract(3, 'hour');
    }
    return moment(d.geo_updated_at) > moment().subtract(20, 'day');
  });
  const filterDevices = (devs) => {
    let filteredDevices = devs.map((d) => ({
      ...d, id: d.id, latitude: d.lat, longitude: d.lng,
    }));
    filteredDevices = filterDevicesByUpdated(filteredDevices);
    if (filterQ) {
      const filterQArray = filterQ.split(/,| /).filter((x) => !!x).map((x) => x.toUpperCase());
      filteredDevices = filteredDevices.filter(
        (d) => (filterQArray.includes(d.qr) || filterQArray.includes(d.ref)),
      );
    }
    if (filterLastReport) {
      filteredDevices = filteredDevices.filter(
        (d) => d.data.mins_since_last_connection > (filterLastReport[0] * 60)
          && d.data.mins_since_last_connection < (filterLastReport[1] * 60),
      );
    }
    if (filterBattery) {
      filteredDevices = filteredDevices.filter(
        (d) => d.data.battery >= filterBattery[0] && d.data.battery <= filterBattery[1],
      );
    }
    if (filterStatus && filterStatus.length > 0) {
      filteredDevices = filteredDevices.filter(
        (d) => {
          if (d.type === 'internal_transport') {
            return true;
          }

          if (filterStatus === '') {
            return d.status === '';
          }
          if (filterStatus.includes('with_error')) {
            if (d.data.coderr > 0) {
              return true;
            }
          }
          if (filterStatus.includes('pickup_street_day_40') || filterStatus.includes('pickup_street_night_40') || filterStatus.includes('pickup_street_night_60')) {
            const bat = parseInt(d.data?.battery, 10);
            if (['missing_connection', 'disabled'].includes(d.status)) {
              return true;
            }
            if (['live'].includes(d.status) && bat >= filterBattery[0] && bat <= filterBattery[1]) {
              return true;
            }
            return false;
          }
          if (filterStatus.includes('out_of_area')) {
            if (d.out_of_area) {
              if (d.out_of_area) {
                return true;
              }
            }
          }

          return d.status !== '' && filterStatus.includes(d.status);
        },
      );
    }
    return filteredDevices;
  };
  const filterInGeofence = (ds, geofence) => {
    const polygonGeofence = new window.google.maps.Polygon({
      paths: geofence.points.map((x) => ({ lat: x.latitude, lng: x.longitude })),
    });
    const rsp = ds.filter((d) => (
      polygonGeofence.containsLatLng(d.lat, d.lng)
    ));
    return rsp;
  };
  const filterInOverlay = (ds, overlay) => {
    const rsp = ds.filter((d) => (
      overlay.getBounds().contains({ lat: d.lat, lng: d.lng })
    ));
    return rsp;
  };
  const onRightClickGeofence = (geofence) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`¿Seleccionar todos los dispositivos visibles en esta zona (${geofence.name})?`)) {
      const filteredDevices = filterInGeofence(filterDevices(devices), geofence);
      setSelectedMode('multiple');
      setMultipleSelectedDevices(filteredDevices);
    }
  };
  const onSelectDevicesInOverlay = (type, overlay) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('¿Seleccionar todos los dispositivos visibles en este sector?')) {
      const filteredDevices = filterInOverlay(filterDevices(devices), overlay);
      setSelectedMode('multiple');
      setMultipleSelectedDevices(filteredDevices);
    }
  };

  useEffect(async () => {
    localStorage.setItem('__monitor__last_status_filters', JSON.stringify(filterStatus));
  }, [filterStatus]);

  useEffect(() => {
    if (!isMobile()) {
      loadForecast();
    }
  }, [fleetId]);

  useEffect(() => (() => {
    document.title = 'Paperboy';
    if (loadDevicePidRef?.current) {
      window.clearTimeout(loadDevicePidRef.current);
    }
  }), []);
  useEffect(() => {
    if (!queryParamQ) {
      return;
    }
    setFilterQ(queryParamQ);
    setShowFilters(true);
  }, [queryParamQ]);

  useEffect(() => {
    console.log('onlyfordesktop');
    if (isMobile()) { return; }
    console.log('do onlyfordesktop');
    const selectedFleet = fleets.find((x) => x.id === fleetId);
    const centerLocation = {
      latitude: selectedFleet.cost.headquarter.lat,
      longitude: selectedFleet.cost.headquarter.lng,
      lat: selectedFleet.cost.headquarter.lat,
      lng: selectedFleet.cost.headquarter.lng,
    };

    setMapPosition(centerLocation);
    setFindLocation(centerLocation);
    loadDevices(centerLocation);
    loadGeofences();
  }, [fleetId, filterStatus]);

  useEffect(() => {
    if (!isMobile()) { return; }
    console.log('onlyformobile', position, filterStatus, fleetId);
    if (!position.latitude) { return; }
    console.log('do onlyformobile');
    const selectedFleet = fleets.find((x) => x.id === fleetId);
    const centerLocation = isMobile()
      ? {
        latitude: position.latitude,
        longitude: position.longitude,
        lat: position.latitude,
        lng: position.longitude,
      }
      : {
        latitude: selectedFleet.cost.headquarter.lat,
        longitude: selectedFleet.cost.headquarter.lng,
        lat: selectedFleet.cost.headquarter.lat,
        lng: selectedFleet.cost.headquarter.lng,
      };

    setMapPosition(centerLocation);
    setFindLocation(centerLocation);
    loadDevices(centerLocation);
    loadGeofences();
  }, [fleetId, filterStatus, position?.latitude, position?.longitude]);

  const visibleDevices = filterDevices(devices);
  const onlyOneDevice = visibleDevices && visibleDevices.length === 1;

  const menuBarHeight = document.getElementById('menubar') && document.getElementById('menubar').clientHeight;

  const getDeviceComponent = (d) => {
    switch (d.type) {
      case 'internal_transport':
        return (
          <InternalTransportResume
            buttonCols={2}
            user={user}
            onClose={onCloseResume}
            defaultDevice={selectedDevice}
            openActions
            onAction={onDeviceAction}
            updating={deviceUpdating}
          />
        );
      case 'scooter':
      default:
        return (
          <MobileScooterInfo
            fleets={fleets}
            buttonCols={2}
            position={position}
            user={user}
            onClose={onCloseResume}
            defaultDevice={selectedDevice}
            openActions
            onAction={onDeviceAction}
            updating={deviceUpdating}
          />
        );
    }
  };

  const mapCenterLat = onlyOneDevice
    ? visibleDevices.latitude : (selectedDevice ? selectedDevice.lat : mapPosition?.latitude);
  const mapCenterLng = onlyOneDevice
    ? visibleDevices.longitude : (selectedDevice ? selectedDevice.lng : mapPosition?.longitude);

  const showSidebar = (selectedMode === 'multiple' && multipleSelectedDevices?.length > 0) || (selectedDevice || deviceUpdating);

  const renderedMapComponent = useMemo(() => (
    <MapComponent
      key={fleetId}
      currentLocation={position}
      zoom={isMobile() ? 16 : 15}
      defaultCenter={{ lat: mapCenterLat, lng: mapCenterLng }}
      clusterGridSize={clusterGridSize}
      onBoundsChanged={(p1, p2, center) => { setFindLocation(center); }}
      minZoom={1}
      maxZoom={20}
      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `calc(100%-${isMobile() ? 0 : 100}px-116px-${isMobile() && (selectedDevice || deviceUpdating) ? 300 : 0}px)` }} />}
      containerElement={(
        <div
          style={{
            height: window.innerHeight
              - (isMobile() ? (40 + menuBarHeight + 52) : (40 + 120))
              - (isMobile() && (selectedDevice || deviceUpdating) ? 300 : 0),
          }}
        />
      )}
      mapElement={<div style={{ borderRadius: 5, height: '100%' }} />}
      markers={showDevices && [...visibleDevices, ...internalTransports]}
      selectedMarkers={selectedMode === 'multiple' ? multipleSelectedDevices.map((x) => x.id) : []}
      onSelectMarker={onSelectDevice}
      onPolygonRightClick={(polygon) => onRightClickGeofence(polygon)}
      onOverlayComplete={(type, overlay) => onSelectDevicesInOverlay(type, overlay)}
      drawingControlEnabled
      polygons={geofences
        && geofences.map(
          (x) => ({ ...x, color: x.status === 'INACTIVE' ? '#999999' : (colorZone[x.type]) }),
        )}
      blocks={showForecast && forecastTrips}
    />
  ), [visibleDevices, fleetId,
    internalTransports, geofences, mapCenterLng, mapCenterLat, selectedDevice,
    deviceUpdating, multipleSelectedDevices]);

  if (!mapPosition) {
    return null;
  }

  const stolenSuspectDevices = visibleDevices ? visibleDevices.filter((x) => x.status === 'stolen_suspect').length : 0;
  if (stolenSuspectDevices > 0) {
    document.title = `${stolenSuspectDevices} sospecha de robo - Paperboy`;
  } else {
    document.title = 'Paperboy';
  }

  return (
    <>
      <div className={[styles.main].join(' ')}>
        <div className={[styles.tools, styles.filters, showFilters ? styles.visible : ''].join(' ')}>
          <div className={styles.title}>
            <div className={styles.closeBtn} onClick={() => setShowFilters(false)} role="button">
              <FontAwesomeIcon icon={faTimes} style={{ fontSize: 18 }} />
            </div>
            <span>
              Filtrar
            </span>
          </div>

          <div className={styles.statusQr}>
            <div className={styles.statusFilter}>
              <Select
                mode="multiple"
                allowClear
                showSearch={false}
                style={{ width: '100%' }}
                placeholder="Selecciona status..."
                value={filterStatus}
                onChange={onStatusFilterChange}
              >
                <Option key="pickup_street_day_40">
                  <img src={getScooterMarker('pickup_street_day_40')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Recoger de calle Día (
                  {'<40%'}
                  )
                </Option>
                <Option key="pickup_street_night_40">
                  <img src={getScooterMarker('pickup_street_night_40')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Recoger de calle Noche (
                  {'<40%'}
                  )
                </Option>
                <Option key="pickup_street_night_60">
                  <img src={getScooterMarker('pickup_street_night_60')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Recoger de calle Noche (
                  {'<60%'}
                  )
                </Option>
                <Option key="live">
                  <img src={getScooterMarker('live')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Desplegado
                </Option>
                <Option key="in_ride">
                  <div style={{
                    display: 'inline-block', width: 25, height: 25, marginRight: 11, position: 'relative',
                  }}
                  >
                    <img src={getScooterMarker('in_ride')} alt="" style={{ width: 20 }} />
                    <img
                      src={getScooterMarker('in_ride', true)}
                      alt=""
                      style={{
                        width: 15, top: 10, left: 10, position: 'absolute',
                      }}
                    />
                  </div>

                  En viaje
                </Option>
                <Option key="reserved">
                  <img src={getScooterMarker('reserved')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Reservado
                </Option>
                <Option key="stolen_suspect">
                  <img src={getScooterMarker('stolen_suspect')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Sospecha de robo
                </Option>
                <Option key="stolen">
                  <img src={getScooterMarker('stolen')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Robado

                </Option>
                <Option key="in_warehouse">
                  <img src={getScooterMarker('in_warehouse')} alt="" style={{ width: 25, marginRight: 11 }} />
                  En bodega
                </Option>
                <Option key="in_transport_to_deploy">
                  <img src={getScooterMarker('in_transport_to_deploy')} alt="" style={{ width: 25, marginRight: 11 }} />
                  En transporte a ser desplegado
                </Option>
                <Option key="in_transport_to_warehouse">
                  <img src={getScooterMarker('in_transport_to_warehouse')} alt="" style={{ width: 25, marginRight: 11 }} />
                  En transporte a bodega
                </Option>
                <Option key="motorist">
                  <img src={getScooterMarker('motorist')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Motorizado
                </Option>
                <Option key="disabled">
                  <img src={getScooterMarker('disabled')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Deshabilitado
                </Option>
                <Option key="missing_connection">
                  <img src={getScooterMarker('missing_connection')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Sin conexión
                </Option>
                <Option key="damaged">
                  <img src={getScooterMarker('in_transport_to_warehouse')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Dañado
                </Option>
                <Option key="maintenance">
                  <img src={getScooterMarker('maintenance')} alt="" style={{ width: 25, marginRight: 11 }} />
                  En mantenimiento
                </Option>
                <Option key="in_transport_to_grin4u_user">
                  <img src={getScooterMarker('in_transport_to_grin4u_user')} alt="" style={{ width: 25, marginRight: 11 }} />
                  En transporte a usuario Grin 4U
                </Option>
                <Option key="no_status">
                  <img src={getScooterMarker('no_status')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Sin Estado
                </Option>
                <Option key="with_error">
                  <img src={getScooterMarker('with_error')} alt="" style={{ width: 25, marginRight: 11 }} />
                  Con error
                </Option>
                <Option key="out_of_area">
                  <img src={getScooterMarker('in_ride', true)} alt="" style={{ width: 25, marginRight: 11 }} />
                  Fuera de zona
                </Option>

              </Select>
              <div>
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => {
                    setFilterStatus(['pickup_street_day_40']);
                  }}
                >
                  <small>Recoger de calle día (&lt;25%)</small>
                </div>
                {' '}
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => {
                    setFilterStatus(['pickup_street_night_40']);
                  }}
                >
                  <small>Recoger de calle noche (&lt;40%)</small>
                </div>
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => {
                    setFilterStatus(['pickup_street_night_60']);
                  }}
                >
                  <small>Recoger de calle noche (&lt;60%)</small>
                </div>
                {' '}
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => setFilterStatus(defaultFilterStatus)}
                >
                  <small>Filtro por defecto</small>
                </div>
                {' '}
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => setFilterStatus(defaultFilterStatusLive)}
                >
                  <small>Filtro por defecto + desplegados</small>
                </div>
                {' '}
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => setFilterStatus(['live'])}
                >
                  <small>Solo desplegados</small>
                </div>
                <div
                  className={stylesLayout.link}
                  style={{ display: 'inline-block' }}
                  onClick={() => { setFilterStatus(['missing_connection']); setFilterBattery([0, 100]); setFilterLastReport([0, 480]); }}
                >
                  <small>Solo Sin conexión</small>
                </div>
              </div>
            </div>
            <div className={styles.searchqr}>
              <Input placeholder="QR o MAC..." key={filterQ} defaultValue={filterQ} onKeyDown={(e) => (e.key === 'Enter' ? setFilterQ(e.target.value) : null)} />
            </div>
          </div>
          <div className={styles.extraFilters1}>
            <div className={styles.batteryFilter}>
              <div>
                Batería:
              </div>
              <Slider
                onChange={onBatteryFilterChange}
                range
                value={filterBattery}
              />
              <div>
                {filterBattery[0]}
                {' '}
                a
                {' '}
                {filterBattery[1]}
              </div>
            </div>
            {true
              && (
                <div className={styles.lastReportFilter}>
                  <div>
                    Ultimo reporte (horas):
                  </div>
                  <Slider
                    onChange={onLastReportFilterChange}
                    range
                    defaultValue={filterLastReport}
                    min={0}
                    max={720}
                  />
                  <div>
                    {filterLastReport[0]}
                    {' '}
                    a
                    {' '}
                    {filterLastReport[1]}
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className={[styles.inner, showSidebar ? styles.withSecondaryBar : null].join(' ')}>
          <div className={styles.map}>
            <div className={styles.results}>
              <div className={styles.line}>
                <div style={{ display: 'inline-block' }}>
                  {visibleDevices.filter((x) => x.type !== 'internal_transport').length}
                  {' '}
                  {visibleDevices.filter((x) => x.type !== 'internal_transport').length === 1 ? 'dispositivo' : 'dispositivos'}
                  {loading && <span> ...</span>}
                </div>
                <div>
                  <Button onClick={() => loadDevices()}>
                    <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
                  </Button>
                </div>
              </div>
              <div className={styles.byStatus}>
                <RowByStatus
                  devices={visibleDevices}
                  showStatus={filterStatus.filter((x) => !['pickup_street_day_40', 'pickup_street_night_60', 'out_of_area', 'with_error'].includes(x))}
                  size="small"
                  cols={isMobile() ? 4 : 8}
                  onClick={(k) => { onStatusFilterChange([k]); }}
                />
              </div>
              <div className={styles.selectorClusterGridSize}>
                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  <small>Nivel de agrupación de patines en mapa:</small>
                  {' '}
                </div>
                <div style={{
                  display: 'inline-block', width: 200, marginLeft: 10, verticalAlign: 'middle',
                }}
                >
                  <Slider
                    onChange={(v) => setClusterGridSize(v)}
                    value={clusterGridSize}
                    min={1}
                    max={50}
                  />
                </div>
              </div>

            </div>

            {renderedMapComponent}

            <div className={styles.bottomTools}>
              <Button
                className={stylesLayout.roundedButton}
                type="primary"
                onClick={() => setShowFilters(true)}
              >
                <FontAwesomeIcon icon={faFilter} style={{ marginRight: 'var(--spacing_quarter)' }} />

                Filtrar
              </Button>

              <Dropdown
                trigger={['click']}
                overlay={(
                  <Menu>
                    <Menu.Item onClick={() => setSelectedMode('single')}>
                      {selectedMode === 'single' && <FontAwesomeIcon icon={faCheck} style={{ marginRight: 'var(--spacing_quarter)' }} />}
                      Único
                    </Menu.Item>
                    <Menu.Item onClick={() => setSelectedMode('multiple')}>
                      {selectedMode === 'multiple' && <FontAwesomeIcon icon={faCheck} style={{ marginRight: 'var(--spacing_quarter)' }} />}
                      Múltiple
                    </Menu.Item>
                  </Menu>
                )}
                placement="top"
              >
                <Button
                  className={stylesLayout.roundedButton}
                  type="primary"
                >
                  Seleccionar...
                </Button>
              </Dropdown>
              {!isMobile()
                && (
                  <div className={styles.layers}>
                    <Checkbox
                      defaultChecked={!!showDevices}
                      onChange={(e) => { console.log(e); setShowDevices(e.target.checked); }}
                    >
                      Mostrar patines
                    </Checkbox>
                    <Checkbox
                      defaultChecked={!!showForecast}
                      onChange={(e) => { console.log(e); setShowForecast(e.target.checked); }}
                    >
                      Mostrar probabilidad de viajes prox hora
                    </Checkbox>
                  </div>
                )}

            </div>

          </div>

          {selectedMode === 'multiple'
            && multipleSelectedDevices && multipleSelectedDevices.length > 0
            && (
              <div className={[styles.mapSidebar, styles.taskArea].join(' ')}>
                <TaskHandleModule
                  userPosition={position}
                  user={user}
                  initialDevices={multipleSelectedDevices}
                  onChangeDevices={(ds) => { setMultipleSelectedDevices(ds); }}
                  fleetId={fleetId}
                  permissions={{
                    reassign: user?.can?.manage_tasks,
                    modifyDevices: user?.can?.manage_tasks,
                    modifyTask: user?.can?.manage_tasks,
                    viewTask: user?.can?.handle_tasks,
                  }}
                />
              </div>
            )}
          {selectedMode === 'single' && (selectedDevice || deviceUpdating)
            && (
              <div className={[styles.mapSidebar, styles.selectedDevice].join(' ')}>
                {(deviceUpdating && !selectedDevice)
                  ? <div style={{ padding: '130px 10px', textAlign: 'center' }}><Loading /></div>
                  : (
                    selectedDevice
                    && (
                      <>
                        <div className={styles.closebar}>
                          <div className={styles.close} onClick={onCloseResume} role="button">
                            <FontAwesomeIcon icon={faTimes} style={{ fontSize: 18 }} />
                          </div>
                        </div>
                        <div className={styles.content}>
                          {getDeviceComponent(selectedDevice)}
                        </div>

                      </>
                    )
                  )}
              </div>
            )}
        </div>
      </div>
    </>
  );
};
MonitorModule.propTypes = {
  user: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};
export default MonitorModule;
