/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Col, Input, Row,
  Select,
} from 'antd';

import {
  updateFirware,
} from '../../services/devices';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';

const { Option } = Select;
const { TextArea } = Input;

const DevicesUpdateFirmwareModule = () => {
  const [loading, setLoading] = useState(false);
  const [macs, setMacs] = useState(localStorage.getItem('__device__update_firmware_macs') || '');
  const [status, setStatus] = useState(null);
  const [binaryNumber, setBinaryNumber] = useState(localStorage.getItem('__device__update_firmware_binaryNumber') || null);

  useEffect(async () => {
    localStorage.setItem('__device__update_firmware_macs', macs);
    if (binaryNumber !== null) {
      localStorage.setItem('__device__update_firmware_binaryNumber', binaryNumber);
    }
  }, [macs, binaryNumber]);

  const onUpdateFirmwares = async () => {
    if (!macs || !binaryNumber) {
      return;
    }
    setStatus(null);
    setLoading(true);
    const rsp = await updateFirware({
      refs: macs.split('\n'),
      type: 'scooter',
      binaryNumber,
    });
    if (rsp && rsp.status) {
      setStatus(true);
    } else {
      setStatus(false);
    }
    window.setTimeout(() => setStatus(null), 5000);
    setLoading(false);
  };

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Update firmware
      </div>
      <div className={stylesLayout.content}>

        <Row gutter={24}>
          <Col sm={24} xs={24}>
            <div className={styles.input}>
              <div>
                <div>
                  <Select
                    defaultValue={binaryNumber}
                    onChange={(v) => setBinaryNumber(v)}
                    placeholder="Select Binary..."
                    style={{ width: '100%' }}
                  >
                    <Option value="320010102">Chile (version: 32001, binary file: 320010102)</Option>
                    <Option value="320010403">Perú (version: 32001, binary file: 320010403)</Option>
                  </Select>
                  <br />
                  <br />
                </div>
                <TextArea
                  rows={6}
                  defaultValue={macs}
                  placeholder="Macs..."
                  onChange={(e) => setMacs(e.target.value)}
                  onKeyDown={(e) => (e.key === 'Enter' ? onUpdateFirmwares() : null)}
                />
              </div>
              <Button type="primary" loading={loading} onClick={onUpdateFirmwares}>Actualizar</Button>
            </div>
            {status === true && <div className={`${styles.msg} ${styles.ok}`}>Update process started successfully</div>}
            {status === false && <div className={`${styles.msg} ${styles.fail}`}>Update process started failed</div>}
          </Col>
        </Row>
      </div>
    </div>
  );
};

DevicesUpdateFirmwareModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  tenant: PropTypes.object.isRequired,
};

export default DevicesUpdateFirmwareModule;
