export default (client, defaultParams) => ({
  get: (payload) => (
    client(`/mqtt/log?topic=${payload.topic}&limit=${payload.limit}&start=${payload.start}&end=${payload.end}`, {
      method: 'GET',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),

  getMessage: (k) => {
    const messages = {
      '': '',
    };
    return (messages[k] || k);
  },
});
