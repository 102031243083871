import { useState } from 'react';

import * as horusService from '../services/horus';

const useHorusQuery = () => {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState('loading');

  const load = async (query) => {
    setStatus('loading');
    const rsp = await horusService.executeQuery(query);
    if (rsp?.status) {
      setStatus('loaded');
      setData(rsp.data);
    } else {
      setStatus('error');
      setData([]);
    }
  };

  return {
    data,
    status,
    load,
  };
};

export default useHorusQuery;
