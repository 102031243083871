/* eslint-env browser */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';

import rootReducer from './reducers';
import rootSaga from './sagas';

import {
  saveState,
} from './helpers/storage';

export default function configureStore(initialState = {}, history) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
  ];

  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  const composeEnhancers = process.env.NODE_ENV !== 'production' && typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(...enhancers),
  );

  store.subscribe(() => {
    saveState({
      auth: store.getState().auth,
    });
  });

  sagaMiddleware.run(rootSaga);
  store.close = () => store.dispatch(END);
  return store;
}
