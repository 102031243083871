import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Button, notification, Radio, Row, Col, Select,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faFileImage, faFilePdf, faUpload,
} from '@fortawesome/free-solid-svg-icons';
import {
  updateGrin4UInfo,
  updateCustomerDocument,
} from '../../services/users';

import {
  districts,
} from '../../helpers/params';
import {
  humanFileSize,
} from '../../helpers/utils';

import SectionTitle from '../../components/SectionTitle';

import styles from './index.module.scss';
import stylesForm from '../../common/form.module.scss';
import stylesLayout from '../../common/layout.module.scss';
import UploaderFile from '../../components/UploaderFile';

const requiredDocs = {
  PE: [
    { id: 'vatid', label: 'DNI/RUC' },
    { id: 'cert_dom', label: 'Certificado domicilio' },
    { id: 'cert_work', label: 'Certificado trabajo' },
    { id: 'other_1', label: 'Otro 1' },
    { id: 'other_2', label: 'Otro 2' },
    { id: 'other_3', label: 'Otro 3' },
    { id: 'other_4', label: 'Otro 4' },
  ],
  CL: [
    { id: 'vatid', label: 'RUT' },
    { id: 'cert_dom', label: 'Certificado domicilio' },
  ],
};
const CustomerGrin4UInfo = ({
  customer,
  // eslint-disable-next-line no-unused-vars
  user,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando datos...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await updateGrin4UInfo(customer.id, values);

    if (rsp?.status) {
      notification.success({
        message: 'Datos actualizados.',
        placement: 'top',
        key: 'result',
      });
      onUpdate();
    } else {
      notification.error({
        message: 'Error al actualizar datos.',
        placement: 'top',
        key: 'result',
      });
    }
    setLoading(false);
  };

  const setCustomerDocument = async (documentId, file) => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Guardando documento...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await updateCustomerDocument(customer.id, documentId, file);
    if (rsp?.status) {
      onUpdate();
      notification.success({
        message: 'Documento guardado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al guardar documento.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const onFinishFailed = () => {
    // console.log('Failed:', errorInfo);
  };
  const customerCountry = customer.data?.country || 'CL';
  const customerDocuments = customer.data?.documents || {};
  return (
    <div className={styles.cnt}>
      <div className={stylesForm.fields}>
        <Form
          name="basic"
          form={form}
          initialValues={customer.data?.grin4uinfo || {}}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          size="large"
        >
          <Row gutter={24}>
            <Col sm={24} xs={24}>
              <Form.Item
                label="Origen"
                name="origin"
              >
                <Select options={[
                  'Ad FB',
                  'Ad IG',
                  'Ad Whatsapp',
                  'Inbox',
                  'DM',
                  'Whatsapp',
                  'Landing',
                ].map((x) => ({ label: x, value: x }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={12} xs={12}>
              <Form.Item
                label="Tipo"
                name="type"
              >
                <Radio.Group>
                  <Radio value="person">Persona</Radio>
                  <Radio value="company">Empresa</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col sm={12} xs={12}>
              <Form.Item
                label="Documento de Identidad"
                name="vatid"
              >
                <Input placeholder="" type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={12} xs={12}>
              <Form.Item
                label="Género"
                name="gender"
              >
                <Radio.Group>
                  <Radio value="male">Hombre</Radio>
                  <Radio value="female">Mujer</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col sm={12} xs={12}>
              <Form.Item
                label="Edad"
                name="age"
              >
                <Input placeholder="" type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={12} xs={12}>
              <Form.Item
                label="Direccion del domicilio"
                name="house_address"
              >
                <Input
                  placeholder=""
                  type="text"
                />
              </Form.Item>
            </Col>
            <Col sm={12} xs={12}>
              <Form.Item
                label="Localidad del domicilio"
                name="house_district"
              >
                <Select showSearch placeholder="" type="text" options={districts[customerCountry].map((x) => ({ label: x, value: x }))} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={12} xs={12}>
              <Form.Item
                label="Direccion del trabajo"
                name="work_address"
              >
                <Input placeholder="" type="text" />
              </Form.Item>
            </Col>
            <Col sm={12} xs={12}>
              <Form.Item
                label="Localidad del trabajo"
                name="work_district"
              >
                <Select showSearch placeholder="" type="text" options={districts[customerCountry].map((x) => ({ label: x, value: x }))} />
              </Form.Item>
            </Col>
          </Row>
          <div>
            <SectionTitle>Documentos del cliente</SectionTitle>
            <div>
              {requiredDocs[customerCountry].map((r) => (
                <div className={styles.document}>
                  <div className={styles.documentName}>{r.label}</div>
                  <div className={styles.documentInput}>
                    <div className={styles.documentContent}>
                      {customerDocuments[r.id]?.url
                        ? (
                          <>
                            {
                              customerDocuments[r.id]?.mimetype === 'application/pdf'
                              && <FontAwesomeIcon icon={faFilePdf} style={{ color: 'var(--mainColor)' }} />
                            }
                            {customerDocuments[r.id]?.mimetype === 'image/png'
                              && <FontAwesomeIcon icon={faFileImage} style={{ color: 'var(--mainColor)' }} />}
                            {customerDocuments[r.id]?.mimetype === 'image/jpeg'
                              && <FontAwesomeIcon icon={faFileImage} style={{ color: 'var(--mainColor)' }} />}
                            {' '}
                            <a target="_blank" rel="noreferrer" href={customerDocuments[r.id]?.private_url}>
                              Ver
                              {' '}
                            </a>
                          </>
                        ) : 'No cargado'}
                    </div>
                    {' '}
                    <div>
                      <UploaderFile
                        onUpload={({ file }) => { setCustomerDocument(r.id, file); }}
                        render={({
                          uploading, progress,
                        }) => (
                          <>
                            {!uploading && (
                              <div className={stylesLayout.uploader}>
                                <div className={stylesLayout.uploaderInner}>
                                  <FontAwesomeIcon icon={faUpload} style={{ marginRight: 10 }} />
                                  {' '}
                                  Haz click o arrastra un nuevo archivo aquí
                                </div>
                              </div>
                            )}
                            {uploading && progress && progress.map((p) => (
                              <div className={styles.fileDetail}>
                                <div className={styles.fileDetailProgress} style={{ width: `${p.percent}%` }} />
                                <div className={styles.fileDetailInner}>
                                  {p.percent >= 100
                                    ? <FontAwesomeIcon icon={faCheck} color="#093" style={{ marginRight: 10, color: '#093' }} />
                                    : <FontAwesomeIcon icon={faUpload} color="#999" style={{ marginRight: 10, color: '#999' }} />}
                                  <div className={styles.fileDetailName}>
                                    <span style={{
                                      display: 'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '80%',
                                    }}
                                    >
                                      {p.name}

                                    </span>
                                    {p.percent >= 100 && <span> - Guardando...</span>}
                                  </div>
                                  <div className={styles.fileDetailSize}>
                                    {humanFileSize((p.size * p.percent) / 100)}
                                    {' '}
                                    de
                                    {' '}
                                    {humanFileSize(p.size)}
                                  </div>
                                  <div className={styles.fileDetailPercent}>
                                    {parseInt(p.percent, 10)}
                                    %
                                  </div>

                                </div>

                              </div>
                            ))}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading}>
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
CustomerGrin4UInfo.defaultProps = {
  onUpdate: () => { },
};

CustomerGrin4UInfo.propTypes = {
  customer: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};
export default CustomerGrin4UInfo;
