/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import isMobile from 'is-mobile';
import { QrReader } from '@blackbox-vision/react-qr-reader';

import {
  Button,
  Input,

} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLightbulb, faQrcode
  ,
} from '@fortawesome/free-solid-svg-icons';
import { useTorchLight } from '../../hooks/torch';

import styles from './index.module.scss';

const DevicesInputWithQR = ({
  placeholder,
  buttonLabel,
  autofocus,
  onChange,
}) => {
  const [scanning, setScanning] = useState(false);
  const [qr, setQr] = useState([]);
  const qrInput = useRef(null);
  const streamRef = useRef(null);
  const [torchOn, torchToggle] = useTorchLight({
    onError: (e) => console.log('TORCH ERROR', e),
    onSuccess: (status) => console.log('TORCH OK', status),
  });

  const correctAndSendOnChange = (sendQr) => {
    if (!sendQr) {
      return;
    }
    if (onChange) {
      const qrList = sendQr.replaceAll('.', '').split(' ').filter((x) => !!x);
      onChange(qrList);
    }
  };

  const endScanning = () => {
    setScanning(false);
  };
  const startScanning = () => {
    setScanning(true);
  };

  useEffect(() => {
    if (autofocus && qrInput?.current && !isMobile()) {
      qrInput.current.focus();
    }
  }, [qrInput]);

  return (
    <>
      <div className={styles.cnt}>
        <div className={styles.input}>
          <div className={styles.inputInner}>
            <div>
              <Input
                value={qr}
                ref={qrInput}
                autoComplete={false}
                placeholder={placeholder}
                onChange={(e) => setQr(e.target.value.toUpperCase())}
                onKeyDown={(e) => {
                  if (qr && e.key === 'Enter') {
                    const newQr = qr?.split(/[-/]+/).slice(-1)[0];
                    correctAndSendOnChange(newQr);
                  }
                }}
              />
            </div>
            <div className={styles.scan} onClick={startScanning}>
              <FontAwesomeIcon icon={faQrcode} />
            </div>
          </div>
          <Button
            type="primary"
            onClick={() => { correctAndSendOnChange(qr); }}
          >
            {buttonLabel}
          </Button>
        </div>
        <div className={[styles.scanCamera, scanning ? styles.visible : ''].join(' ')}>
          {scanning
            && (
              <>
                <div className={styles.closeBtn} onClick={endScanning}>
                  &times;
                </div>
                <div className={styles.camera}>
                  <QrReader
                    resolution={600}
                    constraints={{
                      aspectRatio: 1,
                      facingMode: 'environment',
                    }}
                    onError={(e) => console.log('QR ERROR', e)}
                    onLoad={({ stream }) => { console.log('ONLOAD'); streamRef.current = stream; }}
                    onResult={(result) => {
                      if (result) {
                        const newQr = result?.text.split(/[-/]+/).slice(-1)[0];
                        setQr(newQr);
                        correctAndSendOnChange(newQr);
                      }
                    }}
                  />
                </div>
                {true
                  && (
                    <>
                      <div className={styles.torchButton}>
                        <FontAwesomeIcon onClick={torchToggle} icon={faLightbulb} color={torchOn ? '#fc0' : '#999'} style={{ fontSize: 24 }} />
                      </div>
                      <div className={styles.torchMessage}>
                        <small>
                          * Si la linterna no enciende, puedes prenderla desde
                          {' '}
                          el panel de notificaciones del teléfono.
                        </small>
                      </div>
                    </>
                  )}
              </>
            )}
        </div>

      </div>
    </>
  );
};

DevicesInputWithQR.defaultProps = {
  onChange: () => { },
  buttonLabel: 'Buscar',
  placeholder: 'QR o MAC...',
  autofocus: false,
};
DevicesInputWithQR.propTypes = {
  onChange: PropTypes.func,
  buttonLabel: PropTypes.string,
  placeholder: PropTypes.string,
  autofocus: PropTypes.bool,
};

export default DevicesInputWithQR;
