/* eslint-disable max-len */
import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const endTrip = async (id) => executeAndWaitResponse(() => apiClient.trips.end(id));
export const endTripNoPay = async (id) => executeAndWaitResponse(() => apiClient.trips.endNoPay(id));
export const payTrip = async (id) => executeAndWaitResponse(() => apiClient.trips.pay(id));
export const findTrips = async (payload) => executeAndWaitResponse(() => apiClient.trips.find(payload));
export const changeTotal = async (id, amount) => executeAndWaitResponse(() => apiClient.trips.changeTotal(id, amount));
export const start = async (id) => executeAndWaitResponse(() => apiClient.trips.start(id));
export const setPaid = async (id) => executeAndWaitResponse(() => apiClient.trips.setPaid(id));
export const doRefund = async (id, amount) => executeAndWaitResponse(() => apiClient.trips.doRefund(id, amount));
export const getTripById = async (id) => executeAndWaitResponse(() => apiClient.trips.get(id));
export const getRecentTrips = async (hours) => executeAndWaitResponse(() => apiClient.trips.recent(hours));
export const getTripByIdWithLogs = async (id) => executeAndWaitResponse(() => apiClient.trips.getWithLogs(id));
export const setTripGrinQualification = async (id, q) => executeAndWaitResponse(() => apiClient.trips.setGrinQualification(id, q));
export const findRecentForDeviceTrips = async (deviceId, days) => executeAndWaitResponse(() => apiClient.trips.findRecentForDevice(deviceId, days));

export default {
  endTrip,
  endTripNoPay,
  start,
  setPaid,
  payTrip,
  findTrips,
  changeTotal,
  getTripById,
  getRecentTrips,
  getTripByIdWithLogs,
  setTripGrinQualification,
  findRecentForDeviceTrips,
};
