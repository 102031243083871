/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Col, Row, Button, Table,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';

import numeral from 'numeral';
import Loading from '../../components/Loading';

import * as horusService from '../../services/horus';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';

const CustomersDashboardModule = ({
  tenant,
  selectedFleet,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [updatedAt, setUpdatedAt] = useState({});

  const updateData = (key, value) => {
    setData((s) => ({
      ...s,
      [key]: value,
    }));
  };

  const loadData = async () => {
    setLoading(true);

    const querySuspectUsers = `select u.user_id, name, u.status, u.created_at, email, recent_trips, recent_trips_zero, count(*) total_trips, sum(t.total) total_paid, sum(if(timestampdiff(SECOND, t.trip_start_at, t.end_at)<60,1,0)) total_trips_zero 
    from (
   select u.id user_id, u.name, u.status, u.created_at, u.email, count(*) recent_trips, sum(if(timestampdiff(SECOND, t.trip_start_at, t.end_at)<60,1,0)) recent_trips_zero 
       from users u, trips t where t.user_id=u.id and t.trip_start_at>now()-interval 4 day and t.total>=0 and t.status='PAID'
       and t.fleet_id=${selectedFleet.id} group by 1 having recent_trips>=2 and (recent_trips_zero>=recent_trips*.65 or recent_trips_zero>=4) and u.status='ACTIVE'
       ) u, trips t where t.user_id=u.user_id and t.total>=0 and t.status='PAID'
   group by 1 having total_trips>=4 order by recent_trips_zero desc;
    `;

    const [
      rspSuspectUsers,
    ] = await Promise.all([
      horusService.executeQuery(querySuspectUsers),
    ]);
    if (rspSuspectUsers?.status) {
      const s = rspSuspectUsers.data.map((x) => ({
        ...x,
        risk_level: (
          (x.total_trips_zero / x.total_trips)
          * (x.recent_trips_zero / x.recent_trips)
        ),
      })).sort((a, b) => a.risk_level - b.risk_level);

      updateData('suspectUsers', s);
    } else {
      updateData('suspectUsers', null);
    }
    setUpdatedAt(moment());
    setLoading(false);
  };

  useEffect(() => {
    setData({});
    loadData();
  }, [selectedFleet.id]);

  if (!tenant) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Dashboard De Clientes
        <div className={stylesLayout.secAction} style={{ marginTop: -5 }}>
          <Button onClick={() => { loadData(); }} size="medium">
            <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
          </Button>
        </div>
        <div className={stylesLayout.small}>
          Actualizado:
          {' '}
          {moment(updatedAt).fromNow()}
        </div>
      </div>
      <div className={stylesLayout.content} id="content">
        <>

          <Row gutter={24}>
            <Col sm={24} xs={24}>
              <div className={styles.block}>
                <div className={styles.title}>
                  Usuarios con comportamiento sospechoso
                </div>
                <Table
                  size="small"
                  pagination={{ hideOnSinglePage: true, defaultPageSize: 500 }}
                  locale={{ emptyText: 'Sin datos' }}
                  columns={[
                    {
                      title: 'Usuario',
                      dataIndex: 'user_id',
                      key: 'user_id',
                      render: (v, r) => (
                        <>
                          <Link to={`/customer/${v}`}>
                            {r.email}
                          </Link>
                          <br />
                          {r.name}
                        </>
                      ),
                    },
                    {
                      title: 'Creado',
                      dataIndex: 'created_at',
                      key: 'created_at',
                      render: (v) => (
                        <div>
                          {moment.utc(v).local().format('YYYY-MM-DD HH:mm')}
                          <div><small>{moment(v).fromNow()}</small></div>
                        </div>
                      ),
                    },
                    {
                      title: 'Total de viajes recientes',
                      dataIndex: 'recent_trips',
                      key: 'recent_trips',
                      render: (v) => v,
                    },
                    {
                      title: 'Total de viajes recientes valor cero',
                      dataIndex: 'recent_trips_zero',
                      key: 'recent_trips_zero',
                      render: (v) => (
                        <>
                          {v}
                        </>
                      ),
                    },
                    {
                      title: '% de viajes recientes valor cero',
                      dataIndex: 'pct_recent_trips_zero',
                      key: 'pct_recent_trips_zero',
                      render: (v, r) => (
                        <>
                          {numeral((r.recent_trips_zero / r.recent_trips)).format('0%')}
                        </>
                      ),
                    },
                    {
                      title: 'Monto total pagado histórico',
                      dataIndex: 'total_paid',
                      key: 'total_paid',
                      render: (v, r) => (
                        <>
                          {numeral(v).format('$0,000')}
                          <br />
                          Promedio:
                          {' '}
                          {numeral(v / r.total_trips).format('$0,000')}
                        </>
                      ),
                    },
                    {
                      title: 'Total de viajes histórico',
                      dataIndex: 'total_trips',
                      key: 'total_trips',
                      render: (v) => v,
                    },
                    {
                      title: 'Total de viajes histórico valor cero',
                      dataIndex: 'total_trips_zero',
                      key: 'total_trips_zero',
                      render: (v) => (
                        <>
                          {v}
                        </>
                      ),
                    },
                    {
                      title: '% de viajes historia valor cero',
                      dataIndex: 'pct_total_trips_zero',
                      key: 'pct_total_trips_zero',
                      render: (v, r) => (
                        <>
                          {numeral((r.total_trips_zero / r.total_trips)).format('0%')}
                        </>
                      ),
                    },
                    {
                      title: 'Nivel de Riesgo',
                      dataIndex: 'risk_level',
                      key: 'risk_level',
                      defaultSortOrder: 'descend',
                      sorter: (a, b) => (a.risk_level < b.risk_level ? -1 : 1),
                      render: (v) => (
                        <>
                          {(v > 0.65 ? 'Alto' : 'Medio')}
                          {' ('}
                          {numeral(v).format('0%')}
                          )
                        </>
                      ),
                    },
                  ]}
                  dataSource={data.suspectUsers?.map((d, i) => ({
                    key: i,
                    ...d,
                  }))}
                />
              </div>
            </Col>
          </Row>
        </>
      </div>
    </div>
  );
};

CustomersDashboardModule.propTypes = {
  tenant: PropTypes.object.isRequired,
  selectedFleet: PropTypes.object.isRequired,
};

export default CustomersDashboardModule;
