import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Button } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';

const pointMap = {
  house: 'Domicilio',
  work: 'Trabajo',
};

const SubscriptionItem = ({
  subscription,
  customer,
  onPaySubscription,
}) => {
  const [showAssignItem, setShowAssignItem] = useState(false);
  return (
    <div className={styles.subscription}>
      <div className={styles.info}>
        <div className={[styles.field].join(' ')}>
          #
          {subscription.id}
          {' '}
          -
          {subscription.type}
          {' '}
          -
          {' '}
          {subscription.status_tr}
        </div>
        <div className={[styles.field].join(' ')}>
          Comienzo:
          {' '}
          {subscription.start_at ? moment.utc(subscription.start_at).local().format('YYYY-MM-DD') : 'No definido'}
        </div>

        <div className={[styles.field].join(' ')}>
          Fin:
          {' '}
          {subscription.end_at ? moment.utc(subscription.start_at).local().format('YYYY-MM-DD') : 'No definido'}
        </div>
        <div className={[styles.field].join(' ')}>
          Lugar de entrega:
          {' '}
          {pointMap[subscription.data?.shipping_point]}
          {' '}
          -
          {' '}
          {customer.data?.grin4uinfo
            // eslint-disable-next-line prefer-template
            ? `${customer.data?.grin4uinfo[subscription.data?.shipping_point + '_address']}, ${customer.data?.grin4uinfo[subscription.data?.shipping_point + '_district']}`
            : 'No definido'}
        </div>

      </div>
      <div className={styles.actions}>
        {subscription.status === 'NON_PAID'
          && (
            <Button onClick={onPaySubscription}>
              Marcar como pagada
            </Button>
          )}
        {['PAID', 'ACTIVE'].includes(subscription.status)
          && (
            !showAssignItem && (
              <Button onClick={() => setShowAssignItem((s) => !s)}>
                Asignar patín
              </Button>
            )
          )}
      </div>
      {
        ['PAID', 'ACTIVE'].includes(subscription.status)
        && (
          <div className={styles.itemsArea}>
            <div className={styles.items}>
              {subscription.items?.length > 0
                ? (
                  <>
                    {
                      subscription.items.map((x) => (
                        x.id
                      ))
                    }
                  </>
                )
                : <div>No hay items asignados</div>}
            </div>
            <div className={styles.newItem}>
              <Button onClick={() => setShowAssignItem((s) => !s)}>
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
                Asignar patín
              </Button>
            </div>
          </div>
        )
      }
    </div>
  );
};

SubscriptionItem.propTypes = {
  subscription: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  onPaySubscription: PropTypes.func.isRequired,

};
export default SubscriptionItem;
