export const calculateTotal = (order) => {
  const total = order.rows.reduce((a, b) => {
    if (!b) {
      return a;
    }
    // eslint-disable-next-line no-param-reassign
    if (!b.qty) { b.qty = 1; }
    // eslint-disable-next-line no-param-reassign
    if (!b.unit_price) { b.unit_price = 0; }
    return a + (
      (b ? parseFloat(b.unit_price) : 0) * (b ? parseInt(b.qty, 10) : 1)
    );
  }, 0);
  return total;
};

export const computeOrderReference = (order) => order.internal_reference || order.short_hash;

export const computeShortHash = (hash) => hash
  .split('-')
  .slice(-2)
  .join('-');

export default {
  computeShortHash,
  calculateTotal,
  computeOrderReference,
};
