import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AlertItem from '../AlertItem';

import styles from './index.module.scss';

const AlertsTable = ({
  updating,
  user,
  alerts,
  defaultSelectedAlerts,
  onChangeSelectedAlerts,
  onAction,
  onChangeAlertStatus,
}) => {
  const [, setSelectedAlerts] = useState([]);

  useEffect(() => {
    setSelectedAlerts(defaultSelectedAlerts);
  }, [defaultSelectedAlerts]);
  // eslint-disable-next-line no-unused-vars
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const newSelectedRows = selectedRows.map((a) => a.id);
      setSelectedAlerts(newSelectedRows);
      onChangeSelectedAlerts(newSelectedRows);
    },
    getCheckboxProps: (record) => ({
      // Column configuration not to be checked
      id: record.ref,
    }),
  };
  return (
    <div className={styles.table}>
      {alerts.map((alert) => (
        <AlertItem
          key={alert.id}
          alert={alert}
          user={user}
          onAction={onAction}
          onChangeAlertStatus={onChangeAlertStatus}
          updating={updating}
        />
      ))}
    </div>
  );
};
AlertsTable.defaultProps = {
  updating: false,
  defaultSelectedAlerts: [],
  onChangeSelectedAlerts: () => { },
  onAction: () => { },
  onChangeAlertStatus: () => { },
};

AlertsTable.propTypes = {
  updating: PropTypes.bool,
  alerts: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  defaultSelectedAlerts: PropTypes.array,
  onChangeSelectedAlerts: PropTypes.func,
  onAction: PropTypes.func,
  onChangeAlertStatus: PropTypes.func,
};

export default AlertsTable;
