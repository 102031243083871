/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import styles from './index.module.scss';

const Metric = ({
  n,
  title,
  subtitle,
  icon,
  size,
  onClick,
  format,
  formatDelta,
  formatTotalPercent,
  totalPercent,
  delta,
  isDeltaPositive,
  style,
}) => (
  <div
    className={`${styles.cnt} ${styles[`size_${size}`]}  ${icon ? styles.withicon : null}`}
    style={{ ...style }}
    onClick={onClick}
  >
    {icon && (
      <div className={styles.icon}>{icon}</div>
    )}
    <div className={styles.info}>
      <div className={styles.n}>
        {!Number.isNaN(n) ? numeral(n).format(format) : n}
        {delta && !Number.isNaN(delta) ? (
          <span className={[styles.delta, isDeltaPositive(delta) ? styles.positive : styles.negative].join(' ')}>
            {!Number.isNaN(delta) ? numeral(delta).format(formatDelta) : null}
          </span>
        ) : null}
        {totalPercent && !Number.isNaN(totalPercent) ? (
          <span className={[styles.totalPercent].join(' ')}>
            {!Number.isNaN(totalPercent) ? numeral(totalPercent).format(formatTotalPercent) : null}
          </span>
        ) : null}
      </div>
      <div className={styles.title}>
        {title}
      </div>
      {subtitle
        && (
          <div className={styles.subtitle}>
            {subtitle}
          </div>
        )}
    </div>
  </div>
);
Metric.defaultProps = {
  icon: null,
  delta: null,
  totalPercent: null,
  format: '0,0',
  formatDelta: '+0.00%',
  formatTotalPercent: '0.00%',
  size: 'normal',
  style: {},
  subtitle: null,
  onClick: () => { },
  isDeltaPositive: (delta) => delta >= 0,
};

Metric.propTypes = {
  icon: PropTypes.any,
  format: PropTypes.string,
  formatDelta: PropTypes.string,
  formatTotalPercent: PropTypes.string,
  n: PropTypes.any.isRequired,
  delta: PropTypes.any,
  totalPercent: PropTypes.any,
  style: PropTypes.object,
  size: PropTypes.string,
  isDeltaPositive: PropTypes.func,
  title: PropTypes.any.isRequired,
  subtitle: PropTypes.any,
  onClick: PropTypes.func,
};

export default Metric;
