/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import moment from 'moment';
import numeral from 'numeral';

import {
  Button,
  Tabs,
  Input, Table,
} from 'antd';
// import { useSearchableTable } from '../../hooks/useSearchableTable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faStar,
} from '@fortawesome/free-solid-svg-icons';

import {
  getTripQualificationColor,
} from '../../helpers/utils';
import {
  computeShortHash,
} from '../../helpers/orders';

import useQuery from '../../hooks/useQuery';
import {
  findUsers,
} from '../../services/users';

import {
  findTrips,
  getRecentTrips,
} from '../../services/trips';

import styles from './index.module.scss';
import Loading from '../../components/Loading';
import stylesLayout from '../../common/layout.module.scss';

const { TabPane } = Tabs;

const TripsModule = () => {
  const params = useQuery();
  const qParam = params.get('q') || '';
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [q, setQ] = useState(null);
  const [tabActiveKey, setTabActiveKey] = useState('results');
  const [results, setResults] = useState(null);
  const [recentTrips, setRecentTrips] = useState(null);
  // const getColumnSearchProps = useSearchableTable();

  const onStartSearch = async (forceQ) => {
    const searchQ = (forceQ || q).replaceAll('\n', ',');
    if (!searchQ) {
      return;
    }
    const newUrl = `/trips/?q=${encodeURIComponent(searchQ)}`;
    if (newUrl !== `${history.location.pathname}${history.location.search}`) {
      history.push(newUrl);
    }
    setLoading(true);
    setTabActiveKey('results');
    const rspUsers = await findUsers({
      q: searchQ,
    });

    const r = { users: [], trips: [] };
    if (rspUsers && rspUsers.status && rspUsers?.data.length > 0) {
      r.users = rspUsers.data;
    } else {
      const rspTrips = await findTrips({
        q: searchQ,
      });

      if (rspTrips && rspTrips.status) {
        r.trips = rspTrips.data;
      }
    }
    setResults(r);
    setLoading(false);
  };
  const loadRecentTrips = async () => {
    const rsp = await getRecentTrips(1);
    if (rsp?.status) {
      setRecentTrips(rsp.data);
    }
  };

  useEffect(async () => {
    if (qParam && qParam !== q) {
      setQ(qParam);
      onStartSearch(qParam);
    }
  }, [qParam]);

  const usersColumns = [
    {
      title: '',
      dataIndex: 'grin_qualification',
      key: 'grin_qualification',
      width: 30,
      render: (v) => (
        <FontAwesomeIcon
          icon={faStar}
          style={{
            display: 'inline-block',
            color: getTripQualificationColor(
              v,
            ),
          }}
        />
      ),
    },
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
      render: (id) => (
        <>
          <div>
            <Link to={`/customer/${id}`}>{id}</Link>

          </div>
        </>
      ),
    },
    {
      title: 'Grin4U',
      key: 'grin4u',
      dataIndex: 'id',
      render: (id) => (
        <>
          <div>
            <Link to={`/grin4u/customer/${id}`}>Ver info Grin4U</Link>

          </div>
        </>
      ),
    },

    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (name) => name,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email) => email,
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone) => phone,
    },
  ];

  const tripsColumns = [
    {
      title: '',
      dataIndex: 'grin_qualification',
      key: 'grin_qualification',
      width: 30,
      render: (v) => (
        <FontAwesomeIcon
          icon={faStar}
          style={{
            display: 'inline-block',
            color: getTripQualificationColor(
              v,
            ),
          }}
        />
      ),
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: 180,
      render: (id, r) => (
        <>
          <div>
            <Link to={`/trip/${r.id}`}>{id}</Link>
            {' '}
            -
            {' '}
            <Link to={`/trip/${r.id}`}>{computeShortHash(r.hash)}</Link>
          </div>
        </>
      ),
    },

    {
      title: 'Usuario',
      dataIndex: 'user',
      key: 'user',
      render: (v) => (
        <>
          <div>
            #
            {v.id}
            {' '}
            -
            {' '}
            {v.name || '(Sin nombre)'}
          </div>
          <div><small><Link to={`/trips/?q=${v.email}`}>{v.email}</Link></small></div>
          <div>
            <small>
              Registro:
              {' '}
              {moment.utc(v.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
            </small>
          </div>
        </>
      ),
    },
    {
      title: 'Dispositivo',
      dataIndex: 'device',
      key: 'device',
      render: (v) => (
        <>
          <div>
            <Link to={`/device/${v.qr}`}>{v.qr}</Link>
            <br />
            <small>{v.status_tr}</small>
          </div>
        </>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'cost_calculated',
      key: 'total',
      render: (costCalculated) => (
        numeral(costCalculated.details.payment_total).format('$0,000.00')
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status_tr',
      key: 'status_tr',
      render: (statusTr) => (
        statusTr
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'status_tr',
      key: 'status_tr',
      render: (v, r) => {
        if (r.status === 'STARTING') { return 'Intento de inicio sin completar'; }
        if (r.trip_start_at) { return 'Viaje'; }
        return 'Reserva sin viaje';
      },
    },
    {
      title: 'Comienzo',
      dataIndex: 'trip_start_at',
      key: 'trip_start_at',
      render: (v, r) => (
        (
          <div>
            <div>
              {moment.utc(v || r.start_at).local().format('YYYY-MM-DD HH:mm:ss')}
            </div>
            <div><small>{moment.utc(v || r.start_at).fromNow()}</small></div>
          </div>
        )
      ),
    },
    {
      title: 'Fin',
      dataIndex: 'end_at',
      key: 'end_at',
      render: (v, r) => (
        (v || r.reserved_finished_at || r.reserved_autocancel_at) && (
          <div>
            <div>{moment.utc(v || r.reserved_finished_at || r.reserved_autocancel_at).local().format('YYYY-MM-DD HH:mm:ss')}</div>
            <div>
              <small>
                {moment.utc(v || r.reserved_finished_at || r.reserved_autocancel_at).fromNow()}
              </small>
            </div>
          </div>
        )
      ),
    },
    {
      title: 'Duración (min)',
      align: 'center',
      dataIndex: 'cost_calculated',
      key: 'min',
      render: (v) => (
        v.minutes
      ),
    },
    {
      title: 'Pagado',
      dataIndex: 'paid_at',
      key: 'paid_at',
      render: (paidAt) => (
        paidAt && moment.utc(paidAt).local().format('YYYY-MM-DD HH:mm:ss')
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: (x, r) => (
        <Link to={`/trip/${r.id}`}><Button>Ver</Button></Link>
      ),
    },
  ];
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        <div className={stylesLayout.secAction} style={{ marginTop: -5 }}>
          <Button onClick={() => history.push('/register')} size="medium">
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
            {' '}
            Crear usuario
          </Button>
        </div>
        Usuarios y Viajes
      </div>
      <div className={stylesLayout.content}>
        <div className={styles.input}>
          <Input value={q} placeholder="Email del usuario o id de viaje..." onChange={(e) => setQ(e.target.value)} onKeyDown={(e) => (e.key === 'Enter' ? onStartSearch() : null)} />

          <Button type="primary" onClick={() => onStartSearch()} disabled={!q || loading}>{loading ? 'Cargando' : 'Buscar'}</Button>
        </div>
        <Tabs
          activeKey={tabActiveKey}
          onChange={(k) => {
            setTabActiveKey(k);
            if (k === 'recents') {
              loadRecentTrips();
            }
          }}
        >
          <TabPane
            tab={`Resultados ${!loading ? `(${(results?.users.length || 0) + (results?.trips.length || 0)})` : '(Buscando...)'}`}
            key="results"
          >
            {loading ? <Loading />
              : (results
                ? (
                  <div className={stylesLayout.box}>
                    {!loading && !results?.users.length && !results?.trips.length && (
                      <div className={stylesLayout.message}>
                        No hay resultados
                      </div>
                    )}

                    {!loading && (results?.users && results.users.length > 0) && (
                      <>
                        <div className={styles.table}>
                          <Table
                            loading={loading}
                            size="small"
                            pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
                            locale={{ emptyText: 'Sin datos' }}
                            columns={usersColumns}
                            dataSource={results.users.map((d) => ({
                              key: d.id,
                              ...d,
                            }))}
                          />
                        </div>
                      </>
                    )}
                    {!loading && (results?.trips && results.trips.length > 0) && (
                      <>
                        <div className={styles.table}>
                          <Table
                            loading={loading}
                            size="small"
                            pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
                            locale={{ emptyText: 'Sin datos' }}
                            columns={tripsColumns}
                            dataSource={results.trips.map((d) => ({
                              key: d.id,
                              ...d,
                            }))}
                          />
                        </div>
                      </>
                    )}
                  </div>
                ) : 'No hay resultados')}
          </TabPane>
          <TabPane
            tab="Viajes recientes, últ hora"
            key="recents"
          >
            {recentTrips && (
              <div className={stylesLayout.box}>
                <div className={styles.table}>
                  <Table
                    size="small"
                    pagination={{ hideOnSinglePage: true, defaultPageSize: 10 }}
                    locale={{ emptyText: 'Sin datos' }}
                    columns={tripsColumns}
                    dataSource={recentTrips.map((d) => ({
                      key: d.id,
                      ...d,
                    }))}
                  />
                </div>
              </div>

            )}
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

TripsModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  tenant: PropTypes.object.isRequired,
};
export default TripsModule;
