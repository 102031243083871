import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import * as horusService from '../../services/horus';
import Loading from '../Loading';

import styles from './index.module.scss';

const Widget = ({
  title,
  source,
  style,
  render,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const loadSource = async () => {
    setLoading(true);
    const rsp = await horusService.execute(source);
    if (rsp?.status) {
      setData(rsp.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadSource();
  }, []);
  useEffect(() => {
    loadSource();
  }, [source]);

  return (
    <div className={styles.cnt} style={style}>
      <div className={styles.bar}>
        <div className={styles.title}>{title}</div>
        <div className={styles.actions}>
          <Button
            size="small"
            onClick={() => loadSource()}
            type="secondary"
          >

            <FontAwesomeIcon icon={faSync} spin={loading} />
          </Button>
        </div>
      </div>
      <div className={styles.body}>
        {
          loading
            ? (
              <div style={{
                height: '100%', alignItems: 'center', display: 'grid', justifyContent: 'center',
              }}
              >
                <Loading />
              </div>
            )
            : render(data)
        }
      </div>
    </div>
  );
};

Widget.defaultProps = {
  title: '',
  style: {},
};
Widget.propTypes = {
  title: PropTypes.string,
  source: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default Widget;
