export default (client, defaultParams) => ({
  createOrUpdate: (payload) => (
    client('/task', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  find: ({
    status, fleetId, creatorId = null, assigneeId = null,
  }) => (
    client(`/tasks/find?status=${status || ''}&fleet_id=${fleetId}${assigneeId ? `&assignee_id=${assigneeId || ''}` : ''}${creatorId ? `&creator_id=${creatorId || ''}` : ''}`,
      {
        method: 'GET',
        params: {
          ...defaultParams,
        },
      })
  ),
});
