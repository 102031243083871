/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import isMobile from 'is-mobile';
import PropTypes from 'prop-types';
import {
  Button,
} from 'antd';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GOOGLE_MAPS_API_KEY } from '../../environment';
import { MapComponent } from '../../components/MapComponent2';
import DeployPlanItem from '../../components/DeployPlanItem';

import * as deployPlansService from '../../services/deploy_plans';

import {
  calculateCentroid,
} from '../../helpers/utils';

import styles from './index.module.scss';

const DeployPlanModule = ({ position, fleets, fleetId }) => {
  const [mapPosition, setMapPosition] = useState(null);
  const [mapPanTo, setMapPanTo] = useState(null);
  const [deployPlans, setDeployPlans] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [snackMessage, setSnackMessage] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const setTemporaryMessage = ({ message, type, time = 2000 }) => {
    setSnackMessage({
      message,
      type,
    });
    window.setTimeout(() => {
      setSnackMessage(null);
    }, time);
  };

  const loadDeployPlans = async () => {
    const rsp = await deployPlansService.getForFleet([fleetId], ['all']);
    if (rsp && rsp.status) {
      setDeployPlans(rsp.data);
    } else {
      setDeployPlans([]);
    }
  };

  const onCreateDeployPlan = async () => {
    const rsp = await deployPlansService.create({
      name: 'Nuevo plan',
      status: 'ACTIVE',
      fleet_id: fleetId,
      data: { points: [] },
    });
    if (rsp && rsp.status) {
      setDeployPlans((s) => [...s, { ...rsp.data, editable: true }]);
    }
  };
  const getDeployPlanById = (id) => deployPlans.find((x) => x.id === id);

  // eslint-disable-next-line no-unused-vars
  const onDeployPlanPathChanged = async (id, points) => {

  };

  const onSelectDeployPlan = async (id) => {
    setSelectedId(id);
  };
  const onStartEditingDeployPlan = (id) => {
    setDeployPlans((s) => s.map((x) => ({ ...x, editable: x.id === id })));
    const currentDeployPlan = getDeployPlanById(id);
    onSelectDeployPlan(id);
    if (currentDeployPlan) {
      const center = calculateCentroid(currentDeployPlan?.data?.points || []);
      setMapPanTo(center);
      window.setTimeout(() => setMapPanTo(null), 1000);
    }
  };

  const onSaveEditingDeployPlan = async (id, fields) => {
    const newFields = { ...fields };
    delete newFields.points;
    await deployPlansService.update(id, newFields);
    setDeployPlans((s) => s.map(
      (x) => (x.id === id ? { ...x, ...newFields, editable: false } : { ...x, editable: false }),
    ));
    loadDeployPlans();
  };
  const onDeleteDeployPlan = async (id) => {
    await deployPlansService.update(id, { status: 'DELETED' });
    setDeployPlans((s) => s.map(
      (x) => (x.id === id ? null : { ...x, editable: false }),
    ).filter((x) => !!x));
  };

  const onEndEditingDeployPlan = () => {
    setDeployPlans((s) => s.map((x) => ({ ...x, editable: false })));
  };

  useEffect(() => {
    if (position && position.latitude && !mapPosition) {
      setMapPosition(position);
    }
  }, [position]);

  useEffect(() => {
    loadDeployPlans();
    const selectedFleet = fleets.find((x) => x.id === fleetId);
    setMapPosition({
      latitude: selectedFleet.cost.headquarter.lat,
      longitude: selectedFleet.cost.headquarter.lng,
    });
  }, [fleetId]);

  if (!mapPosition) {
    return null;
  }

  const selectedDeployPlan = selectedId && getDeployPlanById(selectedId);
  console.log(selectedDeployPlan);

  return (
    <>
      <div className={[styles.main].join(' ')}>
        <div className={styles.list}>
          <div className={styles.actions}>
            <div>
              <Button
                type="primary"
                block
                onClick={onCreateDeployPlan}
              >
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
                Agregar zona
              </Button>
            </div>
          </div>
          <div className={styles.content}>
            {deployPlans && fleetId
              && deployPlans.filter(
                (x) => x.fleet_id === fleetId,
              ).map((g) => (
                <DeployPlanItem
                  key={g.id}
                  deployPlan={g}
                  fleets={fleets}
                  onSelect={onSelectDeployPlan}
                  onStartEditing={(id) => onStartEditingDeployPlan(id)}
                  onSaveEditing={onSaveEditingDeployPlan}
                  onEndEditing={onEndEditingDeployPlan}
                  onDelete={onDeleteDeployPlan}
                  open={g.editable}
                />
              ))}

          </div>
        </div>

        <div className={styles.map}>
          <MapComponent
            key={fleetId}
            minimumClusterSize={1000}
            apiKey={GOOGLE_MAPS_API_KEY}
            defaultZoom={13}
            defaultCenter={{ lat: mapPosition.latitude, lng: mapPosition.longitude }}
            panTo={mapPanTo && { lat: mapPanTo.latitude, lng: mapPanTo.longitude }}
            minZoom={1}
            maxZoom={20}
            containerStyle={{
              height: window.innerHeight
                - (isMobile() ? 40 : 40),
            }}
            loadingElement={<div style={{ height: isMobile() ? 300 : 'calc(100% - 40px)' }} />}
            containerElement={(
              <div
                style={{
                  height: isMobile() ? 400 : window.innerHeight - 40,
                }}
              />
            )}
            onClickPolygon={(polygon) => onStartEditingDeployPlan(polygon.id)}
            mapElement={<div style={{ borderRadius: 5, height: isMobile() ? 400 : '100%' }} />}
            markers={selectedDeployPlan?.data?.points.map((x) => ({
              ...x,
              icon: 'x',
              label: (
                <div style={{ textAlign: 'center', position: 'relative' }}>
                  <div style={{
                    borderRadius: 5,
                    background: '#f09',
                    width: 25,
                    height: 25,
                    left: '50%',
                    top: -30,
                    color: '#fff',
                    fontSize: 17,
                    fontWeight: 'bold',
                    marginLeft: -12.5,
                    position: 'absolute',
                  }}
                  >
                    {x.qty}

                  </div>
                  <div style={{ display: 'none' }}>{x.name}</div>
                </div>),
            }))}
          />
        </div>
        {snackMessage
          && (
            <div className={[styles.message, styles[snackMessage.type]].join(' ')}>
              {snackMessage.message}
            </div>
          )}
      </div>
    </>
  );
};
DeployPlanModule.propTypes = {
  position: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};
export default DeployPlanModule;
