export default (client, defaultParams) => ({

  getTable: (table) => (
    client(`/bi/table/${table}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),

});
