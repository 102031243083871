import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Table } from 'antd';
import { Link } from 'react-router-dom';
import * as grin4uService from '../../services/grin4u';
import stylesLayout from '../../common/layout.module.scss';

const Grin4UHomeModule = ({
  // eslint-disable-next-line no-unused-vars
  country,
}) => {
  const [loading, setLoading] = useState(false);
  const [q, setQ] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState(null);

  const [inProcessUsers, setInProcessUsers] = useState(null);

  const onStartSearch = async (forceQ) => {
    const searchQ = forceQ || q;
    if (!searchQ) {
      return;
    }
    setLoading(true);
    const rspUsers = await grin4uService.findUsers(searchQ, country);

    if (rspUsers && rspUsers.status) {
      setResults(rspUsers.data);
    } else {
      setResults([]);
    }
    setShowResults(true);
    setLoading(false);
  };

  const loadUsersByStatus = async () => {
    const [
      rspInProcess,
    ] = await Promise.all([
      grin4uService.getUsersByStatus('in_process', country),
    ]);

    if (rspInProcess?.status) {
      setInProcessUsers(rspInProcess.data);
    }
  };
  useEffect(() => {
    loadUsersByStatus();
  }, [country]);

  const usersColumns = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
      render: (id) => <Link to={`/grin4u/customer/${id}`}>{id}</Link>,
    },
    {
      title: 'Status Grin 4U',
      key: 'grin4u_status_tr',
      dataIndex: 'grin4u_status_tr',
      render: (st) => st,
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (name, r) => <Link to={`/grin4u/customer/${r.id}`}>{name}</Link>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email) => email,
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone) => phone,
    },
  ];

  return (

    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Grin 4U
        {' '}
        {country}
      </div>
      <div className={stylesLayout.content}>
        <div className={stylesLayout.inputWithButton}>
          <Input value={q} placeholder="Email del usuario..." onChange={(e) => setQ(e.target.value)} onKeyDown={(e) => (e.key === 'Enter' ? onStartSearch() : null)} />

          <Button type="primary" onClick={() => onStartSearch()} disabled={!q || loading}>{loading ? 'Cargando' : 'Buscar'}</Button>
        </div>
        {showResults && (
          <div className={stylesLayout.section}>
            <div className={stylesLayout.sectionTitle}>
              Resultados de la busqueda
            </div>
            <Table
              size="small"
              pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
              locale={{ emptyText: 'Sin datos' }}
              columns={usersColumns}
              dataSource={results.map((d) => ({
                key: d.id,
                ...d,
              }))}
            />
          </div>
        )}
        <div className={stylesLayout.section}>
          <div className={stylesLayout.sectionTitle}>
            Clientes en proceso de creación
          </div>
          {inProcessUsers
            && (
              <Table
                size="small"
                pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
                locale={{ emptyText: 'Sin datos' }}
                columns={usersColumns}
                dataSource={inProcessUsers.map((d) => ({
                  key: d.id,
                  ...d,
                }))}
              />
            )}
        </div>
      </div>
    </div>
  );
};

Grin4UHomeModule.propTypes = {
  country: PropTypes.string.isRequired,
};
export default Grin4UHomeModule;
