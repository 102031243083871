// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, notification, Space, Select, Button,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  addRole as addRoleToUser,
  removeRole as removeRoleFromUser,
  addFleet as addFleetToUser,
  removeFleet as removeFleetFromUser,
} from '../../services/users';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';

const availableRoles = ['transportist', 'motorist', 'warehouse_operator', 'warehouse_electronic', 'react', 'cs', 'analyst', 'cs_manager',
  'executive_grin4u', 'analyst_grin4u', 'finance', 'operations_grin4u'];
const rolesTr = {
  transportist: 'Transporte',
  motorist: 'Motorizado',
  activator: 'Activador en calle',
  warehouse_operator: 'Operador de bodega/Taller',
  warehouse_electronic: 'Operador de bodega/Taller Electrónico',
  react: 'React',
  cs: 'Atención a cliente',
  analyst: 'Analista',
  cs_manager: 'Encargado de Atención a Cliente',
  executive_grin4u: 'Asesor/Ventas Grin4U',
  analyst_grin4u: 'Analista Grin4U',
  finance: 'Finanzas',
  operations_grin4u: 'Operaciones Grin4U',
};
const UserRolesAndFleet = ({
  customer,
  onUpdate,
  canModify,
  fleets,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [form] = Form.useForm();
  // const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedFleet, setSelectedFleet] = useState(null);
  const addRole = async () => {
    if (!canModify) {
      return;
    }
    if (!selectedRole) {
      return;
    }
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Agregando rol...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await addRoleToUser(customer.id, selectedRole);
    if (rsp?.status) {
      onUpdate();
      notification.success({
        message: 'Rol agregado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al agregar rol.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const deleteRole = async (role) => {
    if (!canModify) {
      return;
    }
    if (!role) {
      return;
    }
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Eliminando rol...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await removeRoleFromUser(customer.id, role);
    if (rsp?.status) {
      onUpdate();
      notification.success({
        message: 'Rol eliminado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al eliminar rol.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const addFleet = async () => {
    if (!canModify) {
      return;
    }
    if (!selectedFleet) {
      return;
    }
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Agregando flota...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await addFleetToUser(customer.id, selectedFleet);
    if (rsp?.status) {
      onUpdate();
      notification.success({
        message: 'Flota agregada.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al agregar flota.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const deleteFleet = async (fleetId) => {
    if (!canModify) {
      return;
    }
    if (!fleetId) {
      return;
    }
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Eliminando flota...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await removeFleetFromUser(customer.id, fleetId);
    if (rsp?.status) {
      onUpdate();
      notification.success({
        message: 'Flota eliminada.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al eliminar flota.',
        placement: 'top',
        key: 'result',
      });
    }
  };
  const fleetsIds = fleets.map((x) => x.id);
  return (
    <div className={styles.cnt}>
      <div className={styles.roles}>
        <div className={stylesLayout.sectionHeader}>Roles</div>
        <table className={[stylesLayout.table].join(' ')}>
          {customer.roles?.map((x) => (
            <tr>
              <td>{rolesTr[x.role] || x.role}</td>
              <td>
                {canModify && x.role !== 'admin' && (
                  <Button danger onClick={() => deleteRole(x.role)} style={{ float: 'right' }}>
                    <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: 5 }} />
                    {' '}
                    Eliminar
                  </Button>
                )}
              </td>

            </tr>
          ))}
        </table>
        <br />
        {canModify
          && (
            <Space direction="horizontal">
              <Select
                style={{ width: 300 }}
                value={selectedRole}
                onChange={(v) => setSelectedRole(v)}
                options={availableRoles.map((x) => ({ label: rolesTr[x] || x, value: x }))}
              />
              <Button onClick={addRole}>Agregar</Button>
            </Space>
          )}

      </div>
      <div className={styles.fleets}>
        <div className={stylesLayout.sectionHeader}>Flotas</div>
        <table className={[stylesLayout.table].join(' ')}>
          {customer.fleets?.map((x) => (
            <tr>
              <td>
                {x.id}
                {' '}
                -
                {' '}
                {x.name}

              </td>
              <td>
                {canModify && fleetsIds.includes(x.id) && (
                  <Button danger onClick={() => deleteFleet(x.id)} style={{ float: 'right' }}>
                    <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: 5 }} />
                    {' '}
                    Eliminar
                  </Button>
                )}
              </td>

            </tr>
          ))}
        </table>
        <br />
        {canModify && (
          <Space direction="horizontal">
            <Select
              style={{ width: 300 }}
              value={selectedFleet}
              onChange={(v) => setSelectedFleet(v)}
              options={fleets.map((x) => ({ value: x.id, label: x.name }))}
            />
            <Button onClick={addFleet}>Agregar</Button>
          </Space>
        )}
      </div>
    </div>
  );
};

UserRolesAndFleet.defaultProps = {
  onUpdate: () => { },
  canModify: false,
};

UserRolesAndFleet.propTypes = {
  fleets: PropTypes.array.isRequired,
  customer: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  canModify: PropTypes.bool,
};
export default UserRolesAndFleet;
