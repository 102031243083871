/* eslint-disable max-len */
import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const createSubscription = async (payload) => executeAndWaitResponse(() => apiClient.grin4u.createSubscription(payload));
export const findUsers = async (q, country) => executeAndWaitResponse(() => apiClient.grin4u.findUsers(q, country));
export const getUserSubscriptions = async (id) => executeAndWaitResponse(() => apiClient.grin4u.getUserSubscriptions(id));
export const getUsersByStatus = async (status, country) => executeAndWaitResponse(() => apiClient.grin4u.getUsersByStatus(status, country));
export const setSubscriptionStatus = async (subscriptionId, status) => executeAndWaitResponse(() => apiClient.grin4u.setSubscriptionStatus(subscriptionId, status));

export default {
  createSubscription,
  getUsersByStatus,
  getUserSubscriptions,
  setSubscriptionStatus,
};
