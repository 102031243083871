/* eslint-disable no-tabs */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button, Input, Select,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { SimpleMapComponent } from '../../components/SimpleMapComponent';
import useLocalStorage from '../../hooks/useLocalStorage';

import styles from './index.module.scss';

import * as devicesService from '../../services/devices';
import Loading from '../../components/Loading';

import stylesLayout from '../../common/layout.module.scss';

const BalanceModule = ({
  fleets,
  fleetId,
}) => {
  const [mapPosition, setMapPosition] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [hours, setHours] = useState(6);
  const [minProb, setMinProb] = useState(0.5);

  const defaultBlockColors = {
    ok: { label: 'OK', color: '#009900' },
    over: { label: 'Sobredeployado', color: '#ffcc00' },
    under: { label: 'Subdeployado', color: '#c30' },

  };

  const [blockColors] = useLocalStorage('__use_block_colors', defaultBlockColors);

  const updateData = (key, value) => {
    setData((s) => ({
      ...s,
      [key]: value,
    }));
  };

  const loadData = async () => {
    setLoading(true);
    const rsp = await devicesService.getBalanceSuggestions(fleetId, hours, minProb);
    if (rsp?.status) {
      const max = Math.max(...rsp.data.x_blocks.map((x) => parseFloat(x.needed_devices)));
      const blocks = rsp.data.x_blocks
        .filter((x) => x.realistic || x.available_devices >= Math.ceil(x.forecast * 1))
        .map(
          (x, index) => {
            let c = '';
            if (x.available_devices > Math.ceil(x.forecast * 1.25)) {
              c = blockColors.over.color;
            } else if (x.available_devices < x.forecast) {
              c = blockColors.under.color;
            } else {
              c = blockColors.ok.color;
            }
            return {
              ...x,
              id: `${Math.random()}-${index}`,
              realistic: x.realistic,
              lat0: parseFloat(x.lat),
              lat1: parseFloat(x.lat) + parseFloat(x.delta),
              lng0: parseFloat(x.lng),
              lng1: parseFloat(x.lng) + parseFloat(x.delta),
              value: parseFloat(x.forecast),
              color: c,
              label: (
                <div>
                  <strong>
                    Patines disponibles:
                    {' '}
                    {x.available_devices}
                  </strong>
                  <br />
                  <strong>
                    Demanda estimada:
                    {' '}
                    {x.forecast.toFixed(2)}
                  </strong>
                  <br />
                  <strong>
                    Patines necesarios:
                    {' '}
                    {x.needed_devices}
                  </strong>
                  <br />
                  <strong>
                    Patines sobrantes:
                    {' '}
                    {x.exceeded_devices_block}
                  </strong>
                </div>
              ),
              strokeWeight: 0,
              opacity: x.needed_devices > 0
                ? Math.min(0.2 + ((0.8 * parseFloat(x.needed_devices)) / max), 1) : 0.5,
            };
          },
        );
      updateData('blocks', blocks);
      updateData('all', rsp.data);
    } else {
      updateData('blocks', []);
      updateData('all', null);
    }
    setLoading(false);
  };

  useEffect(() => {
    const selectedFleet = fleets.find((x) => x.id === fleetId);
    setMapPosition({
      latitude: selectedFleet.cost.headquarter.lat,
      longitude: selectedFleet.cost.headquarter.lng,
    });
    loadData();
  }, [fleetId]);

  return (
    <>
      <div className={[styles.main].join(' ')}>
        <div className={styles.tools}>
          <div className={styles.filters}>
            <div>
              Cantidad de horas:
              {' '}
              <Input
                value={hours}
                onChange={(e) => setHours(e.target.value)}
                style={{ width: 100 }}
              />
            </div>
            <div>
              Mínimo de viajes estimado:
              {' '}
              <Select
                value={minProb}
                onChange={(v) => setMinProb(v)}
                style={{ width: 100 }}
                options={[
                  {
                    label: 0.1,
                    value: 0.1,
                  },
                  {
                    label: 0.25,
                    value: 0.25,
                  },
                  {
                    label: 0.5,
                    value: 0.5,
                  },
                  {
                    label: 1,
                    value: 1,
                  },
                ]}
              />
            </div>
          </div>
          <div>
            <Button onClick={loadData} size="large">
              <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
            </Button>
          </div>
        </div>
        {loading && <div style={{ padding: 50, textAlign: 'center' }}><Loading /></div>}
        {!loading
          && (
            <div className={styles.maps}>
              <div className={[styles.map, styles.mapBlocks].join(' ')}>
                <div className={styles.mapTitle} />
                <div className={styles.mapContent}>
                  {mapPosition
                    && (
                      <SimpleMapComponent
                        key={`${fleetId}`}
                        minimumClusterSize={1000}
                        zoom={mapPosition.zoom || 14}
                        onChangeBoundsZoom={(a, b, c, z) => {
                          setMapPosition({ latitude: c.lat, longitude: c.lng, zoom: z });
                        }}
                        defaultCenter={{ lat: mapPosition.latitude, lng: mapPosition.longitude }}
                        panTo={{ lat: mapPosition.latitude, lng: mapPosition.longitude }}
                        minZoom={1}
                        maxZoom={20}
                        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places,visualization"
                        loadingElement={<div style={{ height: '100%' }} />}
                        containerElement={(
                          <div
                            style={{
                              height: '100%',
                            }}
                          />
                        )}
                        mapElement={<div style={{ borderRadius: 5, height: 'calc(100%)' }} />}
                        onSelectMarker={() => { }}
                        blocks={data.blocks}
                      />
                    )}
                </div>
              </div>
              <div style={{ overflow: 'auto' }}>
                {data.all
                  && (
                    <>
                      <div>
                        Incremento de viajes esperado:
                        {' '}
                        {Math.round(data.all.expected_improvement_realistic)}
                      </div>
                      <div>
                        Patines necesarios:
                        {' '}
                        {data.all.needed_devices_realistic}
                      </div>
                      <div>
                        Puntos con patines necesarios:
                        {' '}
                        {(data.blocks.filter((x) => x.realistic).length)}
                      </div>

                      <br />
                      <br />
                      <div style={{ fontWeight: 'bold' }}>
                        Puntos con patines necesarios
                      </div>
                      <br />
                      <table className={[stylesLayout.table, stylesLayout.tableSmall].join(' ')}>
                        <tbody>
                          {data.blocks.filter((x) => x.realistic).map((x) => (
                            <tr>
                              <td>
                                <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${x.lat},${x.lng}`}>
                                  {`https://www.google.com/maps/search/?api=1&query=${x.lat},${x.lng}`}
                                </a>
                                {' -> '}
                                {x.needed_devices}
                                {' '}
                                {x.needed_devices !== 1 ? ' Patines' : 'Patín'}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
              </div>
            </div>
          )}
      </div>
    </>
  );
};
BalanceModule.propTypes = {
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};
export default BalanceModule;
