/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Button, Input, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';
import {
  fixDamage,
  addDamage,
} from '../../services/devices';

const DeviceDamageModule = ({
  user,
  deviceRef,
  damages,
  onUpdateDamage,
}) => {
  const [damageType, setDamageType] = useState(null);
  const [damageNote, setDamageNote] = useState('');
  const [showForm, setShowForm] = useState(false);
  const fix = async (hash) => {
    const rsp = await fixDamage(deviceRef, hash, {});
    if (rsp?.status) {
      onUpdateDamage();
    }
  };
  const add = async () => {
    if (!damageType) {
      return;
    }
    const rsp = await addDamage(deviceRef, {
      type: damageType,
      note: damageNote,
    });
    setDamageType(null);
    setDamageNote(null);
    if (rsp?.status) {
      onUpdateDamage();
    }
    setShowForm(false);
  };
  return (
    <div className={styles.cnt}>
      <div className={styles.title}>
        <div>
          {damages && damages.length > 0

            ? 'Daños'
            : 'No hay daños'}
        </div>
        <div style={{ float: 'right' }}>
          {!showForm
            && (
              <Button onClick={() => setShowForm(true)} type="primary">
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{ marginRight: 5 }}
                />
                Agregar daño
              </Button>
            )}
        </div>
      </div>
      {showForm
        && (
          <div className={styles.form}>
            <div className={styles.field}>
              <Select
                style={{ width: '100%' }}
                placeholder="Selecciona un tipo de daño..."
                value={damageType}
                onChange={(v) => setDamageType(v)}
                options={[
                  ...['IOT sin conexión a Patín', 'Freno', 'Acelerador', 'Ruido del motor', 'Tornillos del tubo flojos', 'Tornillos de batería flojos',
                    'Error 14', 'Error 15', 'Error 27', 'Error 18', 'Eje soporte flojo',
                    'Posapie roto', 'Luces no encienden',
                    'Tornillos de manubrio flojos', 'Ruido en rueda trasera',
                    'Salpicadera delantera rota', 'Salpicadera trasera rota'].sort(),
                  'Otro (detallar)'].map((x) => ({ label: x, value: x }))}
              />
            </div>
            <div className={styles.field}>
              <Input.TextArea
                style={{ width: '100%' }}
                placeholder="Comentarios adicionales (opcional)..."
                value={damageNote}
                onChange={(e) => setDamageNote(e.target.value)}
              />
            </div>
            <div className={styles.field}>
              <Button disabled={!damageType} onClick={() => add()}>
                Guardar
              </Button>
            </div>
          </div>
        )}
      {
        damages && damages.length > 0
        && (
          <div className={styles.list}>
            {
              [...damages].reverse().map((damage) => (
                <div className={[styles.damage, damage.status === 'FIXED' ? styles.fixed : null].join(' ')}>
                  <div className={styles.info}>
                    <div className={styles.type}>
                      {damage.type}
                    </div>
                    {damage.note && (
                      <div className={styles.note}>
                        {damage.note}
                      </div>
                    )}
                    <div className={styles.created_at}>
                      Creado:
                      {' '}
                      {moment.utc(damage.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
                      {' '}
                      (
                      <span className={styles.user}>
                        {damage.user?.name}
                        {' '}
                        -
                        {' '}
                        {damage.user?.email}
                      </span>
                      )
                    </div>
                    {damage.fixed_at
                      && (
                        <div className={styles.fixed_at}>
                          Solucionado:
                          {' '}
                          {moment.utc(damage.fixed_at).local().format('YYYY-MM-DD HH:mm:ss')}
                          {' '}
                          (
                          {damage.fixed_user?.name}
                          {' '}
                          -
                          {' '}
                          {damage.fixed_user?.email}
                          )
                        </div>
                      )}

                  </div>
                  <div className={styles.actions}>
                    {damage.status !== 'FIXED' && user.can.fix_damages && (
                      <Button onClick={() => fix(damage.hash)}>
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ marginRight: 5 }}
                        />
                        Solucionado
                      </Button>
                    )}
                  </div>
                </div>
              ))
            }
          </div>
        )
      }
    </div>

  );
};
DeviceDamageModule.defaultProps = {
  onUpdateDamage: () => { },
};

DeviceDamageModule.propTypes = {
  deviceRef: PropTypes.string.isRequired,
  damages: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  onUpdateDamage: PropTypes.func,
};
export default DeviceDamageModule;
