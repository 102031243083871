/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getByHash } from '../../services/users';

import stylesLayout from '../../common/layout.module.scss';
import styles from './index.module.scss';
import Loading from '../../components/Loading';

const UserInfoModule = () => {
  const { hash: hashParam } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadUser = async () => {
    setLoading(true);
    const rsp = await getByHash(hashParam);
    if (rsp?.status) {
      setUser(rsp.data);
    } else {
      setUser(null);
    }
    setLoading(false);
  };
  useEffect(() => { loadUser(); }, [hashParam]);

  if (loading) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading />
        </div>
      </div>
    );
  }

  if (!user || user.status !== 'ACTIVE') {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          Usuario no encontrado
        </div>
      </div>
    );
  }
  return (
    <div className={styles.user}>
      {user.data?.photo?.private_url && (
        <div className={styles.photo} style={{ backgroundImage: `url(${user.data?.photo?.private_url})` }} />
      )}
      <div className={styles.name}>
        {user.name}
      </div>
      <div className={styles.email}>
        {user.email}
      </div>
    </div>
  );
};

UserInfoModule.propTypes = {
};
export default UserInfoModule;
