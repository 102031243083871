/* eslint-disable max-len */
import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const createOrUpdateTask = async (payload) => executeAndWaitResponse(() => apiClient.tasks.createOrUpdate(payload));
export const getTasks = async ({
  status = null, fleetId, assigneeId = null, creatorId = null,
}) => executeAndWaitResponse(() => apiClient.tasks.find({
  status, fleetId, creatorId, assigneeId,
}));

export default {
  createOrUpdateTask,
};
