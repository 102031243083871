/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Button, Input, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';
import {
  addReparation,
} from '../../services/devices';

const DeviceReparationModule = ({
  deviceRef,
  reparations,
  onUpdateReparation,
}) => {
  const [reparationType, setReparationType] = useState(null);
  const [reparationNote, setReparationNote] = useState('');
  const [showForm, setShowForm] = useState(false);
  const add = async () => {
    if (!reparationType) {
      return;
    }
    const rsp = await addReparation(deviceRef, {
      type: reparationType,
      note: reparationNote,
    });
    setReparationType(null);
    setReparationNote(null);
    if (rsp?.status) {
      onUpdateReparation();
    }
    setShowForm(false);
  };
  return (
    <div className={styles.cnt}>
      <div className={styles.title}>
        <div>
          {reparations && reparations.length > 0

            ? 'Reparaciones recientes'
            : 'No hay reparaciones'}
        </div>
        <div style={{ float: 'right' }}>
          {!showForm
            && (
              <Button onClick={() => setShowForm(true)} type="primary">
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{ marginRight: 5 }}
                />
                Agregar reparación
              </Button>
            )}
        </div>
      </div>
      {showForm
        && (
          <div className={styles.form}>
            <div className={styles.field}>
              <Select
                style={{ width: '100%' }}
                placeholder="Selecciona un tipo de reparacion..."
                value={reparationType}
                onChange={(v) => setReparationType(v)}
                options={[
                  ...['Instalar nuevo IOT', 'Actualización Firmware IOT', 'Cambio de acelerador', 'Cambio de freno', 'Cambio de botón de reset', 'Cambio de batería', 'Cambio de goma tabla', 'Reparación de motor', 'Reparación de acelerador', 'Reparación de freno', 'Cierre de IOT', 'Ajuste tornillos volante', 'Ajuste tornillos tubo', 'Cables', 'Cambio de volante', 'Error 21', 'Pata de apoyo'].sort(),
                  'Otro (detallar)'].map((x) => ({ label: x, value: x }))}
              />
            </div>
            <div className={styles.field}>
              <Input.TextArea
                style={{ width: '100%' }}
                placeholder="Comentarios adicionales (opcional)..."
                value={reparationNote}
                onChange={(e) => setReparationNote(e.target.value)}
              />
            </div>
            <div className={styles.field}>
              <Button disabled={!reparationType} onClick={() => add()}>
                Guardar
              </Button>
            </div>
          </div>
        )}
      {
        reparations && reparations.length > 0
        && (
          <div className={styles.list}>
            {
              [...reparations].reverse().slice(0, 10).map((reparation) => (
                <div className={[styles.reparation, reparation.status === 'FIXED' ? styles.fixed : null].join(' ')}>
                  <div className={styles.info}>
                    <div className={styles.type}>
                      {reparation.type}
                    </div>
                    {reparation.note && (
                      <div className={styles.note}>
                        {reparation.note}
                      </div>
                    )}
                    <div className={styles.created_at}>
                      {moment.utc(reparation.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                    <div className={styles.user}>
                      {reparation.user?.name}
                      {' '}
                      -
                      {' '}
                      {reparation.user?.email}
                    </div>

                  </div>
                </div>
              ))
            }
          </div>
        )
      }
    </div>

  );
};
DeviceReparationModule.defaultProps = {
  onUpdateReparation: () => { },
};

DeviceReparationModule.propTypes = {
  deviceRef: PropTypes.string.isRequired,
  reparations: PropTypes.array.isRequired,
  onUpdateReparation: PropTypes.func,
};
export default DeviceReparationModule;
