import { useState } from 'react';

const isFunction = (val) => typeof val === 'function';

const SUPPORTS_MEDIA_DEVICES = 'mediaDevices' in navigator;

export const useTorchLight = ({
  onError,
  onSuccess,
}) => {
  const [on, setOn] = useState(false);

  const turn = async (status) => {
    if (!SUPPORTS_MEDIA_DEVICES) {
      throw new Error('No supported media devices in navigator');
    }
    try {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const cameras = devices.filter((device) => device.kind === 'videoinput');
        if (cameras.length === 0) {
          throw new Error('No camera found on this device.');
        }
        const camera = cameras[cameras.length - 1];

        // Create stream and get video track
        navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: camera.deviceId,
            facingMode: ['user', 'environment'],
            height: { ideal: 1080 },
            width: { ideal: 1920 },
          },
        }).then((stream) => {
          const track = stream.getVideoTracks()[0];

          // Create image capture object and get camera capabilities
          const imageCapture = new ImageCapture(track);
          imageCapture.getPhotoCapabilities().then(() => {
            track.applyConstraints({
              advanced: [{ torch: status }],
            });
          });
        }).catch((e) => {
          console.log('TORCH ERROR', e);
          if (isFunction(onError)) {
            onError(e);
          }
        });

        setOn(status);
        if (isFunction(onSuccess)) {
          onSuccess(status);
        }
      }).catch((e) => {
        console.log('TORCH ERROR', e);
        if (isFunction(onError)) {
          onError(e);
        }
      });
    } catch (e) {
      console.log('TORCH ERROR', e);
      if (isFunction(onError)) {
        onError(e);
      }
    }
  };

  const turnOn = async () => {
    console.log('TURN ON');
    return turn(true);
  };

  const turnOff = async () => {
    console.log('TURN OFF');
    return turn(false);
  };

  return [on, on ? turnOff : turnOn];
};

export default {
  useTorchLight,
};
