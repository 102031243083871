export default (client, defaultParams) => ({
  create: (payload) => (
    client('/tenant/create', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  getUsers: () => (
    client('/admin/tenant/users', {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),

  getUsersWithRoles: (roles) => (
    client(`/admin/tenant/users/with_roles/${roles.join(',')}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),

  update: (payload) => (
    client(`/tenant/${payload.id}`, {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),

  getMessage: (k) => {
    const messages = {
      'User already has store': 'Ya existe una empresa con este email. Si quieres crear una nueva debes usar otro email.',
    };
    return (messages[k] || k);
  },
});
