export default (client, defaultParams) => ({
  create: (payload) => (
    client('/deploy_plans', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  get: (id) => (
    client(`/deploy_plan/${id}}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  getForFleet: (fleetIds, statuses) => (
    client(`/deploy_plans/fleet/${fleetIds.join(',')}/${statuses.join(',')}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  update: (id, payload) => (
    client(`/deploy_plan/${id}`, {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
});
