export const executeAndWaitResponse = async (retriever) => {
  try {
    const rsp = await (retriever());
    const { data } = rsp;
    return {
      status: true,
      data,
    };
  } catch (e) {
    // console.log('e', e.response);
    if (e && e.response && e.response.status >= 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: rsp.msg,
        };
      }
    }
    return {
      status: false,
      msg: 'Error desconocido',
    };
  }
};

export default {
  executeAndWaitResponse,
};
