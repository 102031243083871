/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import ReactJson from "react-json-view";
import PropTypes from "prop-types";
import moment from "moment";
import numeral from "numeral";
import {Button, Input, Popconfirm} from "antd";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faExternalLinkAlt,
  faStar,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./index.module.scss";
import Loading from "../../components/Loading";

import {
  endTrip,
  endTripNoPay,
  payTrip,
  setPaid,
  getTripById,
  setTripGrinQualification,
  changeTotal,
  doRefund,
  getTripByIdWithLogs,
} from "../../services/trips";

import {
  convertPositionListToSegments,
  formatPriceFromBillingScheme,
  getTripQualificationColor,
} from "../../helpers/utils";
import {computeShortHash} from "../../helpers/orders";

import stylesLayout from "../../common/layout.module.scss";
import {SimpleMapComponent} from "../../components/SimpleMapComponent";
import EventsList from "../../components/EventsList";
import ScooterBlock from "../../components/Devices/ScooterBlock";

const actions = {
  create_payment: "Cargo",
  refund_payment: "Devolución",
};

const TripModule = ({user}) => {
  const {id: idParam} = useParams();
  const [loading, setLoading] = useState(true);
  const [newTotal, setNewTotal] = useState(null);
  const [refundAmount, setRefundAmount] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [trip, setTrip] = useState(null);

  const [showChangePrice, setShowChangePrice] = useState(false);
  const [savingTripTotal, setSavingTripTotal] = useState(false);
  const [savingRefund, setSavingRefund] = useState(false);
  const [mapSegments, setMapSegments] = useState(null);

  const loadTrip = async (id) => {
    const rsp = await getTripById(id);
    if (rsp && rsp.status) {
      const newTrip = rsp.data;
      if (trip?.events) {
        newTrip.events = trip.events;
      }
      if (trip?.trip_events) {
        newTrip.trip_events = trip.trip_events;
      }

      setTrip(newTrip);
      setRefundAmount(newTrip.calculated_total_from_payments);
    } else {
      // setError(rsp);
    }
    setLoading(false);
  };
  // eslint-disable-next-line no-unused-vars
  const loadTripWithLogs = async (id) => {
    const rsp = await getTripByIdWithLogs(id);
    if (rsp && rsp.status) {
      setTrip(rsp.data);
      setRefundAmount(rsp.data.calculated_total_from_payments);
    } else {
      // setError(rsp);
    }
    setLoading(false);
  };

  const setTripTotal = async () => {
    setSavingTripTotal(true);
    const rsp = await changeTotal(trip.id, newTotal.replace(",", "."));
    if (rsp && rsp.status) {
      await loadTrip(trip.id);
      setShowChangePrice(false);
      setSavingTripTotal(false);
    }
  };
  const refund = async () => {
    setSavingRefund(true);
    const rsp = await doRefund(trip.id, String(refundAmount).replace(",", "."));
    if (rsp && rsp.status) {
      await loadTrip(trip.id);
    }
    setSavingRefund(false);
  };

  const setGrinQualification = async (q) => {
    const rsp = await setTripGrinQualification(trip.id, q);
    if (rsp && rsp.status) {
      setTrip({
        ...trip,
        grin_qualification: rsp.data.grin_qualification,
      });
    }
  };

  const onEndTripNoPay = async () => {
    if (!trip) {
      return;
    }
    const rsp = await endTripNoPay(trip.id);
    if (rsp?.status) {
      loadTrip(trip.id);
    }
  };

  const onEndTrip = async () => {
    if (!trip) {
      return;
    }
    const rsp = await endTrip(trip.id);
    if (rsp?.status) {
      loadTrip(trip.id);
    }
  };
  const onPayTrip = async () => {
    if (!trip) {
      return;
    }
    setLoading(true);
    const rsp = await payTrip(trip.id);
    if (rsp?.status) {
      loadTrip(trip.id);
    }
    setLoading(false);
  };

  const onSetPaidManually = async () => {
    if (!trip) {
      return;
    }
    setLoading(true);
    const rsp = await setPaid(trip.id);
    if (rsp?.status) {
      loadTrip(trip.id);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadTripWithLogs(idParam);
  }, [idParam]);
  useEffect(() => {
    if (!trip) {
      return null;
    }
    let pid = null;
    if (
      ["STARTING", "RESERVED", "IN_PROCESS", "FINISHED"].includes(trip.status)
    ) {
      pid = window.setInterval(() => loadTrip(idParam), 5000);
    }
    if (!newTotal) {
      setNewTotal(trip.cost_calculated.details.payment_total);
    }
    if (trip.trip_path && trip.trip_path.length > 0) {
      const segments = convertPositionListToSegments(trip.trip_path, 180);
      setMapSegments(segments);
    }
    return () => {
      if (pid) {
        window.clearInterval(pid);
      }
    };
  }, [trip]);

  if (loading) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading />
        </div>
      </div>
    );
  }
  if (!trip) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>Viaje no encontrado</div>
      </div>
    );
  }

  let minLat = trip.lat_start;
  let maxLat = trip.lat_start;
  let minLng = trip.lng_start;
  let maxLng = trip.lng_end;
  let startBattery = null;
  let endBattery = null;
  if (trip.trip_path && trip.trip_path.length > 0) {
    const lats = trip.trip_path?.map((x) => x.lat);
    minLat = Math.min(...lats);
    maxLat = Math.max(...lats);
    const lngs = trip.trip_path?.map((x) => x.lng);
    minLng = Math.min(...lngs);
    maxLng = Math.max(...lngs);
    const batts = trip.trip_path?.map((x) => x.data?.batsco).filter((x) => !!x);
    startBattery = Math.max(...batts);
    endBattery = Math.min(...batts);
  }

  const endTripSuccessEvent =
    trip?.events?.length > 0 &&
    trip?.events.find((x) => x.event === "endTripSuccess");
  const endTripFailureEvent =
    trip?.events?.length > 0 &&
    trip?.events.find((x) => x.event === "endTripFailure");
  const endTripStartEvent =
    trip?.events?.length > 0 &&
    trip?.events.find((x) => x.event === "endTripStart");

  const markers = [
    {
      key: "start",
      latitude: trip.lat_start,
      longitude: trip.lng_start,
      label: `${trip.device.qr} - Comienzo ${moment
        .utc(trip.start_at)
        .local()
        .format("HH:mm")}`,
    },
  ];
  if (selectedPoint) {
    markers.push({
      key: selectedPoint.id,
      latitude: selectedPoint.lat,
      longitude: selectedPoint.lng,
      label: `${trip.device.qr} - Seleccionado ${moment
        .utc(selectedPoint.created_at)
        .local()
        .format("HH:mm")}`,
    });
  }
  if (trip.lat_end && trip.lng_end) {
    markers.push({
      key: "end",
      latitude: trip.lat_end,
      longitude: trip.lng_end,
      label: `${trip.device.qr} - Fin ${moment
        .utc(trip.end_at)
        .local()
        .format("HH:mm")}`,
    });
  } else if (trip.trip_path && trip.trip_path.length > 0) {
    markers.push({
      key: trip.trip_path[trip.trip_path.length - 1].id,
      latitude: trip.trip_path[trip.trip_path.length - 1].lat,
      longitude: trip.trip_path[trip.trip_path.length - 1].lng,
      label: "Actual",
    });
  }

  if (
    endTripSuccessEvent &&
    endTripSuccessEvent.lat &&
    endTripSuccessEvent.lng
  ) {
    markers.push({
      key: "user-endTripSuccessEvent",
      latitude: endTripSuccessEvent.lat,
      longitude: endTripSuccessEvent.lng,
      label: `${trip.device.qr} - Cierre de viaje por el usuario`,
    });
  }
  if (
    endTripFailureEvent &&
    endTripFailureEvent.lat &&
    endTripFailureEvent.lng
  ) {
    markers.push({
      key: "user-endTripFailureEvent",
      latitude: endTripFailureEvent.lat,
      longitude: endTripFailureEvent.lng,
      label: `${trip.device.qr} - Fallo en cierre de viaje por el usuario`,
    });
  }
  if (endTripStartEvent && endTripStartEvent.lat && endTripStartEvent.lng) {
    markers.push({
      key: "user-endTripStartEvent",
      latitude: endTripStartEvent.lat,
      longitude: endTripStartEvent.lng,
      label: `${trip.device.qr} - Inicio de cierre de viaje por el usuario`,
    });
  }
  let lastpos = null;
  lastpos =
    trip?.trip_path &&
    trip.trip_path.length > 0 &&
    trip.trip_path[trip.trip_path.length - 1];
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCIi-49zQc_qm-8TCRX26suYgt0oL1YETw&scale=2&center=${lastpos?.lat},${lastpos?.lng}&zoom=16&size=350x350&maptype=roadmap&markers=color:green%7Clabel:G%7C${lastpos?.lat},${lastpos?.lng}`;

  return (
    <div className={styles.cnt}>
      <div className={stylesLayout.page}>
        <div className={stylesLayout.breadcrumb}>
          <Link to='/trips'>Usuarios y Viajes</Link> &raquo;
        </div>
        <div className={stylesLayout.title}>
          Viaje {trip.id} - {computeShortHash(trip.hash)} <br />
          <small>{trip.status_tr}</small>
        </div>
        <div className={stylesLayout.tools}>
          {trip.status === "IN_PROCESS" && (
            <s>
              <Button onClick={onEndTrip}>Finalizar viaje</Button>{" "}
              {user.can.modify_trip_price && (
                <Button onClick={onEndTripNoPay}>
                  Finalizar viaje sin cobrar
                </Button>
              )}
            </s>
          )}
          {["FINISHED", "FINISHED_NO_PAY"].includes(trip.status) && (
            <Button onClick={onPayTrip}>Intentar pago del viaje</Button>
          )}{" "}
          {user.can.modify_trip_price &&
            ["FINISHED", "FINISHED_NO_PAY"].includes(trip.status) && (
              <Popconfirm
                placement='bottom'
                title='¿Confirmas que quieres marcar este viaje como pagado en forma manual?'
                onConfirm={onSetPaidManually}
                okText='Si'
                okButtonProps={{
                  type: "danger",
                }}
                cancelText='No'
              >
                <Button danger>Marcar como pagado manualmente</Button>
              </Popconfirm>
            )}
        </div>
        <div className={[stylesLayout.content, styles.main].join(" ")}>
          <div className={styles.info}>
            <table>
              <tr>
                <td width='25%'>Calificación GRIN del viaje</td>
                <td>
                  <FontAwesomeIcon
                    icon={faStar}
                    style={{
                      display: "inline-block",
                      color: getTripQualificationColor(trip.grin_qualification),
                    }}
                  />
                  <span style={{marginLeft: 10, verticalAlign: "middle"}}>
                    Cambiar a:{" "}
                    <Button
                      type='secondary'
                      style={{color: "#090", borderColor: "#090"}}
                      onClick={() => setGrinQualification(100)}
                    >
                      Verde
                    </Button>{" "}
                    <Button
                      type='secondary'
                      style={{color: "#f60", borderColor: "#f60"}}
                      onClick={() => setGrinQualification(50)}
                    >
                      Naranjo
                    </Button>{" "}
                    <Button
                      type='secondary'
                      style={{color: "#f00", borderColor: "#f00"}}
                      onClick={() => setGrinQualification(0)}
                    >
                      Rojo
                    </Button>
                  </span>
                </td>
              </tr>
              <tr>
                <td width='25%'>Usuario</td>
                <td>
                  <Link to={`/customer/${trip.user.id}`}>
                    #{trip.user.id} - {trip.user.name} - {trip.user.email}
                  </Link>
                  <FontAwesomeIcon
                    icon={faStar}
                    style={{
                      display: "inline-block",
                      marginLeft: 5,
                      color: getTripQualificationColor(
                        trip.user.grin_qualification
                      ),
                    }}
                  />
                  <br />
                  {"Status: "}
                  {trip.user.status_tr}
                </td>
              </tr>
              <tr>
                <td width='25%'>Dispositivo</td>
                <td>
                  <Link to={`/device/${trip.device.qr}`}>{trip.device.qr}</Link>
                  <ScooterBlock defaultDevice={trip.device} user={user} />
                </td>
              </tr>

              <tr>
                <td width='25%'>Total</td>
                <td>
                  {trip.cost_calculated.details.notes ===
                    "amount_0_no_ride" && (
                    <>
                      <strong>Viaje no cobrado por uso menor a 1 minuto</strong>
                      <br />
                    </>
                  )}
                  {formatPriceFromBillingScheme(
                    trip.cost_scheme,
                    trip.status == "IN_PROCESS"
                      ? trip.cost_calculated.details.total
                      : trip.total
                  )}
                  {" ("}
                  {trip.cost_calculated.minutes} min) (País del pago:{" "}
                  {trip.data.country}
                  {") "}
                  {user.can.modify_trip_price &&
                    ["FINISHED", "FINISHED_NO_PAY"].includes(trip?.status) &&
                    (showChangePrice ? (
                      <div className={stylesLayout.inputWithButton}>
                        <Input
                          type='text'
                          defaultValue={numeral(
                            trip.cost_calculated.details.payment_total
                          )
                            .format("0.00")
                            .replace(",", ".")}
                          disabled={savingTripTotal}
                          onKeyDown={(e) =>
                            e.key === "Enter" ? setTripTotal() : null
                          }
                          onChange={(e) => setNewTotal(e.target.value)}
                        />
                        <Button
                          disabled={savingTripTotal}
                          onClick={() => setTripTotal()}
                        >
                          {savingTripTotal ? (
                            <FontAwesomeIcon icon={faSyncAlt} spin />
                          ) : (
                            "Cambiar"
                          )}
                        </Button>
                      </div>
                    ) : (
                      <>
                        <Button
                          size='small'
                          onClick={() => setShowChangePrice(true)}
                        >
                          Cambiar total
                        </Button>
                      </>
                    ))}
                  <br />
                  {trip.total_paid > 0 && (
                    <>
                      Cobrado:{" "}
                      {formatPriceFromBillingScheme(
                        trip.cost_scheme,
                        trip.total_paid
                      )}
                    </>
                  )}
                </td>
              </tr>
              {trip.path_length_m > 0 && (
                <tr>
                  <td width='25%'>Distancia recorrida</td>
                  <td>
                    {numeral(trip.path_length_m.toFixed(0)).format("0")} metros
                    {trip.cost_calculated.minutes > 0 && (
                      <>
                        {" "}
                        (
                        {(
                          trip.path_length_m /
                          1000 /
                          (trip.cost_calculated.minutes / 60)
                        ).toFixed(1)}{" "}
                        km/h,{" "}
                        {(
                          trip.path_length_m / trip.cost_calculated.minutes
                        ).toFixed(0)}{" "}
                        mt/min)
                      </>
                    )}
                  </td>
                </tr>
              )}
              {startBattery !== null && endBattery !== null && (
                <tr>
                  <td width='25%'>Uso de batería</td>
                  <td>
                    {startBattery - endBattery}
                    %
                    <br />
                    <small>
                      {startBattery}%{" -> "}
                      {endBattery}%
                    </small>
                    <br />
                    <small>
                      {(
                        (startBattery - endBattery) /
                        (trip.path_length_m / 1000)
                      ).toFixed(1)}
                      %/km
                    </small>
                  </td>
                </tr>
              )}
              {(user.can.modify_trip_price || user.can.refund_trip) &&
                ["PAID"].includes(trip?.status) && (
                  <tr>
                    <td width='25%'>Devolución</td>
                    <td>
                      <div className={stylesLayout.inputWithButton}>
                        <Input
                          type='text'
                          defaultValue={numeral(refundAmount)
                            .format("0.00")
                            .replace(",", ".")}
                          disabled={savingRefund}
                          onKeyDown={(e) =>
                            e.key === "Enter" ? refund() : null
                          }
                          onChange={(e) => setRefundAmount(e.target.value)}
                        />
                        <Button
                          disabled={savingRefund}
                          onClick={() => refund()}
                        >
                          {savingRefund ? (
                            <FontAwesomeIcon icon={faSyncAlt} spin />
                          ) : (
                            "Devolver"
                          )}
                        </Button>
                      </div>
                    </td>
                  </tr>
                )}
              {trip.cost_calculated && (
                <tr>
                  <td width='25%'>Detalle del cobro</td>
                  <td>
                    Reserva:{" "}
                    {formatPriceFromBillingScheme(
                      trip.cost_scheme,
                      trip.cost_calculated.details.reservation
                    )}
                    <br />
                    Inicio del viaje:{" "}
                    {formatPriceFromBillingScheme(
                      trip.cost_scheme,
                      trip.cost_calculated.details.trip_cost_start
                    )}
                    <br />
                    Minutos del viaje:{" "}
                    {formatPriceFromBillingScheme(
                      trip.cost_scheme,
                      trip.cost_calculated.details.trip_cost_ride
                    )}
                    <br />
                    Descuento:{" "}
                    {formatPriceFromBillingScheme(
                      trip.cost_scheme,
                      trip.cost_calculated.details.discount
                    )}
                    {trip.cost_calculated.details.bin_discount?.description && (
                      <>
                        {" "}
                        ({
                          trip.cost_calculated.details.bin_discount.description
                        }{" "}
                        -{" "}
                        {
                          trip.cost_calculated.details.bin_discount
                            .discount_percent
                        }
                        %)
                      </>
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <td width='25%'>Medio de pago elegido</td>
                <td>
                  {trip.card ? (
                    <div className={styles.cardInfo}>
                      Id: {trip.card.id}
                      <br />
                      Status: {trip.card.status}
                      <br />
                      Nombre titular: {trip.card.data.source.cardholder.name}
                      {", "}
                      {trip.card.data.source.cardholder.identification.type}
                      {": "}
                      {trip.card.data.source.cardholder.identification.number}
                      <br />
                      {trip.card.data.source.payment_method.name}
                      {" - "}
                      {trip.card.data.source.first_six_digits}
                      ...
                      {trip.card.data.source.last_four_digits}
                      {" - "}
                      {trip.card.validated
                        ? "Tarjeta Validada"
                        : "Tarjeta no validada"}
                      <div>
                        {
                          [
                            ...new Set(
                              trip.card.other_cards_with_same_fingerprint
                                .filter((x) => x.user_id !== trip.user_id)
                                .map((x) => x.user_id)
                            ),
                          ].length
                        }{" "}
                        otros usuarios con la misma tarjeta en el sistema
                        <div>
                          {trip.card.other_cards_with_same_fingerprint
                            .filter((x) => x.user_id !== trip.user_id)
                            .map((x, ix) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <span key={ix}>
                                <Link to={`/customer/${x.user.id}`}>
                                  {x.user.email}
                                </Link>{" "}
                              </span>
                            ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    "Sin info"
                  )}
                </td>
              </tr>

              <tr>
                <td width='25%'>Detalles del cobro</td>
                <td>
                  <ReactJson
                    src={trip.cost_calculated}
                    displayObjectSize={false}
                    displayDataTypes={false}
                    indentWidth={2}
                  />
                </td>
              </tr>

              <tr>
                <td width='25%'>Inicio de viaje</td>
                <td>
                  {trip.trip_start_at
                    ? moment
                        .utc(trip.trip_start_at)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss")
                    : ""}
                  {" ("}
                  {trip.lat_start}, {trip.lng_start})
                  <a
                    style={{marginLeft: 20}}
                    href={`https://www.google.com/maps/search/?api=1&query=${trip.lat_start},${trip.lng_start}`}
                    target='_map'
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} /> Ver en mapa
                  </a>
                </td>
              </tr>
              <tr>
                <td width='25%'>Fin de viaje</td>
                <td>
                  {trip.end_at
                    ? moment
                        .utc(trip.end_at)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss")
                    : ""}
                  {" ("}
                  {trip.lat_end}, {trip.lng_end})
                  <a
                    style={{marginLeft: 20}}
                    href={`https://www.google.com/maps/search/?api=1&query=${trip.lat_end},${trip.lng_end}`}
                    target='_map'
                  >
                    {" "}
                    <FontAwesomeIcon icon={faExternalLinkAlt} /> Ver en mapa
                  </a>
                  <>
                    <br />
                    <small>
                      {endTripStartEvent && (
                        <div>
                          Ubicación del usuario al iniciar cierre del viaje (
                          {moment
                            .utc(endTripStartEvent.created_at)
                            .local()
                            .format("YYYY-MM-DD HH:mm:ss")}
                          ): {endTripStartEvent.lat},{endTripStartEvent.lng}{" "}
                          <a
                            style={{marginLeft: 20}}
                            href={`https://www.google.com/maps/search/?api=1&query=${endTripStartEvent.lat},${endTripStartEvent.lng}`}
                            target='_map'
                          >
                            {" "}
                            <FontAwesomeIcon icon={faExternalLinkAlt} /> Ver en
                            mapa
                          </a>
                        </div>
                      )}
                      {endTripSuccessEvent && (
                        <div>
                          Ubicación del usuario al finalizar el viaje (
                          {moment
                            .utc(endTripSuccessEvent.created_at)
                            .local()
                            .format("YYYY-MM-DD HH:mm:ss")}
                          ): {endTripSuccessEvent.lat},{endTripSuccessEvent.lng}{" "}
                          <a
                            style={{marginLeft: 20}}
                            href={`https://www.google.com/maps/search/?api=1&query=${endTripSuccessEvent.lat},${endTripSuccessEvent.lng}`}
                            target='_map'
                          >
                            {" "}
                            <FontAwesomeIcon icon={faExternalLinkAlt} /> Ver en
                            mapa
                          </a>
                        </div>
                      )}
                      {endTripFailureEvent && (
                        <div>
                          Ubicación del usuario al fallar el fin del viaje (
                          {moment
                            .utc(endTripFailureEvent.created_at)
                            .local()
                            .format("YYYY-MM-DD HH:mm:ss")}
                          ): {endTripFailureEvent.lat},{endTripFailureEvent.lng}{" "}
                          <a
                            style={{marginLeft: 20}}
                            href={`https://www.google.com/maps/search/?api=1&query=${endTripFailureEvent.lat},${endTripFailureEvent.lng}`}
                            target='_map'
                          >
                            {" "}
                            <FontAwesomeIcon icon={faExternalLinkAlt} /> Ver en
                            mapa
                          </a>
                        </div>
                      )}
                    </small>
                  </>
                </td>
              </tr>

              {trip?.status === "IN_PROCESS" && lastpos && (
                <tr>
                  <td width='25%'>Última posicion recibida</td>
                  <td>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${lastpos.lat},${lastpos.lng}`}
                      target='_map'
                    >
                      <img
                        style={{maxWidth: "100%", width: 300}}
                        src={mapUrl}
                        alt='Map'
                      />
                      <div style={{textAlign: "left", marginTop: 10}}>
                        <FontAwesomeIcon icon={faExternalLinkAlt} /> Ver en mapa
                      </div>
                    </a>
                    <small>
                      {trip.trip_path && trip.trip_path.length > 0 ? (
                        <>
                          {moment
                            .utc(
                              trip.trip_path[trip.trip_path.length - 1]
                                .created_at
                            )
                            .fromNow()}
                          <br />
                          {moment
                            .utc(
                              trip.trip_path[trip.trip_path.length - 1]
                                .created_at
                            )
                            .local()
                            .format("YYYY-MM-DD HH:mm:ss")}
                        </>
                      ) : (
                        ""
                      )}
                    </small>
                  </td>
                </tr>
              )}

              <tr>
                <td width='25%'>Inicio de reserva</td>
                <td>
                  {trip.reserved_at
                    ? moment
                        .utc(trip.reserved_at)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss")
                    : "No"}
                </td>
              </tr>
              <tr>
                <td width='25%'>Fin de reserva</td>
                <td>
                  {trip.reserved_finished_at
                    ? moment
                        .utc(trip.reserved_finished_at)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss")
                    : "No"}
                </td>
              </tr>
              <tr>
                <td width='25%'>Reserva autocancelada</td>
                <td>
                  {trip.reserved_autocancel_at
                    ? moment
                        .utc(trip.reserved_autocancel_at)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss")
                    : "No"}
                </td>
              </tr>
              <tr>
                <td width='25%'>Pagado</td>
                <td>
                  {trip.paid_at
                    ? moment
                        .utc(trip.paid_at)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss")
                    : "No"}
                </td>
              </tr>
            </table>
          </div>
          <div className={styles.path}>
            {trip.trip_start_at ? (
              <>
                <SimpleMapComponent
                  zoom={15}
                  fleetId={trip.fleet_id}
                  defaultCenter={{
                    lat: (minLat + maxLat) / 2,
                    lng: (minLng + maxLng) / 2,
                  }}
                  minZoom={1}
                  minimumClusterSize={10000}
                  maxZoom={20}
                  googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places'
                  loadingElement={<div style={{height: 400}} />}
                  containerElement={
                    <div
                      style={{
                        height: 400,
                      }}
                    />
                  }
                  mapElement={<div style={{borderRadius: 5, height: 400}} />}
                  markers={markers}
                  lines={mapSegments?.map((x) => ({path: x}))}
                />
                <div style={{height: 300, overflowY: "auto"}}>
                  <table className={styles.tableSmall}>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Origen</th>
                        <th>Fecha</th>
                        <th>Lat</th>
                        <th>Lng</th>
                        <th>Motor encendido</th>
                        <th>Batería</th>
                        <th>Velocidad Motor</th>
                        <th>Bloqueado</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trip.trip_path?.map((x) => (
                        <tr
                          onMouseOver={() => setSelectedPoint(x)}
                          onFocus={() => setSelectedPoint(x)}
                        >
                          <td align='center'>{x.id}</td>
                          <td align='center'>{x.data?.source || "Patín"}</td>
                          <td align='center'>
                            {moment
                              .utc(x.created_at)
                              .local()
                              .format("YYYY-MM-DD HH:mm:ss")}
                          </td>
                          <td align='center'>{x.lat}</td>
                          <td align='center'>{x.lng}</td>
                          <td align='center'>
                            {x.data?.engine_on === "0" ? "No" : "Si"}
                          </td>
                          <td align='center'>
                            {x.data?.batsco} ({x.data?.batiot}
                          </td>
                          <td align='center'>
                            {x.data?.speedsco ? x.data?.speedsco / 10 : ""}
                          </td>
                          <td align='center'>
                            {x.data?.locked === 0 && "No"}
                            {x.data?.locked === 1 && "Si"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div style={{textAlign: "center", paddingTop: 50}}>
                Sin viaje realizado
              </div>
            )}
          </div>
          <div className={styles.events}>
            <div className={stylesLayout.sectionHeader}>Eventos del patín</div>
            {trip.events.length > 0 ? (
              <EventsList
                events={trip.events?.map((x) => ({
                  ...x,
                }))}
              />
            ) : (
              <div
                onClick={() => loadTripWithLogs(trip.id)}
                className={styles.clickLoadArea}
              >
                {loading ? "Cargando..." : <a>Cargar </a>}
              </div>
            )}
          </div>
          <div className={styles.events}>
            <div className={stylesLayout.sectionHeader}>Eventos del viaje</div>
            {trip.trip_events?.length > 0 ? (
              <EventsList
                events={trip.trip_events?.map((x) => ({
                  ...x,
                }))}
              />
            ) : (
              <div
                onClick={() => loadTripWithLogs(trip.id)}
                className={styles.clickLoadArea}
              >
                {loading ? "Cargando..." : <a>Cargar </a>}
              </div>
            )}
          </div>
          <div className={styles.payments}>
            <div className={stylesLayout.sectionHeader}>Pagos</div>
            {trip.payments ? (
              <table className={stylesLayout.table}>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>País</th>
                    <th>Tipo</th>
                    <th>Status</th>
                    <th>Tarjeta</th>
                    <th>PSP Status</th>
                    <th>Monto</th>
                    <th>Fecha intento</th>
                    <th>Fecha captura</th>
                  </tr>
                </thead>
                <tbody>
                  {trip.payments?.map((p) =>
                    p.messages?.map((x) => (
                      <tr
                        className={
                          p.status === "CAPTURED" ? styles.ok : styles.nok
                        }
                      >
                        <td>{p.id}</td>
                        <td>{p.data.country}</td>
                        <td>
                          {p.status === "CAPTURED" && (
                            <>
                              {x.action === "create_payment" && (
                                <FontAwesomeIcon
                                  icon={faArrowUp}
                                  style={{color: "var(--mainColor)"}}
                                />
                              )}
                              {x.action === "refund_payment" && (
                                <FontAwesomeIcon
                                  icon={faArrowDown}
                                  style={{color: "#f30"}}
                                />
                              )}
                            </>
                          )}
                          {"   "}
                          {actions[x.action]}
                        </td>
                        <td>{p.status_tr || p.status}</td>
                        <td>
                          {[
                            x.data?.payment_method_id || null,
                            x.data?.card?.last_four_digits || null,
                            p.data?.useCardValidated
                              ? "Tarjeta Validada"
                              : "Tarjeta no validada",
                          ]
                            .filter((a) => !!a)
                            .join(" - ")}
                          {user.id === 1 && (
                            <small>
                              <br />
                              CVV: {p.data.useCardCvv}
                            </small>
                          )}
                        </td>
                        <td>
                          {p.psp_status}
                          {" ("}
                          {p.messages && p.messages[0]
                            ? p.messages[0].data?.status_detail
                            : ""}
                          )
                        </td>
                        <td>
                          {formatPriceFromBillingScheme(
                            trip.cost_scheme,
                            (x.action === "create_payment" &&
                              x.data?.transaction_amount) ||
                              (x.action === "refund_payment" &&
                                x.data?.amount_refunded_to_payer * -1)
                          )}
                        </td>
                        <td>
                          {moment
                            .utc(p.created_at)
                            .local()
                            .format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                        <td>
                          {p.captured_at
                            ? moment
                                .utc(p.captured_at)
                                .local()
                                .format("YYYY-MM-DD HH:mm:ss")
                            : "No"}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            ) : (
              <div style={{textAlign: "center", paddingTop: 50}}>Sin pagos</div>
            )}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

TripModule.propTypes = {
  user: PropTypes.object.isRequired,
};

export default TripModule;
