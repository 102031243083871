/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import { Spin } from 'antd';

import stylesLayout from '../../common/layout.module.scss';

const Loading = ({ styles }) => (
  <div style={styles} className={stylesLayout.loading}><Spin /></div>
);

Loading.defaultProps = {
  styles: {},
};

Loading.propTypes = {
  styles: PropTypes.object,
};
export default Loading;
