/* eslint-disable max-len */
import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const getGeofencesForFleet = async (fleetId, status = ['ACTIVE']) => executeAndWaitResponse(() => apiClient.geofences.getForFleet(fleetId, status));
export const getGeofencesAndPointsForFleet = async (fleetId, status = ['ACTIVE']) => executeAndWaitResponse(() => apiClient.geofences.getGeofencesAndPointsForFleet(fleetId, status));
export const createGeofence = async (payload) => executeAndWaitResponse(() => apiClient.geofences.createGeofence(payload));
export const setGeofencesPoints = async (id, points) => executeAndWaitResponse(() => apiClient.geofences.setGeofencesPoints(id, points));
export const updateGeofence = async (id, points) => executeAndWaitResponse(() => apiClient.geofences.updateGeofence(id, points));
export const isAllowePointInGeofencesForFleet = async (fleetId, lat, lng) => executeAndWaitResponse(() => apiClient.geofences.isAllowePointInGeofencesForFleet(fleetId, lat, lng));

export default {
  getGeofencesForFleet,
  updateGeofence,
  setGeofencesPoints,
  createGeofence,
  getGeofencesAndPointsForFleet,
  isAllowePointInGeofencesForFleet,
};
