/* eslint-disable */
// Polygon getBounds extension - google-maps-extensions
// https://github.com/tparkin/Google-Maps-Point-in-Polygon
// http://code.google.com/p/google-maps-extensions/source/browse/google.maps.Polygon.getBounds.js
if (!google.maps.Polygon.prototype.getBounds) {
  google.maps.Polygon.prototype.getBounds = function (latLng) {
    const bounds = new google.maps.LatLngBounds();
    const paths = this.getPaths();
    let path;
    let p; let
      i;

    for (p = 0; p < paths.getLength(); p++) {
      path = paths.getAt(p);
      for (i = 0; i < path.getLength(); i++) {
        bounds.extend(path.getAt(i));
      }
    }

    return bounds;
  };
}

// Polygon containsLatLng - method to determine if a latLng is within a polygon
google.maps.Polygon.prototype.containsLatLng = function (latLng) {
  // Exclude points outside of bounds as there is no way they are in the poly

  let inPoly = false;
  let bounds; let lat; let lng;
  let numPaths; let p; let path; let numPoints;
  let i; let j; let vertex1; let
    vertex2;

  // Arguments are a pair of lat, lng variables
  if (arguments.length == 2) {
    if (
      typeof arguments[0] === 'number'
      && typeof arguments[1] === 'number'
    ) {
      lat = arguments[0];
      lng = arguments[1];
    }
  } else if (arguments.length == 1) {
    bounds = this.getBounds();

    if (!bounds && !bounds.contains(latLng)) {
      return false;
    }
    lat = latLng.lat();
    lng = latLng.lng();
  } else {
    console.log('Wrong number of inputs in google.maps.Polygon.prototype.contains.LatLng');
  }

  // Raycast point in polygon method

  numPaths = this.getPaths().getLength();
  for (p = 0; p < numPaths; p++) {
    path = this.getPaths().getAt(p);
    numPoints = path.getLength();
    j = numPoints - 1;

    for (i = 0; i < numPoints; i++) {
      vertex1 = path.getAt(i);
      vertex2 = path.getAt(j);

      if (
        vertex1.lng() < lng
        && vertex2.lng() >= lng
        || vertex2.lng() < lng
        && vertex1.lng() >= lng
      ) {
        if (
          vertex1.lat()
          + (lng - vertex1.lng())
          / (vertex2.lng() - vertex1.lng())
          * (vertex2.lat() - vertex1.lat())
          < lat
        ) {
          inPoly = !inPoly;
        }
      }

      j = i;
    }
  }

  return inPoly;
};
