/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import styles from './index.module.scss';

import { aproxMinsToHoursDays, deviceMessage } from '../../helpers/utils';
import CopyWithMessage from '../CopyWithMessage';

const DeviceList = ({
  devices,
}) => (

  <div className={styles.devices}>
    {(!devices || devices.length === 0)
      ? <div>No hay dispositivos</div>

      : (
        <>
          {devices.map((d, i) => (
            <div className={styles.device} key={d.id}>
              <div className={styles.info}>
                <div className={styles.l}>
                  {i + 1}
                  {' - '}
                  <Link to={`/device/${d.qr}`}>{d.qr}</Link>
                  {' - '}
                  <span className={styles.status}>{d.status_tr}</span>
                </div>

                <div className={styles.l}>
                  Bat:
                  {' '}
                  {d.data.batsco}
                  %
                </div>

                <div className={styles.l}>
                  Últ. con.:
                  {' '}
                  {aproxMinsToHoursDays(d.data.mins_since_last_connection)}
                </div>
                <div className={styles.lsep}>
                  Últ. pos.:
                  <br />
                  {d.data.out_of_area && <div>Fuera de zona</div>}
                  <div className={styles.coordinates}>
                    <CopyWithMessage
                      inline
                      value={`${d.lat}, ${d.lng}`}
                      messageCopied="Copiado!"
                      messageTime={2000}
                    >
                      {d.lat}
                      {', '}
                      {d.lng}
                    </CopyWithMessage>
                  </div>
                  <a href={`https://www.google.com/maps/search/?api=1&query=${d.lat},${d.lng}`} target="_map">
                    <div style={{ marginTop: 0 }}>
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                      {' '}
                      Ver en mapa
                    </div>
                  </a>
                  {d.data?.instant_velocity_km_h !== -1
                    && (
                      <div>
                        Vel instantanea:
                        {' '}
                        {d.data.instant_velocity_km_h}
                        {' '}
                        km/h
                      </div>
                    )}
                  <small>
                    {moment.utc(d.geo_updated_at).local().format('DD/MM HH:mm:ss')}
                    {' - '}
                    {moment.utc(d.geo_updated_at).fromNow()}
                  </small>

                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <CopyWithMessage
                  value={deviceMessage(d)}
                  messageCopied="Copiado!"
                  messageTime={2000}
                >
                  <FontAwesomeIcon icon={faCopy} style={{ fontSize: 16 }} />
                  {' '}
                  Indicaciones
                </CopyWithMessage>
              </div>
            </div>
          ))}
        </>
      )}
  </div>
);
DeviceList.defaultProps = {
};

DeviceList.propTypes = {
  devices: PropTypes.array.isRequired,
};

export default DeviceList;
