import React, {useEffect} from "react";
import PropTypes from "prop-types";

import {Table} from "antd";

import numeral from "numeral";
import moment from "moment";
import isMobile from "is-mobile";
import useHorusQuery from "../../../hooks/useHorusQuery";
import DeltaPercentageBadge from "../../../components/DeltaPercentBadge";
import stylesLayout from "../../../common/layout.module.scss";
import {RenderLineChart} from "../../../components/Charts";
import Loading from "../../../components/Loading";

const MonthlyModule = ({selectedFleet}) => {
  const {
    data: monthlyData,
    load: loadMonthly,
    status: statusMonthly,
  } = useHorusQuery();

  const loadAll = () => {
    const monthlyQuery = `
    select 
    month,
    min(day) start_day,
    max(day) end_day,
    count(*) n_days, 
    sum(count_paid) sum_count_paid,
    sum(amount_paid) sum_amount_paid,
    round(avg(count_paid)) avg_count_paid,
    round(avg(amount_paid)) avg_amount_paid,
    sum(count_non_paid) sum_count_non_paid,
    sum(amount_non_paid) sum_amount_non_paid,
    round(avg(count_non_paid)) avg_count_non_paid,
    round(avg(amount_non_paid)) avg_amount_non_paid,
    sum(count_new_users_with_trips) sum_count_new_users_with_trips,
    sum(count_paid)+sum(count_non_paid) sum_count,
    sum(amount_paid)+sum(amount_non_paid) sum_amount_total,
    avg(devices_used) avg_devices_used,
    round(avg(avg_max_vos)) avg_max_vos,
    round(avg(avg_avg_vos)) avg_avg_vos,
    round(avg(ar)*100) avg_ar
    from (
    select
      json_unquote(json_extract(s.data, '$.day')) day,
      date_format(json_unquote(json_extract(s.data, '$.day')), '%Y-%m') month,
      json_unquote(json_extract(s.data, '$.count_paid')) count_paid, 
      json_unquote(json_extract(s.data, '$.amount_paid')) amount_paid, 
      json_unquote(json_extract(s.data, '$.count_non_paid')) count_non_paid, 
      json_unquote(json_extract(s.data, '$.amount_non_paid')) amount_non_paid, 
      json_unquote(json_extract(s.data, '$.count_new_users_with_trips')) count_new_users_with_trips, 
      json_unquote(json_extract(s.data, '$.max_vos')) avg_max_vos, 
      json_unquote(json_extract(s.data, '$.avg_vos')) avg_avg_vos, 
      json_unquote(json_extract(s.data, '$.devices_used')) devices_used,
      least(json_unquote(json_extract(s.data, '$.devices_used'))/json_unquote(json_extract(s.data, '$.max_vos')),1) ar 
      
    
     from paperboy_bi.store s where
    s.tbl='${selectedFleet.id}_daily_resume'
    and s.k between date(DATE_ADD(NOW()+interval ${selectedFleet?.cost?.tz} hour, INTERVAL(-WEEKDAY(NOW()+interval ${selectedFleet?.cost?.tz} hour)) DAY))-interval 52 week
    and DATE(NOW()-interval 1 day+interval ${selectedFleet?.cost?.tz} hour)
           group by 1
    )tmp group by 1;
  
  `;

    console.log("monthlyQuery", monthlyQuery);

    loadMonthly(monthlyQuery);
  };

  useEffect(() => {
    loadAll();
  }, []);
  useEffect(() => {
    loadAll();
  }, [selectedFleet?.id]);

  const monthlyDataDiff = monthlyData.map((x, ix) => {
    if (ix === 0) {
      return x;
    }
    return {
      ...x,
      diff_sum_amount_paid:
        x.sum_amount_paid / monthlyData[ix - 1].sum_amount_paid - 1,
      diff_avg_amount_paid:
        x.avg_amount_paid / monthlyData[ix - 1].avg_amount_paid - 1,
      diff_sum_count_paid:
        x.sum_count_paid / monthlyData[ix - 1].sum_count_paid - 1,
      diff_avg_count_paid:
        x.avg_count_paid / monthlyData[ix - 1].avg_count_paid - 1,
      diff_avg_devices_used:
        x.avg_devices_used / monthlyData[ix - 1].avg_devices_used - 1,
      diff_avg_max_vos: x.avg_max_vos / monthlyData[ix - 1].avg_max_vos - 1,
      diff_avg_avg_vos: x.avg_avg_vos / monthlyData[ix - 1].avg_avg_vos - 1,
      diff_avg_ar: x.avg_ar - monthlyData[ix - 1].avg_ar,
    };
  });

  if (statusMonthly === "loading") {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Reporte - Status Semanal
        <br />
        <small>{selectedFleet.name}</small>
        <div className={stylesLayout.subtitle}>
          * Moneda y hora local (Chile considera GMT-3)
          <br />* No incluye ventas del día actual
        </div>
      </div>
      <div className={stylesLayout.content}>
        <div className={stylesLayout.sectionHeader}>Hoy</div>
        <Table
          size='small'
          pagination={{hideOnSinglePage: true, defaultPageSize: 8}}
          locale={{emptyText: "Sin datos"}}
          columns={[
            {
              title: "Mes",
              dataIndex: "month",
              key: "month",
              width: 100,
              defaultSortOrder: "descend",
              render: (v, r) => (
                <>
                  <div>{v}</div>
                  <div>
                    <small>
                      {moment(r.start_day).format("DD/MM")}-
                      {moment(r.end_day).format("DD/MM")}
                    </small>
                  </div>
                  <div>
                    <small>({r.n_days} días)</small>
                  </div>
                </>
              ),
              sorter: (a, b) => (a.month < b.month ? -1 : 1),
            },
            {
              title: "Viajes Pagados",
              dataIndex: "sum_count_paid",
              key: "sum_count_paid",
              align: "right",
              render: (v, r) => (
                <>
                  {numeral(v).format("0,000")}
                  {r.diff_sum_count_paid ? (
                    <small>
                      <DeltaPercentageBadge n={r.diff_sum_count_paid * 100} />
                    </small>
                  ) : null}
                </>
              ),
            },
            {
              title: "Viajes Pagados por día",
              dataIndex: "avg_count_paid",
              key: "avg_count_paid",
              align: "right",
              render: (v, r) => (
                <>
                  {numeral(v).format("0,000")}
                  {r.diff_avg_count_paid ? (
                    <small>
                      <DeltaPercentageBadge n={r.diff_avg_count_paid * 100} />
                    </small>
                  ) : null}
                </>
              ),
            },
            {
              title: "Monto Pagado",
              dataIndex: "sum_amount_paid",
              key: "sum_amount_paid",
              align: "right",
              render: (v, r) => (
                <>
                  {numeral(v).format("0,000")}
                  {r.diff_sum_amount_paid ? (
                    <small>
                      <DeltaPercentageBadge n={r.diff_sum_amount_paid * 100} />
                    </small>
                  ) : null}
                </>
              ),
            },
            {
              title: "Monto Pagado por día",
              dataIndex: "avg_amount_paid",
              key: "avg_amount_paid",
              align: "right",
              render: (v, r) => (
                <>
                  {numeral(v).format("0,000")}
                  {r.diff_avg_amount_paid ? (
                    <small>
                      <DeltaPercentageBadge n={r.diff_avg_amount_paid * 100} />
                    </small>
                  ) : null}
                </>
              ),
            },
            {
              title: "Viajes No pagados",
              dataIndex: "sum_count_non_paid",
              key: "count_non_paid",
              align: "right",
              render: (v) => numeral(v).format("0,000"),
            },
            {
              title: "Monto no pagado",
              dataIndex: "sum_amount_non_paid",
              key: "amount_non_paid",
              align: "right",
              render: (v) => numeral(v).format("0,000"),
            },
            {
              title: "% No pagado",
              dataIndex: "x",
              key: "x",
              align: "right",
              render: (v, r) =>
                numeral((r.sum_count_non_paid / r.sum_count) * 100).format(
                  "0,000.0"
                ),
            },
            {
              title: "% Monto No pagado",
              dataIndex: "x",
              key: "x",
              align: "right",
              render: (v, r) =>
                numeral(
                  (r.sum_amount_non_paid / r.sum_amount_total) * 100
                ).format("0,000.0"),
            },
            {
              title: "Nuevos Usuarios con viajes",
              dataIndex: "x",
              key: "x",
              align: "right",
              render: (v, r) =>
                numeral(r.sum_count_new_users_with_trips).format("0,000"),
            },
            {
              title: "Patines usados",
              dataIndex: "avg_devices_used",
              key: "avg_devices_used",
              align: "right",
              render: (v, r) => (
                <>
                  {numeral(v).format("0,000")}
                  {r.diff_avg_devices_used ? (
                    <small>
                      <DeltaPercentageBadge n={r.diff_avg_devices_used * 100} />
                    </small>
                  ) : null}
                </>
              ),
            },
            {
              title: "VOS Max",
              dataIndex: "avg_max_vos",
              key: "avg_max_vos",
              align: "right",
              render: (v, r) => (
                <>
                  {numeral(v).format("0,000")}
                  {r.diff_avg_max_vos ? (
                    <small>
                      <DeltaPercentageBadge n={r.diff_avg_max_vos * 100} />
                    </small>
                  ) : null}
                </>
              ),
            },
            {
              title: "VOS Promedio",
              dataIndex: "avg_avg_vos",
              key: "avg_avg_vos",
              align: "right",
              render: (v, r) => (
                <>
                  {numeral(v).format("0,000")}
                  {r.diff_avg_avg_vos ? (
                    <small>
                      <DeltaPercentageBadge n={r.diff_avg_avg_vos * 100} />
                    </small>
                  ) : null}
                </>
              ),
            },
            {
              title: "AR",
              dataIndex: "avg_ar",
              key: "avg_ar",
              align: "right",
              render: (v, r) => (
                <>
                  {numeral(v).format("0,000")}
                  {r.diff_avg_ar ? (
                    <small>
                      <DeltaPercentageBadge n={r.diff_avg_ar} />
                    </small>
                  ) : null}
                </>
              ),
            },
          ]}
          dataSource={monthlyDataDiff}
        />

        <RenderLineChart
          data={monthlyDataDiff.map((x) => ({
            month: x.month,
            avg_amount_paid: x.avg_amount_paid,
          }))}
          yAxisScale='linear'
          xAxisType='linear'
          yAxisMin={0}
          title={<>Monto pagado por día</>}
          width={100}
          height={isMobile() ? 60 : 28}
          xAxisStepSize={1}
          xLabelsField='month'
          yDatasets={{
            "Monto por día": "avg_amount_paid",
          }}
        />
        <RenderLineChart
          data={monthlyDataDiff.map((x) => ({
            month: x.month,
            avg_count_paid: x.avg_count_paid,
          }))}
          yAxisScale='linear'
          xAxisType='linear'
          yAxisMin={0}
          title={<>Viajes pagados por día</>}
          width={100}
          height={isMobile() ? 60 : 28}
          xAxisStepSize={1}
          xLabelsField='month'
          yDatasets={{
            "Viajes pagados por día": "avg_count_paid",
          }}
        />
        <RenderLineChart
          data={monthlyDataDiff.map((x) => ({
            month: x.month,
            avg_max_vos: x.avg_max_vos,
          }))}
          yAxisScale='linear'
          xAxisType='linear'
          yAxisMin={0}
          title={<>Vos Max</>}
          width={100}
          height={isMobile() ? 60 : 28}
          xAxisStepSize={1}
          xLabelsField='month'
          yDatasets={{
            "VOS Max": "avg_max_vos",
          }}
        />
        <RenderLineChart
          data={monthlyDataDiff.map((x) => ({
            month: x.month,
            avg_ar: x.avg_ar,
          }))}
          yAxisScale='linear'
          xAxisType='linear'
          yAxisMin={0}
          title={<>AR</>}
          width={100}
          height={isMobile() ? 60 : 28}
          xAxisStepSize={1}
          xLabelsField='month'
          yDatasets={{
            AR: "avg_ar",
          }}
        />
      </div>
    </div>
  );
};
MonthlyModule.propTypes = {
  selectedFleet: PropTypes.object.isRequired,
};
export default MonthlyModule;
