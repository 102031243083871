/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { QrReader } from '@blackbox-vision/react-qr-reader';
import { useTorchLight } from '@blackbox-vision/use-torch-light';

import {
  Button,
  Input,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle, faLightbulb, faLocationArrow, faQrcode,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
  getDeviceByTypeQROrRef,
  findDevices,
} from '../../services/devices';

import styles from './index.module.scss';
import Loading from '../../components/Loading';
import MobileScooterInfo from '../../components/Devices/MobileScooterInfo';

import InternalTransportResume from '../../components/Devices/InternalTransportResume';

const MobileDeviceModule = ({ position, user, fleets }) => {
  const { q: qrParam } = useParams();
  const history = useHistory();
  let deviceLastQRS = [];
  try {
    deviceLastQRS = localStorage.getItem('__device__last_qrs') ? JSON.parse(localStorage.getItem('__device__last_qrs')) : [];
  } catch (e) {
    deviceLastQRS = [];
  }
  const [showDevicesAround, setShowDevicesAround] = useState(false);
  const [devicesAround, setDevicesAround] = useState(null);

  const [scanning, setScanning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatingDevice, setUpdatingDevice] = useState(false);
  const updatingDeviceRef = useRef(updatingDevice);
  updatingDeviceRef.current = updatingDevice;

  const [qr, setQr] = useState(qrParam || (localStorage.getItem('__device__qr') || ''));
  const [lastQRs, setLastQRs] = useState(deviceLastQRS);
  const [device, setDevice] = useState(null);
  const [error, setError] = useState(null);
  const [found, setFound] = useState(null);

  const [pidScan, setPidScan] = useState(null);
  const streamRef = useRef(null);
  const [torchOn, torchToggle] = useTorchLight(streamRef.current);

  const loadDevice = async (deviceQr, deviceType) => {
    const rsp = await getDeviceByTypeQROrRef({
      type: deviceType,
      code: deviceQr,
    });
    if (rsp && rsp.status) {
      setDevice(rsp.data);
      setFound(true);
    } else {
      setError(rsp);
      setFound(false);
    }
    setLoading(false);
    setUpdatingDevice(false);
  };

  useEffect(async () => {
    localStorage.setItem('__device__qr', qr);
  }, [qr]);

  const endScanning = () => {
    window.clearTimeout(pidScan);
    setTimeout(() => setScanning(false), 100);
  };
  const startScanning = () => {
    setTimeout(() => setScanning(true), 100);
    window.clearTimeout(pidScan);
    const pid = window.setTimeout(
      () => endScanning(),
      30000,
    );
    setPidScan(pid);
  };

  const onStartSearch = async (forceQR) => {
    const searchQR = forceQR || qr;
    if (!searchQR) {
      return;
    }
    const newUrl = `/device/${searchQR}`;
    if (newUrl !== history.location.pathname) {
      history.push(newUrl);
    }

    let newLastQRs = lastQRs;
    if (!Array.isArray(newLastQRs) || !newLastQRs.length > 0) {
      newLastQRs = [];
    }
    newLastQRs.unshift(searchQR.toUpperCase());
    newLastQRs = [...new Set(newLastQRs)].slice(0, 10);
    setLastQRs(newLastQRs);
    localStorage.setItem('__device__last_qrs', JSON.stringify(newLastQRs));
    setUpdatingDevice(false);
    setDevice(null);
    setLoading(true);
    loadDevice(searchQR, 'all');
  };

  const searchAround = async () => {
    setDevice(null);
    setLoading(true);
    const rsp = await findDevices({
      fleetId: null,
      type: 'scooter',
      position: { lat: position.latitude, lng: position.longitude },
      radius: 0.05,
      output: 'map',
    });
    if (rsp?.status) {
      if (rsp?.data.length === 1) {
        setQr(rsp.data[0].qr);
        onStartSearch(rsp.data[0].qr);
      } else {
        setDevicesAround(rsp.data);
        setShowDevicesAround(true);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (qr) {
      onStartSearch(qr);
    }
  }, []);
  useEffect(async () => {
    if (qrParam && qrParam !== qr) {
      setQr(qrParam);
      onStartSearch(qrParam);
    }
  }, [qrParam]);

  const getDeviceComponent = (d) => {
    switch (d.type) {
      case 'internal_transport':
        return (
          <InternalTransportResume
            defaultDevice={d}
            user={user}
            pollPeriod={10000}
            showMap
            updating={updatingDevice}
          />
        );
      case 'scooter':
      default:
        return (
          <MobileScooterInfo
            defaultDevice={d}
            position={position}
            user={user}
            fleets={fleets}
            pollPeriod={10000}
            showMap
            updating={updatingDevice}
          />
        );
    }
  };

  if (error) {
    console.log('** ERROR **', error);
  }

  return (
    <>
      {showDevicesAround && devicesAround?.length > 0
        && (
          <div className={styles.modalDevicesAround}>
            <div>
              <div className={styles.closeBtn} onClick={() => setShowDevicesAround(false)}>
                &times;
              </div>
            </div>
            <div>
              {devicesAround?.map((x) => (
                <div
                  className={styles.device}
                  onClick={() => {
                    setQr(x.qr);
                    onStartSearch(x.qr);
                    setShowDevicesAround(false);
                  }}
                >
                  <div>
                    <div className={styles.qr}>
                      {x.qr}
                    </div>
                    <div className={styles.battery}>
                      Bat:
                      {' '}
                      {x.data.batsco}
                      %
                    </div>
                    <div className={styles.last_conn}>
                      {x.data?.last_heartbeat_from_device_at
                        && moment.duration(moment.utc().diff(
                          moment.utc(x.data?.last_heartbeat_from_device_at),
                        )).asHours() >= 48
                        && (
                          <>
                            <FontAwesomeIcon style={{ color: '#c30' }} icon={faExclamationTriangle} />
                            {' '}
                          </>
                        )}
                      Últ HB:
                      {' '}
                      {x.data?.last_heartbeat_from_device_at ? moment.utc(x.data?.last_heartbeat_from_device_at).fromNow() : 'Sin info'}
                    </div>
                    <div className={styles.last_conn}>
                      {x.data?.last_message_from_device_at
                        && moment.duration(moment.utc().diff(
                          moment.utc(x.data?.last_message_from_device_at),
                        )).asHours() >= 48
                        && (
                          <>
                            <FontAwesomeIcon style={{ color: '#c30' }} icon={faExclamationTriangle} />
                            {' '}
                          </>
                        )}
                      Últ Mensaje:
                      {' '}
                      {x.data?.last_message_from_device_at ? moment.utc(x.data?.last_message_from_device_at).fromNow() : 'Sin info'}
                    </div>
                  </div>
                  <div>
                    {x.status_tr}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      {
        scanning
        && (
          <div className={styles.modalScan}>
            <div>
              <div className={styles.closeBtn} onClick={endScanning}>
                &times;
              </div>
            </div>
            <div>

              <QrReader
                resolution={600}
                constraints={{
                  aspectRatio: 1,
                  facingMode: 'environment',
                }}
                onLoad={({ stream }) => { streamRef.current = stream; }}
                onResult={(result) => {
                  if (result) {
                    const newQr = result?.text.slice(result?.text.length - 4);
                    setQr(newQr);
                    onStartSearch(newQr);
                    endScanning();
                  }
                }}
              />
              {false
                && <FontAwesomeIcon onClick={torchToggle} icon={faLightbulb} color={torchOn ? '#999' : 'inherit'} style={{ fontSize: 30 }} />}
            </div>
          </div>
        )
      }
      <div className={styles.cnt}>
        <div className={styles.input}>
          <div className={styles.inputInner}>
            <div>
              <Input style={{ textTransform: 'uppercase' }} value={qr} placeholder="QR o MAC..." onChange={(e) => setQr(e.target.value.toUpperCase())} onKeyDown={(e) => (e.key === 'Enter' ? onStartSearch() : null)} />
            </div>
            {position && (
              <div className={styles.searchAround} onClick={searchAround}>
                <FontAwesomeIcon icon={faLocationArrow} />
              </div>
            )}
            <div className={styles.scan} onClick={startScanning}>
              <FontAwesomeIcon icon={faQrcode} />
            </div>
          </div>
          <Button type="primary" onClick={() => onStartSearch()}>Buscar</Button>
          {lastQRs && lastQRs.length > 0
            && (
              <div className={styles.qrlinks}>
                {lastQRs.map((lqr) => (
                  <a
                    key={lqr}
                    className={styles.qrlink}
                    onClick={() => { setQr(lqr); onStartSearch(lqr); }}
                  >
                    {lqr}
                  </a>

                ))}
              </div>
            )}
        </div>
        <div className={styles.main}>
          {device === null && loading && <div className={styles.loading}><Loading /></div>}
          {!found && !loading
            ? (
              <div style={{
                margin: '120px 0 0', textAlign: 'center', fontSize: 24, fontWeight: 'normal', color: '#c30',
              }}
              >
                Patín no Encontrado
              </div>
            )
            : (
              <>

                {device === false && <div className={`${styles.message} ${styles.notFound}`}>Dispositivo no encontrado</div>}
                {device && device.data && (
                  getDeviceComponent(device)
                )}
              </>
            )}
        </div>
      </div>
    </>
  );
};

MobileDeviceModule.propTypes = {
  user: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  position: PropTypes.any,
};

MobileDeviceModule.defaultProps = {
  position: null,
};

export default MobileDeviceModule;
