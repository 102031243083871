/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import isMobile from 'is-mobile';
import moment from 'moment';

import {
  Button,
  Col, Row, Table,
} from 'antd';

import { faCircle, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  findNonLocatable,
  changeDeviceLightColor,
  sendDeviceCommand,
} from '../../services/devices';

import styles from './index.module.scss';
import Loading from '../../components/Loading';
import RowByStatus from '../../components/RowByStatus';
import stylesLayout from '../../common/layout.module.scss';
import stylesCommon from '../../common/common.module.scss';

import {
  networks,
} from '../../helpers/params';

const DevicesNonLocatableModule = ({
  // eslint-disable-next-line no-unused-vars
  fleets,
  fleetId,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [devices, setDevices] = useState(null);
  const [selectedRefs, setSelectedRefs] = useState([]);

  const onStartSearch = async () => {
    setLoading(true);
    setSelectedRefs([]);
    const rsp = await findNonLocatable(
      fleetId,
    );
    if (rsp && rsp.status) {
      setDevices(rsp.data);
    }
    setLoading(false);
  };
  useEffect(async () => {
    onStartSearch();
  }, [fleetId]);

  const deviceColumns = [
    {
      title: '#',
      key: 'index',
      render: (value, item, index) => 1 + index,
    },
    {
      title: 'Online (últ 30m)',
      dataIndex: 'is_online_last_30m',
      key: 'is_online_last_30m',
      align: 'center',
      render: (isOnlineLast30m) => (
        <>
          <FontAwesomeIcon icon={faCircle} style={{ color: isOnlineLast30m ? '#093' : '#c30' }} />
        </>
      ),
    },
    {
      title: 'Bloqueo',
      dataIndex: 'data',
      key: 'locked',
      align: 'center',
      render: (data) => (
        <>
          <FontAwesomeIcon
            icon={
              data.locked === 1 ? faLock : faUnlock
            }
            style={{ marginLeft: 5 }}
            className={data.locked ? stylesCommon.locked : stylesCommon.unlocked}
          />
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status_tr',
      key: 'status_tr',
      filterMode: 'tree',
      filterSearch: true,
      filters: [...new Set(devices?.map((x) => x.status_tr))].map((x) => ({ text: x, value: x })),
      onFilter: (value, r) => r.status_tr === value,
      render: (status) => status,
      sorter: (a, b) => (a.status < b.status ? -1 : 1),
    },
    {
      title: 'MAC',
      align: 'center',
      dataIndex: 'ref',
      width: 200,
      key: 'ref',
      render: (ref) => <Link to={`/device/${ref}`}>{ref}</Link>,
      sorter: (a, b) => (a.ref < b.ref ? -1 : 1),
    }, {
      title: 'QR',
      dataIndex: 'qr',
      align: 'center',
      key: 'qr',
      render: (qr, r) => (
        <>
          <Link to={`/device/${r.ref}`}>{qr}</Link>
        </>
      ),
      sorter: (a, b) => (a.qr < b.qr ? -1 : 1),
    }, {
      title: 'Versión Firmware',
      dataIndex: 'iot_firmware_version',
      key: 'iot_firmware_version',
      align: 'center',
      filterMode: 'tree',
      filterSearch: true,
      filters: [...new Set(devices?.map((x) => x.data.iot_firmware_version))]
        .map((x) => ({ text: x || 'Sin info', value: x })),
      onFilter: (value, r) => r.iot_firmware_version === value,
      render: (v) => v || <span style={{ color: '#c30', fontWeight: 'bold' }}>Sin info</span>,
      sorter: (a, b) => (a.iot_firmware_version < b.iot_firmware_version ? -1 : 1),
    }, {
      title: 'IOT conectado a scooter',
      dataIndex: 'is_scooter_present',
      key: 'is_scooter_present',
      align: 'center',
      filterMode: 'tree',
      filterSearch: true,
      filters: [{ text: 'SI', value: '1' }, { text: 'Desconocido', value: '0' }],
      onFilter: (value, r) => r.is_scooter_present === value,
      render: (v) => (parseInt(v, 10) === 1 ? <span style={{ color: '#090', fontWeight: 'bold' }}>SI</span> : <span style={{ color: '#c30', fontWeight: 'bold' }}>Desconocido</span>),
      sorter: (a, b) => (
        parseInt(a.is_scooter_present, 10) < parseInt(b.is_scooter_present, 10) ? -1 : 1),
    }, {
      title: 'HB Periodo',
      dataIndex: 'data',
      key: 'heartbeat_period',
      align: 'center',
      render: (data) => data.heartbeat_period,
    },
    {
      title: 'Vel máxima',
      dataIndex: 'max_speed',
      align: 'center',
      filterMode: 'tree',
      filterSearch: true,
      filters: [...new Set(devices?.map((x) => x.data.max_speed))]
        .map((x) => ({ text: x, value: x })),
      onFilter: (value, r) => r.max_speed === value,
      render: (v) => v,
      sorter: (a, b) => (parseInt(a.data.max_speed, 10) < parseInt(b.data.max_speed, 10) ? -1 : 1),
    }, {
      title: 'Actualizado',
      dataIndex: 'updated_at',
      key: 'updated',
      align: 'center',
      render: (updatedAt) => moment.utc(updatedAt).fromNow(),
      sorter: (a, b) => moment(b.updated_at).format('X') - moment(a.updated_at).format('X'),
    }, {
      title: 'Batería Scooter',
      dataIndex: 'data',
      key: 'batsco',
      align: 'center',
      render: (data) => `${data.batsco ? data.batsco : ''} ${data.charging === '1' ? '(C)' : ''}`,
      sorter: (a, b) => (
        // eslint-disable-next-line no-nested-ternary
        parseInt(a.data.batsco, 10) === parseInt(b.data.batsco, 10) ? 0
          : (parseInt(a.data.batsco, 10) < parseInt(b.data.batsco, 10) ? -1 : 1)),
    },
    {
      title: 'Batería IOT',
      dataIndex: 'data',
      key: 'batiot',
      align: 'center',
      render: (data) => `${data.batiot ? data.batiot : ''} ${data.charging === '1' ? '(C)' : ''}`,
      sorter: (a, b) => (
        // eslint-disable-next-line no-nested-ternary
        parseInt(a.data.batiot, 10) === parseInt(b.data.batiot, 10) ? 0
          : (parseInt(a.data.batiot, 10) < parseInt(b.data.batiot, 10) ? -1 : 1)),
    }, {
      title: '2G/4G (rssi)',
      dataIndex: 'data',
      key: '2g4g',
      align: 'center',
      render: (data) => `${networks[data['4g2g']] ? networks[data['4g2g']] : ''} ${data.rssi ? `(${data.rssi})` : ''}`,
    }, {
      title: 'Posición',
      dataIndex: 'position',
      align: 'center',
      key: 'position',
      render: (p, r) => (
        <>
          {r.lat}
          {', '}
          {r.lng}
          <br />
          <small>
            {moment.utc(r.geo_updated_at).fromNow()}
          </small>
        </>
      ),
    },
    {
      title: 'Últ Heartbeat',
      dataIndex: 'data',
      key: 'hb',
      align: 'center',
      render: (data) => (data.last_heartbeat_from_device_at ? moment.utc(data.last_heartbeat_from_device_at).fromNow() : ''),
      sorter: (a, b) => moment(b.data.last_heartbeat_from_device_at).format('X') - moment(a.data.last_heartbeat_from_device_at).format('X'),
    },
    {
      title: 'Últ mensaje recibido',
      dataIndex: 'data',
      key: 'lm',
      defaultSortOrder: 'ascend',
      align: 'center',
      render: (data) => (data.last_message_from_device_at ? moment.utc(data.last_message_from_device_at).fromNow() : ''),
      sorter: (a, b) => moment(b.data.last_message_from_device_at).format('X') - moment(a.data.last_message_from_device_at).format('X'),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRefs(selectedRows.map((r) => r.ref));
    },
    getCheckboxProps: (record) => ({
      // Column configuration not to be checked
      id: record.ref,
    }),
  };

  const changeSelectedToLight = async (color) => {
    setLoadingAction(true);
    await changeDeviceLightColor({
      ref: selectedRefs,
      type: 'scooter',
      mode: 1,
      color,
    });
    setLoadingAction(false);
  };

  const sendCommand = async (command) => {
    setLoadingAction(true);
    await sendDeviceCommand({
      ref: selectedRefs,
      type: 'scooter',
      command,
    });
    setLoadingAction(false);
  };
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Patines no ubicables
      </div>
      <div className={stylesLayout.subtitle}>
        Patines sin conexión,
        {' '}
        desplegados o deshabilitados sin posición actualizada en ultimos 20 minutos
        {' '}
        o en transporte por más de 3 horas
      </div>
      <div className={stylesLayout.content}>

        <Row gutter={24}>
          <Col sm={24} xs={24}>
            {loading && <Loading />}
            {!loading && (devices && devices.length > 0) && (
              <>
                <div className={styles.actions}>
                  <Button type="primary" disabled={loadingAction || !selectedRefs || selectedRefs.length === 0} onClick={() => changeSelectedToLight('off')}>Apagar luces</Button>
                  {' '}
                  <Button type="primary" disabled={loadingAction || !selectedRefs || selectedRefs.length === 0} onClick={() => changeSelectedToLight('red')}>Poner rojo</Button>
                  {' '}
                  <Button type="primary" disabled={loadingAction || !selectedRefs || selectedRefs.length === 0} onClick={() => changeSelectedToLight('yellow')}>Poner amarillo</Button>
                  {' '}
                  <Button type="primary" disabled={loadingAction || !selectedRefs || selectedRefs.length === 0} onClick={() => changeSelectedToLight('green')}>Poner verde</Button>
                  {' '}
                  <Button type="primary" disabled={loadingAction || !selectedRefs || selectedRefs.length === 0} onClick={() => changeSelectedToLight('blue')}>Poner azul</Button>
                  {' '}
                  <Button type="primary" disabled={loadingAction || !selectedRefs || selectedRefs.length === 0} onClick={() => changeSelectedToLight('violet')}>Poner morado</Button>
                  {' '}
                  <Button type="primary" disabled={loadingAction || !selectedRefs || selectedRefs.length === 0} onClick={() => changeSelectedToLight('pink')}>Poner rosa</Button>
                  {' '}
                  <Button type="primary" disabled={loadingAction || !selectedRefs || selectedRefs.length === 0} onClick={() => sendCommand('50,0')}>Buzz Off</Button>
                </div>
                <div className={styles.actions}>
                  <Button type="primary" disabled={loadingAction || !selectedRefs || selectedRefs.length === 0} onClick={() => sendCommand('211')}>Actualizar versión FW</Button>
                  {' '}
                  <Button type="primary" disabled={loadingAction || !selectedRefs || selectedRefs.length === 0} onClick={() => sendCommand('201')}>Actualizar geolocalización</Button>
                  {' '}
                  <Button type="primary" disabled={loadingAction || !selectedRefs || selectedRefs.length === 0} onClick={() => sendCommand('39,30')}>Heartbeat 30s</Button>
                  {' '}
                  <Button type="primary" disabled={loadingAction || !selectedRefs || selectedRefs.length === 0} onClick={() => sendCommand('39,600')}>Heartbeat 10m</Button>
                </div>
                <div className={styles.byStatus}>
                  <RowByStatus
                    devices={devices}
                    size="small"
                    cols={isMobile() ? 4 : 6}
                  />
                </div>
                <div className={styles.table}>

                  <Table
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection,
                    }}
                    loading={loading}
                    size="small"
                    pagination={{
                      hideOnSinglePage: true,
                      defaultPageSize: 200,
                      pageSizeOptions: [10, 20, 50, 100, 200, 500],
                    }}
                    locale={{ emptyText: 'Sin datos' }}
                    columns={deviceColumns}
                    dataSource={devices.map((d) => ({
                      key: d.id,
                      fleet_name: d.fleet?.name || '',
                      max_speed: d.data.max_speed,
                      is_scooter_present: d.data.is_scooter_present,
                      iot_firmware_version: d.data.iot_firmware_version,
                      ...d,
                    }))}
                  />
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

DevicesNonLocatableModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  tenant: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};

export default DevicesNonLocatableModule;
