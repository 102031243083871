/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import numeral from 'numeral';
import moment from 'moment';
import {
  Button,
} from 'antd';

import {
  alertTypes,
} from '../../helpers/params';

import ScooterResume from '../Devices/ScooterResume';

import styles from './index.module.scss';

const AlertItem = ({
  updating,
  alert,
  position,
  user,
  fleets,
  onAction,
  open,
  onChangeAlertStatus,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  return (
    <div className={styles.alert} key={alert.id}>
      <div className={styles.info} role="button" onClick={() => setIsOpen(!isOpen)}>
        #
        {alert.id}
        {', '}
        {alertTypes[alert.type]}
        {' '}
        (
        {alert.device.qr}
        )
        {' '}

        <br />
        {alert.data?.triggered_by}
        <div className={styles.fleet}>
          Flota:
          {' '}
          {alert.device.fleet?.name}
        </div>
        <div className={styles.date}>
          Creada:
          {' '}
          {moment.utc(alert.created_at).fromNow()}
          <br />
          Actualizada:
          {' '}
          {moment.utc(alert.updated_at).fromNow()}
        </div>
      </div>
      <div className={styles.actions}>
        {!isOpen && (
          <Button type="secondary" size="large" onClick={() => setIsOpen(true)}>
            View
          </Button>
        )}
        {' '}
        {alert.status.toUpperCase() === 'NEW'
          && (
            <>
              <Button type="primary" size="large" onClick={() => onChangeAlertStatus(alert.id, 'SOLVED')}>
                Resolve
              </Button>
            </>
          )}
        {alert.status.toUpperCase() === 'SOLVED'
          && (
            <>
              <Button type="primary" size="large" onClick={() => onChangeAlertStatus(alert.id, 'NEW')}>
                Reopen
              </Button>
            </>
          )}
      </div>
      {isOpen
        && (
          <div className={styles.device}>
            <ScooterResume
              showMap
              position={position}
              fleets={fleets}
              user={user}
              defaultDevice={alert.device}
              onAction={onAction}
              updating={updating}
            />
          </div>
        )}
    </div>

  );
};

AlertItem.defaultProps = {
  updating: false,
  open: false,
  onAction: () => { },
  onChangeAlertStatus: () => { },
  position: null,
};

AlertItem.propTypes = {
  updating: PropTypes.bool,
  alert: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  position: PropTypes.object,
  fleets: PropTypes.array.isRequired,
  onAction: PropTypes.func,
  open: PropTypes.bool,
  onChangeAlertStatus: PropTypes.func,
};

export default AlertItem;
