/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
// import numeral from 'numeral';
import moment from 'moment';

import styles from './index.module.scss';

const TaskItem = ({
  task,
  onSelect,
}) => {
  const stolenDevices = task.devices.filter((x) => ['stolen', 'stolen_suspect'].includes(x.status));
  return (
    <div className={styles.task} role="button" key={task.id} onClick={() => onSelect(task)}>
      <div className={styles.info}>

        <div className={styles.steps}>
          <div className={styles.id}>
            #
            {task.id}
            :
          </div>
          {task.data?.steps.map(((s) => (
            <div className={styles.step}>{s.action_tr}</div>
          )))}
        </div>
        <div className={styles.devices}>
          {task.data.devices.length}
          {' '}
          dispositivo
          {task.data.devices.length > 1 ? 's' : ''}
          {stolenDevices && stolenDevices.length > 0 && (
            <span className={styles.devicesSuspectStolen}>
              (
              {stolenDevices.length}
              {' '}
              en sospecha de robo)
            </span>
          )}
          <div>{task.devices?.map((x) => x.qr).join(', ')}</div>
        </div>
        <div className={styles.status}>
          Status:
          {' '}
          {task.status_tr}
        </div>
        <div className={styles.fleet}>
          Flota:
          {' '}
          {task.fleet?.name}
        </div>
        {task.creator && (
          <div className={styles.fleet}>
            Creada por:
            {' '}
            {task.creator?.name}
            {' '}
            -
            {' '}
            {task.creator?.email}
          </div>
        )}
        {task.assignee && (
          <div className={styles.fleet}>
            Asignada a:
            {' '}
            {task.assignee?.name}
            {' '}
            -
            {' '}
            {task.assignee?.email}
          </div>
        )}
        <div className={styles.date}>
          Creada:
          {' '}
          {moment.utc(task.created_at).fromNow()}
          <br />
          Actualizada:
          {' '}
          {moment.utc(task.updated_at).fromNow()}
        </div>
        {task.data.comments
          && (
            <div className={styles.comments}>
              {task.data.comments}
            </div>
          )}
      </div>

    </div>

  );
};

TaskItem.defaultProps = {
  onSelect: () => { },
};

TaskItem.propTypes = {
  task: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
};

export default TaskItem;
