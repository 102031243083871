/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Input, Form,
} from 'antd';
import { faTrashAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './index.module.scss';

const DeployPlanItem = ({
  deployPlan,
  onStartEditing,
  onEndEditing,
  onSaveEditing,
  onDelete,
  open,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [fields, setFields] = useState(deployPlan);
  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  const startEditing = () => {
    setIsOpen(true);
    onStartEditing(deployPlan.id);
  };
  const saveEditing = () => {
    setIsOpen(false);
    onSaveEditing(deployPlan.id, fields);
  };
  const endEditing = () => {
    setIsOpen(false);
    onEndEditing(deployPlan.id);
  };
  const setField = (field, value) => {
    setFields((s) => ({ ...s, [field]: value }));
  };
  const deleteItem = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('¿Estás seguro de eliminar esta zona?')) {
      onDelete(deployPlan.id);
    }
  };
  return (
    <div className={styles.cnt}>
      {!isOpen
        && (
          <div className={styles.info} onClick={startEditing}>
            <div className={[styles.title, styles[`status_${deployPlan.status.toLowerCase()}`]].join(' ')}>
              {deployPlan.name}
              {' '}
              -
              {' '}
              <small>{deployPlan.status}</small>
            </div>
            <div className={styles.editBtn}>
              <Button onClick={startEditing}>Editar</Button>
            </div>
          </div>
        )}
      {isOpen && (
        <div className={styles.editArea}>
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            autoComplete="off"
          >
            <div className={styles.field}>
              <Form.Item
                label="Nombre"
              >
                <Input type="text" placeholder="Nombre de la zona..." value={fields.name} onChange={(e) => setField('name', e.target.value)} />
              </Form.Item>
            </div>

            <div className={styles.buttons}>
              <Button onClick={saveEditing} type="secondary">
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                Guardar
              </Button>
              <a style={{ marginLeft: 10 }} onClick={endEditing}>Salir</a>
              <Button danger onClick={deleteItem} style={{ float: 'right' }}>
                <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: 5 }} />
                {' '}
                Eliminar
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

DeployPlanItem.defaultProps = {
  onStartEditing: () => { },
  onEndEditing: () => { },
  onSaveEditing: () => { },
  onDelete: () => { },
  open: false,
};
DeployPlanItem.propTypes = {
  deployPlan: PropTypes.object.isRequired,
  onStartEditing: PropTypes.func,
  onEndEditing: PropTypes.func,
  onSaveEditing: PropTypes.func,
  onDelete: PropTypes.func,
  open: PropTypes.bool,
};

export default DeployPlanItem;
