/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useEffect, useState} from "react";
import {SketchPicker} from "react-color";
import PropTypes from "prop-types";
import numeral from "numeral";
import {CSVLink} from "react-csv";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {DatePicker, Table, Select, Button, Affix, Tabs, Slider} from "antd";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faSyncAlt} from "@fortawesome/free-solid-svg-icons";

import {Link} from "react-router-dom";
import Loading from "../../components/Loading";
import Metric from "../../components/Metric";

import * as tothService from "../../services/toth";

import styles from "./index.module.scss";
import stylesLayout from "../../common/layout.module.scss";
import {SimpleMapComponent} from "../../components/SimpleMapComponent";
import {calculateCentroid} from "../../helpers/utils";
import {RenderLineChart} from "../../components/Charts";

dayjs.extend(utc);

const {TabPane} = Tabs;

const {RangePicker} = DatePicker;
const {Option} = Select;

const DashboardModule = ({tenant, fleets, fleetId}) => {
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("trips");
  const [heatMapRange, setHeatMapRange] = useState([0, 24]);
  const [range, setRange] = useState([
    dayjs().startOf("day"),
    dayjs().endOf("day"),
  ]);
  const [data, setData] = useState({});
  const [mapColor, setMapColor] = useState("#cc336644");
  const [selectedFleets, setSelectedFleets] = useState(fleetId);
  const [filterTripIds, setFilterTripIds] = useState(null);
  const [weightFactor, setWeightFactor] = useState(30);

  const onChangeRange = (dates) => {
    setRange(dates);
  };

  const updateData = (key, value) => {
    setData((s) => ({
      ...s,
      [key]: value,
    }));
  };

  const loadData1 = async () => {
    setLoading(true);
    const [rspTrips, rspTripsPaidDetails] = await Promise.all([
      tothService.compute({
        metric: "trips.get.ByDay",
        configurations: {
          paperboy: "paperboy",
        },
        parameters: {
          start: range[0].utc().format("YYYY-MM-DD HH:mm:ss"),
          end: range[1].utc().format("YYYY-MM-DD HH:mm:ss"),
          local_tz: dayjs().utcOffset() / 60,
          fleets: String(selectedFleets),
        },
      }),
      tothService.compute({
        metric: "trips.get.DetailsPaid",
        configurations: {
          paperboy: "paperboy",
        },
        parameters: {
          start: range[0].utc().format("YYYY-MM-DD HH:mm:ss"),
          end: range[1].utc().format("YYYY-MM-DD HH:mm:ss"),
          fleets: String(selectedFleets),
        },
      }),
    ]);
    if (rspTrips?.status) {
      updateData("trips", rspTrips.data);
    } else {
      updateData("trips", []);
    }
    if (rspTripsPaidDetails?.status) {
      updateData("tripsPaidDetails", rspTripsPaidDetails.data);
    } else {
      updateData("tripsPaidDetails", []);
    }

    setLoading(false);
  };

  const loadData2 = async () => {
    setLoading(true);
    const [
      rspTripsResume,
      rspTripsResume7DBefore,
      rspTripsResume14DBefore,
      rspTripsbyHour,
    ] = await Promise.all([
      tothService.compute({
        metric: "trips.get.Resume",
        configurations: {
          paperboy: "paperboy",
        },
        parameters: {
          days_delta: 0,
          start: range[0].utc().format("YYYY-MM-DD HH:mm:ss"),
          end: range[1].utc().format("YYYY-MM-DD HH:mm:ss"),
          fleets: String(selectedFleets),
        },
      }),
      tothService.compute({
        metric: "trips.get.Resume",
        configurations: {
          paperboy: "paperboy",
        },
        parameters: {
          days_delta: -7,
          start: range[0].utc().format("YYYY-MM-DD HH:mm:ss"),
          end: range[1].utc().format("YYYY-MM-DD HH:mm:ss"),
          fleets: String(selectedFleets),
        },
      }),
      tothService.compute({
        metric: "trips.get.Resume",
        configurations: {
          paperboy: "paperboy",
        },
        parameters: {
          days_delta: -14,
          start: range[0].utc().format("YYYY-MM-DD HH:mm:ss"),
          end: range[1].utc().format("YYYY-MM-DD HH:mm:ss"),
          fleets: String(selectedFleets),
        },
      }),
      tothService.compute({
        metric: "trips.get.ByHour",
        configurations: {
          paperboy: "paperboy",
        },
        parameters: {
          start: range[0].utc().format("YYYY-MM-DD HH:mm:ss"),
          end: range[1].utc().format("YYYY-MM-DD HH:mm:ss"),
          local_tz: dayjs().utcOffset() / 60,
          fleets: String(selectedFleets),
        },
      }),
    ]);

    if (rspTripsResume?.status) {
      updateData("tripsResume", rspTripsResume.data);
    } else {
      updateData("tripsResume", []);
    }
    if (rspTripsResume7DBefore?.status) {
      updateData("tripsResume7DBefore", rspTripsResume7DBefore.data);
    } else {
      updateData("tripsResume7DBefore", []);
    }
    if (rspTripsResume14DBefore?.status) {
      updateData("tripsResume14DBefore", rspTripsResume14DBefore.data);
    } else {
      updateData("tripsResume14DBefore", []);
    }
    if (rspTripsbyHour?.status) {
      updateData("tripsbyHour", rspTripsbyHour.data);
    } else {
      updateData("tripsbyHour", []);
    }
    setLoading(false);

    const rspTripsDetails = await tothService.compute({
      metric: "trips.get.Details",
      configurations: {
        paperboy: "paperboy",
      },
      parameters: {
        start: range[0].utc().format("YYYY-MM-DD HH:mm:ss"),
        end: range[1].utc().format("YYYY-MM-DD HH:mm:ss"),
        fleets: String(selectedFleets),
      },
    });

    if (rspTripsDetails?.status) {
      updateData("tripsDetails", rspTripsDetails.data);
    } else {
      updateData("tripsDetails", []);
    }
  };

  const loadData3 = async () => {
    setLoading(true);
    const [rspDevicesStatus] = await Promise.all([
      tothService.compute({
        metric: "devices.get.ByStatusDay",
        configurations: {
          paperboy: "paperboy",
        },
        parameters: {
          start: range[0].utc().format("YYYY-MM-DD HH:mm:ss"),
          end: range[1].utc().format("YYYY-MM-DD HH:mm:ss"),
          fleets: String(selectedFleets),
        },
      }),
    ]);

    if (rspDevicesStatus?.status) {
      updateData("devicesByStatusDay", rspDevicesStatus.data);
    } else {
      updateData("devicesByStatusDay", []);
    }

    setLoading(false);
  };

  const loadData = () => {
    const days = (range[1].valueOf() - range[0].valueOf()) / (86400 * 1000);
    if (days > 7) {
      alert("Solamente puedes ver 7 días o menos");
      return;
    }

    if (activeTab === "trips_paid") {
      loadData1();
    }
    if (activeTab === "trips") {
      loadData2();
    }
    if (activeTab === "devices_with_status_changes") {
      loadData3();
    }
  };

  console.log("data.tripsbyHour", data.tripsbyHour);

  useEffect(async () => {
    loadData();
  }, [activeTab, selectedFleets]);

  if (!tenant) {
    return (
      <div className={stylesLayout.page}>
        <Loading />
      </div>
    );
  }

  let tripsStartMapCenter = null;
  let tripsEndMapCenter = null;
  let tripsDetails = [];
  if (data?.tripsDetails) {
    tripsDetails = data?.tripsDetails.filter(
      (x) =>
        dayjs.utc(x.trip_start_at).local().format("HH") >= heatMapRange[0] &&
        dayjs.utc(x.trip_start_at).local().format("HH") < heatMapRange[1]
    );
    tripsStartMapCenter = calculateCentroid(
      tripsDetails.map((x) => ({lat: x.lat_start, lng: x.lng_start}))
    );
    tripsEndMapCenter = calculateCentroid(
      tripsDetails
        .filter((x) => x.lat_end && x.lng_end)
        .map((x) => ({lat: x.lat_end, lng: x.lng_end}))
    );
  }
  let filteredTripDetails = tripsDetails;
  if (filterTripIds) {
    filteredTripDetails = tripsDetails?.filter((x) =>
      filterTripIds.includes(x.id)
    );
  }
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.content} id='content'>
        <Affix offsetTop={-1} target={() => document.getElementById("content")}>
          <div className={styles.filters}>
            <div className={styles.fleets}>
              <Select
                allowClear
                showSearch={false}
                size='large'
                value={selectedFleets}
                onChange={(value) => setSelectedFleets(value)}
                style={{width: "100%"}}
              >
                {fleets &&
                  fleets.map((f) => (
                    <Option key={f.id} value={f.id}>
                      {f.name}
                    </Option>
                  ))}
              </Select>
            </div>

            <div className={styles.range}>
              <RangePicker
                ranges={{
                  Hoy: [dayjs().startOf("day"), dayjs().endOf("day")],
                  Ayer: [
                    dayjs().subtract(1, "day").startOf("day"),
                    dayjs().subtract(1, "day").endOf("day"),
                  ],
                  Anteayer: [
                    dayjs().subtract(2, "day").startOf("day"),
                    dayjs().subtract(2, "day").endOf("day"),
                  ],
                  "3 días antes": [
                    dayjs().subtract(3, "day").startOf("day"),
                    dayjs().subtract(3, "day").endOf("day"),
                  ],
                  "4 días antes": [
                    dayjs().subtract(4, "day").startOf("day"),
                    dayjs().subtract(4, "day").endOf("day"),
                  ],
                  "5 días antes": [
                    dayjs().subtract(5, "day").startOf("day"),
                    dayjs().subtract(5, "day").endOf("day"),
                  ],
                  "6 días antes": [
                    dayjs().subtract(6, "day").startOf("day"),
                    dayjs().subtract(6, "day").endOf("day"),
                  ],
                  "Hace 7 días (mismo horario)": [
                    dayjs().subtract(7, "day").startOf("day"),
                    dayjs().subtract(7, "day"),
                  ],
                  "Semana actual": [
                    dayjs().startOf("week"),
                    dayjs().endOf("week"),
                  ],
                  "Semana anterior": [
                    dayjs().subtract(1, "week").startOf("week"),
                    dayjs().subtract(1, "week").endOf("week"),
                  ],
                }}
                size='large'
                defaultValue={range}
                showTime
                format='YYYY/MM/DD HH:mm:ss'
                onChange={onChangeRange}
              />
              <div style={{fontSize: 12}}>
                Hora local: {dayjs().format("YYYY-MM-DD HH:mm")}, Zona horaria:{" "}
                {dayjs().utcOffset() / 60}, Hora UTC:{" "}
                {dayjs.utc().format("YYYY-MM-DD HH:mm")}
              </div>
            </div>
            <div>
              <Button onClick={loadData} size='large'>
                <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
              </Button>
            </div>
          </div>
        </Affix>
        <>
          <Tabs defaultActiveKey='trips' onChange={(k) => setActiveTab(k)}>
            <TabPane tab='Todos los viajes' key='trips'>
              {loading ? (
                <div className={stylesLayout.page}>
                  <Loading />
                </div>
              ) : (
                <>
                  <div className={styles.block}>
                    <div className={styles.title}>Viajes realizados</div>

                    <div style={{marginBottom: 20}}>
                      Considera todos los viajes pagados y no pagados.{" "}
                      <strong>Iniciados</strong> en el rango de fecha elegido
                    </div>
                    <div className={styles.block}>
                      <div>
                        Comparación con mismo bloque horario 7 días atras.
                        <br />
                        * Dia actual considera 7 días atras hasta hora actual
                        <br />
                        <br />
                      </div>
                      <div className={styles.metrics}>
                        <Metric
                          title={
                            <>
                              Total incluyendo gratis
                              <br />
                              {data.tripsResume?.trips_in_process > 0
                                ? `(${data.tripsResume?.trips_in_process} en proceso)`
                                : ""}
                            </>
                          }
                          n={data.tripsResume?.trips_total_including_free}
                        />
                        <Metric
                          title='Total con costo'
                          n={data.tripsResume?.trips_total}
                        />
                        <Metric
                          title='Pagados'
                          isDeltaPositive={(delta) => delta >= 0}
                          n={data.tripsResume?.trips_paid}
                          delta={
                            data.tripsResume?.trips_paid /
                              data.tripsResume7DBefore?.trips_paid -
                            1
                          }
                        />
                        <Metric
                          title='No pagados'
                          n={data.tripsResume?.trips_non_paid}
                          isDeltaPositive={(delta) => delta < 0}
                          delta={
                            data.tripsResume?.trips_non_paid /
                              data.tripsResume7DBefore?.trips_non_paid -
                            1
                          }
                        />
                        <Metric
                          title='Monto pagado ($)'
                          n={data.tripsResume?.amount_paid}
                          delta={
                            data.tripsResume?.amount_paid /
                              data.tripsResume7DBefore?.amount_paid -
                            1
                          }
                        />
                        <Metric
                          title='Monto no pagado ($)'
                          n={data.tripsResume?.amount_non_paid}
                          isDeltaPositive={(delta) => delta < 0}
                          delta={
                            data.tripsResume?.amount_non_paid /
                              data.tripsResume7DBefore?.amount_non_paid -
                            1
                          }
                        />
                        <Metric
                          title='Monto promedio por viaje pagado ($) monto > 0'
                          format='0,000.00'
                          n={data.tripsResume?.avg_amount_paid}
                          isDeltaPositive={(delta) => delta >= 0}
                          delta={
                            data.tripsResume?.avg_amount_paid /
                              data.tripsResume7DBefore?.avg_amount_paid -
                            1
                          }
                        />
                        <Metric
                          title='% viajes pagados'
                          format='0,000.00%'
                          n={data.tripsResume?.pct_trips_paid}
                          isDeltaPositive={(delta) => delta >= 0}
                          delta={
                            data.tripsResume?.pct_trips_paid /
                              data.tripsResume7DBefore?.pct_trips_paid -
                            1
                          }
                        />
                        <Metric
                          title='% monto pagado (monto > 0)'
                          format='0,000.00%'
                          n={data.tripsResume?.pct_amount_paid}
                          isDeltaPositive={(delta) => delta >= 0}
                          delta={
                            data.tripsResume?.pct_amount_paid /
                              data.tripsResume7DBefore?.pct_amount_paid -
                            1
                          }
                        />
                      </div>
                    </div>
                    <div>Mismo bloque horario 7 días atras</div>
                    <div className={styles.block}>
                      <div className={styles.metrics}>
                        <Metric
                          title='Total incluyendo gratis'
                          n={
                            data.tripsResume7DBefore?.trips_total_including_free
                          }
                        />
                        <Metric
                          title='Total con costo'
                          n={data.tripsResume7DBefore?.trips_total}
                        />
                        <Metric
                          title='Pagados'
                          n={data.tripsResume7DBefore?.trips_paid}
                        />
                        <Metric
                          title='No pagados'
                          n={data.tripsResume7DBefore?.trips_non_paid}
                        />
                        <Metric
                          title='Monto pagado ($)'
                          n={data.tripsResume7DBefore?.amount_paid}
                        />
                        <Metric
                          title='Monto no pagado ($)'
                          n={data.tripsResume7DBefore?.amount_non_paid}
                        />
                        <Metric
                          title='Monto promedio por viaje pagado ($)'
                          format='0,000.00'
                          n={data.tripsResume7DBefore?.avg_amount_paid}
                        />
                        <Metric
                          title='% viajes pagados'
                          format='0,000.00%'
                          n={data.tripsResume7DBefore?.pct_trips_paid}
                        />
                        <Metric
                          title='% monto pagado'
                          format='0,000.00%'
                          n={data.tripsResume7DBefore?.pct_amount_paid}
                        />
                      </div>
                    </div>
                    <div>Mismo bloque horario 14 días atras</div>
                    <div className={styles.block}>
                      <div className={styles.metrics}>
                        <Metric
                          title='Total incluyendo gratis'
                          n={
                            data.tripsResume14DBefore
                              ?.trips_total_including_free
                          }
                        />
                        <Metric
                          title='Total con costo'
                          n={data.tripsResume14DBefore?.trips_total}
                        />
                        <Metric
                          title='Pagados'
                          n={data.tripsResume14DBefore?.trips_paid}
                        />
                        <Metric
                          title='No pagados'
                          n={data.tripsResume14DBefore?.trips_non_paid}
                        />
                        <Metric
                          title='Monto pagado ($)'
                          n={data.tripsResume14DBefore?.amount_paid}
                        />
                        <Metric
                          title='Monto no pagado ($)'
                          n={data.tripsResume14DBefore?.amount_non_paid}
                        />
                        <Metric
                          title='Monto promedio por viaje pagado ($)'
                          format='0,000.00'
                          n={data.tripsResume14DBefore?.avg_amount_paid}
                        />
                        <Metric
                          title='% viajes pagados'
                          format='0,000.00%'
                          n={data.tripsResume14DBefore?.pct_trips_paid}
                        />
                        <Metric
                          title='% monto pagado'
                          format='0,000.00%'
                          n={data.tripsResume14DBefore?.pct_amount_paid}
                        />
                      </div>
                    </div>
                    <div className={stylesLayout["2cols"]}>
                      <div className={styles.block}>
                        {data?.tripsbyHour && (
                          <RenderLineChart
                            data={() =>
                              data.tripsbyHour.map((x) => ({
                                ...x,
                              }))
                            }
                            yAxisScale='linear'
                            xAxisType='linear'
                            showYAxisSelector
                            yAxisMin={0}
                            title='Viajes por hora'
                            width={100}
                            height={40}
                            xAxisStepSize={1}
                            xLabelsField='day_h'
                            yDatasets={{
                              "Viajes pagados": "count_paid",
                              "Viajes no pagados": "count_non_paid",
                            }}
                          />
                        )}
                      </div>
                      <div className={styles.block}>
                        {data?.tripsbyHour && (
                          <RenderLineChart
                            data={() =>
                              data.tripsbyHour.map((x) => ({
                                ...x,
                              }))
                            }
                            yAxisScale='linear'
                            xAxisType='linear'
                            showYAxisSelector
                            yAxisMin={0}
                            title='Monto pagado por hora'
                            width={100}
                            height={40}
                            xAxisStepSize={1}
                            xLabelsField='day_h'
                            yDatasets={{
                              "Monto pagado": "amount_paid",
                              "Monto no pagado": "amount_non_paid",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.block}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr auto",
                          gap: "var(--spacing)",
                        }}
                      >
                        <div>
                          <div>Rango Horario del mapa de calor</div>
                          <Slider
                            onChange={(v) => setHeatMapRange(v)}
                            range
                            value={heatMapRange}
                            min={0}
                            max={24}
                          />
                          <div style={{marginBottom: 40}}>
                            <Button
                              type='secondary'
                              size='small'
                              onClick={() => setHeatMapRange([0, 12])}
                            >
                              AM (0-12)
                            </Button>{" "}
                            <Button
                              type='secondary'
                              size='small'
                              onClick={() => setHeatMapRange([12, 24])}
                            >
                              PM (12-24)
                            </Button>{" "}
                            <Button
                              type='secondary'
                              size='small'
                              onClick={() => setHeatMapRange([12, 17])}
                            >
                              Mediodía-Tarde (12-17)
                            </Button>{" "}
                            <Button
                              type='secondary'
                              size='small'
                              onClick={() => setHeatMapRange([17, 24])}
                            >
                              Tarde-Noche (17-24)
                            </Button>
                          </div>
                          <div style={{marginBottom: 40}}>
                            <div>Tamaño de las marcas</div>
                            <Slider
                              onChange={(v) => setWeightFactor(v)}
                              defaultValue={weightFactor}
                              max={200}
                              min={0}
                            />
                          </div>
                        </div>
                        <div>
                          <div style={{marginBottom: 40}}>
                            <SketchPicker
                              color={mapColor}
                              onChangeComplete={(c) => {
                                console.log("color", c);
                                setMapColor(
                                  `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={stylesLayout["2cols"]}
                        style={{marginBottom: 20}}
                      >
                        {tripsStartMapCenter && (
                          <div>
                            <div className={styles.title}>
                              De {heatMapRange.join(" a ")} - Inicio de viaje (
                              {filteredTripDetails?.length} viajes)
                              {filterTripIds ? (
                                <>
                                  {". "}
                                  Filtrado{" "}
                                  <div
                                    className={stylesLayout.link}
                                    style={{display: "inline-block"}}
                                    onClick={() => setFilterTripIds(null)}
                                  >
                                    Quitar Filtro
                                  </div>
                                </>
                              ) : null}
                            </div>
                            <SimpleMapComponent
                              key='start'
                              zoom={14}
                              defaultCenter={{
                                lat: tripsStartMapCenter.latitude,
                                lng: tripsStartMapCenter.longitude,
                              }}
                              minZoom={1}
                              minimumClusterSize={10000}
                              maxZoom={20}
                              onClickBlockHeatmap={(block) =>
                                setFilterTripIds((s) => {
                                  const newIds = block?.items?.map((x) => x.id);
                                  if (s) {
                                    return s.filter((x) => newIds.includes(x));
                                  }
                                  return newIds;
                                })
                              }
                              googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places,visualization'
                              loadingElement={<div style={{height: 500}} />}
                              containerElement={
                                <div
                                  style={{
                                    height: 500,
                                  }}
                                />
                              }
                              mapElement={
                                <div style={{borderRadius: 5, height: 500}} />
                              }
                              blockHeatmap={{
                                data: filteredTripDetails
                                  ?.filter((x) => x.lat_start && x.lng_start)
                                  .map((x) => ({
                                    ...x,
                                    location: new window.google.maps.LatLng(
                                      x.lat_start,
                                      x.lng_start
                                    ),
                                    opacity: 1,
                                    color: mapColor,
                                    weight: x.total,
                                  })),
                                options: {
                                  delta: 0.001,
                                  weightFactor: 0.005 * weightFactor,
                                },
                              }}
                            />
                          </div>
                        )}
                        {tripsEndMapCenter && (
                          <div>
                            <div className={styles.title}>
                              De {heatMapRange.join(" a ")} - Fin de viaje (
                              {filteredTripDetails?.length} viajes)
                              {filterTripIds ? (
                                <>
                                  {". "}
                                  Filtrado{" "}
                                  <div
                                    className={stylesLayout.link}
                                    style={{display: "inline-block"}}
                                    onClick={() => setFilterTripIds(null)}
                                  >
                                    Quitar Filtro
                                  </div>
                                </>
                              ) : null}
                            </div>
                            <SimpleMapComponent
                              key='end'
                              zoom={14}
                              defaultCenter={{
                                lat: tripsEndMapCenter.latitude,
                                lng: tripsEndMapCenter.longitude,
                              }}
                              minZoom={1}
                              minimumClusterSize={10000}
                              maxZoom={20}
                              onClickBlockHeatmap={(block) =>
                                setFilterTripIds((s) => {
                                  const newIds = block?.items?.map((x) => x.id);
                                  console.log("newIds", newIds, s);
                                  if (s) {
                                    return s.filter((x) => newIds.includes(x));
                                  }
                                  return newIds;
                                })
                              }
                              googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places,visualization'
                              loadingElement={<div style={{height: 500}} />}
                              containerElement={
                                <div
                                  style={{
                                    height: 500,
                                  }}
                                />
                              }
                              mapElement={
                                <div style={{borderRadius: 5, height: 500}} />
                              }
                              blockHeatmap={{
                                data: filteredTripDetails
                                  ?.filter((x) => x.lat_end && x.lng_end)
                                  .map((x) => ({
                                    ...x,
                                    location: new window.google.maps.LatLng(
                                      x.lat_end,
                                      x.lng_end
                                    ),
                                    opacity: 1,
                                    color: mapColor,
                                    weight: x.total,
                                  })),
                                options: {
                                  delta: 0.001,
                                  weightFactor: 0.005 * weightFactor,
                                },
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.block}>
                    <div className={styles.toolsSec}>
                      <CSVLink
                        filename={`detalles-por-viaje-${range[0].format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}-${range[1].format("YYYY-MM-DD HH:mm:ss")}.csv`}
                        data={data.tripsDetails || []}
                      >
                        <Button type='secondary'>
                          <FontAwesomeIcon
                            icon={faDownload}
                            style={{marginRight: 5}}
                          />
                          Exportar como CSV
                        </Button>
                      </CSVLink>
                    </div>
                    <div className={styles.title}>
                      Detalle
                      {filterTripIds ? (
                        <>
                          {". "}
                          Filtrado{" "}
                          <div
                            className={stylesLayout.link}
                            style={{display: "inline-block"}}
                            onClick={() => setFilterTripIds(null)}
                          >
                            Quitar Filtro
                          </div>
                        </>
                      ) : null}
                    </div>

                    <Table
                      size='small'
                      pagination={{hideOnSinglePage: true, defaultPageSize: 25}}
                      locale={{emptyText: "Sin datos"}}
                      columns={[
                        {
                          title: "Id",
                          key: "trip_id",
                          dataIndex: "id",
                          render: (v) => <Link to={`/trip/${v}`}>{v}</Link>,
                        },
                        {
                          title: "Status",
                          key: "status",
                          dataIndex: "status",
                          render: (v) => v,
                        },
                        {
                          title: "Inicio",
                          dataIndex: "trip_start_at",
                          key: "trip_start_at",
                          align: "center",
                          render: (v) =>
                            v
                              ? dayjs
                                  .utc(v)
                                  .local()
                                  .format("YYYY-MM-DD HH:mm:ss")
                              : "",
                        },
                        {
                          title: "Fin",
                          dataIndex: "end_at",
                          key: "end_at",
                          align: "center",
                          render: (v) =>
                            v
                              ? dayjs
                                  .utc(v)
                                  .local()
                                  .format("YYYY-MM-DD HH:mm:ss")
                              : "",
                        },
                        {
                          title: "Nombre",
                          key: "name",
                          dataIndex: "name",
                          render: (v) => v,
                        },
                        {
                          title: "Email",
                          key: "email",
                          dataIndex: "email",
                          render: (v) => v,
                        },
                        {
                          title: "Nombre titular tarjeta",
                          key: "cardholder_name",
                          dataIndex: "cardholder_name",
                          render: (v) => v,
                        },
                        {
                          title: "Documento titular tarjeta",
                          key: "cardholder_id",
                          align: "right",
                          dataIndex: "cardholder_id",
                          render: (v) => v,
                        },
                        {
                          title: "Forma de pago",
                          key: "payment_method",
                          align: "right",
                          dataIndex: "payment_method",
                          render: (v) => v,
                        },
                        {
                          title: "Total pagado",
                          key: "total_paid",
                          align: "right",
                          dataIndex: "total_paid",
                          render: (v) => numeral(v).format("0,000.00"),
                        },
                        {
                          title: "Id viaje",
                          key: "trip_id",
                          align: "right",
                          dataIndex: "trip_id",
                          render: (v) => v,
                        },
                        {
                          title: "Id Pago",
                          key: "payment_id",
                          align: "right",
                          dataIndex: "payment_id",
                          render: (v) => v,
                        },

                        {
                          title: "Id PSP",
                          key: "psp_id",
                          align: "right",
                          dataIndex: "psp_id",
                          render: (v) => v,
                        },
                        {
                          title: "PSP",
                          key: "psp",
                          align: "right",
                          dataIndex: "psp",
                          render: (v) => v,
                        },
                        {
                          title: "Fecha pago",
                          dataIndex: "paid_at",
                          key: "paid_at",
                          align: "center",
                          render: (v) =>
                            v
                              ? dayjs
                                  .utc(v)
                                  .local()
                                  .format("YYYY-MM-DD HH:mm:ss")
                              : "",
                        },
                        {
                          title: "",
                          dataIndex: "",
                          key: "actions",
                          render: (x, r) => (
                            <Link to={`/trip/${r.id}`}>
                              <Button>Ver</Button>
                            </Link>
                          ),
                        },
                      ]}
                      dataSource={filteredTripDetails?.map((d) => ({
                        key: d.id,
                        ...d,
                      }))}
                    />
                  </div>
                </>
              )}
            </TabPane>
            <TabPane tab='Viajes pagados' key='trips_paid'>
              {loading ? (
                <div className={stylesLayout.page}>
                  <Loading />
                </div>
              ) : (
                <>
                  <div className={styles.block}>
                    <div className={styles.title}>Resumen por día</div>
                    <div style={{marginBottom: 20}}>
                      Considera todos los viajes pagados con monto mayor a 0.{" "}
                      <strong>Iniciados</strong> en el rango de fecha elegido
                    </div>
                    <Table
                      size='small'
                      pagination={{
                        hideOnSinglePage: true,
                        defaultPageSize: 500,
                      }}
                      locale={{emptyText: "Sin datos"}}
                      columns={[
                        {
                          title: "Día",
                          key: "day",
                          width: 120,
                          dataIndex: "day",
                          render: (day) => day,
                        },

                        {
                          title: "Flota",
                          key: "fleet_name",
                          dataIndex: "fleet_name",
                          render: (fleetName) => fleetName,
                        },
                        {
                          title: "Viajes",
                          key: "trips",
                          align: "right",
                          dataIndex: "trips",
                          render: (trips) => trips,
                        },
                        {
                          title: "Clientes",
                          key: "buyers",
                          align: "right",
                          dataIndex: "buyers",
                          render: (buyers) => buyers,
                        },
                        {
                          title: "Patines usados",
                          key: "devices",
                          align: "right",
                          dataIndex: "devices",
                          render: (v) => numeral(v).format("0,000"),
                        },

                        {
                          title: "Total Pagado ($)",
                          key: "total_paid",
                          align: "right",
                          dataIndex: "total_paid",
                          render: (totalPaid) => totalPaid,
                        },
                        {
                          title: "Tiempo promedio (m)",
                          key: "avg_minutes",
                          align: "center",
                          dataIndex: "avg_minutes",
                          render: (v) => numeral(v).format("0,000.0"),
                        },
                        {
                          title: "Viaje promedio ($)",
                          key: "avg_total_paid",
                          align: "right",
                          dataIndex: "avg_total_paid",
                          render: (v) => numeral(v).format("0,000.00"),
                        },
                      ]}
                      dataSource={data.trips?.map((d) => ({
                        key: d.id,
                        ...d,
                      }))}
                    />
                  </div>
                  <div className={styles.block}>
                    <div className={styles.toolsSec}>
                      <CSVLink
                        filename={`detalles-viaje-${range[0].format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}-${range[1].format("YYYY-MM-DD HH:mm:ss")}.csv`}
                        data={data.tripsPaidDetails || []}
                      >
                        <Button type='secondary'>
                          <FontAwesomeIcon
                            icon={faDownload}
                            style={{marginRight: 5}}
                          />
                          Exportar como CSV
                        </Button>
                      </CSVLink>
                    </div>
                    <div className={styles.title}>
                      Detalle viajes pagados (por fecha de pago) (
                      {data.tripsPaidDetails?.length})
                    </div>
                    <div style={{marginBottom: 20}}>
                      Considera todos los viajes pagados con monto mayor a 0.{" "}
                      <strong>Pagados</strong> en el rango de fecha elegido
                    </div>

                    <Table
                      size='small'
                      pagination={{
                        hideOnSinglePage: true,
                        defaultPageSize: 100,
                      }}
                      locale={{emptyText: "Sin datos"}}
                      columns={[
                        {
                          title: "Nombre",
                          key: "name",
                          dataIndex: "name",
                          render: (v) => v,
                        },
                        {
                          title: "Email",
                          key: "email",
                          dataIndex: "email",
                          render: (v) => v,
                        },
                        {
                          title: "Nombre titular tarjeta",
                          key: "cardholder_name",
                          dataIndex: "cardholder_name",
                          render: (v) => v,
                        },
                        {
                          title: "Tipo Documento titular tarjeta",
                          key: "cardholder_id_type",
                          align: "center",
                          dataIndex: "cardholder_id_type",
                          render: (v) => v,
                        },
                        {
                          title: "Documento titular tarjeta",
                          key: "cardholder_id",
                          align: "right",
                          dataIndex: "cardholder_id",
                          render: (v) => v,
                        },
                        {
                          title: "Forma de pago",
                          key: "payment_method",
                          align: "right",
                          dataIndex: "payment_method",
                          render: (v) => v,
                        },
                        {
                          title: "Total pagado",
                          key: "total_paid",
                          align: "right",
                          dataIndex: "total_paid",
                          render: (v) => v,
                        },
                        {
                          title: "Id viaje",
                          key: "trip_id",
                          align: "right",
                          dataIndex: "trip_id",
                          render: (v) => v,
                        },
                        {
                          title: "Id Pago",
                          key: "payment_id",
                          align: "right",
                          dataIndex: "payment_id",
                          render: (v) => v,
                        },

                        {
                          title: "Id PSP",
                          key: "psp_id",
                          align: "right",
                          dataIndex: "psp_id",
                          render: (v) => v,
                        },
                        {
                          title: "PSP",
                          key: "psp",
                          align: "right",
                          dataIndex: "psp",
                          render: (v) => v,
                        },
                        {
                          title: "Pais del pago",
                          key: "payment_country",
                          align: "center",
                          dataIndex: "payment_country",
                          render: (v) => v,
                        },
                        {
                          title: "Fecha pago",
                          dataIndex: "paid_at",
                          key: "paid_at",
                          align: "center",
                          render: (v) =>
                            dayjs.utc(v).local().format("YYYY-MM-DD HH:mm:ss"),
                        },
                        {
                          title: "Inicio viaje",
                          dataIndex: "trip_start_at",
                          key: "trip_date",
                          align: "center",
                          render: (v) =>
                            dayjs.utc(v).local().format("YYYY-MM-DD HH:mm:ss"),
                        },
                        {
                          title: "Fin viaje",
                          dataIndex: "payment_date",
                          key: "trip_end_at",
                          align: "center",
                          render: (v) =>
                            dayjs.utc(v).local().format("YYYY-MM-DD HH:mm:ss"),
                        },
                      ]}
                      dataSource={data.tripsPaidDetails?.map((d) => ({
                        key: d.id,
                        ...d,
                      }))}
                    />
                  </div>
                </>
              )}
            </TabPane>

            <TabPane
              tab='Patines con movimientos'
              key='devices_with_status_changes'
            >
              {loading ? (
                <div className={stylesLayout.page}>
                  <Loading />
                </div>
              ) : (
                <>
                  <div className={styles.block}>
                    <div className={styles.title}>Patines con movimientos</div>
                    <Table
                      size='small'
                      pagination={{
                        hideOnSinglePage: true,
                        defaultPageSize: 500,
                      }}
                      locale={{emptyText: "Sin datos"}}
                      columns={[
                        {
                          title: "Día",
                          width: 120,
                          key: "day",
                          dataIndex: "day",
                          render: (day) => day,
                        },
                        {
                          title: "Flota",
                          key: "fleet_name",
                          dataIndex: "fleet_name",
                          render: (fleetName) => fleetName,
                        },
                        {
                          title: "En bodega",
                          key: "in_warehouse",
                          align: "right",
                          dataIndex: "in_warehouse",
                          render: (v) => v,
                        },
                        {
                          title: "En transporte a deploy",
                          key: "in_transport_to_deploy",
                          dataIndex: "in_transport_to_deploy",
                          align: "right",
                          render: (v) => v,
                        },
                        {
                          title: "En transporte a bodega",
                          key: "in_transport_to_warehouse",
                          dataIndex: "in_transport_to_warehouse",
                          align: "right",
                          render: (v) => v,
                        },
                        {
                          title: "Desplegado",
                          key: "live",
                          dataIndex: "live",
                          align: "right",
                          render: (v) => v,
                        },
                        {
                          title: "En viaje",
                          key: "in_ride",
                          dataIndex: "in_ride",
                          align: "right",
                          render: (v) => v,
                        },
                        {
                          title: "Reservado",
                          key: "reserved",
                          dataIndex: "reserved",
                          align: "right",
                          render: (v) => v,
                        },
                        {
                          title: "En bodega",
                          key: "in_warehouse",
                          align: "right",
                          dataIndex: "in_warehouse",
                          render: (v) => v,
                        },
                        {
                          title: "Sospecha de robo",
                          key: "stolen_suspect",
                          dataIndex: "stolen_suspect",
                          align: "right",
                          render: (v) => v,
                        },
                        {
                          title: "Robado",
                          key: "stolen",
                          dataIndex: "stolen",
                          align: "right",
                          render: (v) => v,
                        },
                        {
                          title: "Dañado",
                          key: "damaged",
                          dataIndex: "damaged",
                          align: "right",
                          render: (v) => v,
                        },
                        {
                          title: "Recuperado",
                          key: "recovered",
                          dataIndex: "recovered",
                          align: "right",
                          render: (v) => v,
                        },
                      ]}
                      dataSource={data.devicesByStatusDay?.map((d) => ({
                        key: d.id,
                        ...d,
                      }))}
                    />
                  </div>
                </>
              )}
            </TabPane>
          </Tabs>
        </>
      </div>
    </div>
  );
};

DashboardModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};

export default DashboardModule;
