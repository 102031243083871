/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  Form, Input, Button, notification,
} from 'antd';

import styles from './index.module.scss';
import stylesForm from '../../common/form.module.scss';

import { register, assignPromocodeToUser } from '../../services/users';

const PreregisterModule = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const doPreregister = async (values) => {
    setLoading(true);
    const rsp = await register({
      email: values.email,
      registration_complete: 0,
      data: { country: 'CL' },
    });
    if (rsp?.status) {
      notification.success({
        message: `Usuario ${values.email} registrado correctamente`,
        placement: 'bottom',
        key: 'result',
      });
      form.setFieldsValue({ email: '' });
      assignPromocodeToUser(rsp.data.id, 16);
    } else {
      notification.error({
        message: 'Error al crear el usuario.',
        placement: 'bottom',
        key: 'result',
      });
    }
    setLoading(false);
  };

  return (
    <div className={styles.cnt}>
      <div className={styles.login}>
        <div className={`${styles.form} ${stylesForm.form}`}>
          <div className={stylesForm.fields}>
            <Form
              name="basic"
              form={form}
              initialValues={{ email: '' }}
              layout="vertical"
              onFinish={doPreregister}
              size="large"
            >
              <Form.Item
                label=""
                name="email"
                rules={[{ required: true, message: 'El email es requerido' }]}
              >
                <Input placeholder="Email" type="email" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={loading}>
                  Registrar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

PreregisterModule.defaultProps = {
};

PreregisterModule.propTypes = {
};

export default PreregisterModule;
