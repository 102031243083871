import React from 'react';
import PropTypes from 'prop-types';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconLinkMap = ({ lat, lng }) => (
  <a href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`} target="_map">
    <FontAwesomeIcon icon={faExternalLinkAlt} />
    {' '}
    Ver en mapa
  </a>
);

IconLinkMap.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

export default IconLinkMap;
