export default (client, defaultParams) => ({
  createGeofence: (payload) => (
    client('/geofences', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  getForFleet: (fleetIds, statuses) => (
    client(`/admin/geofences/fleet/${fleetIds.join(',')}/${statuses.join(',')}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  getGeofencesAndPointsForFleet: (fleetIds, statuses) => (
    client(`/admin/geofences_and_points/fleet/${fleetIds.join(',')}/${statuses.join(',')}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  setGeofencesPoints: (id, points) => (
    client(`/geofence/${id}/points`, {
      method: 'PUT',
      data: { points },
      params: {
        ...defaultParams,
      },
    })
  ),
  updateGeofence: (id, payload) => (
    client(`/geofence/${id}`, {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),

  isAllowePointInGeofencesForFleet: (fleetId, lat, lng) => (
    client(`/geofences/fleet/${fleetId}/allows/${lat},${lng}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  getMessage: (k) => {
    const messages = {
      '': '',
    };
    return (messages[k] || k);
  },
});
