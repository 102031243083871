export default (client, defaultParams) => ({
  getAlerts: ({ status = 'NEW', fleetId }) => (
    client(`/alerts/status/${status}/${fleetId}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  setAlertStatus: ({ id, status = 'NEW', deviceStatusAfterSolve = null }) => (
    client(`/alert/${id}/status/${status}?${deviceStatusAfterSolve ? `device_status=${deviceStatusAfterSolve}` : null}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  getMessage: (k) => {
    const messages = {
      '': '',
    };
    return (messages[k] || k);
  },
});
