/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCopy,
  faStar,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import numeral from 'numeral';
import {
  notification, Button, Tabs, Input, Space, Popconfirm, Select, DatePicker,
} from 'antd';

import UploaderFile from '../../components/UploaderFile';

import styles from './index.module.scss';
import Loading from '../../components/Loading';
import TripsTable from '../../components/TripsTable';
import PromocodesTable from '../../components/PromocodesTable';
import CardsTable from '../../components/CardsTable';
import UserRolesAndFleet from '../UserRolesAndFleet';

import {
  getById,
  createPromocodeForUser,
  setUserStatus,
  setCardStatus,
  modifyPassword,
  updatePaymentMultiplier,
  updatePhone,
  updateDni,
  updateContract,
  updateCountry,
  updatePhoto,
} from '../../services/users';

import {
  getTripQualificationColor, humanFileSize,
} from '../../helpers/utils';

import { contractTypesTranslations } from '../../helpers/params';

import stylesLayout from '../../common/layout.module.scss';
import Section from '../../components/Section';
import SectionTitle from '../../components/SectionTitle';
import CopyWithMessage from '../../components/CopyWithMessage';

const { TabPane } = Tabs;

const CustomerModule = ({ user, fleets }) => {
  const { id: idParam } = useParams();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [newMultiplier, setNewMultiplier] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newCountry, setNewCountry] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [newDni, setNewDni] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const loadCustomer = async (id, showNotification) => {
    if (showNotification) {
      notification.info({
        message: 'Actualizando cliente...',
        placement: 'top',
        key: 'result',
        duration: 0,
      });
    }
    const rsp = await getById(id);
    if (rsp && rsp.status) {
      const newCustomer = rsp.data;
      if (customer?.events) {
        newCustomer.events = customer.events;
      }
      if (customer?.customer_events) {
        newCustomer.customer_events = customer.customer_events;
      }

      setCustomer(newCustomer);
      setNewMultiplier(newCustomer.data && ('payment_multiplier' in newCustomer.data) ? newCustomer.data.payment_multiplier : 1);
      setNewPhone(newCustomer.phone || ' ');
      setNewDni(newCustomer.data?.dni || ' ');
      setNewCountry(newCustomer.data?.country || ' ');
      if (showNotification) {
        notification.success({
          message: 'Cliente actualizado.',
          placement: 'top',
          key: 'result',
        });
      }
    } else if (showNotification) {
      notification.error({
        message: 'Error al actualizar cliente.',
        placement: 'top',
        key: 'result',
      });
    }
    setLoading(false);
  };

  const changePhone = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando teléfono...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await updatePhone(customer.id, newPhone);
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Teléfono cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar teléfono.',
        placement: 'top',
        key: 'result',
      });
    }
  };
  const changeDni = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando documento de identidad...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await updateDni(customer.id, newDni);
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Documento de identidad cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar Documento de identidad.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const updateContractField = (field, value) => {
    setCustomer((s) => {
      const newS = s;
      if (!newS.data) {
        newS.data = {};
      }
      newS.data.contract = {
        ...(newS.data?.contract || {}),
        [field]: value,
      };
      return newS;
    });
  };
  const changeContract = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando contrato...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await updateContract(customer.id,
      (customer.data?.contract || {}));
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Contrato cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar contrato.',
        placement: 'top',
        key: 'result',
      });
    }
  };
  const changeCountry = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando país...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await updateCountry(customer.id, newCountry.trim());
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'País cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar país.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const changePaymentMultiplier = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando multiplicador...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await updatePaymentMultiplier(customer.id, parseFloat(newMultiplier));
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Multiplicador cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar multiplicador.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const changePhoto = async (file) => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Guardando foto...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await updatePhoto(customer.id, file);
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Foto guardada.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al guardar foto.',
        placement: 'top',
        key: 'result',
      });
    }
  };
  const changePassword = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando contraseña...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await modifyPassword({ user_id: customer.id, password: newPassword });
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Contraseña cambiada.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar contraseña.',
        placement: 'top',
        key: 'result',
      });
    }
  };
  const onChangeStatus = async (newStatus) => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando estado...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await setUserStatus(customer.id, newStatus);
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Estado cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar estado.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const onChangeCardStatus = async (cardId, newStatus) => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando estado de tarjeta...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await setCardStatus(cardId, newStatus);
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Estado de tarjeta cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar estado de tarjeta.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const onCreatePromocode = async (payload) => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Creando código promocional...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });

    const rsp = await createPromocodeForUser({
      valid_for_user_id: customer.id,
      duration_days: payload.duration_days,
      data: payload.data,
    });
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Código promocional creado con éxito y asignado al usuario.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al crear código promocional.',
        placement: 'top',
        key: 'result',
      });
    }
  };
  useEffect(() => {
    loadCustomer(idParam);
  }, [idParam]);

  if (loading) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading />
        </div>
      </div>
    );
  }
  if (!customer) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          Cliente no encontrado
        </div>
      </div>
    );
  }

  const nPaidTrips = customer?.all_trips?.filter((x) => x.status === 'PAID').length || 0;

  return (
    <div className={styles.cnt}>
      <div className={stylesLayout.page}>
        <div className={stylesLayout.breadcrumb}>
          <Link to="/trips">Usuarios y Viajes</Link>
          {' '}
          &raquo;
        </div>
        <div className={stylesLayout.title} style={{ display: 'grid', gap: 10, gridTemplateColumns: '1fr auto' }}>
          <div>
            #
            {customer.id}
            {' '}
            -
            {' '}
            {customer.email}
            <FontAwesomeIcon
              icon={faStar}
              style={{
                display: 'inline-block',
                marginLeft: 5,
                color: getTripQualificationColor(
                  customer.grin_qualification,
                ),
              }}
            />
            <br />
            <small>
              {customer.name}
              {' '}
              (
              {customer.status_tr}
              )
              {customer.data?.dni && (
                <>
                  <br />
                  {customer.data?.dni}
                </>
              )}
            </small>
          </div>
          {customer?.data?.photo?.private_url && (
            <div className={styles.userPhoto} style={{ backgroundImage: `url(${customer.data?.photo?.private_url})` }} />
          )}

        </div>
        <div className={stylesLayout.tools}>
          {customer.status === 'ACTIVE'
            && (
              <>
                <Button onClick={() => onChangeStatus('INACTIVE')}>Desactivar usuario</Button>
                {' '}
                <Popconfirm
                  placement="bottom"
                  title="¿Confirmas que quieres marcar como usuario fraudulento?"
                  onConfirm={() => onChangeStatus('FRAUD')}
                  okText="Si"
                  okButtonProps={{
                    type: 'danger',
                    danger: true,
                  }}
                  cancelText="No"
                >
                  <Button danger>Marcar como usuario fraudulento</Button>
                </Popconfirm>
              </>
            )}
          {['DELETED', 'INACTIVE', 'FRAUD'].includes(customer.status)
            && (
              <Button onClick={() => onChangeStatus('ACTIVE')}>Activar usuario</Button>
            )}
        </div>
        <div className={[stylesLayout.content, styles.main].join(' ')}>

          <div className={styles.info}>
            <table>

              <tr>
                <td width="25%">Registrado</td>
                <td>
                  {moment.utc(customer.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
                  {' - '}
                  {customer.registration_complete === 1 ? 'Registro completo' : 'Registro incompleto'}
                </td>
              </tr>
              <tr>
                <td width="25%">Terminos y privacidad aceptados</td>
                <td>
                  {customer.terms_accepted_version_at ? (
                    <>
                      Términos de uso:
                      {' '}
                      {customer.terms_accepted_version}
                      {' '}
                      -
                      {' '}
                      {moment.utc(customer.terms_accepted_version_at).local().format('YYYY-MM-DD HH:mm:ss')}
                      <br />
                      Privacidad:
                      {' '}
                      {customer.privacy_accepted_version}
                      {' '}
                      -
                      {' '}
                      {moment.utc(customer.privacy_accepted_version_at).local().format('YYYY-MM-DD HH:mm:ss')}

                    </>
                  ) : 'No'}
                </td>
              </tr>

              {customer.is_internal && user.can.manage_users
                && (
                  <tr>
                    <td width="25%">Contraseña</td>
                    <td>
                      <Space direction="horizontal">
                        <Input.Password
                          autoComplete="off"
                          value={newPassword}
                          id="customer-new_p"
                          onChange={(e) => setNewPassword(e.target.value)}
                          style={{ width: 300 }}
                          visibilityToggle={{
                            visible: passwordVisible,
                            onVisibleChange: setPasswordVisible,
                          }}
                        />
                        {!!newPassword && (
                          <CopyWithMessage
                            inline
                            value={newPassword}
                            messageCopied="Contraseña copiada!"
                            messageTime={2000}

                          >
                            <FontAwesomeIcon icon={faCopy} style={{ fontSize: 16 }} />
                          </CopyWithMessage>
                        )}
                        <Button onClick={changePassword}>Cambiar</Button>
                      </Space>
                      <br />
                      <a
                        onClick={() => {
                          setPasswordVisible(true);
                          setNewPassword(String(parseInt(100000 + Math.random() * 999999, 10)));
                        }}
                      >
                        Generar contraseña aleatoria
                      </a>
                    </td>
                  </tr>
                )}
              <tr>
                <td width="25%">Teléfono</td>
                <td>
                  <Space direction="horizontal" align="start">
                    {user.can.manage_users
                      ? (
                        <div>
                          <Input
                            value={newPhone}
                            autoComplete="off"
                            id="customer-tel"
                            onChange={(e) => setNewPhone(e.target.value)}
                            style={{ width: 300 }}
                          />
                        </div>
                      )
                      : newPhone}
                    {user.can.manage_users
                      && <Button onClick={changePhone}>Cambiar</Button>}
                  </Space>
                </td>
              </tr>

              <tr>
                <td width="25%">País del usuario</td>
                <td>
                  <Space direction="horizontal">
                    {user.can.manage_users
                      ? (
                        <div>
                          <Input
                            value={newCountry}
                            autoComplete="off"
                            id="x-customer-country"
                            onChange={(e) => setNewCountry(e.target.value)}
                            style={{ width: 300 }}
                          />
                        </div>
                      )
                      : customer.data?.country}
                    {user.can.manage_users
                      && <Button onClick={changeCountry}>Cambiar</Button>}
                  </Space>
                </td>
              </tr>
              <tr>
                <td width="25%">ID de Mercadopago</td>
                <td>
                  {customer.mp_customer_id ? 'SI' : 'NO'}
                </td>
              </tr>
              <tr>
                <td width="25%">
                  Multiplicador de cobro de viaje
                  {user.can.manage_users
                    && (
                      <>
                        <br />
                        <small>
                          * Todos los cobros del usuario se multiplicarán por este
                          {' '}
                          factor. Usar con precaución
                        </small>
                      </>
                    )}
                </td>
                <td>
                  <Space direction="horizontal" align="start">
                    {user.can.manage_users
                      ? (
                        <div>
                          <Input
                            value={newMultiplier}
                            onChange={(e) => setNewMultiplier(e.target.value)}
                            style={{ width: 300 }}
                          />
                          <br />
                          <small>* Usar punto como separador decimal ej: 1.5</small>
                        </div>
                      )
                      : newMultiplier}
                    {user.can.manage_users
                      && <Button onClick={changePaymentMultiplier}>Cambiar</Button>}
                  </Space>
                </td>
              </tr>
              <tr>
                <td width="25%">Total pagado</td>
                <td>
                  {numeral(customer.all_trips?.filter((x) => x.status === 'PAID').map((x) => x.cost_calculated?.details.payment_total).reduce((a, x) => a + x, 0) || 0).format('$0,000.00')}
                  {' ('}
                  {nPaidTrips}
                  {' '}
                  viaje
                  {nPaidTrips !== 1 ? 's' : ''}
                  )
                </td>
              </tr>
              <tr>
                <td width="25%">
                  Documento de identidad
                  <br />
                  <small>
                    RUT/DNI/Pasaporte/Etc.
                  </small>

                </td>
                <td>
                  <Space direction="horizontal" align="start">
                    {user.can.manage_users
                      ? (
                        <div>
                          <Input
                            value={newDni}
                            autoComplete="off"
                            id="customer-dni"
                            onChange={(e) => setNewDni(e.target.value)}
                            style={{ width: 300 }}
                          />
                        </div>
                      )
                      : newPhone}
                    {user.can.manage_users
                      && <Button onClick={changeDni}>Cambiar</Button>}
                  </Space>
                </td>
              </tr>
              <tr>
                <td width="25%">Foto</td>
                <td>
                  <div>
                    <UploaderFile
                      onUpload={({ file }) => { changePhoto(file); }}
                      render={({
                        uploading, progress,
                      }) => (
                        <>
                          {!uploading && (
                            <div className={stylesLayout.uploader}>
                              <div className={stylesLayout.uploaderInner}>
                                <FontAwesomeIcon icon={faUpload} style={{ marginRight: 10 }} />
                                {' '}
                                Haz click o arrastra un nuevo archivo aquí
                              </div>
                            </div>
                          )}
                          {uploading && progress && progress.map((p) => (
                            <div className={styles.fileDetail}>
                              <div className={styles.fileDetailProgress} style={{ width: `${p.percent}%` }} />
                              <div className={styles.fileDetailInner}>
                                {p.percent >= 100
                                  ? <FontAwesomeIcon icon={faCheck} color="#093" style={{ marginRight: 10, color: '#093' }} />
                                  : <FontAwesomeIcon icon={faUpload} color="#999" style={{ marginRight: 10, color: '#999' }} />}
                                <div className={styles.fileDetailName}>
                                  <span style={{
                                    display: 'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '80%',
                                  }}
                                  >
                                    {p.name}

                                  </span>
                                  {p.percent >= 100 && <span> - Guardando...</span>}
                                </div>
                                <div className={styles.fileDetailSize}>
                                  {humanFileSize((p.size * p.percent) / 100)}
                                  {' '}
                                  de
                                  {' '}
                                  {humanFileSize(p.size)}
                                </div>
                                <div className={styles.fileDetailPercent}>
                                  {parseInt(p.percent, 10)}
                                  %
                                </div>

                              </div>

                            </div>
                          ))}
                        </>
                      )}
                    />
                  </div>
                </td>
              </tr>
              {user.can?.executive_grin4u && (
                <tr>
                  <td width="25%">Información Grin 4U</td>
                  <td>
                    <Link to={`/grin4u/customer/${customer.id}`}>Ver información</Link>
                  </td>
                </tr>
              )}

            </table>
            <br />
            <br />
            <Tabs className={styles.tabs}>
              <TabPane
                tab={(
                  <>
                    Viajes
                  </>
                )}
                key="trips"
              >
                {customer.current_trips?.length > 0
                  && (
                    <Section>
                      <SectionTitle>Viajes en curso</SectionTitle>
                      <TripsTable trips={customer.current_trips} show={['trip']} />
                    </Section>
                  )}
                {customer.unpaid_trips?.length > 0
                  && (
                    <Section>
                      <SectionTitle>Viajes pendientes de pago</SectionTitle>
                      <TripsTable trips={customer.unpaid_trips} show={['trip']} />
                    </Section>
                  )}
                <Section>
                  <SectionTitle>
                    Todos los viajes (total
                    {' '}
                    {customer.all_trips.length}
                    {' - '}
                    pagado
                    {nPaidTrips !== 1 ? 's' : ''}
                    {' '}
                    {nPaidTrips}

                    )
                  </SectionTitle>
                  <TripsTable trips={customer.all_trips} show={['trip']} />
                </Section>
              </TabPane>
              <TabPane
                tab={(
                  <>
                    Tarjetas
                  </>
                )}
                key="cards"
              >
                <Section>
                  <CardsTable
                    cards={customer.cards}
                    onChangeStatus={onChangeCardStatus}
                  />
                </Section>
              </TabPane>
              <TabPane
                tab={(
                  <>
                    Códigos promocionales
                  </>
                )}
                key="promocodes"
              >
                <Section>
                  <PromocodesTable
                    show={['table', user?.can?.create_promocodes ? 'form' : '']}
                    promocodes={customer.active_promocodes}
                    onCreatePromocode={onCreatePromocode}
                  />
                </Section>
              </TabPane>
              {customer.is_internal
                && (
                  <TabPane
                    tab={(
                      <>
                        Roles y flotas
                      </>
                    )}
                    key="roles"
                  >
                    <UserRolesAndFleet
                      customer={customer}
                      fleets={fleets}
                      canModify={customer.id !== user.id}
                      onUpdate={() => loadCustomer(customer.id)}
                    />
                  </TabPane>
                )}

              {customer.is_internal && (user.can.manage_hr || user.can.manage_employees)
                && (
                  <TabPane
                    tab={(
                      <>
                        Contratación
                      </>
                    )}
                    key="contract"
                  >
                    <div className={styles.info}>
                      <table>
                        <tr>
                          <td width="25%">Tipo de contrato</td>
                          <td>
                            {(user.can.manage_hr)
                              ? (
                                <Space direction="horizontal">
                                  <Select
                                    placeholder="Selecciona..."
                                    options={['BOLETA_HONORARIOS', 'CONTRATO_PLAZO_FIJO', 'CONTRATO_INDEFINIDO'].map((x) => ({
                                      label: contractTypesTranslations[x] ?? x, value: x,
                                    }))}
                                    style={{ width: 200 }}
                                    defaultValue={customer.data?.contract?.type || ''}
                                    onChange={(v) => updateContractField('type', v)}
                                  />
                                </Space>
                              )
                              : (contractTypesTranslations[customer.data?.contract?.type] ?? customer.data?.contract?.type) || 'Sin info'}
                          </td>
                        </tr>
                        <tr>
                          <td width="25%">Fecha de inicio</td>
                          <td>
                            {(user.can.manage_hr)
                              ? (
                                <Space direction="horizontal">
                                  <DatePicker
                                    size="large"
                                    style={{ width: 300 }}
                                    defaultValue={customer.data?.contract?.start_at
                                      ? dayjs(customer.data?.contract?.start_at) : undefined}
                                    format="YYYY-MM-DD"
                                    onChange={(v) => updateContractField('start_at', v ? dayjs(v).format('YYYY-MM-DD') : null)}
                                  />
                                </Space>
                              )
                              : (customer.data?.contract?.start_at
                                ? dayjs(customer.data?.contract?.start_at).format('YYYY-MM-DD') : 'Sin info')}
                          </td>
                        </tr>
                        <tr>
                          <td width="25%">Fecha de fin</td>
                          <td>
                            {(user.can.manage_hr)
                              ? (
                                <Space direction="horizontal">
                                  <DatePicker
                                    size="large"
                                    style={{ width: 300 }}
                                    defaultValue={customer.data?.contract?.end_at
                                      ? dayjs(customer.data?.contract?.end_at) : undefined}
                                    format="YYYY-MM-DD"
                                    onChange={(v) => updateContractField('end_at', v ? dayjs(v).format('YYYY-MM-DD') : null)}
                                  />

                                </Space>
                              )
                              : (customer.data?.contract?.end_at
                                ? dayjs(customer.data?.contract?.end_at).format('YYYY-MM-DD') : 'Sin info')}
                          </td>
                        </tr>
                      </table>
                      {(user.can.manage_hr)
                        && (
                          <div style={{ marginTop: 20 }}>
                            <Button onClick={changeContract}>Guardar</Button>
                          </div>
                        )}
                    </div>
                  </TabPane>
                )}
            </Tabs>

          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

CustomerModule.propTypes = {
  user: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
};

export default CustomerModule;
