/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Table } from 'antd';
import { SimpleMapComponent } from '../../components/SimpleMapComponent';

import * as horusService from '../../services/horus';

import styles from './index.module.scss';
import Loading from '../../components/Loading';

// const MAX = 1000;

const QueryModule = ({
  fleets,
  fleetId,
}) => {
  const [mapPosition, setMapPosition] = useState(null);
  const responseRef = useRef(null);
  const [query, setQuery] = useState("select floor(lat*1000)/1000 lat, floor(lng*1000)/1000 lng, count(*) n from devices d where d.fleet_id=1 and d.status='live' group by 1;");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    const selectedFleet = fleets.find((x) => x.id === fleetId);
    setMapPosition({
      latitude: selectedFleet.cost.headquarter.lat,
      longitude: selectedFleet.cost.headquarter.lng,
    });
  }, [fleetId]);

  const execute = async () => {
    setError(null);
    setLoading(true);
    setResponseData(null);
    const rsp = await horusService.executeQuery(query);
    if (rsp?.status) {
      setResponseData(rsp.data);
    } else {
      setError(rsp);
    }
    setLoading(false);
  };

  const blocks = [];
  const points = [];

  let columns = [];
  if (responseData) {
    columns = Object.keys(responseData[0]).map((x) => (
      {
        title: x,
        dataIndex: x,
        key: x,
        ellipsis: true,
        width: 'auto',
      }
    ));
  }
  return (
    <>
      <div className={[styles.main].join(' ')}>
        <div className={styles.query}>
          <div className={styles.input}>
            <Input.TextArea
              style={{ resize: 'none' }}
              className={styles.input_area}
              defaultValue={query}
              onChange={(e) => { setQuery(e.target.value); }}
            />
          </div>
          <div className={styles.action}>
            <Button type="primary" onClick={execute}>Ejecutar</Button>
          </div>
        </div>
        <div className={styles.response} ref={responseRef}>
          {loading && <div style={{ padding: 50, textAlign: 'center' }}><Loading /></div>}
          {responseData && (
            <>
              <Table
                size="small"
                pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
                locale={{ emptyText: 'Sin datos' }}
                columns={columns}
                scroll={{ y: responseRef?.current?.offsetHeight }}
                dataSource={responseData.map((r, ix) => ({
                  key: r.id || ix,
                  ...r,
                }))}
              />

            </>
          )}
        </div>
      </div>
    </>
  );
};
QueryModule.propTypes = {
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};
export default QueryModule;
