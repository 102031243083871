/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useEffect, useState} from "react";
import moment from "moment";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faSyncAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import numeral from "numeral";
import isMobile from "is-mobile";
import {Button, Select} from "antd";
import * as ingestService from "../../services/ingest";
import {RenderLineChart} from "../../components/Charts";

import Loading from "../../components/Loading";

import styles from "./index.module.scss";
import stylesLayout from "../../common/layout.module.scss";
import dayjs from "dayjs";

const SystemModule = () => {
  const [loading, setLoading] = useState(true);
  const [statsMinutes, setStatsMinutes] = useState(60);
  const [ingestMetrics, setIngestMetrics] = useState(null);
  const [prometheusLoad, setPrometheusLoad] = useState(null);

  const loadIngestMetrics = async () => {
    const rsp = await ingestService.metrics();
    if (rsp?.status) {
      setIngestMetrics(rsp.data);
    }
  };
  const loadPrometheusLoad = async () => {
    const t = new Date().getTime() / 1000;
    const rsp = await ingestService.prometheusLoad(
      t - statsMinutes * 60,
      t,
      Math.max(Math.floor(statsMinutes / 10), 15)
    );
    if (rsp?.status) {
      const pl = rsp.data.data.result[0].values.map((x, i) => ({
        ts: dayjs(x[0] * 1000).format("YYYY-MM-DD HH:mm:ss"),
        load1: x[1],
        load2: rsp.data.data.result[1].values[i][1],
      }));
      setPrometheusLoad(pl);
    }
  };
  const load = async () => {
    setLoading(true);
    await Promise.all([loadIngestMetrics(), loadPrometheusLoad()]);
    setLoading(false);
  };

  useEffect(() => {
    load();
    const pid = window.setInterval(() => load(), 10000);
    return () => {
      window.clearInterval(pid);
    };
  }, [statsMinutes]);
  const isHeartbeatOK =
    ingestMetrics?.status.heartbeat.pending_process <= 80 &&
    ingestMetrics?.status.heartbeat.last_avg_exec_ms <= 1000;
  const isAnswerOK =
    ingestMetrics?.status.answer.pending_process <= 80 &&
    ingestMetrics?.status.answer.last_avg_exec_ms <= 1000;

  if (loading && !ingestMetrics && !prometheusLoad) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading />
        </div>
      </div>
    );
  }
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Sistema
        <div style={{float: "right"}}>
          <Button onClick={load} size='large'>
            <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
          </Button>
        </div>
      </div>
      <div className={stylesLayout.content}>
        {ingestMetrics && (
          <>
            <strong style={{fontWeight: 500, fontSize: 14}}>
              Mensajes entrantes procesados
              <br />
              <small>
                Process last started at:{" "}
                {moment
                  .utc(ingestMetrics.started)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss")}
              </small>
              <br />
              <small>
                Uptime: {moment.utc(ingestMetrics.started).fromNow(true)}
              </small>
              <br />
              <br />
              <div>Max Queue Size: {ingestMetrics.maxQueueSize}</div>
              <br />
            </strong>

            <table className={styles.table}>
              <thead>
                <tr>
                  <th />
                  <th className={styles.left}>Tipo</th>

                  <th>
                    En cola /
                    <br />
                    Pend de procesar
                  </th>
                  <th>
                    Recibidos /
                    <br />
                    Procesados
                  </th>
                  <th>Ult execución (ms)</th>
                  <th>Max Process</th>
                  <th>Últ proceso</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <FontAwesomeIcon
                      icon={isHeartbeatOK ? faCheckCircle : faTimesCircle}
                      color={isHeartbeatOK ? "#090" : "#c30"}
                    />
                  </td>
                  <td className={styles.left}>Heartbeat</td>
                  <td>
                    {ingestMetrics.status.heartbeat.in_queue}
                    <br />
                    {ingestMetrics.status.heartbeat.pending_process}
                  </td>
                  <td>
                    {numeral(ingestMetrics.status.heartbeat.received).format(
                      "0,000"
                    )}
                    <br />
                    {numeral(ingestMetrics.status.heartbeat.processed).format(
                      "0,000"
                    )}
                  </td>
                  <td>
                    {numeral(
                      parseInt(
                        ingestMetrics.status.heartbeat.last_avg_exec_ms,
                        10
                      )
                    ).format("0,000")}
                  </td>
                  <td>{ingestMetrics.maxProcessing.heartbeat}</td>
                  <td>
                    {ingestMetrics.status.heartbeat.last_processed
                      ? moment
                          .utc(ingestMetrics.status.heartbeat.last_processed)
                          .local()
                          .fromNow()
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon
                      icon={isAnswerOK ? faCheckCircle : faTimesCircle}
                      color={isAnswerOK ? "#090" : "#c30"}
                    />
                  </td>
                  <td className={styles.left}>Answer</td>
                  <td>
                    {ingestMetrics.status.answer.in_queue} <br />
                    {ingestMetrics.status.answer.pending_process}
                  </td>
                  <td>
                    {numeral(ingestMetrics.status.answer.received).format(
                      "0,000"
                    )}{" "}
                    <br />
                    {numeral(ingestMetrics.status.answer.processed).format(
                      "0,000"
                    )}
                  </td>
                  <td>
                    {numeral(
                      parseInt(ingestMetrics.status.answer.last_avg_exec_ms, 10)
                    ).format("0,000")}
                  </td>
                  <td>{ingestMetrics.maxProcessing.answer}</td>
                  <td>
                    {ingestMetrics.status.answer.last_processed
                      ? moment
                          .utc(ingestMetrics.status.answer.last_processed)
                          .local()
                          .fromNow()
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {prometheusLoad && (
          <>
            <div>
              <br />
              <br />
              <>
                <strong style={{fontWeight: 500, fontSize: 14}}>
                  Carga [1m] - Actual
                </strong>
                <br />
                <br />
                <FontAwesomeIcon
                  style={{marginRight: 5}}
                  icon={
                    prometheusLoad[prometheusLoad.length - 1].load1 < 70
                      ? faCheckCircle
                      : faTimesCircle
                  }
                  color={
                    prometheusLoad[prometheusLoad.length - 1].load1 < 70
                      ? "#090"
                      : "#c30"
                  }
                />
                grin-prod-1:{" "}
                {parseInt(prometheusLoad[prometheusLoad.length - 1].load1, 10)}
                %
                <br />
                <br />
                <FontAwesomeIcon
                  style={{marginRight: 5}}
                  icon={
                    prometheusLoad[prometheusLoad.length - 1].load2 < 70
                      ? faCheckCircle
                      : faTimesCircle
                  }
                  color={
                    prometheusLoad[prometheusLoad.length - 1].load2 < 70
                      ? "#090"
                      : "#c30"
                  }
                />
                grin-prod-2:{" "}
                {parseInt(prometheusLoad[prometheusLoad.length - 1].load2, 10)}%
              </>
            </div>
            <br />
            <br />

            <RenderLineChart
              data={prometheusLoad}
              yAxisScale='linear'
              xAxisType='time'
              showYAxisSelector
              yAxisMin={0}
              yAxisMax={100}
              title={
                <>
                  Carga [1m] - últ{" "}
                  <Select
                    style={{
                      display: "inline-block",
                      width: "200px",
                    }}
                    value={statsMinutes}
                    options={[
                      {value: 5, label: "5m"},
                      {value: 10, label: "10m"},
                      {value: 15, label: "15m"},
                      {value: 30, label: "30m"},
                      {value: 60, label: "60m"},
                      {value: 90, label: "1.5h"},
                      {value: 120, label: "2h"},
                      {value: 180, label: "3h"},
                      {value: 6 * 60, label: "6h"},
                      {value: 12 * 60, label: "12h"},
                      {value: 24 * 60, label: "24h"},
                    ]}
                    onChange={(v) => setStatsMinutes(v)}
                  />{" "}
                </>
              }
              width={100}
              height={isMobile() ? 60 : 28}
              xAxisStepSize={2}
              xLabelsField='ts'
              yDatasets={{
                "grin-prod-1": "load1",
                "grin-prod-2": "load2",
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
SystemModule.propTypes = {};
export default SystemModule;
