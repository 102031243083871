/* eslint-disable guard-for-in */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { CSVLink } from 'react-csv';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { SimpleMapComponent } from '../SimpleMapComponent';
import styles from './index.module.scss';
import { convertPositionListToSegments } from '../../helpers/utils';

import stylesLayout from '../../common/layout.module.scss';

const MapPositionsList = ({
  positions,
  device,
  showSetDeviceInPosition,
  onSetDeviceInPosition,
}) => {
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [mapSegments, setMapSegments] = useState(null);

  useEffect(() => {
    if (!positions) {
      return;
    }
    const segments = convertPositionListToSegments(positions, 180);
    setMapSegments(segments);
  }, [positions]);
  let markers = [];
  if (positions?.length > 0) {
    const lastPosition = positions[0];
    markers = [
      {
        latitude: lastPosition.lat,
        longitude: lastPosition.lng,
        label: `Actual  ${moment
          .utc(lastPosition.created_at)
          .local()
          .format('HH:mm')}`,
      },
    ];
    if (selectedPosition) {
      markers.push({
        latitude: selectedPosition.lat,
        longitude: selectedPosition.lng,
        label: `Seleccionada  ${moment
          .utc(selectedPosition.created_at)
          .local()
          .format('HH:mm')}`,
      });
    }
  }

  return (
    <div className={styles.cnt}>
      <div className={styles.tools}>
        <CSVLink
          filename={`posicion-patin-${device.qr}.csv`}
          data={positions.map((e) => ({
            datetime_local: moment
              .utc(e.created_at)
              .local()
              .format('YYYY-MM-DD HH:mm:ss'),
            lat: parseFloat(e.lat),
            lng: parseFloat(e.lng),
            email: e.user?.email,
            velocidad_motor_km_h: e.data?.speedsco
              ? parseFloat(e.data?.speedsco) / 10
              : '',
            velocidad_patin_km_h:
              e.data && e.data['position.speed']
                ? parseFloat(e.data['position.speed'])
                : '',
          }))}
        >
          <Button type="secondary">
            <FontAwesomeIcon icon={faDownload} style={{ marginRight: 5 }} />
            Exportar como CSV
          </Button>
        </CSVLink>
      </div>

      <div className={styles.positions}>
        {!positions || positions.length === 0 ? (
          <div>No hay eventos</div>
        ) : (
          positions.map((e) => (
            <div
              className={[
                styles.item,
                selectedPosition?.id === e.id ? styles.selected : '',
              ].join(' ')}
              onClick={() => setSelectedPosition(e)}
            >
              <div className={styles.date}>
                {moment.utc(e.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
                <br />
                {moment.utc(e.created_at).fromNow()}
                <br />
                {e.user?.email}
                {' '}
                -
                {' '}
                {e.user?.name || 'Sin nombre'}
              </div>
              <div className={styles.position}>
                {e.lat}
                ,
                {' '}
                {e.lng}
                {showSetDeviceInPosition && (
                  <div
                    className={stylesLayout.link}
                    style={{ display: 'inline-block' }}
                    onClick={() => onSetDeviceInPosition(e.lat, e.lng)}
                  >
                    Poner en esta ubicación
                  </div>
                )}
              </div>
              <div className={styles.position}>
                {e.data && (
                  <>
                    Batería Scooter:
                    {' '}
                    {e.data?.batsco ? `${e.data?.batsco}%` : 'Sin info'}
                    <br />
                    Vel motor:
                    {' '}
                    {e.data?.speedsco
                      ? `${e.data?.speedsco / 10} km/h`
                      : 'Sin info'}
                    <br />
                    Vel patin:
                    {' '}
                    {e.data['position.speed']
                      ? `${e.data['position.speed']} km/h`
                      : 'Sin info'}
                    <br />
                    Motor:
                    {' '}
                    {e.data.engine_on === '1' ? 'Encendido' : 'Apagado'}
                    <br />
                    Est bloqueo:
                    {' '}
                    {
                      // eslint-disable-next-line no-nested-ternary
                      e.data && typeof e.data === 'object' && 'locked' in e.data
                        ? e.data.locked === 1
                          ? 'Bloqueado'
                          : 'Desbloqueado'
                        : 'Sin info'
                    }
                    <br />
                    Patín conectado al IOT:
                    {' '}
                    {
                      // eslint-disable-next-line no-nested-ternary
                      e.data && typeof e.data === 'object' && 'is_scooter_present' in e.data
                        ? e.data.is_scooter_present === '1'
                          ? 'Si'
                          : 'No'
                        : 'Sin info'
                    }
                  </>
                )}
              </div>

              <div className={styles.data}>
                <div
                  style={{ fontFamily: 'monospace', wordBreak: 'break-all' }}
                >
                  <small>{JSON.stringify(e.data)}</small>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <div className={styles.map}>
        {positions?.length > 0 && (
          <SimpleMapComponent
            zoom={15}
            defaultCenter={positions[0]}
            minZoom={1}
            minimumClusterSize={10000}
            maxZoom={20}
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: 500 }} />}
            containerElement={(
              <div
                style={{
                  height: 500,
                }}
              />
            )}
            mapElement={
              <div style={{ borderRadius: 5, height: 500 - 32 - 12 }} />
            }
            markers={markers}
            lines={mapSegments?.map((x) => ({ path: x }))}
          />
        )}
      </div>
    </div>
  );
};
MapPositionsList.defaultProps = {
  onSetDeviceInPosition: () => { },
  showSetDeviceInPosition: false,
};

MapPositionsList.propTypes = {
  positions: PropTypes.array.isRequired,
  device: PropTypes.object.isRequired,
  onSetDeviceInPosition: PropTypes.func,
  showSetDeviceInPosition: PropTypes.bool,
};

export default MapPositionsList;
