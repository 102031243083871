import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { Button, Table } from 'antd';
import styles from './index.module.scss';

const CardsTable = ({
  cards,
  // eslint-disable-next-line no-unused-vars
  show,
  onChangeStatus,
}) => {
  const cardsColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (v, r) => (
        <div>
          {v}
          {['BLOCKED_UNPAID', 'BLOCKED_UNPAID_OTHER_USER', 'BLOCKED_FRAUD'].includes(v) && (
            <div>
              <Button size="small" onClick={() => onChangeStatus(r.id, 'ACTIVE')}>Activar</Button>
            </div>
          )}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'x',
      key: 'x',
      render: (a, x) => (
        <>
          {x.data.country}
          {' - '}
          {x.data.source.payment_method.name}
          {' - '}
          {x.data.source.first_six_digits}
          ...
          {x.data.source.last_four_digits}
          <br />
          Emisor:
          {' '}
          {x.data.source.issuer?.name}
          <br />
          Titular:
          {' '}
          {x.data.source.cardholder.name}
          <br />
          {x.data.source.cardholder.identification.type}
          {': '}
          {x.data.source.cardholder.identification.number}
          <br />
          PSP:
          {' '}
          {x.source_type}
          {' '}
          -
          {' '}
          {x.source_id}
          <br />

        </>
      ),
    },
    {
      title: 'Validación',
      dataIndex: 'validated',
      key: 'validated',
      render: (validated) => (
        validated ? 'Tarjeta Validada' : 'Tarjeta no validada'
      ),
    },
    {
      title: 'Creada',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (x) => (
        moment.utc(x).local().format('YYYY-MM-DD HH:mm:ss')
      ),
    },
  ].filter((x) => !!x);
  return (
    <div className={styles.table}>
      <Table
        size="small"
        pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
        locale={{ emptyText: 'Sin datos' }}
        columns={cardsColumns}
        dataSource={cards.map((d) => ({
          key: d.id,
          ...d,
        }))}
      />
    </div>
  );
};

CardsTable.defaultProps = {
  cards: [],
  show: ['card'],
  onChangeStatus: () => { },
};
CardsTable.propTypes = {
  cards: PropTypes.array,
  show: PropTypes.array,
  onChangeStatus: PropTypes.func,
};

export default CardsTable;
