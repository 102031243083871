/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import Timeline from 'react-calendar-timeline';
import Widget from '../../components/Widget';

// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css';
import { calculateCentroid } from '../../helpers/utils';
import { SimpleMapComponent } from '../../components/SimpleMapComponent';

const BoardModule = () => (

  <div style={{ padding: 20, display: 'grid', gap: 20 }}>
    <Widget
      title="Posiciones de usuario"
      style={{
        height: 500,
      }}
      source={`
      const users=[35393, 12839]
    const conn = db.getConnectionFromConfig('paperboy');

const query = \`
select u.email, u.id, e.lat, e.lng, e.created_at created_at, e.event from events e, users u where e.user_id=u.id and e.created_at>now()-interval 240 minute and e.user_id in (
  select u.id from cards c, users u where c.user_id=u.id and c.fingerprint in (select c.fingerprint from cards c where c.user_id in (35393, 12839))
  ) and lat is not null order by e.id;

\`;

const query2 = \`
select d.qr, u.email, last_trips_of_device.stolen_at, t.end_at, if(end_at>last_trips_of_device.stolen_at-interval 3 hour,1,0) recent_trip, d.lat, d.lng, t.* from devices d, users u, trips t,
(select device_id, stolen_at, max(t.id) last_trip_id from 
trips t, 
(select id, qr, date_format(json_unquote(json_extract(d.data, '$.last_status_change_at')), '%Y-%m-%d %H:%i:%s') stolen_at from devices d where status='stolen' and fleet_id=1 order by json_extract(d.data, '$.last_status_change_at') desc limit 100) stolen
where t.device_id=stolen.id and t.status in ('FINISHED', 'PAID') group by 1) last_trips_of_device
 where 
t.id=last_trips_of_device.last_trip_id
and d.id=t.device_id
and u.id=t.user_id
order by last_trips_of_device.stolen_at desc;
\`;
    const rsp = await db.query(conn,query);

    return rsp;
    `}
      render={(data) => {
        const markers = data.map((x) => ({
          latitude: x.lat,
          longitude: x.lng,
          ts: moment(x.created_at).unix(),
          label: (
            <div>
              {x.email}
              <br />
              {x.event}
              {' '}
              -
              {' '}
              {x.created_at}
            </div>
          ),
        }));
        console.log(markers);
        const center = calculateCentroid(markers);
        return (
          <>
            <SimpleMapComponent
              withTimeline
              zoom={15}
              defaultCenter={center}
              minZoom={1}
              minimumClusterSize={10000}
              maxZoom={20}
              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
              loadingElement={(
                <div style={{ height: 400 }} />
              )}
              containerElement={(
                <div
                  style={{
                    height: 400,
                  }}
                />
              )}
              mapElement={<div style={{ borderRadius: 5, height: 400 }} />}
              markers={markers}
            />
          </>
        );
      }}
    />
    {false && (
      <Widget
        title="Patines reseteados hoy"
        style={{
          height: 500,
        }}
        source={`
    const conn = db.getConnectionFromConfig('paperboy');

          const query = \`
select u.email, d.qr, d.status, e.lat, e.lng, e.created_at reseted_at from events e, devices d, users u where e.item_id=d.id and e.user_id=u.id and e.created_at>date(now()-interval 3 hour)+interval 3 hour and e.event='device:reset'
    and e.lat is not null;
    \`;

    const rsp = await db.query(conn,query);

    return rsp;
    `}
        render={(data) => {
          const emails = [...new Set(data.map((x) => x.email))];
          const groups = emails.map((x) => ({ id: x, title: x }));
          const items = data.map((x, index) => (
            {
              id: index,
              group: x.email,
              title: x.qr,
              start_time: moment(x.reseted_at),
              end_time: moment(x.reseted_at),
            }
          ));
          return (
            <>
              <Timeline
                groups={groups}
                items={items}
                defaultTimeStart={moment().startOf('day')}
                defaultTimeEnd={moment().endOf('day')}
                canMove={false}
                canChangeGroup={false}
                canResize={false}
                stackItems={false}
              />
              <div>
                {data.length}
                {' '}
                reseteos
              </div>
              <Table
                size="small"
                pagination={{ hideOnSinglePage: true, defaultPageSize: 500 }}
                locale={{ emptyText: 'Sin datos' }}
                columns={[
                  {
                    title: 'Patín',
                    dataIndex: 'qr',
                    align: 'center',
                    key: 'qr',
                    render: (v, r) => <Link to={`/device/${r.qr}`}>{v}</Link>,
                    sorter: (a, b) => (a.qr < b.qr ? -1 : 1),
                  },
                  {
                    title: 'Status',
                    key: 'status',
                    align: 'center',
                    dataIndex: 'status',
                  },
                  {
                    title: 'Email',
                    key: 'email',
                    align: 'center',
                    dataIndex: 'email',
                  },
                  {
                    title: 'Hora',
                    dataIndex: 'data',
                    key: 'lm',
                    defaultSortOrder: 'ascend',
                    align: 'center',
                    render: (v, r) => (r.reseted_at ? moment.utc(r.reseted_at).fromNow() : ''),
                    sorter: (a, b) => moment(b.reseted_at).format('X') - moment(a.reseted_at).format('X'),
                  },
                  {
                    title: 'Ubicación',
                    key: 'lat',
                    align: 'center',
                    dataIndex: 'lat',
                    render: (v, r) => (
                      <a href={`https://www.google.com/maps/search/?api=1&query=${r.lat},${r.lng}`} target="_map">
                        {r.lat}
                        ,
                        {r.lng}
                      </a>
                    ),
                  },
                ]}
                dataSource={data}
              />
            </>
          );
        }}
      />
    )}
  </div>
);

export default BoardModule;
