/* eslint-disable max-len */
import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const execute = async (payload) => executeAndWaitResponse(() => apiClient.horus.execute(payload));

export const executeQuery = async (query) => {
  const command = `
  const conn = db.getConnectionFromConfig('paperboy');

const query = \`${query}\`;

const rsp = await db.query(conn,query);
conn.end();
return rsp;
  `;

  const rsp = await execute(command);
  return rsp;
};

export default {
  execute,
  executeQuery,
};
