import { HORUS_BASE_URL } from '../../environment';

export default (client, defaultParams) => ({

  execute: (payload) => (
    client(`${HORUS_BASE_URL}/execute`, {
      method: 'POST',
      data: payload,
      headers: {
        'Content-Length': 0,
        'Content-Type': 'text/plain',
      },
      params: {
        ...defaultParams,
      },
    })
  ),

});
