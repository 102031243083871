/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, notification, Radio, Row, Col,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import * as grin4uService from '../../services/grin4u';

import styles from './index.module.scss';
import stylesForm from '../../common/form.module.scss';
import Loading from '../../components/Loading';
import SubscriptionItem from '../../components/SubscriptionItem';

const Grin4USubscriptions = ({
  customer,
  country,
  // eslint-disable-next-line no-unused-vars
  user,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showFormCreate, setShowFormCreate] = useState(false);
  const [saving, setSaving] = useState(false);
  const [userSubscriptions, setUserSubscriptions] = useState([]);

  const createSubscriptionEnabled = customer.grin4u_status === 'ACTIVE';

  const loadSubscriptions = async () => {
    setLoading(true);
    const rsp = await grin4uService.getUserSubscriptions(customer.id);

    if (rsp?.status) {
      setUserSubscriptions(rsp.data);
    } else {
      setUserSubscriptions([]);
    }
    setLoading(false);
    return true;
  };

  const paySubscription = async (subscriptionId) => {
    const rsp = await grin4uService.setSubscriptionStatus(subscriptionId, 'PAID');

    if (rsp?.status) {
      setUserSubscriptions((s) => (
        s.map((x) => (
          x.id === subscriptionId ? rsp.data : x
        ))
      ));
    }
    return true;
  };

  const onFinish = async (values) => {
    if (!createSubscriptionEnabled) {
      return null;
    }
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Creando suscripción...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });

    const payload = {
      type: `PLAN_${values.duration}`,
      user_id: customer.id,
      country,
      data: {
        duration_days: values.duration,
        shipping_point: values.shipping_point,
      },
    };

    const rsp = await grin4uService.createSubscription(payload);

    if (rsp?.status) {
      notification.success({
        message: 'Suscripción creada.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al crear suscripción.',
        placement: 'top',
        key: 'result',
      });
    }
    setSaving(false);
    return true;
  };

  const onFinishFailed = () => {
    // console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    loadSubscriptions();
  }, [customer.id]);

  return (
    <div className={styles.cnt}>
      <Button onClick={() => setShowFormCreate((s) => !s)} type="secondary" disabled={!createSubscriptionEnabled}>
        <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
        {' '}
        Crear Suscripción
      </Button>
      {!createSubscriptionEnabled && (
        <div>
          * No puedes crear suscripciones porque el cliente no tiene todos los datos completos
        </div>
      )}
      {showFormCreate && (
        <div className={[styles.createForm, stylesForm.fields].join(' ')}>
          <Form
            name="basic"
            form={form}
            initialValues={{}}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="large"
          >
            <Row gutter={24}>
              <Col sm={12} xs={12}>
                <Form.Item
                  label="Duración"
                  name="duration"
                >
                  <Radio.Group>
                    <Radio value="30">1 Mes - 30 días</Radio>
                    <Radio value="60">2 Meses - 60 días</Radio>
                    <Radio value="90">3 Meses - 90 días</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="Punto de entrega"
                  name="shipping_point"
                >
                  <Radio.Group>
                    <Radio value="house">
                      Domicilio -
                      {' '}
                      {customer.data?.grin4uinfo.house_address}
                      ,
                      {' '}
                      {customer.data?.grin4uinfo.house_district}
                    </Radio>
                    <Radio value="work">
                      Trabajo -
                      {' '}
                      {customer.data?.grin4uinfo.work_address}
                      ,
                      {' '}
                      {customer.data?.grin4uinfo.work_district}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={saving}>
                Guardar
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      <div>
        {loading
          ? <Loading />
          : (
            <div className={styles.subscriptions}>
              {userSubscriptions.map((x) => (
                <SubscriptionItem
                  subscription={x}
                  customer={customer}
                  onPaySubscription={() => paySubscription(x.id)}
                />
              ))}
            </div>
          )}
      </div>
    </div>
  );
};
Grin4USubscriptions.propTypes = {
  customer: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
};
export default Grin4USubscriptions;
