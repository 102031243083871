/* eslint-disable react/prop-types */
import React from 'react';
import styles from './index.module.scss';

const DeviceCard = ({
  device,
}) => (
  <div className={styles.card}>{device.qr}</div>
);

export default DeviceCard;
