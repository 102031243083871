/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Button,
  Input,
  notification,
} from 'antd';

import Loading from '../../components/Loading';

import {
  // eslint-disable-next-line no-unused-vars
  getDeviceByTypeQROrRef,
} from '../../services/devices';

import styles from './index.module.scss';

const MultiSelectModule = ({
  initialDevices,
  onChangeDevices,
  editable,
}) => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [devices, setDevices] = useState(initialDevices);
  const [qr, setQr] = useState();

  useEffect(async () => {
    setLoading(false);
    onChangeDevices(devices);
  }, [devices]);

  useEffect(async () => {
    setDevices(initialDevices);
  }, [initialDevices]);

  const loadDevice = async (code, type) => {
    const rsp = await getDeviceByTypeQROrRef({
      type,
      code,
    });
    if (rsp && rsp.status) {
      return rsp.data;
    }
    return null;
  };

  const removeDevice = (id) => {
    setDevices((s) => s.filter((d) => d.id !== id));
  };

  const addDevice = async (code) => {
    setLoading(true);
    const d = await loadDevice(code, 'scooter');
    if (d) {
      setDevices((s) => [...s, d]);
    } else {
      notification.error({
        message: 'Dispositivo no encontrado',
        placement: 'top',
      });
    }
    setLoading(false);
  };

  return (
    <div className={[styles.cnt, editable ? styles.cntEditable : null].join(' ')}>
      {editable
        && (
          <div className={styles.input}>
            <div className={styles.inputInner}>
              <div>
                <Input
                  disabled={loading}
                  value={qr}
                  placeholder="QR o MAC..."
                  onChange={(e) => { setQr(e.target.value); }}
                  onKeyDown={(e) => {
                    if (qr) {
                      if (qr && e.key === 'Enter') {
                        addDevice(qr);
                        setQr('');
                      }
                    }
                  }}
                />
              </div>
            </div>
            <Button
              type="primary"
              disabled={loading}
              onClick={() => {
                addDevice(qr);
                setQr('');
              }}
            >
              {loading
                && (
                  <>
                    <Loading styles={{ marginRight: 10 }} />
                    {' '}
                  </>
                )}

              Agregar
            </Button>
          </div>
        )}
      <div className={styles.results}>
        <div className={styles.resultsContent}>
          {[...devices].reverse().map((d, idx) => (
            <div className={[styles.deviceItem, styles[`status_${d.status}`]].join(' ')}>
              <div>
                <div className={styles.qr}>
                  {idx + 1}
                  {' '}
                  -
                  {' '}
                  {d.qr}
                </div>
                <div className={styles.fleet}>
                  {d.fleet?.name || 'Sin flota asignada'}
                </div>
                <div className={styles.battery} style={{ color: d.data?.batsco <= 20 ? '#c30' : 'inherit' }}>
                  Batería:
                  {' '}
                  {d.data?.batsco}
                  %
                </div>
              </div>
              <div style={{ textAlign: 'right' }}>
                <div className={styles.status}>
                  {d.status_tr}
                </div>
                <div className={styles.updatedAt}>
                  Act:
                  {' '}
                  {moment.utc(d.updated_at).fromNow()}
                </div>
              </div>
              <div style={{ textAlign: 'right' }}>
                {editable
                  && (
                    <div className={styles.deleteItemBtn} onClick={() => { removeDevice(d.id); }}>
                      &times;
                    </div>
                  )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
MultiSelectModule.defaultProps = {
  initialDevices: [],
  editable: false,
  onChangeDevices: () => { },
};
MultiSelectModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired,
  initialDevices: PropTypes.array,
  editable: PropTypes.bool,
  onChangeDevices: PropTypes.func,
};

export default MultiSelectModule;
