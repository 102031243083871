/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import stylesLayout from '../../common/layout.module.scss';

const Section = ({
  children,
}) => (
  <div className={stylesLayout.section}>{children}</div>
);

Section.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Section;
