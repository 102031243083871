/* eslint-disable max-len */
import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const create = async (payload) => executeAndWaitResponse(() => apiClient.geofences.create(payload));
export const get = async (id) => executeAndWaitResponse(() => apiClient.deployPlans.get(id));
export const update = async (id, payload) => executeAndWaitResponse(() => apiClient.deployPlans.update(id, payload));
export const getForFleet = async (fleetId, status = ['ACTIVE']) => executeAndWaitResponse(() => apiClient.deployPlans.getForFleet(fleetId, status));

export default {
  get,
  create,
  update,
  getForFleet,
};
