import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { Button } from 'antd';
import stylesLayout from '../../common/layout.module.scss';
import styles from './index.module.scss';
import * as usersService from '../../services/users';
import Loading from '../../components/Loading';

const UserModule = ({
  user,
  onUpdate,
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const changeWorkingStatus = async (status) => {
    setLoading(true);
    const rsp = await usersService.changeWorkingStatus(user.id, status);
    if (rsp?.status) {
      await onUpdate();
      history.push('/');
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading />
        </div>
      </div>
    );
  }
  const workingStatus = user.data?.working?.status || 'NON_ACTIVE';
  return (
    <div className={styles.cnt}>
      <div className={stylesLayout.page}>
        {false && (
          <div className={stylesLayout.title}>
            #
            {user.id}
            {' '}
            -
            {' '}
            {user.email}
            <br />
            <small>
              {user.name}
            </small>
          </div>
        )}
        <div className={stylesLayout.content}>
          {
            workingStatus === 'NON_ACTIVE'
            && (
              <>
                <div style={{ marginBottom: 20 }}>
                  Para poder iniciar el trabajo haz click en comenzar turno
                </div>
                <Button type="primary" size="large" block style={{ padding: '50px 20px 70px 20px' }} onClick={() => { changeWorkingStatus('ACTIVE'); }}>Comenzar turno</Button>
              </>
            )
          }
          {
            workingStatus === 'ACTIVE' && (
              <>
                <Button onClick={() => { changeWorkingStatus('NON_ACTIVE'); }}>Finalizar turno</Button>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};
UserModule.propTypes = {
  user: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default UserModule;
