/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './index.module.scss';

const CopyWithMessage = ({
  value, children, messageCopied, messageTime, onCopied, inline, style,
}) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const pid = window.setTimeout(() => { setCopied(false); }, messageTime);
      return () => {
        if (pid) { window.clearTimeout(pid); }
      };
    }
    return () => { };
  }, [copied]);

  return (
    <div
      className={styles.cnt}
      style={{ display: inline ? 'inline-block' : 'block', ...style }}
    >
      <CopyToClipboard
        text={value}
        onCopy={() => {
          onCopied(value);
          setCopied(true);
        }}
      >
        <div className={styles.children}>
          {children}
        </div>
      </CopyToClipboard>
      {copied && <div className={styles.message}>{messageCopied}</div>}

    </div>
  );
};
CopyWithMessage.defaultProps = {
  value: '',
  messageCopied: 'Copiado',
  messageTime: 2000,
  onCopied: () => { },
  children: <></>,
  style: {},
  inline: false,
};

CopyWithMessage.propTypes = {
  value: PropTypes.string,
  messageCopied: PropTypes.string,
  messageTime: PropTypes.number,
  onCopied: PropTypes.func,
  children: PropTypes.any,
  style: PropTypes.object,
  inline: PropTypes.bool,
};

export default CopyWithMessage;
