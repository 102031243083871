/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isMobile from 'is-mobile';
import {
  Button,
  Input,
  // eslint-disable-next-line no-unused-vars
  notification,
  Popconfirm,
  Select,
} from 'antd';
import userIcon from '../../assets/scootersMap/user.svg';
import flagIcon from '../../assets/scootersMap/flag-black.svg';
import MultiSelectModule from '../MultiSelectModule';

import {
  getUsersOfTenant,
} from '../../services/tenants';

import {
  createOrUpdateTask,
} from '../../services/tasks';

import styles from './index.module.scss';
import { calculateCentroid } from '../../helpers/utils';
import TaskItem from '../../components/TaskItem';
import { SimpleMapComponent } from '../../components/SimpleMapComponent';
import { MapComponent } from '../../components/MapComponent';

const { Option } = Select;

const TaskHandleModule = ({
  task,
  permissions,
  initialDevices,
  user,
  initialData,
  onChangeDevices,
  userPosition,
  onSaved,
  onCancelled,
  fleetId,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(initialData);
  const [devices, setDevices] = useState(initialDevices);

  const [showTaskForm, setShowTaskForm] = useState(false);
  const [mapPosition, setMapPosition] = useState(null);
  const [centroidOfDevices,
    setCentroidOfDevices] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [centroidOfAll, setCentroidOfAll] = useState(null);
  const [allMarkers, setAllMarkers] = useState(null);
  const [users, setUsers] = useState(null);

  const getTenantUsers = async () => {
    const rsp = await getUsersOfTenant();
    if (rsp?.status) {
      setUsers(rsp.data);
    } else {
      setUsers([]);
    }
  };
  const resetData = () => {
    setData({
      steps: [
        { action: 'collect' },
        { action: null },
      ],
    });
  };

  const onChangeDevicesFromMultiSelect = (ds) => {
    setDevices(ds);
  };
  const onCreateTask = async (additionalPayload = {}) => {
    const payload = {
      type: 'task',

      data: {
        ...data,
        devices: devices.map((x) => x.id),
      },
      assignee_id: data.assignee_id,
      fleet_id: fleetId,
      ...additionalPayload,
    };
    if (task?.id) {
      payload.id = task.id;
    }
    const rsp = await createOrUpdateTask(payload);
    if (rsp?.status) {
      notification.success({
        message: 'Misión guardada',
        placement: 'top',
      });
      setDevices([]);
      resetData();
      onSaved();
    }
  };
  const onResolveTask = async () => onCreateTask({ status: 'SOLVED' });

  const onCancelTask = () => {
    setDevices([]);
    resetData();
    onCancelled();
  };

  const updateField = (key, value) => {
    setData((s) => ({
      ...s,
      [key]: value,
    }));
  };

  const updateStep = (stepIndex, key, value) => {
    setData((s) => ({
      ...s,
      steps: s.steps.map((x, index) => (stepIndex === index ? { ...x, [key]: value } : x)),
    }));
  };

  useEffect(async () => {
    setDevices(initialDevices);
  }, [initialDevices]);

  useEffect(async () => {
    console.log('effect[devices]');
    onChangeDevices(devices);
    if (devices && devices.length > 0) {
      setCentroidOfDevices(
        calculateCentroid(
          devices,
        ),
      );
      if (!mapPosition) {
        setMapPosition(calculateCentroid(devices));
      }
      setShowTaskForm(true);
    } else {
      setShowTaskForm(false);
    }
  }, [devices]);

  useEffect(async () => {
    console.log('effect[devices,userPosition]', userPosition);
    let markers = [{
      lat: userPosition.latitude, lng: userPosition.longitude, label: 'Tu posición', icon: userIcon,
    }];
    if (task?.devices?.length > 0) {
      markers = [...markers, ...(task && task.devices ? task.devices : [])];
    }
    if (task?.data?.steps[1]?.action_location) {
      markers = [
        ...markers,
        {
          lat: task.data.steps[1].action_location.lat,
          lng: task.data.steps[1].action_location.lng,
          label: 'Destino',
          icon: flagIcon,
        },
      ];
    }
    const c = calculateCentroid(
      markers,
    );

    setCentroidOfAll(c);
    setAllMarkers(markers);
  }, [devices, userPosition]);

  useEffect(() => {
    getTenantUsers();
  }, []);
  useEffect(() => {
    if (!data) {
      resetData();
    }
  }, [data]);
  useEffect(() => {
    if (task) {
      if (task.data) {
        setData({ ...task.data, assignee_id: task.assignee_id });
        if (task.data.steps[1]?.action_location) {
          const p = {
            latitude: task.data.steps[1]?.action_location.lat,
            longitude: task.data.steps[1]?.action_location.lng,
          };
          setMapPosition(p);
        }
      } else {
        setData(resetData());
      }
      setDevices(task.devices);
    }
  }, [task]);
  if (!users) {
    return null;
  }
  return (
    <div className={styles.cnt} key={`cnt-${task?.id || 0}`}>
      {permissions.viewTask && !permissions.modifyTask && (
        <>
          <div className={styles.title}>
            <div className={styles.steps}>
              #
              {task.id}
              :
              {task?.data?.steps.map(((s) => (
                <div className={styles.stepInfo}>{s.action_tr}</div>
              )))}
            </div>

          </div>
          <div
            className={styles.bigmap}
            style={{
              height: isMobile() ? window.innerHeight - 105 : 400,
            }}
          >
            {centroidOfAll
              && (
                <MapComponent
                  key={task?.id || 'no-id'}
                  fleetId={fleetId}
                  zoom={15}
                  minimumClusterSize={5}
                  defaultCenter={{
                    lat: userPosition.latitude,
                    lng: userPosition.longitude,
                  }}
                  minZoom={1}
                  maxZoom={20}
                  googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={
                    <div style={{ height: isMobile() ? window.innerHeight - 105 : 400 }} />
                  }
                  containerElement={(
                    <div
                      style={{
                        height: isMobile() ? window.innerHeight - 105 : 400,
                      }}
                    />
                  )}
                  mapElement={(
                    <div
                      style={{
                        borderRadius: 5,
                        height: isMobile() ? window.innerHeight - 105 : 400,
                      }}
                    />
                  )}
                  markers={allMarkers}
                  onSelectMarker={() => { }}
                />
              )}
            {!task?.data.steps[1]?.action_location && (
              <div style={{
                position: 'absolute',
                background: '#c30',
                padding: 5,
                color: '#fff',
                fontSize: 12,
                textAlign: 'center',
                borderRadius: 3,
                fontWeight: 'bold',
                top: 10,
                left: 10,
                right: 10,
                zIndex: 2,
              }}
              >
                No hay ubicación de entrega. Consultar
              </div>
            )}
          </div>

          <div style={{ display: 'none' }}>
            <TaskItem task={task} />

            <div className={styles.devices} style={{ height: 300 }}>
              <MultiSelectModule
                initialDevices={devices}
                onChangeDevices={onChangeDevicesFromMultiSelect}
                editable={permissions.modifyDevices}
              />
            </div>

            <div className={styles.steps}>
              <div className={styles.step}>
                <div className={styles.number}>
                  1
                </div>
                <div className={styles.action}>
                  Recoger patines

                  {centroidOfDevices
                    && (
                      <MapComponent
                        key={task?.id || 'no-id'}
                        fleetId={fleetId}
                        zoom={16}
                        defaultCenter={{
                          lat: userPosition.latitude,
                          lng: userPosition.longitude,
                        }}
                        minZoom={1}
                        maxZoom={20}
                        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                        loadingElement={<div style={{ height: 200 }} />}
                        containerElement={(
                          <div
                            style={{
                              height: 200,
                            }}
                          />
                        )}
                        mapElement={<div style={{ borderRadius: 5, height: 200 }} />}
                        markers={task && task.devices ? task.devices : []}
                        onSelectMarker={() => { }}
                      />
                    )}
                </div>
              </div>
              <div className={styles.step}>
                <div className={styles.number}>
                  2
                </div>
                <div className={styles.action}>
                  {task?.data?.steps[1].action_tr}

                  {task?.data?.steps[1]?.action_location
                    && (
                      <div className={styles.map}>
                        <SimpleMapComponent
                          zoom={14}
                          key={`map-1-${task?.id || 0}`}
                          defaultCenter={{
                            lat: task.data.steps[1].action_location.lat,
                            lng: task.data.steps[1].action_location.lng,
                          }}
                          minZoom={1}
                          markers={data?.steps[1]?.action_location
                            ? [data?.steps[1]?.action_location] : []}
                          maxZoom={20}
                          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                          loadingElement={<div style={{ height: 300 }} />}
                          containerElement={(
                            <div
                              style={{
                                height: 300,
                              }}
                            />
                          )}
                          mapElement={<div style={{ borderRadius: 5, height: 300 }} />}
                        />
                      </div>
                    )}
                </div>
              </div>

            </div>
          </div>
        </>
      )}
      {(permissions.modifyDevices)
        && (
          <div className={styles.devices}>
            <MultiSelectModule
              initialDevices={devices}
              onChangeDevices={onChangeDevicesFromMultiSelect}
              editable
            />
          </div>
        )}
      {showTaskForm && permissions.modifyTask
        ? (
          <div className={styles.taskForm}>
            <div className={styles.steps}>
              <div className={styles.step}>
                <div className={styles.number}>
                  1
                </div>
                <div className={styles.action}>
                  Recoger patines

                  {centroidOfDevices
                    && (
                      <MapComponent
                        key={task?.id || 'no-id'}
                        fleetId={fleetId}
                        zoom={13}
                        defaultCenter={{
                          lat: centroidOfDevices.latitude,
                          lng: centroidOfDevices.longitude,
                        }}
                        minZoom={1}
                        maxZoom={20}
                        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                        loadingElement={<div style={{ height: 200 }} />}
                        containerElement={(
                          <div
                            style={{
                              height: 200,
                            }}
                          />
                        )}
                        mapElement={<div style={{ borderRadius: 5, height: 200 }} />}
                        markers={task && task.devices ? task.devices : []}
                        onSelectMarker={() => { }}
                      />
                    )}
                </div>
              </div>
              <div className={styles.step}>
                <div className={styles.number}>
                  2
                </div>
                <div className={styles.action}>
                  <Select
                    style={{ width: '100%' }}
                    allowClear
                    value={data.steps[1].action}
                    onChange={(v) => updateStep(1, 'action', v)}
                    placeholder="Elige una acción"
                  >
                    <Option key="to_transport">Entregar a transportista</Option>
                    <Option key="to_deploy">Poner en calle Desplegado</Option>
                    <Option key="to_disabled">Poner en calle Deshabilitado</Option>
                    <Option key="to_warehouse">Entregar en bodega</Option>
                  </Select>
                  {devices && devices.length > 0 && mapPosition
                    && mapPosition.latitude && data.steps[1].action
                    ? (
                      <>
                        <div className={styles.map}>
                          <SimpleMapComponent
                            zoom={14}
                            key={`map-1-${task?.id || 0}`}
                            defaultCenter={{
                              lat: mapPosition.latitude,
                              lng: mapPosition.longitude,
                            }}
                            minZoom={1}
                            onClick={(e) => updateStep(1, 'action_location', { lat: e.latLng.lat(), lng: e.latLng.lng() })}
                            markers={data?.steps[1]?.action_location
                              ? [data?.steps[1]?.action_location] : []}
                            maxZoom={20}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{ height: 300 }} />}
                            containerElement={(
                              <div
                                style={{
                                  height: 300,
                                }}
                              />
                            )}
                            mapElement={<div style={{ borderRadius: 5, height: 300 }} />}
                          />
                        </div>

                      </>
                    ) : null}
                </div>

              </div>
            </div>

            {permissions.reassign
              && (
                <div className={[styles.assignee, styles.field].join(' ')}>
                  <div className={styles.label}>
                    Asignar a:
                  </div>
                  <div className={styles.content}>

                    <Select
                      showSearch
                      placeholder="Elige un responsable"
                      filterOption={
                        (inputValue, option) => option.label && option.label
                          .toString()
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      }
                      style={{ width: '100%' }}
                      value={data.assignee_id}
                      allowClear
                      onChange={(v) => { updateField('assignee_id', v); }}
                      options={users.map((u) => ({ value: u.id, label: `${u.name} - ${u.email}` }))}
                    />
                  </div>
                </div>
              )}
            <div className={[styles.assignee, styles.field].join(' ')}>
              <div className={styles.label}>
                Comentarios:
              </div>
              <div className={styles.content}>
                <Input.TextArea style={{ height: '7em' }} defaultValue={data.comments} onChange={(e) => updateField('comments', e.target.value)} />
              </div>
            </div>
            <div className={styles.submit}>
              <Button type="primary" onClick={() => onCreateTask()}>{task?.id ? 'Actualizar' : 'Crear misión'}</Button>
              {' '}
              {task && task.status !== 'SOLVED' && task.assignee_id === user.id && <Button type="primary" onClick={onResolveTask}>Resolver</Button>}
              {' '}
              <Popconfirm
                placement="top"
                title={`¿Confirmas que quieres cancelar la ${task?.id ? 'edición' : 'creación'} de esta misión?`}
                onConfirm={onCancelTask}
                okText="Si"
                okButtonProps={{
                  type: 'danger',
                }}
                cancelText="No"
              >
                <Button danger>Cancelar</Button>
              </Popconfirm>

            </div>
          </div>
        )
        : (
          <>
            {task.status !== 'SOLVED'
              && (
                <div className={[styles.assignee, styles.field].join(' ')}>
                  <div className={styles.label}>
                    Comentarios:
                  </div>
                  <div className={styles.content}>
                    <Input.TextArea style={{ height: '7em' }} defaultValue={data.comments} onChange={(e) => updateField('comments', e.target.value)} />
                  </div>
                </div>
              )}
            <div className={styles.submit}>
              {task.status !== 'SOLVED' && <Button type="primary" onClick={onResolveTask}>Resolver</Button>}
            </div>
          </>
        )}
    </div>
  );
};
TaskHandleModule.defaultProps = {
  initialDevices: [],
  permissions: {},
  task: null,
  initialData: null,
  onChangeDevices: () => { },
  onSaved: () => { },
  onCancelled: () => { },
};
TaskHandleModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired,
  task: PropTypes.object,
  initialData: PropTypes.object,
  permissions: PropTypes.object,
  userPosition: PropTypes.object.isRequired,
  initialDevices: PropTypes.array,
  onChangeDevices: PropTypes.func,
  onSaved: PropTypes.func,
  onCancelled: PropTypes.func,
  fleetId: PropTypes.number.isRequired,
};

export default TaskHandleModule;
