/* eslint-disable max-len */
import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const getAllFleets = async () => executeAndWaitResponse(() => apiClient.fleets.all());
export const setDeviceFleet = async (ref, fleetId) => executeAndWaitResponse(() => apiClient.devices.setFleet(ref, fleetId));
export const setMultipleDeviceFleet = async (refs, fleetId) => executeAndWaitResponse(() => apiClient.devices.setFleetMultiple(refs, fleetId));
export const setDevicesStatus = async (payload) => executeAndWaitResponse(() => apiClient.fleets.setDevicesStatus(payload));

export default {
  getAllFleets,
  setDeviceFleet,
  setMultipleDeviceFleet,
  setDevicesStatus,
};
