/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Select } from 'antd';
import styles from './index.module.scss';
import { statusTranslations } from '../../helpers/params';
import IconLinkMap from '../IconLinkMap';

const EventsList = ({
  events,
  showMore,
}) => {
  const [filtersEvent, setFiltersEvent] = useState([]);
  const [filterUserType, setFilterUserType] = useState('all');
  const renderData = (e) => {
    const renderedJSON = (
      <div style={{ fontFamily: 'monospace', wordBreak: 'break-all' }}>
        <small>
          {JSON.stringify(e.data)}
        </small>

      </div>
    );
    switch (e.event) {
      case 'fleet:change_status':
      case 'fleet:change_status:no_change':
        return (
          <>
            {statusTranslations[e.data.original_status] || e.data.original_status}
            {' '}
            -&gt;
            {statusTranslations[e.data.new_status] || e.data.new_status}
            <br />
            {renderedJSON}
          </>
        );
      default:
        return renderedJSON;
    }
  };
  const filteredEvents = events
    .filter((x) => filtersEvent.length === 0 || filtersEvent.includes(x.event))
    .filter((x) => {
      if (filterUserType === 'all') { return true; }
      if (filterUserType === 'employees') { return x.user?.email.endsWith('@grow.mobi'); }
      if (filterUserType === 'users') { return !x.user?.email.endsWith('@grow.mobi'); }
      return false;
    });
  return (

    <div className={styles.events}>
      {(!events || events.length === 0)
        ? <div>No hay eventos</div>

        : (
          <>
            <div className={styles.filters}>
              <Select
                style={{ width: '100%' }}
                placeholder="Filtrar por evento..."
                mode="multiple"
                showSearch
                allowClear
                options={
                  [
                    ...new Set(events.map((x) => x.event)),
                  ].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })).map((x) => ({ label: x, value: x }))
                }
                onChange={(v) => setFiltersEvent(v)}
              />
              <br />
              <Select
                style={{ marginTop: 5, width: '100%' }}
                placeholder="Por tipo de usuario..."
                value={filterUserType}
                showSearch
                options={[
                  { value: 'employees', label: 'Solo Empleados' },
                  { value: 'users', label: 'Solo Usuarios de la APP' },
                  { value: 'all', label: 'Todos los usuarios' },
                ]}
                onChange={(v) => setFilterUserType(v)}
              />
            </div>
            <div className={styles.list}>
              {filteredEvents.map((e) => (
                <div className={styles.item}>
                  <div className={styles.date}>
                    <div className={styles.event}>
                      {e.id}
                      -
                      {e.event}
                    </div>
                    {moment.utc(e.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
                    <br />
                    {e.user?.email}
                    {' '}
                    -
                    {' '}
                    {e.user?.name || 'Sin nombre'}
                    <br />
                    {e.lat && e.lng && (
                      <>
                        {e.lat}
                        ,
                        {e.lng}
                        <br />
                        <IconLinkMap lat={e.lat} lng={e.lng} />
                      </>
                    )}
                  </div>

                  <div className={styles.data}>{renderData(e)}</div>
                </div>
              ))}
              {showMore}
            </div>
          </>
        )}
    </div>
  );
};
EventsList.defaultProps = {
  showMore: null,
};

EventsList.propTypes = {
  events: PropTypes.array.isRequired,
  showMore: PropTypes.object,
};

export default EventsList;
