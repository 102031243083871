/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Col, Row, Button, Table,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import { parseJsonData } from '../../helpers/utils';
import { contractTypesTranslations, rolesTranslations, fleetNames } from '../../helpers/params';
import Loading from '../../components/Loading';

import * as horusService from '../../services/horus';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';

const WorkersDashboardModule = ({
  tenant,
  selectedFleet,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [updatedAt, setUpdatedAt] = useState({});

  const updateData = (key, value) => {
    setData((s) => ({
      ...s,
      [key]: value,
    }));
  };

  const loadData = async () => {
    setLoading(true);

    const queryWorkers = `select u.id, u.email, u.name, u.phone, u.status, u.data, u.created_at, group_concat(distinct r.role) roles, 
    group_concat(distinct f.item_id) fleets 
    from users u 
    left join relations r on r.user_id=u.id and r.role<>'fleet_access'
    left join relations f on f.user_id=u.id and f.role='fleet_access'
    where u.id in (
      select distinct user_id from relations r where r.role='fleet_access' and r.item_id=${selectedFleet.id}
      ) and u.status='ACTIVE' group by 1 order by email asc;
    `;

    const [
      rspWorkers,
    ] = await Promise.all([
      horusService.executeQuery(queryWorkers),
    ]);
    if (rspWorkers?.status) {
      updateData('workers', parseJsonData(rspWorkers.data, 'data'));
    } else {
      updateData('workers', null);
    }
    setUpdatedAt(moment());
    setLoading(false);
  };

  useEffect(() => {
    setData({});
    loadData();
  }, [selectedFleet.id]);

  if (!tenant) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Dashboard De Trabajadores
        <div className={stylesLayout.secAction} style={{ marginTop: -5 }}>
          <Button onClick={() => { loadData(); }} size="medium">
            <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
          </Button>
        </div>
        <div className={stylesLayout.small}>
          Actualizado:
          {' '}
          {moment(updatedAt).fromNow()}
        </div>
      </div>
      <div className={stylesLayout.content} id="content">
        <>

          <Row gutter={24}>
            <Col sm={24} xs={24}>
              <div className={styles.block}>
                <div className={styles.title}>
                  Trabajadores activos en la flota
                  {' '}
                  {selectedFleet.name}
                </div>
                <Table
                  size="small"
                  pagination={{ hideOnSinglePage: true, defaultPageSize: 500 }}
                  locale={{ emptyText: 'Sin datos' }}
                  columns={[
                    {
                      title: 'Usuario',
                      dataIndex: 'id',
                      key: 'id',
                      render: (v, r) => (
                        <>
                          <Link to={`/customer/${v}`}>
                            {r.email}
                          </Link>
                          <br />
                          {r.name}
                        </>
                      ),
                    },
                    {
                      title: 'Operativo',
                      dataIndex: 'data',
                      key: 'working_active_since',
                      render: (v) => (
                        <div>
                          {v.working_status === 'ACTIVE' ? 'Activo' : 'No activo'}
                          {v.working_status === 'ACTIVE' && (
                            <div>
                              <small>{v.working_active_since ? moment.utc(v.working_active_since).local().format('YYYY-MM-DD HH:mm') : '-'}</small>
                              <div><small>{moment(v.working_active_since).fromNow()}</small></div>
                            </div>
                          )}
                        </div>
                      ),
                    },
                    {
                      title: 'Creado',
                      dataIndex: 'created_at',
                      key: 'created_at',
                      render: (v) => (
                        <div>
                          {moment.utc(v).local().format('YYYY-MM-DD HH:mm')}
                          <div><small>{moment(v).fromNow()}</small></div>
                        </div>
                      ),
                    },
                    {
                      title: 'Teléfono',
                      dataIndex: 'phone',
                      key: 'phone',
                      render: (v) => v,
                    },
                    {
                      title: 'RUT/DNI',
                      dataIndex: 'data',
                      key: 'dni',
                      render: (v) => v?.dni,
                    },
                    {
                      title: 'Tipo de contrato',
                      dataIndex: 'data',
                      key: 'contract',
                      render: (v) => (
                        v?.contract
                          ? (
                            <>
                              <div>
                                {(contractTypesTranslations[v.contract.type] ?? v.contract.type) || 'Sin Info'}
                              </div>
                              <div>
                                Inicio:
                                {' '}
                                {v.contract.start_at ? moment(v.contract.start_at).format('YYYY-MM-DD') : 'Sin Info'}
                              </div>
                              <div>
                                Fin:
                                {' '}
                                {v.contract.end_at ? moment(v.contract.end_at).format('YYYY-MM-DD') : 'Sin Info'}
                              </div>
                            </>
                          )
                          : 'Sin Info'
                      ),
                    },
                    {
                      title: 'Roles',
                      width: 200,
                      dataIndex: 'roles',
                      key: 'roles',
                      render: (v) => v?.split(',').map((x) => rolesTranslations[x] || x).join(',') || 'Sin Info',
                    },
                    {
                      title: 'Flotas',
                      width: 200,
                      dataIndex: 'fleets',
                      key: 'fleets',
                      render: (v) => v?.split(',').map((x) => fleetNames[x] || x).join(',') || 'Sin Info',
                    },

                  ]}
                  dataSource={data.workers?.map((d, i) => ({
                    key: i,
                    ...d,
                  }))}
                />
              </div>
            </Col>
          </Row>
        </>
      </div>
    </div>
  );
};

WorkersDashboardModule.propTypes = {
  tenant: PropTypes.object.isRequired,
  selectedFleet: PropTypes.object.isRequired,
};

export default WorkersDashboardModule;
