/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
import DevicesInputWithQR from '../../components/DevicesInputWithQR';

// const nonValidUserEmails = ['generico.bodega@grow.mobi'];
const MultiOpsModule = ({
  // eslint-disable-next-line no-unused-vars
  tenant,
  // eslint-disable-next-line no-unused-vars
  position,
  // eslint-disable-next-line no-unused-vars
  fleets,
  // eslint-disable-next-line no-unused-vars
  fleetId,
  // eslint-disable-next-line no-unused-vars
  user,
}) => (
  <>
    <div className={styles.cnt}>
      <DevicesInputWithQR
        buttonLabel="Agregar"
        placeholder="QR o MAC..."
        autofocus
        onChange={(v) => { console.log('new QR value:', v); }}
      />

      <div className={styles.results}>
        Results
      </div>
      <div className={styles.actions}>
        Actions
      </div>
    </div>
  </>
);

MultiOpsModule.propTypes = {
  user: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};

export default MultiOpsModule;
