import { useState } from 'react';

import * as biService from '../services/bi';

const useBiTable = () => {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState('loading');

  const load = async (table) => {
    setStatus('loading');
    const rsp = await biService.getTable(table);
    if (rsp?.status) {
      setStatus('loaded');
      setData(rsp.data);
    } else {
      setStatus('error');
      setData([]);
    }
  };

  return {
    data,
    status,
    load,
  };
};

export default useBiTable;
