import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import numeral from 'numeral';
import styles from './index.module.scss';
import { getTripQualificationColor } from '../../helpers/utils';
import { computeShortHash } from '../../helpers/orders';

const TripsTable = ({
  trips,
  show,
}) => {
  const tripsColumns = [
    {
      title: '',
      dataIndex: 'grin_qualification',
      key: 'grin_qualification',
      width: 30,
      render: (v) => (
        <FontAwesomeIcon
          icon={faStar}
          style={{
            display: 'inline-block',
            color: getTripQualificationColor(
              v,
            ),
          }}
        />
      ),
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: 180,
      render: (id, r) => (
        <>
          <div>
            <Link to={`/trip/${r.id}`}>{id}</Link>
            {' '}
            -
            {' '}
            <Link to={`/trip/${r.id}`}>{computeShortHash(r.hash)}</Link>
          </div>
        </>
      ),
    },
    show.includes('user')
    && {
      title: 'Usuario',
      dataIndex: 'user',
      key: 'user',
      render: (v) => (
        <>
          {v
            ? (
              <>
                <div>
                  #
                  {v.id}
                  {' '}
                  -
                  {' '}
                  {v.name || '(Sin nombre)'}
                </div>
                <div><small><Link to={`/trips/?q=${v.email}`}>{v.email}</Link></small></div>
                <div>
                  <small>
                    Registro:
                    {' '}
                    {moment.utc(v.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
                  </small>
                </div>
              </>
            )
            : 'Sin datos de usuario'}
        </>
      ),
    },
    {
      title: 'Dispositivo',
      dataIndex: 'device',
      key: 'device',
      render: (v) => (
        <>
          <div>
            <Link to={`/device/${v.qr}`}>{v.qr}</Link>
            <br />
            <small>{v.status_tr}</small>
          </div>
        </>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'cost_calculated',
      key: 'total',
      render: (costCalculated) => (
        numeral(costCalculated.details.payment_total).format('$0,000.00')
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status_tr',
      key: 'status_tr',
      render: (statusTr) => (
        statusTr
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'status_tr',
      key: 'status_tr',
      render: (v, r) => {
        if (r.status === 'STARTING') { return 'Intento de inicio sin completar'; }
        if (r.trip_start_at) { return 'Viaje'; }
        return 'Reserva sin viaje';
      },
    },
    {
      title: 'Comienzo',
      dataIndex: 'trip_start_at',
      key: 'trip_start_at',
      render: (v, r) => (
        (
          <div>
            <div>
              {moment.utc(v || r.start_at).local().format('YYYY-MM-DD HH:mm:ss')}
            </div>
            <div><small>{moment.utc(v || r.start_at).fromNow()}</small></div>
          </div>
        )
      ),
    },
    {
      title: 'Fin',
      dataIndex: 'end_at',
      key: 'end_at',
      render: (v, r) => (
        (v || r.reserved_finished_at || r.reserved_autocancel_at) && (
          <div>
            <div>{moment.utc(v || r.reserved_finished_at || r.reserved_autocancel_at).local().format('YYYY-MM-DD HH:mm:ss')}</div>
            <div>
              <small>
                {moment.utc(v || r.reserved_finished_at || r.reserved_autocancel_at).fromNow()}
              </small>
            </div>
          </div>
        )
      ),
    },
    {
      title: 'Duración (min)',
      align: 'center',
      dataIndex: 'cost_calculated',
      key: 'min',
      render: (v) => (
        v.minutes
      ),
    },
    {
      title: 'Pagado',
      dataIndex: 'paid_at',
      key: 'paid_at',
      render: (paidAt) => (
        paidAt && moment.utc(paidAt).local().format('YYYY-MM-DD HH:mm:ss')
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: (x, r) => (
        <Link to={`/trip/${r.id}`}><Button>Ver</Button></Link>
      ),
    },
  ].filter((x) => !!x);
  return (
    <div className={styles.table}>
      <Table
        size="small"
        pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
        locale={{ emptyText: 'Sin datos' }}
        columns={tripsColumns}
        dataSource={trips.map((d) => ({
          key: d.id,
          ...d,
        }))}
      />
    </div>
  );
};

TripsTable.defaultProps = {
  trips: [],
  show: ['trip', 'user'],
};
TripsTable.propTypes = {
  trips: PropTypes.array,
  show: PropTypes.array,
};

export default TripsTable;
