/* eslint-disable import/prefer-default-export */

export const GOOGLE_MAPS_API_KEY = "AIzaSyDDSMUq3VMdac_wrSqbYS4q1SJoWYcYI_o";
export const IS_PRODUCTION = process.env.NODE_ENV === "production";
const USE_LOCAL = false;

// eslint-disable-next-line no-nested-ternary
export const API_BASE_URL = IS_PRODUCTION
  ? "https://api.grow.mobi"
  : USE_LOCAL
  ? "http://localhost:40002"
  : "https://api.grow.mobi";

// eslint-disable-next-line no-nested-ternary
export const TOTH_BASE_URL = IS_PRODUCTION
  ? "https://toth.grow.mobi"
  : USE_LOCAL
  ? "http://localhost:23013"
  : "https://toth.grow.mobi";

// eslint-disable-next-line no-nested-ternary
export const INGEST_BASE_URL = IS_PRODUCTION
  ? "https://ingest.grow.mobi"
  : USE_LOCAL
  ? "https://ingest.grow.mobi"
  : "https://ingest.grow.mobi";

// eslint-disable-next-line no-nested-ternary
export const PROMETHEUS_BASE_URL = IS_PRODUCTION
  ? "https://prometheus.grow.mobi"
  : USE_LOCAL
  ? "https://prometheus.grow.mobi"
  : "https://prometheus.grow.mobi";

// eslint-disable-next-line no-nested-ternary
export const HORUS_BASE_URL = IS_PRODUCTION
  ? "https://horus.grow.mobi"
  : USE_LOCAL
  ? "http://localhost:25001"
  : "https://horus.grow.mobi";
