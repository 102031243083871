/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isMobile from 'is-mobile';

import {
  Tabs,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

// import TaskDetailModule from '../TaskDetailModule';
import TaskHandleModule from '../TaskHandleModule';
import Loading from '../../components/Loading';
import TaskItem from '../../components/TaskItem';

import {
  getTasks,
} from '../../services/tasks';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';

const { TabPane } = Tabs;

const TasksModule = ({
  tenant,
  user,
  // eslint-disable-next-line no-unused-vars
  fleets,
  position,
  fleetId,
}) => {
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState({});
  const [selectedTask, setSelectedTask] = useState(null);
  const [newTasksByHour, setNewTasksByHour] = useState({});

  const setTasksInStatus = (status, newTasks) => {
    setTasks((s) => ({
      ...s,
      [status]: newTasks,
    }));
  };
  const loadTasks = async (status) => {
    let rsp = null;
    if (status === 'CREATED_BY_YOU') {
      rsp = await getTasks({
        creatorId: user.id,
        status: 'NEW,ASSIGNED',
        fleetId,
      });
    } else if (status === 'YOURS') {
      rsp = await getTasks({
        assigneeId: user.id,
        status: 'ASSIGNED',
        fleetId,
      });
    } else if (status === 'SOLVED' && !user?.can?.manage_tasks) {
      rsp = await getTasks({
        assigneeId: user.id,
        status,
        fleetId,
      });
    } else {
      rsp = await getTasks({
        status: status.toLowerCase(),
        fleetId,
      });
    }
    if (rsp && rsp.status) {
      setTasksInStatus(status.toLowerCase(), rsp.data);
      if (status.toUpperCase() === 'NEW') {
        const g = isMobile() ? 2 : 1;
        const byHours = rsp.data.reduce((a, x) => {
          let hours = moment.utc().diff(moment.utc(x.created_at), 'hours');
          hours = Math.floor(hours / g) * g;
          if (hours >= 24) {
            hours = 24;
          }
          return {
            ...a,
            [hours]: (a[hours] || 0) + 1,
          };
        }, {});
        setNewTasksByHour(byHours);
      }
    }
    setLoading(false);
  };

  const loadAllTasks = () => {
    loadTasks('YOURS');
    loadTasks('CREATED_BY_YOU');
    if (user?.can?.manage_tasks) {
      loadTasks('NEW');
      loadTasks('ASSIGNED');
    }
    loadTasks('SOLVED');
  };

  useEffect(() => {
    setLoading(true);
    loadAllTasks();
    const pidSync = window.setInterval(() => {
      loadAllTasks();
    }, 5000);

    return () => {
      if (pidSync) {
        window.clearInterval(pidSync);
      }
    };
  }, [fleetId]);

  if (loading || !tenant) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }
  const g = isMobile() ? 2 : 1;
  const colsByHour = 24 / g + 2;
  // const stepsStore = getStepsStore(store);
  // const pendingStepsStore = (stepsStore.filter((x) => !x.completed));
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Misiones
      </div>
      <div className={stylesLayout.content}>
        {user?.can?.manage_tasks
          && (
            <div>
              <div className={styles.hoursTimeline}>
                <div className={styles.line} style={{ gridTemplateColumns: `repeat(${colsByHour}, 1fr)` }}>
                  <div className={[styles.titleLine].join(' ')}>Horas</div>
                  {[...Array(colsByHour - 1).keys()].map((h) => (
                    <div className={[styles.hour, newTasksByHour[h] ? styles.hasValue : ''].join(' ')} key={h}>
                      {h * g >= 24 ? `>${h * g}` : h * g}
                    </div>
                  ))}
                </div>
                <div className={styles.line} style={{ gridTemplateColumns: `repeat(${colsByHour}, 1fr)` }}>
                  <div className={[styles.titleLine].join(' ')}>Taskes</div>
                  {[...Array(colsByHour - 1).keys()].map((h) => (
                    <div className={[styles.item, newTasksByHour[h * g] ? styles.hasValue : ''].join(' ')} key={h}>
                      {newTasksByHour[h * g] || 0}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        <div className={[stylesLayout.content, styles.content, selectedTask ? styles.withSelectedTask : ''].join(' ')}>
          <Tabs defaultActiveKey={tasks?.new?.length > 0 ? 'new' : 'yours'}>
            <TabPane
              tab={(
                <>
                  {tasks?.yours?.length || 0}
                  {' '}
                  Asignadas a ti
                </>
              )}
              key="yours"
            >
              <>
                {tasks?.yours?.length > 0
                  ? tasks.yours.map(
                    (t) => <TaskItem task={t} onSelect={() => { setSelectedTask(t); }} />,
                  )
                  : <div className={stylesLayout.message}> No hay Misiones</div>}
              </>

            </TabPane>
            <TabPane
              tab={(
                <>
                  {tasks?.created_by_you?.length || 0}
                  {' '}
                  Creadas por ti
                </>
              )}
              key="by_you"
            >
              <>
                {tasks?.created_by_you?.length > 0
                  ? tasks.created_by_you.map(
                    (t) => <TaskItem task={t} onSelect={() => { setSelectedTask(t); }} />,
                  )
                  : <div className={stylesLayout.message}> No hay Misiones</div>}
              </>

            </TabPane>
            {user?.can?.manage_tasks && (
              <>
                <TabPane
                  tab={(
                    <>
                      {tasks?.new?.length || 0}
                      {' '}
                      sin asignar
                    </>
                  )}
                  key="new"
                >
                  <>
                    {tasks?.new?.length > 0
                      ? tasks.new.map(
                        (t) => <TaskItem task={t} onSelect={() => { setSelectedTask(t); }} />,
                      )
                      : <div className={stylesLayout.message}> No hay Misiones</div>}
                  </>

                </TabPane>
                <TabPane
                  tab={(
                    <>
                      {tasks?.assigned?.length || 0}
                      {' '}
                      asignadas
                    </>
                  )}
                  key="assigned"
                >
                  <>
                    {tasks?.assigned?.length > 0
                      ? tasks.assigned.map(
                        (t) => <TaskItem task={t} onSelect={() => { setSelectedTask(t); }} />,
                      )
                      : <div className={stylesLayout.message}> No hay Misiones</div>}
                  </>

                </TabPane>

              </>
            )}
            <TabPane tab={`${tasks?.solved?.length || 0} Solucionada${tasks?.solved?.length > 1 ? 's' : ''} recientemente`} key="solved">
              <>
                {tasks?.solved?.length > 0
                  ? tasks.solved.map(
                    (t) => <TaskItem task={t} onSelect={() => { setSelectedTask(t); }} />,
                  )
                  : <div className={stylesLayout.message}>No hay Misiones</div>}
              </>

            </TabPane>
          </Tabs>
          {selectedTask
            && (
              <div className={styles.taskDetail}>
                <div className={styles.title}>
                  Detalles de la misión
                  <div className={stylesLayout.secAction} style={{ marginTop: -5, marginRight: 0 }}>
                    <span
                      onClick={() => setSelectedTask(null)}
                      style={{
                        display: 'inline-block', padding: '5px 5px', lineHeight: '26px',
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </div>
                </div>
                <div className={styles.taskcontent}>
                  <TaskHandleModule
                    key={`task-${selectedTask.id}`}
                    userPosition={position}
                    user={user}
                    task={selectedTask}
                    onSaved={() => { loadAllTasks(); setSelectedTask(null); }}
                    onCancelled={() => setSelectedTask(null)}
                    permissions={{
                      reassign: selectedTask.status !== 'SOLVED' && user?.can?.manage_tasks,
                      modifyDevices: selectedTask.status !== 'SOLVED' && user?.can?.manage_tasks,
                      modifyTask: selectedTask.status !== 'SOLVED' && user?.can?.manage_tasks,
                      viewTask: selectedTask.status === 'SOLVED' || user?.can?.handle_tasks,
                    }}
                  />

                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

TasksModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};

export default TasksModule;
