import red from './red.svg';
import blue from './blue.svg';
import pink from './pink.svg';
import gray from './gray.svg';
import black from './black.svg';
import green from './green.svg';
import yellow from './yellow.svg';
import coffee from './coffee.svg';
import orange from './orange.svg';
import purple from './purple.svg';
import rideRed from './ride-red.svg';
import stolenRed from './stolen-red.svg';
import rideGreen from './ride-green.svg';
import greenlight from './greenlight.svg';
import stolenGray from './stolen-gray.svg';
import stolenBlack from './stolen-black.svg';
import stolenPurple from './stolen-purple.svg';

export default {
  red,
  blue,
  pink,
  gray,
  black,
  green,
  yellow,
  coffee,
  orange,
  purple,
  rideRed,
  stolenRed,
  rideGreen,
  greenlight,
  stolenGray,
  stolenBlack,
  stolenPurple,
};
