/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'antd';
import Widget from '../../components/Widget';

// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css';
import { SimpleMapComponent } from '../../components/SimpleMapComponent';

const ActivityModule = ({
  // eslint-disable-next-line no-unused-vars
  user,
  fleets,
  fleetId,
}) => (

  <div style={{ padding: 20, display: 'grid', gap: 20 }}>
    <Widget
      title="Actividad de hoy"
      style={{ minHeight: 200 }}
      source={`
    const conn = db.getConnectionFromConfig('paperboy');

const query = \`

select u.email, d.qr, e.created_at, e.event, e.lat, e.lng,
  concat("https://www.google.com/maps/search/?api=1&query=",e.lat,",",e.lng) link,
case
   when e.event='fleet:change_status' THEN concat(json_unquote(json_extract(e.data, '$.original_status')),  ' (', json_unquote(json_extract(e.data, '$.device.batsco')), '% bat) -> ', json_unquote(json_extract(e.data, '$.new_status')))
      when e.event='device:not_found' THEN 'No encontrado'
      when e.event='device:reset' THEN 'Reseteado'
   else ''
end info,
 e.data,  
d.status from events e, devices d, users u where u.id=e.user_id and d.id=e.item_id 
and (
e.event in ('device:reset', 'device:not_found', 'fleet:change_status')
or e.event like 'check%'
or e.event like '%Trip%'
)
and u.email<>'system@grow.mobi'
and u.email like '%@grow.mobi'
and e.created_at>date(now()-interval 3 hour)+interval 3 hour - interval 0 day and d.fleet_id=${fleetId} order by e.id asc;
\`;

const rsp = await db.query(conn,query);

return rsp;
    `}
      render={(data) => {
        if (!data) {
          return <div>Error!</div>;
        }
        const markers = data.filter((x) => x.lat && x.lng).map((x) => {
          let top = 0;
          let bottom = 0;
          let color = '#444';
          if (x.event === 'fleet:change_status') {
            let d = {};
            try {
              d = JSON.parse(x.data);
              // eslint-disable-next-line no-empty
            } catch (e) {
            }

            if (d?.new_status === 'in_transport_to_deploy') {
              color = '#090';
              top = '30%';
              bottom = '30%';
            } else if (d?.new_status === 'live') {
              color = '#090';
              top = '0%';
              bottom = '50%';
            } else if (d?.new_status === 'in_warehouse') {
              color = '#f06';
              top = '50%';
              bottom = '0%';
            } else if (d?.new_status === 'in_transport_to_warehouse') {
              color = '#f06';
              top = '30%';
              bottom = '30%';
            } else {
              color = '#999';
            }
          }

          return {
            latitude: x.lat,
            longitude: x.lng,
            ts: moment(x.created_at).unix(),
            timeline: {
              mark: {
                color,
                top,
                bottom,
              },
            },
            label: (
              <div>
                {x.email}
                <br />
                {x.event}
                <br />
                {x.qr}
                {' '}
                -
                {' '}
                {x.info}
                <br />
                {moment.utc(x.created_at).local().format('HH:mm')}
              </div>
            ),
          };
        });
        console.log('markers', markers);
        const selectedFleet = fleets.find((x) => x.id === fleetId);
        const center = {
          lat: selectedFleet.cost.headquarter.lat,
          lng: selectedFleet.cost.headquarter.lng,
        };
        return (
          <>
            <div style={{ height: 820 }}>
              <SimpleMapComponent
                withTimeline
                timelineOptions={{
                  format: 'HH:mm',
                }}
                zoom={13}
                defaultCenter={center}
                minZoom={1}
                minimumClusterSize={10000}
                maxZoom={20}
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                loadingElement={(
                  <div style={{ height: 400 }} />
                )}
                containerElement={(
                  <div
                    style={{
                      height: 400,
                    }}
                  />
                )}
                mapElement={<div style={{ borderRadius: 0, height: 700 }} />}
                markers={markers}
              />
            </div>
            <Table
              size="small"
              pagination={{ hideOnSinglePage: true, defaultPageSize: 200 }}
              locale={{ emptyText: 'Sin datos' }}
              columns={[
                {
                  title: '#',
                  dataIndex: 'id',
                  align: 'center',
                  key: 'id',
                  width: '5%',
                  render: (_, __, index) => (<>{index + 1}</>),
                },
                {
                  title: 'Usuario',
                  dataIndex: 'email',
                  key: 'email',
                  render: (v) => v,
                },
                {
                  title: 'QR',
                  dataIndex: 'qr',
                  key: 'qr',
                  render: (v) => (v ? <Link to={`/device/${v}`}>{v}</Link> : ''),
                },
                {
                  title: 'Evento',
                  dataIndex: 'event',
                  key: 'event',
                  render: (v, r) => (
                    <div>
                      <div>{v}</div>
                      <div style={{ fontSize: 10 }}>{r.info}</div>
                    </div>
                  ),
                },
                {
                  title: 'Fecha/Hora',
                  dataIndex: 'created_at',
                  key: 'created_at',
                  render: (v) => moment.utc(v).local().format('YYYY-MM-DD HH:mm'),
                },
                {
                  title: 'Lat/Lng',
                  dataIndex: 'lat',
                  key: 'lat',
                  render: (_, r) => (

                    (r.lat && r.lng)
                      ? (
                        <>
                          <a href={r.link} target="_blank" rel="noreferrer">
                            {r.lat}
                            ,
                            {r.lng}
                          </a>
                        </>
                      )
                      : <>Sin info</>

                  ),
                },
              ]}
              dataSource={data}
            />
          </>
        );
      }}
    />

  </div>
);

ActivityModule.propTypes = {
  user: PropTypes.object.isRequired,
  fleetId: PropTypes.number.isRequired,
  fleets: PropTypes.array.isRequired,
};
export default ActivityModule;
