/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SimpleMapComponent } from '../SimpleMapComponent';

import styles from './index.module.scss';

const LocationPicker = ({
  location,
  defaultLocation,
  defaultZoom,
  editable,
  onChange,
}) => {
  const [currentLocation, setCurrentLocation] = useState(location || defaultLocation);
  useEffect(() => {
    if (location) {
      setCurrentLocation(location);
    }
  }, [location]);

  return (
    <div className={styles.cntMapFull}>
      <SimpleMapComponent
        zoom={defaultZoom}
        defaultCenter={currentLocation}
        minZoom={defaultZoom - 4}
        googleMapOptions={{ disableDefaultUI: true }}
        minimumClusterSize={10000}
        maxZoom={defaultZoom + 2}
        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
        containerElement={(
          <div
            style={{
              height: '100%',
            }}
          />
        )}
        mapElement={<div style={{ height: '100%' }} />}
        markers={[
          {
            lat: currentLocation.lat,
            lng: currentLocation.lng,
            draggable: editable,
            onDragEnd: (marker, e) => {
              const newLocation = {
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
              };
              setCurrentLocation(newLocation);
              onChange(newLocation);
            },
          },
        ]}
      />
    </div>
  );
};

LocationPicker.defaultProps = {
  onChange: () => { },
  location: null,
  defaultZoom: 18,
  editable: false,
};

LocationPicker.propTypes = {
  defaultLocation: PropTypes.object.isRequired,
  location: PropTypes.object,
  onChange: PropTypes.func,
  defaultZoom: PropTypes.number,
  editable: PropTypes.bool,
};

export default LocationPicker;
