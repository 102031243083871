import { TOTH_BASE_URL } from '../../environment';

export default (client, defaultParams) => ({

  compute: (payload) => (
    client(`${TOTH_BASE_URL}/metric/compute`, {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),

});
