/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useState, useRef,
} from 'react';
import isMobile from 'is-mobile';
import PropTypes from 'prop-types';
import {
  Button,
} from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';

import RowByStatus from '../../components/RowByStatus';
import { MapComponent } from '../../components/MapComponent';

import {
  findDevices,
}
  from '../../services/devices';

import {
  getGeofencesForFleet,
} from '../../services/geofences';

import {
  colorZone,
} from '../../helpers/params';

import styles from './index.module.scss';

const ViewerModule = ({
  fleets,
  fleetId,
}) => {
  const [findLocation, setFindLocation] = useState(null);
  const findLocationRef = useRef(findLocation);
  findLocationRef.current = findLocation;
  const [mapPosition, setMapPosition] = useState(null);
  const [devices, setDevices] = useState([]);
  const [geofences, setGeofences] = useState(null);
  const [selectedDevice] = useState(null);
  const [filterStatus] = useState(['live', 'in_ride', 'reserved', 'stolen_suspect', 'missing_connection', 'in_transport_to_deploy', 'in_transport_to_warehouse']);
  const [filterBattery] = useState([0, 100]);
  const [filterLastReport] = useState([0, 48]);
  const [loading, setLoading] = useState(false);

  const loadGeofences = async () => {
    const rsp = await getGeofencesForFleet([fleetId], ['all']);
    if (rsp && rsp.status) {
      setGeofences(rsp.data.filter((x) => x.id === 1 && x.type !== 'TRIP_MICROZONE' && x.status === 'ACTIVE'));
    } else {
      setGeofences([]);
    }
  };

  const loadDevices = async (pos = null) => {
    setLoading(true);
    const rsp = await findDevices({
      q: null,
      fleetId,
      position: pos || findLocationRef.current,
      output: 'map',
      status: filterStatus?.length > 0 ? filterStatus : null,
    });
    if (rsp && rsp.status) {
      setDevices(rsp.data.filter((x) => x.data?.in_geozones?.includes(1)));
    } else {
      setDevices([]);
    }
    setLoading(false);
  };

  const filterDevicesByUpdated = (devs) => devs.filter((d) => moment(d.geo_updated_at) > moment().subtract(20, 'day'));
  const filterDevices = (devs) => {
    let filteredDevices = devs.map((d) => ({
      ...d, id: d.id, latitude: d.lat, longitude: d.lng,
    }));
    filteredDevices = filterDevicesByUpdated(filteredDevices);
    if (filterLastReport) {
      filteredDevices = filteredDevices.filter(
        (d) => d.data.mins_since_last_connection > (filterLastReport[0] * 60)
          && d.data.mins_since_last_connection < (filterLastReport[1] * 60),
      );
    }
    if (filterBattery) {
      filteredDevices = filteredDevices.filter(
        (d) => d.data.battery > filterBattery[0] && d.data.battery < filterBattery[1],
      );
    }
    if (filterStatus && filterStatus.length > 0) {
      filteredDevices = filteredDevices.filter(
        (d) => {
          if (filterStatus === '') {
            return d.status === '';
          }
          if (filterStatus.includes('with_error')) {
            if (d.data.coderr > 0) {
              return true;
            }
          }
          if (filterStatus.includes('out_of_area')) {
            if (d.out_of_area) {
              if (d.out_of_area) {
                return true;
              }
            }
          }
          return d.status !== '' && filterStatus.includes(d.status);
        },
      );
    }
    return filteredDevices;
  };

  useEffect(() => {
    let pid = null;
    const selectedFleet = fleets.find((x) => x.id === fleetId);
    loadGeofences();
    setMapPosition({
      latitude: selectedFleet.cost.headquarter.lat,
      longitude: selectedFleet.cost.headquarter.lng,
    });
    setFindLocation({
      lat: selectedFleet.cost.headquarter.lat,
      lng: selectedFleet.cost.headquarter.lng,
    });
    loadDevices();
    pid = window.setInterval(() => loadDevices(), 10000);
    return () => {
      if (pid) { window.clearInterval(pid); }
    };
  }, [fleetId]);

  const visibleDevices = filterDevices(devices);
  const onlyOneDevice = visibleDevices && visibleDevices.length === 1;
  if (!mapPosition) {
    return null;
  }
  const mapCenterLat = onlyOneDevice
    ? visibleDevices.latitude : (selectedDevice ? selectedDevice.lat : mapPosition.latitude);
  const mapCenterLng = onlyOneDevice
    ? visibleDevices.longitude : (selectedDevice ? selectedDevice.lng : mapPosition.longitude);

  return (
    <>
      <div className={[styles.main].join(' ')}>

        <div className={[styles.inner].join(' ')}>
          <div className={styles.map}>
            <div className={styles.results}>
              <div className={styles.line}>
                <div style={{ display: 'inline-block' }}>
                  {visibleDevices.length}
                  {' '}
                  {visibleDevices.length === 1 ? 'dispositivo' : 'dispositivos'}
                  {loading && <span> ...</span>}
                </div>
                <div>
                  <Button onClick={() => loadDevices()}>
                    <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
                  </Button>
                </div>
              </div>
              <div className={styles.byStatus}>
                <RowByStatus
                  devices={visibleDevices}
                  size="small"
                  cols={isMobile() ? 4 : 8}
                />
              </div>
            </div>

            <MapComponent
              key={fleetId}
              fleetId={fleetId}
              zoom={onlyOneDevice ? 13 : 13}
              defaultCenter={{ lat: mapCenterLat, lng: mapCenterLng }}
              onBoundsChanged={(p1, p2, center) => { setFindLocation(center); }}
              minZoom={1}
              maxZoom={20}
              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `calc(100%-${isMobile() ? 0 : 100}px-116px-${isMobile() && (selectedDevice) ? 300 : 0}px)` }} />}
              containerElement={(
                <div
                  style={{
                    height: window.innerHeight
                      - (isMobile() ? (40 + 92 + 52) : (40 + 90))
                      - (isMobile() && (selectedDevice) ? 300 : 0),
                  }}
                />
              )}
              mapElement={<div style={{ borderRadius: 5, height: '100%' }} />}
              markers={visibleDevices}
              onSelectMarker={() => { }}
              polygons={geofences
                && geofences.map(
                  (x) => ({ ...x, color: x.status === 'INACTIVE' ? '#999999' : (colorZone[x.type]) }),
                )}
            />
          </div>
        </div>
      </div>
    </>
  );
};
ViewerModule.propTypes = {
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};
export default ViewerModule;
