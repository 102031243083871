/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import QRCode from 'react-qr-code';
import isMobile from 'is-mobile';
import {Link} from 'react-router-dom';
import {TagsInput} from 'react-tag-input-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faLock,
  faUnlock,
  faPlug,
  faSync,
  faLocationArrow,
  faCog,
  faQrcode,
  faExternalLinkAlt,
  faCopy,
  faVolumeUp,
  faVolumeMute,
  faExclamationCircle,
  faPowerOff,
  faTimes,
  faStar,
  faExclamationTriangle,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Input,
  notification,
  Popconfirm,
  Select,
  Table,
  Checkbox,
  Modal,
  Tag,
} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {getTripQualificationColor} from '../../../helpers/utils';
import {
  findRecentForDeviceTrips,
  start as tripStart,
} from '../../../services/trips';
import {createEvent} from '../../../services/events';
import {setDeviceFleet, setDevicesStatus} from '../../../services/fleets';
import wifi0 from '../../../assets/signal/wifi-0.svg';
import wifi1 from '../../../assets/signal/wifi-1.svg';
import wifi2 from '../../../assets/signal/wifi-2.svg';
import wifi3 from '../../../assets/signal/wifi-3.svg';
import wifi4 from '../../../assets/signal/wifi-4.svg';

import purplePoint from '../../../assets/points/purple.svg';
import grayPoint from '../../../assets/points/gray.svg';

import MqttSubscriptionList from '../../MqttSubscriptionList';
import EventsList from '../../EventsList';
import MapPositionsList from '../../MapPositionsList';
import CopyWithMessage from '../../CopyWithMessage';

import stylesLayout from '../../../common/layout.module.scss';
import styles from './index.module.scss';

import {
  networks,
  getBatteryIcon,
  statusTranslations,
  brands,
} from '../../../helpers/params';
import {
  getLog,
  getEvents,
  getGeopositions,
  getDeviceByTypeQROrRef,
  deleteByTypeRef,
  getCommandForAction,
  sendDeviceCommand,
  setDeviceQr,
  setDeviceSerial,
  getEntelInfo,
  getTwilioInfo,
  setDeviceBrand,
  removeIot,
  setIot,
  setDeviceTags as storeDeviceTags,
  setPosition,
  getStatusTransitionsAllowed,
} from '../../../services/devices';
import {MapComponent} from '../../MapComponent';
import DeviceDamageModule from '../../../modules/DeviceDamageModule';
import DeviceReparationModule from '../../../modules/DeviceReparationModule';

const {Option} = Select;

const ScooterResume = ({
  user,
  defaultDevice,
  showClose,
  fleets,
  onClose,
  showMap,
  position,
  openActions,
  updating,
  buttonCols,
  pollPeriod,
}) => {
  const [showActions, setShowActions] = useState(openActions);
  const [loadingLog, setLoadingLog] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [loadingTrips, setLoadingTrips] = useState(false);
  const [loadingGeopositions, setLoadingGeopositions] = useState(false);
  const [geoPositionHours, setGeoPositionHours] = useState(1);
  const [log, setLog] = useState(false);
  const [events, setEvents] = useState(false);
  const [device, setDevice] = useState(defaultDevice);
  const [newQr, setNewQr] = useState('');
  const [newSerialNumberScooter, setNewSerialNumberScooter] = useState('');
  const [newSerialNumberBattery, setNewSerialNumberBattery] = useState('');
  const [newSerialNumberScooterBoard, setNewSerialNumberScooterBoard] =
    useState('');
  const [newIot, setNewIot] = useState('');
  const [deviceTags, setDeviceTags] = useState([]);
  const [statusSendMessage, setStatusSendMessage] = useState('');
  const [deviceSendMessage, setDeviceSendMessage] = useState('');
  const [statusTransitionsAllowed, setStatusTransitionsAllowed] = useState({});
  const [changeLocationOnUpdate, setChangeLocationOnUpdate] = useState(
    isMobile()
  );

  const [lastTrips, setLastTrips] = useState([]);

  const [geopositions, setGeopositions] = useState(false);

  const updateDevice = async (forceRef = null) => {
    if (!device) {
      return;
    }
    const rsp = await getDeviceByTypeQROrRef({
      type: device.type,
      code: forceRef || device.ref,
    });
    if (rsp && rsp.status) {
      setDevice(rsp.data);
    }
  };

  const changeFleet = async (fleetId) => {
    notification.destroy('result');
    notification.info({
      message: 'Cambiando flota...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await setDeviceFleet(device.ref, fleetId);
    if (rsp?.status) {
      notification.success({
        message: 'Flota cambiada con éxito.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message:
          'Error al cambiar flota, verifica que no exista otro patin con el mismo QR y flota asignada.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const getDeviceSimInfo = async () => {
    if (!device.data?.iccid) {
      notification.error({
        message: 'Dispositivo sin iccid.',
        placement: 'top',
        key: 'result',
      });
      return;
    }
    let rsp;
    let provider;
    if (device.data?.iccid.startsWith('8988')) {
      rsp = await getTwilioInfo(device.data.iccid);
      provider = 'Twilio';
    } else {
      rsp = await getEntelInfo(device.data.iccid);
      provider = 'Entel';
    }

    notification.destroy('result');
    notification.info({
      message: `Obteniendo info de ${provider}...`,
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    if (rsp?.status) {
      notification.success({
        message: (
          <>
            Info de {provider}
            <br />
            <br />
            {provider === 'Entel' ? (
              <div style={{fontSize: 14}}>
                User: {rsp.data.user} - {rsp.data.username}
                <br />
                <br />
                Status: {rsp.data.status}
                <br />
                <br />
                Plan: {rsp.data.ratePlan}
                <br />
                <br />
                Última sesion
                <br />
                Inicio:{' '}
                {moment
                  .utc(rsp.data.sessionInfo.dateSessionStarted)
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss')}
                <br />
                Fin:{' '}
                {rsp.data.sessionInfo.dateSessionEnded
                  ? moment
                      .utc(rsp.data.sessionInfo.dateSessionEnded)
                      .local()
                      .format('YYYY-MM-DD HH:mm:ss')
                  : 'Activa'}
              </div>
            ) : (
              <div style={{fontSize: 14}}>
                Status:
                {rsp.data.status}
                <br />
                ICCID:
                {rsp.data.iccid}
                <br />
                SID:
                {rsp.data.sid}
                <br />
              </div>
            )}
          </>
        ),
        placement: 'top',
        key: 'result',
        duration: 0,
      });
    } else {
      notification.error({
        message: `Error al obtener info de ${provider}.`,
        placement: 'top',
        key: 'result',
      });
    }
  };

  const changeQr = async (qr) => {
    notification.destroy('result');
    notification.info({
      message: 'Cambiando QR...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await setDeviceQr(device.ref, qr);
    if (rsp?.status) {
      notification.success({
        message: 'QR cambiado con éxito.',
        placement: 'top',
        key: 'result',
      });
      updateDevice();
    } else {
      notification.error({
        message: 'Error al cambiar QR.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const changeSerial = async (field, value) => {
    notification.destroy('result');
    notification.info({
      message: 'Cambiando Serial...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    console.log('xxxxx', device.ref, field, value);
    const rsp = await setDeviceSerial(device.ref, field, value);
    if (rsp?.status) {
      notification.success({
        message: 'Serial cambiado con éxito.',
        placement: 'top',
        key: 'result',
      });
      updateDevice();
    } else {
      notification.error({
        message: 'Error al cambiar Serial.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const changeBrand = async (brand) => {
    notification.destroy('result');
    notification.info({
      message: 'Cambiando branding...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await setDeviceBrand(device.ref, brand);
    if (rsp?.status) {
      notification.success({
        message: 'Branding cambiado con éxito.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar el branding.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const loadLog = async (limit = 200, beforeId = null, refresh = true) => {
    setLoadingLog(true);
    const rsp = await getLog(device.ref, device.type, limit, beforeId);
    if (rsp && rsp.data) {
      if (refresh) {
        setLog(rsp.data);
      } else {
        setLog([...rsp.data, ...(log || [])]);
      }
    }
    setLoadingLog(false);
  };

  const loadEvents = async (limit = 200, beforeId = null, refresh = true) => {
    setLoadingEvents(true);
    const rsp = await getEvents(device.ref, device.type, limit, beforeId);
    if (rsp && rsp.data) {
      if (refresh) {
        setEvents(rsp.data);
      } else {
        setEvents([...(events || []), ...rsp.data]);
      }
    }
    setLoadingEvents(false);
  };

  const loadStatusTransitionsAllowed = async () => {
    const rsp = await getStatusTransitionsAllowed(device.fleet_id);
    if (rsp?.status) {
      setStatusTransitionsAllowed(rsp.data);
    }
  };

  const newAvailableStatuses = (statusTransitionsAllowed[device.status] || [])
    .map((x) => ({value: x, label: statusTranslations[x] || x}))
    .sort((a, b) => a.label.localeCompare(b.label));

  const loadGeopositions = async (hours) => {
    setLoadingGeopositions(true);
    const rsp = await getGeopositions(device.ref, device.type, hours);
    if (rsp && rsp.data) {
      setGeopositions(rsp.data);
    }
    setLoadingGeopositions(false);
  };

  const loadTrips = async () => {
    if (!device?.id) {
      return;
    }
    setLoadingTrips(true);
    const rsp = await findRecentForDeviceTrips(device.id, 14);
    if (rsp?.status) {
      setLastTrips(rsp.data);
    }
    setLoadingTrips(false);
  };

  const onSetDeviceInPosition = async (lat, lng) => {
    const rsp = await setPosition(device.ref, lat, lng);
    if (rsp && rsp.data) {
      updateDevice();
      loadGeopositions(geoPositionHours);
    }
  };
  const onRemoveIot = async () => {
    notification.info({
      message: 'Quitando IOT...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await removeIot(device.ref);
    if (rsp && rsp.status) {
      updateDevice(rsp.data.ref);
      notification.success({
        message: 'IOT quitado con éxito.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al quitar IOT.',
        placement: 'top',
        key: 'result',
      });
    }
  };
  const onSetIot = async (newRef) => {
    notification.info({
      message: 'Asignando IOT...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await setIot(device.ref, newRef);
    if (rsp && rsp.status) {
      updateDevice(rsp.data.ref);
      notification.success({
        message: 'IOT asignado con éxito.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message:
          'Error al asignar IOT. Verifica que ese MAC no se encuentre asociado a otro patín.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const onDeleteByTypeRef = async () => {
    const rsp = await deleteByTypeRef(device.ref, device.type);
    if (rsp && rsp.status) {
      updateDevice(rsp.data.ref);
    }
  };
  const onTripStart = async (id) => {
    const rsp = await tripStart(id);
    notification.destroy('result');
    notification.info({
      message: 'Iniciando viaje...',
      placement: 'bottom',
      duration: 0,
      key: 'result',
    });

    if (rsp?.status) {
      notification.success({
        message: 'Viaje iniciado con éxito.',
        placement: 'bottom',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'El viaje NO pudo ser iniciado.',
        placement: 'bottom',
        key: 'result',
      });
    }
  };

  const updateDeviceTags = async (tags) => {
    setDeviceTags(tags);
    const rsp = await storeDeviceTags(device.ref, tags);
    if (rsp?.status) {
      console.log('ok');
    }
  };

  const moveDevicesToStatus = async (
    status,
    data = {},
    allowEvenIfNotUpdateLocation = false
  ) => {
    if (!allowEvenIfNotUpdateLocation && !changeLocationOnUpdate) {
      // eslint-disable-next-line no-restricted-globals
      Modal.confirm({
        width: 800,
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            ¿Estás seguro que{' '}
            <strong>no quieres poner los patines en tu ubicación actual</strong>
            ?
          </div>
        ),
        okText: 'Si',
        okButtonProps: {danger: true},
        cancelText: 'No',
        onOk() {
          moveDevicesToStatus(status, data, true);
        },
        onCancel() {},
      });
      return;
    }
    notification.destroy('result');
    notification.info({
      message: 'Cambiando estado...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await setDevicesStatus({
      devices: [device.id],
      status,
      selectedUserId: user.id,
      setLocation: changeLocationOnUpdate,
      data,
    });
    if (rsp && rsp.status) {
      updateDevice();
      notification.destroy('result');
      notification.success({
        message: 'Cambiado con éxito.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.destroy('result');
      notification.error({
        message: 'Ocurrió un error al cambiar el estado.',
        placement: 'top',
        key: 'result',
      });
    }
  };
  const onAction = async (action, params = null, showNotification = true) => {
    const command = getCommandForAction(action, params);
    if (!command) {
      return null;
    }
    if (showNotification) {
      notification.destroy('result');
      notification.info({
        message: 'Enviando comando...',
        placement: 'bottom',
        duration: 0,
        key: 'result',
      });
    }
    const payload = {
      event: 'device:send_command',
      item_type: 'device',
      data: {
        command,
        action,
      },
      user_id: user.id,
      item_id: device.id,
      lat: position && position.latitude,
      lng: position && position.longitude,
    };
    createEvent(payload);
    const rsp = await sendDeviceCommand({
      type: device.type,
      ref: device.ref,
      command,
      sync: true,
      useBackup: device.data?.iot_firmware_version >= 35004,
    });

    if (rsp?.status) {
      if (showNotification) {
        notification.success({
          message: (
            <>
              Enviado con éxito.
              <br />
              <small>{JSON.stringify(rsp.data?.response)}</small>
            </>
          ),
          placement: 'bottom',
          key: 'result',
        });
      }
      if (rsp.data?.response?.command === 201) {
        setDevice({
          ...device,
          lat: parseFloat(rsp.data?.response?.response['position.latitude']),
          lng: parseFloat(rsp.data?.response.response['position.longitude']),
        });
      }
    } else if (showNotification) {
      notification.error({
        message: 'Ocurrió un error al enviar el comando. Intenta nuevamente.',
        placement: 'bottom',
        key: 'result',
      });
    }
    updateDevice();
    return rsp?.status;
  };

  useEffect(() => {
    setShowActions(openActions);
  }, [openActions]);
  useEffect(() => {
    setDevice(defaultDevice);
  }, [defaultDevice]);

  useEffect(() => {
    // onAction('get_lock_status', null, false);
    if (!device) {
      return null;
    }

    const dSendMessage = `${device.qr} - ${
      device.fleet?.name || 'Sin Flota'
    } - ${device.status_tr}

https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}
        
https://paperboy.grow.mobi/device/${device.qr}

${device.data?.locked === 0 ? 'Desbloqueado' : 'Bloqueado'} - ${
      parseInt(device.data?.engine_on, 10) === 0
        ? 'Motor apagado'
        : 'Motor encendido'
    } - ${device.data?.batsco}% de batería

${
  device.data.instant_velocity_km_h > 0
    ? `Velocidad instantánea: ${device.data.instant_velocity_km_h} km/h`
    : ''
}
Últ Posición: ${
      device.geo_updated_at
        ? `${moment.utc(device.geo_updated_at).fromNow()}`
        : 'Sin info'
    }
Últ mensaje: ${
      device.data.last_message_from_device_at
        ? `${moment.utc(device.data.last_message_from_device_at).fromNow()}`
        : 'Sin info'
    }
Últ HB: ${
      device.data.last_heartbeat_from_device_at
        ? `${moment.utc(device.data.last_heartbeat_from_device_at).fromNow()}`
        : 'Sin info'
    }`;

    setDeviceSendMessage(dSendMessage);

    setStatusSendMessage(`🚨🚨 ${device.qr} - ${
      device.fleet?.name || 'Sin Flota'
    } - Sospecha de robo

https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}
        
https://paperboy.grow.mobi/device/${device.qr}

${device.data?.locked === 0 ? 'Desbloqueado' : 'Bloqueado'} - ${
      parseInt(device.data?.engine_on, 10) === 0
        ? 'Motor apagado'
        : 'Motor encendido'
    } - ${device.data?.batsco}% de batería

${
  device.data.instant_velocity_km_h > 0
    ? `Velocidad instantánea: ${device.data.instant_velocity_km_h} km/h`
    : ''
}
Últ Posición: ${
      device.geo_updated_at
        ? `${moment.utc(device.geo_updated_at).fromNow()}`
        : 'Sin info'
    }
Últ mensaje: ${
      device.data.last_message_from_device_at
        ? `${moment.utc(device.data.last_message_from_device_at).fromNow()}`
        : 'Sin info'
    }
Últ HB: ${
      device.data.last_heartbeat_from_device_at
        ? `${moment.utc(device.data.last_heartbeat_from_device_at).fromNow()}`
        : 'Sin info'
    }`);

    setNewSerialNumberScooter(device.data.serial_number_scooter);
    setNewSerialNumberBattery(device.data.serial_number_battery);
    setNewSerialNumberScooterBoard(device.data.serial_number_scooter_board);
    setDeviceTags(device.data.tags || []);

    const pid = window.setInterval(updateDevice, pollPeriod);
    return () => {
      if (pid) {
        window.clearInterval(pid);
      }
    };
  }, [device]);

  useEffect(() => {
    loadTrips();
  }, [device.id]);

  useEffect(() => {
    loadStatusTransitionsAllowed();
  }, [device?.fleet_id]);

  if (!device) {
    return null;
  }

  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCIi-49zQc_qm-8TCRX26suYgt0oL1YETw&scale=2&center=${device.lat},${device.lng}&zoom=16&size=350x350&maptype=roadmap&markers=color:green%7Clabel:G%7C${device.lat},${device.lng}`;
  let connectionColor = null;
  let signalLevel = null;
  let signalIcon = wifi0;
  if (device.data?.rssi) {
    if (device.data?.rssi < 10) {
      connectionColor = '#f60';
      signalLevel = 'Muy baja señal';
      signalIcon = wifi1;
    } else if (device.data?.rssi < 20) {
      connectionColor = '#fc0';
      signalLevel = 'Baja señal';
      signalIcon = wifi2;
    } else if (device.data?.rssi < 30) {
      connectionColor = '#06c';
      signalLevel = 'Señal buena';
      signalIcon = wifi3;
    } else {
      signalLevel = 'Excelente señal';
      connectionColor = '#093';
      signalIcon = wifi4;
    }
  }
  if (!device.is_online_last_30s) {
    signalIcon = wifi0;
    connectionColor = '#c30';
  }
  const tripsColumns = !isMobile()
    ? [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          width: 300,
          render: (id, r) => (
            <>
              <div>
                {id} - {r.hash}
              </div>
            </>
          ),
        },
        {
          title: '',
          dataIndex: 'grin_qualification',
          key: 'grin_qualification',
          width: 30,
          render: (v) => (
            <FontAwesomeIcon
              icon={faStar}
              style={{
                display: 'inline-block',
                color: getTripQualificationColor(v),
              }}
            />
          ),
        },
        {
          title: 'Usuario',
          dataIndex: 'user',
          key: 'user',
          render: (v) => (
            <>
              <div>
                #{v.id} - {v.name || '(Sin nombre)'}
              </div>
              <div>
                <small>
                  <Link to={`/customer/${v.id}`}>{v.email}</Link>
                </small>
              </div>
              <div>
                <small>
                  Registro:{' '}
                  {moment
                    .utc(v.created_at)
                    .local()
                    .format('YYYY-MM-DD HH:mm:ss')}
                </small>
              </div>
            </>
          ),
        },
        {
          title: 'Dispositivo',
          dataIndex: 'device',
          key: 'device',
          render: (v) => (
            <>
              <div>
                <Link to={`/device/${v.qr}`}>{v.qr}</Link>
              </div>
            </>
          ),
        },
        {
          title: 'Total',
          dataIndex: 'cost_calculated',
          key: 'total',
          render: (costCalculated) =>
            numeral(costCalculated.details.payment_total).format('$0,000.00'),
        },
        {
          title: 'Status',
          dataIndex: 'status_tr',
          key: 'status_tr',
          render: (statusTr) => statusTr,
        },
        {
          title: 'Tipo',
          dataIndex: 'status_tr',
          key: 'status_tr',
          render: (v, r) => {
            if (r.status === 'STARTING') {
              return 'Intento de inicio sin completar';
            }
            if (r.trip_start_at) {
              return 'Viaje';
            }
            return 'Reserva sin viaje';
          },
        },
        {
          title: 'Comienzo',
          dataIndex: 'trip_start_at',
          key: 'trip_start_at',
          render: (v, r) => (
            <div>
              <div>
                {moment
                  .utc(v || r.start_at)
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss')}
              </div>
              <div>
                <small>{moment.utc(v || r.start_at).fromNow()}</small>
              </div>
            </div>
          ),
        },
        {
          title: 'Fin',
          dataIndex: 'end_at',
          key: 'end_at',
          render: (v, r) =>
            (v || r.reserved_finished_at || r.reserved_autocancel_at) && (
              <div>
                <div>
                  {moment
                    .utc(
                      v || r.reserved_finished_at || r.reserved_autocancel_at
                    )
                    .local()
                    .format('YYYY-MM-DD HH:mm:ss')}
                </div>
                <div>
                  <small>
                    {moment
                      .utc(
                        v || r.reserved_finished_at || r.reserved_autocancel_at
                      )
                      .fromNow()}
                  </small>
                </div>
              </div>
            ),
        },
        {
          title: 'Duración (min)',
          align: 'center',
          dataIndex: 'cost_calculated',
          key: 'min',
          render: (v) => v.minutes,
        },
        {
          title: 'Pagado',
          dataIndex: 'paid_at',
          key: 'paid_at',
          render: (paidAt) =>
            paidAt && moment.utc(paidAt).local().format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          title: '',
          dataIndex: '',
          key: 'actions',
          render: (x, r) => (
            <Link to={`/trip/${r.id}`}>
              <Button>Ver</Button>
            </Link>
          ),
        },
      ]
    : [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          width: 300,
          render: (id, r) => (
            <>
              <div>
                {r.status_tr} - {id} - {r.hash}
                <FontAwesomeIcon
                  icon={faStar}
                  style={{
                    display: 'inline-block',
                    color: getTripQualificationColor(r.grin_qualification),
                  }}
                />
              </div>
              <div>
                Usuario: {r.user.name || '(Sin nombre)'} ({r.user.email}
                )
                <FontAwesomeIcon
                  icon={faStar}
                  style={{
                    display: 'inline-block',
                    color: getTripQualificationColor(r.user.grin_qualification),
                  }}
                />
              </div>

              <div>
                <small>
                  Inicio: {moment.utc(r.trip_start_at).fromNow()} -{' '}
                  {moment
                    .utc(r.trip_start_at)
                    .local()
                    .format('YYYY-MM-DD HH:mm:ss')}
                </small>
              </div>
              <div>
                <small>
                  Fin: {moment.utc(r.end_at).fromNow()} -{' '}
                  {moment.utc(r.end_at).local().format('YYYY-MM-DD HH:mm:ss')}
                </small>
              </div>
              <div>
                <small>
                  Total:{' '}
                  {numeral(r.cost_calculated.details.payment_total).format(
                    '$0,000.00'
                  )}
                </small>
              </div>
            </>
          ),
        },
        {
          title: '',
          dataIndex: '',
          key: 'actions',
          render: (x, r) => (
            <Link to={`/trip/${r.id}`}>
              <Button>Ver</Button>
            </Link>
          ),
        },
      ];

  const hasAccessToDeviceFleet =
    device?.fleet && fleets && fleets.find((x) => x.id === device.fleet?.id);
  let markersInMap = [device];
  if (device?.data.last_positions) {
    const useLastPositions = device?.data.last_positions
      .filter((x) => x.lat !== device.lat && x.lng !== device.lng)
      .slice(0, 10)
      .map((x, i) => ({
        ...x,
        latitude: x.lat,
        longitude: x.lng,
        zIndex: 1,
        icon: x.precise ? purplePoint : grayPoint,
        iconScale: new window.google.maps.Size(30 - 3 * i, 30 - 3 * i),
        anchor: new window.google.maps.Point((30 - 3 * i) / 2, 30 - 3 * i),
        label: (
          <>
            {x.source}
            <br />
            {moment.utc(x.ts).fromNow()}
          </>
        ),
      }));

    markersInMap = markersInMap.concat(useLastPositions);
  }
  const hasIot = device.data?.iot_type?.toLowerCase() !== 'no';
  console.log('hasIot', hasIot, device.data?.iot_type?.toLowerCase());
  return (
    <>
      <div className={styles.device}>
        <div className={styles.title}>
          <div className={styles.qr}>
            {user.can.manage_devices ? (
              <>
                <FontAwesomeIcon icon={faQrcode} />{' '}
                <Link to={`/device/${device.ref}`}>{device.qr}</Link>{' '}
                <Link to={`/device/${device.ref}`}>{device.ref}</Link>
              </>
            ) : (
              <>
                {device.qr} - {device.ref}
              </>
            )}
          </div>
          {hasIot && (
            <>
              <div
                className={styles.connectionStatus}
                onClick={() => onAction('get_lock_status')}
              >
                <img
                  alt=''
                  title={`${
                    device.is_online_last_30s
                      ? 'Online (últ 30s)'
                      : 'Offline (últ 30s)'
                  } ${signalLevel && ` - ${signalLevel}`}`}
                  src={signalIcon}
                  style={{
                    width: 25,
                    marginTop: -2,
                    marginLeft: 12,
                    color: connectionColor,
                  }}
                />
                <div className={styles.legend}>
                  {device.is_online_last_30s
                    ? 'Online (últ 30s)'
                    : 'Offline (últ 30s)'}
                  {signalLevel && ` - ${signalLevel}`}
                </div>
              </div>
              <div
                className={styles.lockStatus}
                onClick={() => onAction('get_lock_status')}
              >
                <FontAwesomeIcon
                  icon={
                    device.data.locked === 1
                      ? faLock
                      : device.data.locked === 0
                      ? faUnlock
                      : null
                  }
                  className={
                    device.data.locked ? styles.locked : styles.unlocked
                  }
                />
                <div className={styles.legend}>
                  {device.data.locked ? 'Bloqueado' : 'Desbloqueado'}
                </div>
              </div>
              <div className={styles.engineStatus}>
                <FontAwesomeIcon
                  icon={faCog}
                  className={
                    device.data.engine_on === '1'
                      ? styles.engineOn
                      : styles.engineOff
                  }
                />
                <br />
                <div className={styles.legend}>
                  {device.data.engine_on === '1'
                    ? 'Motor encendido'
                    : 'Motor apagado'}
                </div>
              </div>
              <div
                className={styles.battery}
                onClick={() => onAction('get_battery')}
              >
                <div className={styles.info}>
                  <FontAwesomeIcon
                    icon={getBatteryIcon(device.data.batsco)}
                    style={{fontSize: 18}}
                  />{' '}
                  {device.data.charging === '1' ? (
                    <FontAwesomeIcon icon={faPlug} style={{fontSize: 12}} />
                  ) : null}{' '}
                  {device.data.batsco}
                </div>
                <div className={styles.legend}>Batería</div>
              </div>
            </>
          )}
          {showClose && (
            <div className={styles.closeBtn} onClick={onClose} role='button'>
              <FontAwesomeIcon icon={faTimes} style={{fontSize: 18}} />
            </div>
          )}
        </div>
        <div className={styles.tools}>
          {user.can.delete_devices && (
            <Popconfirm
              placement='bottom'
              title='¿Confirmas que quieres eliminar este patín?'
              onConfirm={onDeleteByTypeRef}
              okText='Si'
              okButtonProps={{
                type: 'danger',
                danger: true,
              }}
              cancelText='No'
            >
              <Button size='small' danger>
                Eliminar patín
              </Button>
            </Popconfirm>
          )}
        </div>
        <div className={styles.info}>
          {device.data?.coderr > 0 && (
            <div
              style={{
                display: 'inline-block',
                background: '#f33',
                color: '#fff',
                fontWeight: 'bold',
                padding: '5px 15px',
                borderRadius: 20,
                fontSize: 14,
                marginBottom: 40,
              }}
            >
              Error {device.data?.coderr}
            </div>
          )}
          {device.all_devices_by_qr?.filter((x) => x.ref !== device.ref)
            .length > 0 && (
            <div
              style={{margin: '0 0 30px 0', background: '#ffeecc', padding: 10}}
            >
              Hay más de un dispositivo con este QR:{' '}
              {device.all_devices_by_qr.map((x) => (
                <span style={{marginRight: 20}}>
                  <Link to={`/device/${x.ref}`}>
                    {x.ref} ({x.status_tr})
                  </Link>
                </span>
              ))}
            </div>
          )}
          {!device.fleet_id && (
            <div
              style={{
                background: '#c30',
                color: '#fff',
                padding: 20,
                fontWeight: 'bold',
              }}
            >
              Patín sin flota
            </div>
          )}
          <div style={{marginBottom: 20}}>
            <DeviceDamageModule
              deviceRef={device.ref}
              user={user}
              damages={device.data.damages}
              onUpdateDamage={() => updateDevice()}
            />
          </div>
          {user?.can?.repair && (
            <div style={{marginBottom: 20}}>
              <DeviceReparationModule
                deviceRef={device.ref}
                reparations={device.data.reparations}
                onUpdateReparation={() => updateDevice()}
              />
            </div>
          )}
          <table className={styles.table}>
            <tr>
              <td width='25%'>Id, QR, Ref, SIM</td>
              <td>
                <CopyWithMessage
                  inline
                  value={device.id}
                  messageCopied='Copiado!'
                  messageTime={2000}
                >
                  {device.id}{' '}
                  <FontAwesomeIcon icon={faCopy} style={{fontSize: 16}} />
                </CopyWithMessage>
                ,{' '}
                <CopyWithMessage
                  inline
                  value={device.qr}
                  messageCopied='Copiado!'
                  messageTime={2000}
                >
                  {device.qr}{' '}
                  <FontAwesomeIcon icon={faCopy} style={{fontSize: 16}} />
                </CopyWithMessage>
                ,{' '}
                <CopyWithMessage
                  inline
                  value={device.ref}
                  messageCopied='Copiado!'
                  messageTime={2000}
                >
                  {device.ref}{' '}
                  <FontAwesomeIcon icon={faCopy} style={{fontSize: 16}} />
                </CopyWithMessage>
                , {device.data.sim_provider}
                <br />
                Creado:{' '}
                {moment
                  .utc(device.created_at)
                  .local()
                  .format('YYYY-MM-DD HH:mm')}
                {user.can.manage_devices && hasAccessToDeviceFleet ? (
                  <>
                    <br />
                    <Input
                      style={{display: 'inline-block', width: 200}}
                      type='text'
                      placeholder='Definir QR...'
                      value={newQr}
                      onChange={(e) => setNewQr(e.target.value)}
                      onKeyDown={(e) =>
                        e.key === 'Enter' ? changeQr(newQr) : null
                      }
                    />{' '}
                    <Button
                      size='small'
                      onClick={() => changeQr(newQr)}
                      type='secondary'
                    >
                      Guardar
                    </Button>
                  </>
                ) : null}
              </td>
            </tr>
            <tr>
              <td width='25%'>Etiquetas</td>
              <td>
                {user.can.manage_devices ? (
                  <TagsInput
                    value={deviceTags}
                    onChange={updateDeviceTags}
                    placeHolder='Agrega etiquetas...'
                  />
                ) : (
                  <>
                    {device.data?.tags?.map((x) => (
                      <Tag key={x}>{x}</Tag>
                    ))}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td width='25%'>IOT</td>
              <td>
                <span>{hasIot ? 'SI' : 'NO'}</span>{' '}
                {hasIot ? (
                  <>
                    <span> - {device.ref}</span>
                    {user.can.device_change_iot &&
                      (hasAccessToDeviceFleet || !device.fleet_id) && (
                        <span style={{marginLeft: 10}}>
                          <Popconfirm
                            placement='bottom'
                            title='¿Confirmas que quieres quitar el IOT de este Patín?'
                            onConfirm={onRemoveIot}
                            okText='Si'
                            okButtonProps={{
                              type: 'danger',
                              danger: true,
                            }}
                            cancelText='No'
                          >
                            <Button size='small' danger>
                              Quitar IOT
                            </Button>
                          </Popconfirm>
                        </span>
                      )}
                  </>
                ) : (
                  <>
                    {user.can.device_change_iot && hasAccessToDeviceFleet && (
                      <Input
                        style={{display: 'inline-block', width: 200}}
                        type='text'
                        placeholder='Definir Nuevo IOT...'
                        value={newIot}
                        onChange={(e) => setNewIot(e.target.value)}
                        onKeyDown={(e) =>
                          e.key === 'Enter' ? onSetIot(newIot) : null
                        }
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                {statusTranslations[device.status] || device.status}
                {device.data?.last_status_change_at && (
                  <div>
                    <small>
                      Últ cambio:{' '}
                      {
                        statusTranslations[
                          device.data?.last_status_change.original_status
                        ]
                      }
                      {' -> '}
                      {
                        statusTranslations[
                          device.data?.last_status_change.new_status
                        ]
                      }{' '}
                      (
                      <abbr
                        title={moment
                          .utc(device.data?.last_status_change_at)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')}
                      >
                        {moment
                          .utc(device.data?.last_status_change_at)
                          .fromNow()}
                        {' - '}
                        {moment
                          .utc(device.data?.last_status_change_at)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')}
                      </abbr>
                      )
                    </small>
                  </div>
                )}
                {device.status === 'stolen_suspect' && (
                  <div>
                    <Input.TextArea
                      rows={10}
                      value={statusSendMessage}
                      onChange={(e) => {
                        setStatusSendMessage(e.target.value);
                      }}
                    />
                    <br />
                    <br />
                    <CopyWithMessage
                      value={statusSendMessage}
                      messageCopied='Copiado!'
                      messageTime={2000}
                    >
                      <FontAwesomeIcon icon={faCopy} style={{fontSize: 16}} />
                    </CopyWithMessage>
                  </div>
                )}
                {device.open_theft_incident && (
                  <>
                    {' '}
                    <Link
                      to={`/incidents?view=${device.open_theft_incident.id}`}
                    >
                      Incidente: {device.open_theft_incident.id} (Creado:{' '}
                      <abbr
                        title={moment
                          .utc(device.open_theft_incident.created_at)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')}
                      >
                        {moment
                          .utc(device.open_theft_incident.created_at)
                          .fromNow()}
                        {' - '}
                        {moment
                          .utc(device.open_theft_incident.created_at)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')}
                        )
                      </abbr>
                    </Link>
                  </>
                )}
                {device.current_trip?.id && (
                  <>
                    <br />
                    Viaje:{' '}
                    <Link to={`/trip/${device.current_trip?.id}`}>
                      {device.current_trip?.id}
                    </Link>{' '}
                    - Inicio:{' '}
                    {moment.utc(device.current_trip?.trip_start_at).fromNow()}
                    {' - '}
                    {moment
                      .utc(device.current_trip?.trip_start_at)
                      .local()
                      .format('YYYY-MM-DD HH:mm:ss')}{' '}
                    <br />
                    Usuario:{' '}
                    <Link to={`/trips/?q=${device.current_trip?.user?.email}`}>
                      {device.current_trip?.user?.name} -{' '}
                      {device.current_trip?.user?.email}{' '}
                    </Link>
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{
                        display: 'inline-block',
                        color: getTripQualificationColor(
                          device.current_trip?.user?.grin_qualification
                        ),
                      }}
                    />
                  </>
                )}
                {newAvailableStatuses && newAvailableStatuses.length > 0 && (
                  <div>
                    <br />
                    <Select
                      onChange={(v) => {
                        moveDevicesToStatus(v);
                      }}
                      options={newAvailableStatuses}
                      style={{width: '100%'}}
                      placeholder='Cambiar a...'
                    />
                    <div className={styles.setLocation}>
                      {!!changeLocationOnUpdate}
                      <Checkbox
                        defaultChecked={!!changeLocationOnUpdate}
                        onChange={(e) => {
                          setChangeLocationOnUpdate(e.target.checked);
                        }}
                      >
                        Poner en ubicación del cambio de estado
                      </Checkbox>
                    </div>
                  </div>
                )}
              </td>
            </tr>
            {device.current_trip_starting?.id && device.fleet_id === 1 && (
              <tr>
                <td>Viaje reciente sin iniciar</td>
                <td>
                  <>
                    <br />
                    Viaje:{' '}
                    <Link to={`/trip/${device.current_trip_starting?.id}`}>
                      {device.current_trip_starting?.id}
                    </Link>{' '}
                    - Creado:{' '}
                    {moment
                      .utc(device.current_trip_starting?.created_at)
                      .local()
                      .format('DD/MM HH:mm:ss')}{' '}
                    -
                    <abbr
                      title={moment
                        .utc(device.current_trip_starting?.created_at)
                        .local()
                        .format('YYYY-MM-DD HH:mm:ss')}
                    >
                      {moment
                        .utc(device.current_trip_starting?.created_at)
                        .fromNow()}
                      {' - '}
                      {moment
                        .utc(device.current_trip_starting?.created_at)
                        .local()
                        .format('YYYY-MM-DD HH:mm:ss')}
                    </abbr>{' '}
                    <br />
                    Usuario:{' '}
                    <Link
                      to={`/trips/?q=${device.current_trip_starting?.user?.email}`}
                    >
                      {device.current_trip_starting?.user?.name} -{' '}
                      {device.current_trip_starting?.user?.email}{' '}
                    </Link>
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{
                        display: 'inline-block',
                        color: getTripQualificationColor(
                          device.current_trip_starting?.user?.grin_qualification
                        ),
                      }}
                    />
                    {device.current_trip_starting
                      ?.user_has_started_trip_after && (
                      <>
                        <br />
                        <strong style={{color: '#c30'}}>
                          <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                          ADVERTENCIA: EL USUARIO TIENE UN VIAJE INICIADO
                          POSTERIOR A ESTE
                        </strong>
                      </>
                    )}
                    <br />
                    <Button
                      size='small'
                      onClick={() => {
                        onTripStart(device.current_trip_starting?.id);
                      }}
                      type='secondary'
                    >
                      Iniciar el viaje
                    </Button>
                  </>
                </td>
              </tr>
            )}
            {hasIot && (
              <>
                <tr>
                  <td>Último Reset</td>
                  <td>
                    {device.data?.last_reset_at
                      ? moment
                          .utc(device.data?.last_reset_at)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')
                      : 'Sin info'}
                    {device.data?.last_reset && (
                      <div style={{display: 'inline-block', marginLeft: 20}}>
                        {device.data?.last_reset.lat},
                        {device.data?.last_reset.lng}
                        <div style={{display: 'inline-block', marginLeft: 20}}>
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${device.data?.last_reset.lat},${device.data?.last_reset.lng}`}
                            target='_map'
                          >
                            {' '}
                            <FontAwesomeIcon icon={faExternalLinkAlt} /> Ver en
                            mapa
                          </a>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Último No Encontrado</td>
                  <td>
                    {device.data?.last_not_found_at
                      ? moment
                          .utc(device.data?.last_not_found_at)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')
                      : 'Sin info'}
                    {device.data?.last_not_found && (
                      <div style={{display: 'inline-block', marginLeft: 20}}>
                        {device.data?.last_not_found.lat},
                        {device.data?.last_not_found.lng}
                        <div style={{display: 'inline-block', marginLeft: 20}}>
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${device.data?.last_not_found.lat},${device.data?.last_not_found.lng}`}
                            target='_map'
                          >
                            {' '}
                            <FontAwesomeIcon icon={faExternalLinkAlt} /> Ver en
                            mapa
                          </a>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              </>
            )}
            {hasIot && (
              <tr>
                <td>Último viaje finalizado</td>
                <td>
                  {device.data?.last_trip ? (
                    <>
                      Viaje:{' '}
                      <Link to={`/trip/${device.data?.last_trip.id}`}>
                        {device.data?.last_trip.id}
                      </Link>
                      <br />
                      Inicio:{' '}
                      {moment
                        .utc(device.data?.last_trip.trip_start_at)
                        .fromNow()}
                      {' - '}
                      {moment
                        .utc(device.data?.last_trip.trip_start_at)
                        .local()
                        .format('YYYY-MM-DD HH:mm:ss')}{' '}
                      <br />
                      Fin: {moment.utc(device.data?.last_trip.end_at).fromNow()}
                      {' - '}
                      {moment
                        .utc(device.data?.last_trip.end_at)
                        .local()
                        .format('YYYY-MM-DD HH:mm:ss')}{' '}
                      <br />
                      Usuario:{' '}
                      <Link to={`/customer/${device.data?.last_trip.user?.id}`}>
                        {device.data?.last_trip.user?.name} -{' '}
                        {device.data?.last_trip.user?.email}{' '}
                      </Link>
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{
                          display: 'inline-block',
                          color: getTripQualificationColor(
                            device.data?.last_trip.user?.grin_qualification
                          ),
                        }}
                      />
                    </>
                  ) : device.data?.last_trip_ended ? (
                    `${moment
                      .utc(device.data?.last_trip_ended)
                      .local()
                      .format('YYYY-MM-DD HH:mm:ss')} - ${moment
                      .utc(device.data?.last_trip_ended)
                      .fromNow()} `
                  ) : (
                    'Sin info'
                  )}
                </td>
              </tr>
            )}
            {user.can.manage_devices ? (
              <>
                <tr>
                  <td>Link</td>
                  <td>
                    <Link
                      to={`/device/${device.ref}`}
                    >{`/device/${device.ref}`}</Link>
                    <div style={{display: 'inline-block', marginLeft: 20}}>
                      <CopyWithMessage
                        value={`https://paperboy.grow.mobi/device/${device.ref}`}
                        messageCopied='Copiado!'
                        messageTime={2000}
                      >
                        <FontAwesomeIcon icon={faCopy} style={{fontSize: 16}} />
                      </CopyWithMessage>
                    </div>
                  </td>
                </tr>
                {hasIot && (
                  <tr>
                    <td>Ubicación</td>
                    <td>
                      {device.lat},{device.lng}
                      {' - '}
                      <abbr
                        title={moment
                          .utc(device.geo_updated_at)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')}
                      >
                        {moment.utc(device.geo_updated_at).fromNow()}
                      </abbr>{' '}
                      <div style={{display: 'inline-block', marginLeft: 20}}>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}`}
                          target='_map'
                        >
                          {' '}
                          <FontAwesomeIcon icon={faExternalLinkAlt} /> Ver en
                          mapa
                        </a>
                      </div>
                      <div style={{display: 'inline-block', marginLeft: 20}}>
                        <CopyWithMessage
                          value={`https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}`}
                          messageCopied='Copiado!'
                          messageTime={2000}
                        >
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{fontSize: 16}}
                          />
                        </CopyWithMessage>
                      </div>
                      {device.data?.instant_velocity_km_h !== -1 && (
                        <div>
                          Vel instantanea: {device.data.instant_velocity_km_h}{' '}
                          km/h
                        </div>
                      )}
                      {device.data?.out_of_area ? ' FUERA DE AREA' : ''}
                    </td>
                  </tr>
                )}
              </>
            ) : (
              hasIot && (
                <tr>
                  <td>Ubicación</td>
                  <td>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}`}
                      target='_map'
                    >
                      <img
                        style={{maxWidth: '100%', width: 400}}
                        src={mapUrl}
                        alt='Map'
                      />
                      <div style={{textAlign: 'center', marginTop: 10}}>
                        <FontAwesomeIcon icon={faExternalLinkAlt} /> Ver en mapa
                      </div>
                    </a>
                  </td>
                </tr>
              )
            )}

            <tr>
              <td>Flota</td>
              <td>
                {user.can.manage_devices &&
                (hasAccessToDeviceFleet || !device.fleet_id) ? (
                  <>
                    <Select
                      style={{width: '100%'}}
                      defaultValue={
                        hasAccessToDeviceFleet
                          ? hasAccessToDeviceFleet.id
                          : null
                      }
                      onChange={(v) => changeFleet(v)}
                    >
                      {fleets.map((x) => (
                        <Option key={x.id} value={x.id}>
                          {x.name}
                        </Option>
                      ))}
                    </Select>
                  </>
                ) : (
                  <span>{device.fleet?.name || 'Sin Flota'}</span>
                )}
              </td>
            </tr>
            <tr>
              <td>Branding</td>
              <td>
                {user.can.manage_devices &&
                device.fleet_id &&
                hasAccessToDeviceFleet &&
                Object.keys(brands[device.fleet_id]).length > 1 ? (
                  <>
                    <Select
                      style={{width: 200}}
                      defaultValue={device.brand}
                      onChange={(v) => changeBrand(v)}
                    >
                      {Object.keys(brands[device.fleet_id]).map((x) => (
                        <Option key={x} value={x}>
                          {brands[device.fleet_id][x]}
                        </Option>
                      ))}
                    </Select>
                  </>
                ) : (
                  <span>Sin Branding</span>
                )}
              </td>
            </tr>
            <tr>
              <td width='25%'>Serial Number Scooter</td>
              <td>
                {user.can.manage_devices && hasAccessToDeviceFleet ? (
                  <>
                    <br />
                    <Input
                      style={{display: 'inline-block', width: 200}}
                      type='text'
                      placeholder='Definir...'
                      value={newSerialNumberScooter}
                      onChange={(e) =>
                        setNewSerialNumberScooter(e.target.value)
                      }
                      onKeyDown={(e) =>
                        e.key === 'Enter'
                          ? changeSerial(
                              'serial_number_scooter',
                              newSerialNumberScooter
                            )
                          : null
                      }
                    />{' '}
                    <Button
                      size='small'
                      onClick={() =>
                        changeSerial(
                          'serial_number_scooter',
                          newSerialNumberScooter
                        )
                      }
                      type='secondary'
                    >
                      Guardar
                    </Button>
                  </>
                ) : (
                  device.data?.serial_number_scooter
                )}
              </td>
            </tr>
            <tr>
              <td width='25%'>Serial Number Batería</td>
              <td>
                {user.can.manage_devices && hasAccessToDeviceFleet ? (
                  <>
                    <br />
                    <Input
                      style={{display: 'inline-block', width: 200}}
                      type='text'
                      placeholder='Definir...'
                      value={newSerialNumberBattery}
                      onChange={(e) =>
                        setNewSerialNumberBattery(e.target.value)
                      }
                      onKeyDown={(e) =>
                        e.key === 'Enter'
                          ? changeSerial(
                              'serial_number_battery',
                              newSerialNumberBattery
                            )
                          : null
                      }
                    />{' '}
                    <Button
                      size='small'
                      onClick={() =>
                        changeSerial(
                          'serial_number_battery',
                          newSerialNumberBattery
                        )
                      }
                      type='secondary'
                    >
                      Guardar
                    </Button>
                  </>
                ) : (
                  device.data?.serial_number_battery
                )}
              </td>
            </tr>
            <tr>
              <td width='25%'>Serial Number Scooter Board</td>
              <td>
                {user.can.manage_devices && hasAccessToDeviceFleet ? (
                  <>
                    <br />
                    <Input
                      style={{display: 'inline-block', width: 200}}
                      type='text'
                      placeholder='Definir...'
                      value={newSerialNumberScooterBoard}
                      onChange={(e) =>
                        setNewSerialNumberScooterBoard(e.target.value)
                      }
                      onKeyDown={(e) =>
                        e.key === 'Enter'
                          ? changeSerial(
                              'serial_number_scooter_board',
                              newSerialNumberScooterBoard
                            )
                          : null
                      }
                    />{' '}
                    <Button
                      size='small'
                      onClick={() =>
                        changeSerial(
                          'serial_number_scooter_board',
                          newSerialNumberScooterBoard
                        )
                      }
                      type='secondary'
                    >
                      Guardar
                    </Button>
                  </>
                ) : (
                  device.data?.serial_number_scooter_board
                )}
              </td>
            </tr>
            {hasIot && (
              <>
                <tr>
                  <td>Firmware</td>
                  <td>
                    {device.data.iot_firmware_version}{' '}
                    <Button
                      size='small'
                      onClick={() => onAction('request_firmware')}
                      type='secondary'
                    >
                      {updating && (
                        <FontAwesomeIcon
                          icon={faSync}
                          spin
                          style={{marginRight: 5}}
                        />
                      )}
                      Actualizar
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>Velocidad Máx</td>
                  <td>
                    {device.data.max_speed
                      ? `${device.data.max_speed}km/h`
                      : 'desconocida'}{' '}
                    <Button
                      size='small'
                      onClick={() => onAction('get_max_speed')}
                      type='secondary'
                    >
                      {updating && (
                        <FontAwesomeIcon
                          icon={faSync}
                          spin
                          style={{marginRight: 5}}
                        />
                      )}
                      Actualizar
                    </Button>{' '}
                    {user.can.devices_advanced_ops && (
                      <>
                        <Button
                          size='small'
                          onClick={() => onAction('set_max_speed', {speed: 15})}
                          type='secondary'
                        >
                          {updating && (
                            <FontAwesomeIcon
                              icon={faSync}
                              spin
                              style={{marginRight: 5}}
                            />
                          )}
                          Set 15km/h
                        </Button>{' '}
                        <Button
                          size='small'
                          onClick={() => onAction('set_max_speed', {speed: 25})}
                          type='secondary'
                        >
                          {updating && (
                            <FontAwesomeIcon
                              icon={faSync}
                              spin
                              style={{marginRight: 5}}
                            />
                          )}
                          Set 25km/h
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>IOT</td>
                  <td>
                    Batería: {device.data.batiot}
                    %
                    <br />
                    Conectado al Patín:{' '}
                    {parseInt(device.data?.is_scooter_present, 10) === 1
                      ? 'SI'
                      : 'Desconocido'}
                  </td>
                </tr>
                <tr>
                  <td>Heartbeat</td>
                  <td>
                    {device.data.last_message_from_device_at ? (
                      <>
                        <abbr
                          title={moment
                            .utc(device.data.last_heartbeat_from_device_at)
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss')}
                        >
                          {moment
                            .utc(device.data.last_heartbeat_from_device_at)
                            .fromNow()}
                        </abbr>{' '}
                        -{' '}
                        {moment
                          .utc(device.data.last_heartbeat_from_device_at)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')}
                        , (p: {device.data.heartbeat_period}
                        s)
                      </>
                    ) : (
                      'Sin info'
                    )}{' '}
                    <Button
                      size='small'
                      onClick={() => onAction('set_heartbeat', {period: 20})}
                      type='secondary'
                    >
                      {updating && (
                        <FontAwesomeIcon
                          icon={faSync}
                          spin
                          style={{marginRight: 5}}
                        />
                      )}
                      Set 20s
                    </Button>{' '}
                    <Button
                      size='small'
                      onClick={() => onAction('set_heartbeat', {period: 600})}
                      type='secondary'
                    >
                      {updating && (
                        <FontAwesomeIcon
                          icon={faSync}
                          spin
                          style={{marginRight: 5}}
                        />
                      )}
                      Set 10m
                    </Button>{' '}
                    <Button
                      size='small'
                      onClick={() => onAction('force_heartbeat')}
                      type='secondary'
                    >
                      {updating && (
                        <FontAwesomeIcon
                          icon={faSync}
                          spin
                          style={{marginRight: 5}}
                        />
                      )}
                      Forzar HB
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>2G/4G, rssi, IMEI, iccid</td>
                  <td>
                    {`${
                      networks[device.data['4g2g']]
                        ? networks[device.data['4g2g']]
                        : ''
                    },${
                      device.data.rssi ? device.data.rssi : ''
                    } (${signalLevel}), ${
                      device.data.imei ? device.data.imei : 'Unknown'
                    }, ${device.data.iccid ? device.data.iccid : 'Unknown'}`}
                    {[1, 3, 6].includes(device.fleet_id) && (
                      <>
                        <br />
                        <Button
                          size='small'
                          type='secondary'
                          onClick={() => {
                            getDeviceSimInfo();
                          }}
                        >
                          Obtener info de la SIM
                        </Button>
                      </>
                    )}
                  </td>
                </tr>

                <tr>
                  <td>Últ Mensaje</td>
                  <td>
                    {device.data.last_message_from_device_at ? (
                      <>
                        <abbr
                          title={moment
                            .utc(device.data.last_message_from_device_at)
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss')}
                        >
                          {moment
                            .utc(device.data.last_message_from_device_at)
                            .fromNow()}
                        </abbr>{' '}
                        -{' '}
                        {moment
                          .utc(device.data.last_message_from_device_at)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')}
                        <br />
                        <div
                          style={{
                            fontFamily: 'monospace',
                            wordBreak: 'break-all',
                          }}
                        >
                          <small>
                            {JSON.stringify(
                              device.data?.last_message_from_device
                            )}
                          </small>
                        </div>
                      </>
                    ) : (
                      'Sin info'
                    )}
                  </td>
                </tr>
              </>
            )}
          </table>

          <div className={styles.actions}>
            {showActions && hasIot ? (
              <>
                <div
                  className={styles.commands}
                  style={{
                    gridTemplateColumns: `repeat(${
                      isMobile() ? 2 : buttonCols
                    }, 1fr)`,
                  }}
                >
                  {user.can.unlock_device && (
                    <Button onClick={() => onAction('unlock')} type='secondary'>
                      {updating && <FontAwesomeIcon icon={faSync} spin />}
                      <FontAwesomeIcon icon={faUnlock} />
                      Desbloquear {device.qr}
                    </Button>
                  )}
                  <Button onClick={() => onAction('lock')} type='secondary'>
                    {updating && <FontAwesomeIcon icon={faSync} spin />}
                    <FontAwesomeIcon icon={faLock} />
                    Bloquear {device.qr}
                  </Button>
                  <Button onClick={() => onAction('buzz_on')} type='secondary'>
                    {updating && <FontAwesomeIcon icon={faSync} spin />}
                    <FontAwesomeIcon icon={faVolumeUp} />
                    Buzz On
                  </Button>
                  <Button onClick={() => onAction('buzz_off')} type='secondary'>
                    {updating && <FontAwesomeIcon icon={faSync} spin />}
                    <FontAwesomeIcon icon={faVolumeMute} />
                    Buzz Off
                  </Button>
                  <Button
                    onClick={() => onAction('request_geolocation')}
                    type='secondary'
                  >
                    {updating && <FontAwesomeIcon icon={faSync} spin />}
                    <FontAwesomeIcon icon={faLocationArrow} />
                    Act geolocalización
                  </Button>
                  {user.can.devices_advanced_ops && (
                    <>
                      <Button
                        onClick={() => onAction('alerts_on')}
                        type='secondary'
                      >
                        {updating && <FontAwesomeIcon icon={faSync} spin />}
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        Hab. Alertas
                      </Button>
                      <Button
                        onClick={() => onAction('alerts_off')}
                        type='secondary'
                      >
                        {updating && <FontAwesomeIcon icon={faSync} spin />}
                        Deshab. Alertas
                      </Button>

                      <Button onClick={() => onAction('power_off')} danger>
                        {updating && <FontAwesomeIcon icon={faSync} spin />}
                        <FontAwesomeIcon icon={faPowerOff} />
                        Apagar
                      </Button>
                    </>
                  )}
                </div>
                <div className={styles.colors}>
                  <div className={styles.options}>
                    <div>Luces:</div>
                    <div
                      className={`${styles.color} ${styles.off}`}
                      role='button'
                      onClick={() => onAction('change_light', {color: 'off'})}
                      type='primary'
                      title='Apagadas'
                    />
                    <div
                      className={`${styles.color} ${styles.red}`}
                      role='button'
                      onClick={() => onAction('change_light', {color: 'red'})}
                      type='primary'
                      title='Red'
                    />
                    <div
                      className={`${styles.color} ${styles.orange}`}
                      role='button'
                      onClick={() =>
                        onAction('change_light', {color: 'orange'})
                      }
                      type='primary'
                      title='Orange'
                    />
                    <div
                      className={`${styles.color} ${styles.yellow}`}
                      role='button'
                      onClick={() =>
                        onAction('change_light', {color: 'yellow'})
                      }
                      type='primary'
                      title='Yellow'
                    />
                    <div
                      className={`${styles.color} ${styles.green}`}
                      role='button'
                      onClick={() => onAction('change_light', {color: 'green'})}
                      type='primary'
                      title='Green'
                    />
                    <div
                      className={`${styles.color} ${styles.blue}`}
                      role='button'
                      onClick={() => onAction('change_light', {color: 'blue'})}
                      type='primary'
                      title='Blue'
                    />
                    <div
                      className={`${styles.color} ${styles.cyan}`}
                      role='button'
                      onClick={() => onAction('change_light', {color: 'cyan'})}
                      type='primary'
                      title='Cyan'
                    />
                    <div
                      className={`${styles.color} ${styles.violet}`}
                      role='button'
                      onClick={() =>
                        onAction('change_light', {color: 'violet'})
                      }
                      type='primary'
                      title='Violet'
                    />
                    <div
                      className={`${styles.color} ${styles.pink}`}
                      role='button'
                      onClick={() => onAction('change_light', {color: 'pink'})}
                      type='primary'
                      title='Pink'
                    />
                    <div
                      className={`${styles.color} ${styles.alert}`}
                      role='button'
                      onClick={() => onAction('change_light', {color: 'alert'})}
                      type='primary'
                      title='Alerta'
                    />
                  </div>
                </div>
              </>
            ) : (
              hasIot && (
                <Button onClick={() => setShowActions(true)} type='secondary'>
                  Mostrar acciones
                </Button>
              )
            )}
          </div>
        </div>
        {hasIot &&
          user.can.manage_trips &&
          lastTrips &&
          lastTrips.length > 0 && (
            <div className={styles.lastTrips}>
              <div className={stylesLayout.subtitle}>
                Últimos viajes {device.qr} (14 días){' '}
                <span onClick={() => loadTrips()} style={{marginLeft: 10}}>
                  <FontAwesomeIcon icon={faSync} spin={loadingTrips} />
                </span>
              </div>
              <Table
                size='small'
                pagination={{hideOnSinglePage: true, defaultPageSize: 5}}
                locale={{emptyText: 'Sin datos'}}
                columns={tripsColumns}
                dataSource={lastTrips.map((d) => ({
                  key: d.id,
                  ...d,
                }))}
              />
            </div>
          )}
        {hasIot && showMap && (
          <div
            className={styles.map}
            style={{width: '100%', maxWidth: 500, marginTop: 20}}
          >
            <MapComponent
              key={device.id}
              fleetId={device.fleet_id}
              minimumClusterSize={1000}
              zoom={16}
              defaultCenter={{lat: device.lat, lng: device.lng}}
              minZoom={11}
              maxZoom={20}
              googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places'
              containerElement={
                <div
                  style={{
                    width: isMobile() ? '100%' : 500,
                    height: isMobile() ? 300 : 400,
                  }}
                />
              }
              mapElement={
                <div style={{borderRadius: 5, width: '100%', height: '100%'}} />
              }
              markers={markersInMap}
            />
            <div
              style={{
                marginTop: 10,
                fontSize: 12,
                display: 'grid',
                gridTemplateColumns: '1fr auto',
              }}
            >
              <div>
                <div>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}`}
                    target='_map'
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} /> Ver en mapa
                  </a>
                </div>

                <div className={styles.coordinates}>
                  <CopyWithMessage
                    inline
                    value={`${device.lat}, ${device.lng}`}
                    messageCopied='Copiado!'
                    messageTime={2000}
                  >
                    {device.lat}
                    {', '}
                    {device.lng}
                  </CopyWithMessage>
                </div>
                {device.data?.instant_velocity_km_h !== -1 && (
                  <div>
                    Vel instantanea: {device.data.instant_velocity_km_h} km/h
                  </div>
                )}
                <small>
                  {moment.utc(device.geo_updated_at).fromNow()}
                  {' - '}
                  {moment
                    .utc(device.geo_updated_at)
                    .local()
                    .format('YYYY-MM-DD HH:mm:ss')}
                </small>
              </div>
              <div>
                <Button
                  onClick={() => onAction('request_geolocation')}
                  type='secondary'
                >
                  {updating && <FontAwesomeIcon icon={faSync} spin />}
                  <FontAwesomeIcon icon={faLocationArrow} />
                  Act geolocalización
                </Button>
              </div>
            </div>
            {device?.data.last_positions && (
              <div style={{position: 'relative', marginTop: 20, fontSize: 12}}>
                <span>Últimas posiciones del pátin</span>
                <br />
                <br />
                <table
                  className={[stylesLayout.table, stylesLayout.tableSmall].join(
                    ' '
                  )}
                >
                  {device?.data.last_positions?.slice(0, 10).map((x, ix) => (
                    <tr>
                      <td>
                        {moment.utc(x.ts).local().format('YYYY-MM-DD HH:mm:ss')}
                      </td>
                      <td>
                        <a
                          href={
                            'https://www.google.com/maps/search/?api=1&query=' +
                            x.lat +
                            ',' +
                            x.lng
                          }
                          target='_map'
                        >
                          {x.lat}, {x.lng}
                        </a>
                      </td>
                      <td>
                        <CopyWithMessage
                          inline
                          value={
                            'Patín ' +
                            device.qr +
                            ' - Posible ubicación (P' +
                            (ix + 1) +
                            ', ' +
                            Math.round(
                              moment
                                .duration(moment.utc().diff(moment.utc(x.ts)))
                                .asMinutes()
                            ) +
                            ') > https://www.google.com/maps/search/?api=1&query=' +
                            x.lat +
                            ',' +
                            x.lng
                          }
                          messageCopied='Copiado!'
                          messageTime={2000}
                        >
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{fontSize: 16}}
                          />
                        </CopyWithMessage>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            )}
            <div style={{position: 'relative', marginTop: 20}}>
              <Input.TextArea
                rows={10}
                value={deviceSendMessage}
                onChange={(e) => {
                  setDeviceSendMessage(e.target.value);
                }}
              />
              <br />
              <br />
              <CopyWithMessage
                value={deviceSendMessage}
                messageCopied='Copiado!'
                messageTime={2000}
                style={{
                  top: 10,
                  right: 10,
                  position: 'absolute',
                }}
              >
                <FontAwesomeIcon icon={faCopy} style={{fontSize: 16}} />
              </CopyWithMessage>
            </div>
          </div>
        )}

        {user.can.devices_advanced_ops && (
          <>
            <div className={styles.events}>
              <div className={styles.sectionTitle}>
                Eventos recientes {device.qr}
                {events && (
                  <span
                    onClick={() => loadEvents(200, null, true)}
                    style={{marginLeft: 10}}
                  >
                    <FontAwesomeIcon icon={faSync} spin={loadingEvents} />
                  </span>
                )}
              </div>
              <div className={styles.inner}>
                {events ? (
                  <>
                    <EventsList
                      events={events}
                      showMore={
                        <div>
                          {loadingEvents ? (
                            <div style={{margin: 25, textAlign: 'center'}}>
                              Cargando...
                            </div>
                          ) : (
                            <Button
                              type='secondary'
                              style={{width: '100%', marginTop: 10}}
                              onClick={() => {
                                loadEvents(
                                  200,
                                  events[events.length - 1].id,
                                  false
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPlus}
                                style={{marginRight: 5}}
                              />
                              Cargar más eventos
                            </Button>
                          )}
                        </div>
                      }
                    />
                  </>
                ) : (
                  <div
                    onClick={() => loadEvents(200, null, true)}
                    className={styles.clickLoadArea}
                  >
                    {loadingEvents ? 'Cargando...' : <a>Cargar eventos </a>}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.log}>
              <div className={styles.sectionTitle}>
                MQTT Log {device.qr} (últ 72 h)
                {log && (
                  <span onClick={() => loadLog(500)} style={{marginLeft: 10}}>
                    <FontAwesomeIcon icon={faSync} spin={loadingLog} />
                  </span>
                )}
              </div>
            </div>
            {!isMobile() && (
              <div className={styles.positions}>
                <div className={styles.sectionTitle}>
                  Posiciones del patín {device.qr} (últ{' '}
                  <Input
                    style={{
                      display: 'inline-block',
                      width: 40,
                      textAlign: 'center',
                    }}
                    type='text'
                    value={geoPositionHours}
                    onChange={(e) =>
                      setGeoPositionHours(Math.min(e.target.value, 200))
                    }
                    onKeyDown={(e) =>
                      e.key === 'Enter'
                        ? loadGeopositions(geoPositionHours)
                        : null
                    }
                  />{' '}
                  horas)
                  {geopositions && (
                    <span
                      onClick={() => loadGeopositions(geoPositionHours)}
                      style={{marginLeft: 10}}
                    >
                      <FontAwesomeIcon
                        icon={faSync}
                        spin={loadingGeopositions}
                      />
                    </span>
                  )}
                </div>
                <div className={styles.inner}>
                  {geopositions ? (
                    <MapPositionsList
                      device={device}
                      showSetDeviceInPosition={user.can.devices_advanced_ops}
                      onSetDeviceInPosition={onSetDeviceInPosition}
                      positions={geopositions.map((x) => ({
                        ...x,
                      }))}
                    />
                  ) : (
                    <div
                      onClick={() => loadGeopositions(geoPositionHours)}
                      className={styles.clickLoadArea}
                    >
                      {loadingGeopositions ? (
                        'Cargando...'
                      ) : (
                        <a>Cargar Posiciones del patín </a>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
        {hasIot && user.can.devices_advanced_ops && (
          <div className={styles.mqtt_commands}>
            <MqttSubscriptionList
              topic={`s/c/${device.ref}`}
              title='Commands'
              height={300}
              showResend
              canSend={user.can.send_mqtt}
            />
            <MqttSubscriptionList
              topic={`s/a/17/${device.ref}`}
              title='Answers'
              height={300}
              showTranslations
              canSend={user.can.send_mqtt}
            />
            <MqttSubscriptionList
              topic={`s/h/17/${device.ref}`}
              title='Heartbeats'
              height={300}
              canSend={user.can.send_mqtt}
            />
            {device.data?.iot_firmware_version >= 35004 && (
              <MqttSubscriptionList
                topic={`s/d/${device.ref}`}
                title='Commands Backup'
                height={300}
                showResend
                canSend={user.can.send_mqtt}
              />
            )}
          </div>
        )}
      </div>
      <br />
      <br />
      {user.can.devices_advanced_ops && (
        <div
          style={{
            textAlign: 'center',
            maxWidth: '100%',
            width: 400,
            margin: '20px auto 50px',
          }}
        >
          <QRCode
            value={`https://api.ongrim.com/search/scooter/${device.qr}`}
          />
          <br />
          {device.qr}
        </div>
      )}
    </>
  );
};
ScooterResume.defaultProps = {
  onClose: () => {},
  updating: false,
  openActions: true,
  showMap: false,

  showClose: false,
  buttonCols: 8,
  pollPeriod: 10000,
};
ScooterResume.propTypes = {
  user: PropTypes.object.isRequired,
  defaultDevice: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  updating: PropTypes.bool,
  openActions: PropTypes.bool,
  position: PropTypes.object.isRequired,
  showMap: PropTypes.bool,
  showClose: PropTypes.bool,
  buttonCols: PropTypes.number,
  pollPeriod: PropTypes.number,
};
export default ScooterResume;
