import apiClient from '../api';

export const getAlerts = async ({ status = 'NEW', fleetId }) => {
  try {
    const { data } = await apiClient.incidents.getAlerts({ status, fleetId });
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.tenants.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};

export const setAlertStatus = async ({ id, status = 'NEW', deviceStatusAfterSolve = null }) => {
  try {
    const { data } = await apiClient.incidents.setAlertStatus({
      id,
      status,
      deviceStatusAfterSolve,
    });
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.tenants.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};

export default {
  getAlerts,
  setAlertStatus,
};
