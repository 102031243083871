/* eslint-disable no-tabs */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import moment from 'moment';

import {
  Button, Space, Input, Table,
} from 'antd';

import * as horusService from '../../services/horus';

import stylesLayout from '../../common/layout.module.scss';
import Loading from '../../components/Loading';
import MapPositionsList from '../../components/MapPositionsList';

const DeviceIncidentAnalysisModule = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [qr, setQr] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const updateData = (key, value) => {
    setData((s) => ({
      ...s,
      [key]: value,
    }));
  };

  const loadData = async () => {
    setLoading(true);

    const startUtc = moment(start).utc().format('YYYY-MM-DD HH:mm:ss');
    const endUtc = moment(end).utc().format('YYYY-MM-DD HH:mm:ss');

    const queryHB = `select json_extract(m.data, '$.message') message, m.created_at ts from mqtt_log_2 m, devices d where d.qr='${qr}' and m.data like concat('%',d.ref,'%') and m.created_at between '${startUtc}' and '${endUtc}';`;
    const queryAnswer = `select json_extract(m.data, '$.message') message, m.created_at ts from mqtt_log_answer_2 m, devices d where d.qr='${qr}' and m.data like concat('%',d.ref,'%') and m.created_at between '${startUtc}' and '${endUtc}';`;
    const queryPositions = `select json_extract(gdp.data, '$') message, gdp.lat lat, gdp.lng lng, gdp.created_at ts from geo_position_devices_2 gdp use index (ix_geo_position_devices_2_created_at), devices d where d.qr='${qr}' and gdp.device_id=d.id and gdp.created_at between '${startUtc}' and '${endUtc}';`;

    const [
      rspHB,
      rspAnswers,
      rspPositions,
    ] = await Promise.all([
      horusService.executeQuery(queryHB),
      horusService.executeQuery(queryAnswer),
      horusService.executeQuery(queryPositions),
    ]);
    if (rspHB?.status) {
      console.log(rspHB.data);
      updateData('hb', rspHB.data);
    } else {
      updateData('hb', []);
    }
    if (rspAnswers?.status) {
      console.log(rspAnswers.data);
      updateData('answers', rspAnswers.data);
    } else {
      updateData('answers', []);
    }
    if (rspPositions?.status) {
      console.log(rspPositions.data);
      updateData('positions', rspPositions.data);
    } else {
      updateData('positions', []);
    }
    setLoading(false);
  };

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Análisis de incidente
      </div>
      <div className={stylesLayout.content}>
        <Space>
          <Input
            type="text"
            placeholder="QR..."
            value={qr}
            onChange={(e) => setQr(e.target.value)}
          />
          <Input
            type="text"
            placeholder="YYYY-MM-DD HH:mm"
            value={start}
            onChange={(e) => setStart(e.target.value)}
          />
          <Input
            type="text"
            placeholder="YYYY-MM-DD HH:mm"
            value={end}
            onChange={(e) => setEnd(e.target.value)}
          />
          <Button type="primary" onClick={() => loadData()} disabled={!(qr && start && end)}>
            Cargar
          </Button>
        </Space>
        <div>
          {loading && <div style={{ padding: 50, textAlign: 'center' }}><Loading /></div>}
          {!loading
            && (
              <>
                <div className={stylesLayout.section}>
                  {data.hb && (
                    <>
                      <div className={stylesLayout.sectionHeader}>Heartbeats</div>
                      <Table
                        size="small"
                        pagination={{ hideOnSinglePage: true, defaultPageSize: 500 }}
                        locale={{ emptyText: 'Sin datos' }}
                        columns={[
                          {
                            title: 'Hora',
                            dataIndex: 'ts',
                            key: 'ts',
                            defaultSortOrder: 'ascend',
                            align: 'center',
                            render: (v, r) => (r.ts ? moment.utc(r.ts).local().format('YYYY-MM-DD HH:mm:ss') : ''),
                            sorter: (a, b) => moment(a.ts).format('X') - moment(b.ts).format('X'),
                          },
                          {
                            title: 'Mensaje',
                            key: 'message',
                            align: 'left',
                            dataIndex: 'message',
                            render: (v) => <div style={{ fontFamily: 'monospace', wordBreak: 'break-all', fontSize: 'var(--small)' }}>{v}</div>,
                          },
                        ]}
                        dataSource={data.hb}
                      />
                    </>
                  )}
                </div>
                <div className={stylesLayout.section}>
                  {data.answers && (
                    <>
                      <div className={stylesLayout.sectionHeader}>Mensajes</div>
                      <Table
                        size="small"
                        pagination={{ hideOnSinglePage: true, defaultPageSize: 500 }}
                        locale={{ emptyText: 'Sin datos' }}
                        columns={[
                          {
                            title: 'Hora',
                            dataIndex: 'ts',
                            key: 'ts',
                            defaultSortOrder: 'ascend',
                            align: 'center',
                            render: (v, r) => (r.ts ? moment.utc(r.ts).local().format('YYYY-MM-DD HH:mm:ss') : ''),
                            sorter: (a, b) => moment(a.ts).format('X') - moment(b.ts).format('X'),
                          },
                          {
                            title: 'Mensaje',
                            key: 'message',
                            align: 'left',
                            dataIndex: 'message',
                            render: (v) => <div style={{ fontFamily: 'monospace', wordBreak: 'break-all', fontSize: 'var(--small)' }}>{v}</div>,
                          },
                        ]}
                        dataSource={data.answers}
                      />
                    </>
                  )}
                </div>
                <div className={stylesLayout.section}>
                  {data.positions && (
                    <>
                      <div className={stylesLayout.sectionHeader}>Posiciones</div>
                      <MapPositionsList
                        device={{ qr }}
                        showSetDeviceInPosition={false}
                        positions={data.positions.map((x) => ({
                          ...x,
                          data: x.message,
                          created_at: x.ts,
                        }))}
                      />
                    </>
                  )}
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
};
export default DeviceIncidentAnalysisModule;
