/* eslint-disable max-len */
import apiClient from "../api";
import {executeAndWaitResponse} from "./service";

export const metrics = async (payload) =>
  executeAndWaitResponse(() => apiClient.ingest.metrics(payload));
export const prometheusLoad = async (start, end, step) =>
  executeAndWaitResponse(() =>
    apiClient.ingest.prometheusLoad(start, end, step)
  );

export default {
  metrics,
  prometheusLoad,
};
